<div class="zucchettiMontascaleBg"> ACCESSORI </div>
<div class="zucchettiMontascaleCont">

  <div class="row justify-content-center align-items-center">
    <div class="col-sm-auto text-center">
      <a> FRIGOGASATORE &nbsp;&nbsp;</a>
      <mat-radio-group class="inline-radio-group" [(ngModel)]="form.sections.isFrigoGasatore"
        (ngModelChange)="changeRubinetti(form.sections.isFrigoGasatore)" color="primary">
        <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
        <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <br>

  <div *ngIf="form.sections.accessoriDepuratore?.length > 0">
    <div *ngFor="let acc of form.sections.accessoriDepuratore">
      <hr *ngIf="getDescByTrascId(acc.tipologiaId) == 'GASATORE'">
      <div class="row">
        <div class="col-sm-6">
          <a>{{getDescByTrascId(acc.tipologiaId)}}</a>
        </div>
        <div class="col-sm-6">
          <span class="mr-2"> Quantità </span>
          <app-input-number (onNumChange)="updateQuantita(acc, $event)" [step]="1" [num]="acc.quantita"
            [decimal]="false" [payment]="false" [small]="true" label="">
          </app-input-number>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>

<div class="zucchettiMontascaleBg noteTitle"> NOTE </div>
<div class="zucchettiMontascaleCont">
  <app-note-sh [nota]="form.ordine.note" (onNotaEmit)="form.ordine.note = $event" [maxLength]="255" [minLine]="3">
  </app-note-sh>
</div>