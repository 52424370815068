import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { RemailService } from '../../../services/remail.service';

@Component({
  selector: 'app-nuovidatitecnici',
  templateUrl: './nuovidatitecnici.component.html',
  styleUrls: ['./nuovidatitecnici.component.scss']
})
export class NuovidatitecniciComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand:string;

  constructor
  (
      private remailServ: RemailService,
  ) { }

  ngOnInit(): void {
    this.getVasca();
  }
  
  getVasca(): void {
    this.remailServ.getNuoviDatiTecVasca(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      if (res[0]){
        this.form.sections.nuovidatitecnici = res[0];
      }
    });
  }

  setMuroSx(event): void {
    this.form.sections.nuovidatitecnici.murosx = event.checked;
  }
  setMuroDx(event): void {
    this.form.sections.nuovidatitecnici.murodx = event.checked;
  }
  setMuroSxDx(event): void {
    this.form.sections.nuovidatitecnici.muroSxDx = event.checked;
  }
  setNoMuro(event): void {
    this.form.sections.nuovidatitecnici.noMuro = event.checked;
  }

  isMuroRequired(): boolean {
    const nuoviDati = this.form.sections.nuovidatitecnici;

    const isAnyFieldFilled = [
        nuoviDati.piattol, 
        nuoviDati.piattop,
        nuoviDati.boxl, 
        nuoviDati.boxp, 
    ].some(value => value !== null && value !== undefined && value !== '');

    return isAnyFieldFilled;
  }
}
