import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OpereIdraulicheService } from '../services/opereIdrauliche/opereIdrauliche.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-piatto-doccia-largo',
  templateUrl: './form-piatto-doccia-largo.component.html',
  styleUrls: ['./form-piatto-doccia-largo.component.scss']
})
export class FormPiattoDocciaLargoComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;
  @Input() listPiattoDocciaDim : Trascodifica[];


  listPiattoDocciaColor : Trascodifica [];
  
  constructor(
    private opIdraulicheService: OpereIdraulicheService,
    private formController : FormControllerService
  ) {
  }

  ngOnInit(): void {
		this.opIdraulicheService.findByKey(this.form.idOrder , 'ordineId.equals=', 'opereidrauliches').subscribe(res => {		
				this.form.sections.piattoDoccia = this.formController.mappingPiattoLargoDTOtoForm(res[0] , this.form.sections.piattoDoccia);
				this.listPiattoDocciaColor = this.formController.getTrascodificaByCodice(constants.COLORE_PIATTO_LARGO,this.trascodifiche);
				if(this.form.sections.piattoDoccia.largo.coloreId !== undefined){
					this.changeModel(this.form.sections.piattoDoccia.largo.coloreId);
				}							
		})
  }

  changeModel(event): void {
	  this.listPiattoDocciaDim = this.trascodifiche.filter(tr =>(tr.codice === constants.DIM_PIATTO_LARGO) && (tr.parentId === event));
  }
}
