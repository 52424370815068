<div class="h-100">
    <div class="my-auto">
        <div style="padding:80px 20px 20px;">

            <h4 class="mb-5">Benvenuto {{currentUser.firstName}} {{currentUser.lastName}}</h4>

            <div class="singleButtonDash" matRipple routerLink="/new-estimate">
                <div>
                    <mat-icon mat-list-icon>edit</mat-icon>
                </div>
                <div>
                    Nuovo preventivo
                </div>
            </div>   

            <div class="singleButtonDash" matRipple routerLink="/estimate-list">
                <div>
                    <mat-icon>list</mat-icon>
                </div>
                <div>
                    Lista preventivi
                </div>
            </div>
        </div>
    </div>
</div>