import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivationExtraDepuratoreComponent } from './ariel/depuratore/servizi_depuratore.component';
import { ActivationExtraAllarmeComponent } from './mondial/allarme/servizi_allarme.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaintableModule } from 'paintablejs/angular';
import { MaterialModule } from '../material/material.module';
import { PrintDepuratoreServicesComponent } from './ariel/depuratore/print-depuratore-services/print-depuratore-services.component';
import { PrintAllarmeServicesComponent } from './mondial/allarme/print-allarme-services/print-allarme-services.component';

@NgModule({
  declarations: [
    ActivationExtraDepuratoreComponent, 
    ActivationExtraAllarmeComponent, 
    PrintDepuratoreServicesComponent,
    PrintAllarmeServicesComponent
  ],
  imports: [
    CommonModule,
		MatFormFieldModule,
		MaterialModule,
		PaintableModule,
		SharedModule,
  ]
})
export class ActivationServiceModule { }
