<div class="text-center lorenBg">
    DATI TECNICI ATTUALI
</div>
<div class="lorenCont">
    <div class="row mb-3">
        <div class="col-md-4 col-6">
            <div class="smallfield">
                <span class="mr-2">{{constants.LABEL_DAT_TEC_PIANO_N}}</span>
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.pianointervento = $event"
                    [step]="1"
                    [num]="form.sections.datiTecnici.pianointervento"
                    [decimal]="false"
                    [payment]="false"
                    [small]="true"
                    label="">
                </app-input-number>
            </div>
        </div>
        <div class="col-md-4 col-6">
            <mat-checkbox class="full-width d-block mb-2"
                          color="primary"
                          [(ngModel)]="form.sections.datiTecnici.scale">
                {{constants.LABEL_DAT_TEC_SCALE}}
            </mat-checkbox>
            <mat-checkbox class="full-width d-block mb-2"
                          color="primary"
                          [(ngModel)]="form.sections.datiTecnici.ascensore">
                {{constants.LABEL_DAT_TEC_ASCENSORE}}
            </mat-checkbox>
        </div>
        <div class="col-md-4 col-6">
            <div class="mb-1">{{constants.LABEL_DAT_TEC_OLD_CUCINA}}</div>
            <mat-radio-group color="primary"  aria-label="Select an option" [(ngModel)]="form.sections.datiTecnici.smontaggioCucina">
                <mat-radio-button class="mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <span class="undo material-icons" matTooltip="Cancella selezione"
                *ngIf="form.sections.datiTecnici.smontaggioCucina"
                (click)="form.sections.datiTecnici.smontaggioCucina = null">
             backspace
        </span>
        </div>
    </div>
</div>