<div [ngClass]="{ 'anagraficaContainer': brand === 'bagni' || brand === 'remail',
	               'cristalBoxBackground': brand ===  'cristalbox'}" class="mb-4">
	<div class="text-center text-success  mb-4" [ngClass]="{ 'anagraficaTitle': brand === 'bagni' || brand === 'remail',
	               'cristalBoxAnagraficaTitle': brand ===  'cristalbox'}">
		<a>PROMOZIONE</a></div>
	<div class="row originalRow">
    
            <div class="col-4">
                <mat-form-field class="full-width"> 
					<mat-label>Seleziona promozione</mat-label> 
					<mat-select [(ngModel)]="this.form.ordine.promoId" (selectionChange)="onChange($event)"> 
					<mat-option [value]="null">Seleziona promozione</mat-option> 
					<mat-option *ngFor="let tipo of availablePromo" [value]="tipo.id">{{tipo.descrizione}}</mat-option>
                </mat-select> 
				<mat-hint class="text-danger" *ngIf="!this.form.ordine.promoId">Promozione campo obbligatorio</mat-hint> 
                </mat-form-field>
            </div>
            <div class="col-4 align-self-center">
            </div>
	</div>

	<div class=""
		*ngIf="this.form.ordine.promoId && availablePromo.length > 0 && availablePromo">
		<hr />
		<div class="littleFont">
			<div class="row originalRow">
				<div class="col-5">
					Promozione selezionata: {{returnPromoDesc(this.form.ordine.promoId)}}
				</div>
				<div class="col-2">
					<button [ngClass]="{ 'btnMedium': brand === 'bagni' || brand === 'remail', 'btnMediumCristalBox': brand ===  'cristalbox' }" class="" mat-flat-button (click)="this.form.ordine.promoId = null">Rimuovi</button>
				</div>
			</div>
			<hr/>
			<div style="margin: 15px;">
				<a style="font-weight: 600;"> {{constants.NOTA_IMP_ELETTRICO_1}} </a><br>
				<a style="font-weight: 500; color:red"> {{constants.NOTA_IMP_ELETTRICO_2}} </a>
			</div>
		</div>
	</div>
</div>
