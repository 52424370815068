<div *ngIf="isLoaded" class="lorenBg">PRODOTTO</div>
<div class="lorenCont">
    <div class="container">
        <div class="row originalRow mb-3">
            <div class="col-md-5 align-self-center customBox">
                <mat-form-field class="w-100 formField selectCustom" appearance="outline">
                    <mat-label>Seleziona {{constants.LABEL_LOREN_CUCINA_MODELLO}}</mat-label>
                    <mat-select [(ngModel)]="form.sections.cucina.modelloCucinaId" (click)="searchCucina = ''">
                        <div class="searchInSelect">
                            <input type="text" [(ngModel)]="searchCucina" placeholder="cerca" />
                        </div>
                        <mat-option *ngFor="let modello of modelliCucina" [value]="modello.id"
                            [hidden]="includeModello(modello)">{{modello.descrizione}}</mat-option>
                    </mat-select>
                    <mat-hint class="text-danger"
                        *ngIf="!form.sections.cucina.modelloCucinaId">{{constants.LABEL_LOREN_CUCINA_MODELLO}}
                        obbligatorio</mat-hint>
                </mat-form-field>
            </div>

            <div class="col-md-1 align-self-center customBox"></div>

            <div class="col-md-5 align-self-center customBox">
                <mat-form-field class="w-100" appearance="standard">
                    <mat-label>{{constants.LABEL_LOREN_CUCINA_RIF_ORDINE}}</mat-label>
                    <input matInput type="text" placeholder="Riferimento ordine cucina"
                        [(ngModel)]="form.sections.cucina.rifOrdineCucina" required>
                    <mat-hint class="text-danger" *ngIf="!form.sections.cucina.rifOrdineCucina">Riferimento ordine
                        richiesto</mat-hint>
                </mat-form-field>
            </div>

            <div class="col-md-12 align-self-center customBox">
                <a style="font-weight: 500; color:red"> {{constants.NOTA_DATI_TECH_CUCNA}} </a>
            </div>
        </div>
    </div>
</div>

<app-omaggio-sh [form]="form" [trascodifiche]="trascodifiche" [brand]="'loren'" [prodotto]="'cucina'"></app-omaggio-sh>
<hr />
<div *ngIf="isLoaded" class="lorenBg noteTitle">NOTE</div>
<div class="lorenCont">
    <app-note-sh [nota]="form.sections.cucina.note" (onNotaEmit)="form.sections.cucina.note = $event" [maxLength]="1000"
        [minLine]="3">
    </app-note-sh>
</div>