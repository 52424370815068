<div class="row mt-2" *ngFor="let responsabile of form.sections.responsabileList; let i = index">
        <div class="col-12 col-md-2 d-flex align-items-center">
          <strong>Responsabile ({{ i + 1 }})</strong>
        </div>
        <div class="col-12 col-md-6">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Nome</mat-label>
            <input matInput [(ngModel)]="responsabile.nome">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Telefono</mat-label>
            <input matInput [(ngModel)]="responsabile.tel">
          </mat-form-field>
        </div>

        <div class="col-12 d-flex flex-column flex-md-row justify-content-between gap-3 mt-2">
          <small class="text-muted text-center text-md-start">
            Indicare il referente per la gestione allarmi COGNOME/NOME e RECAPITO TELEFONICO
          </small>
        <div class="d-flex justify-content-center justify-content-md-end gap-2">
          <button mat-flat-button color="primary" [disabled]="checkValidElement(i)" (click)="addResponsabile()">Aggiungi</button>
          <button mat-flat-button color="warn" (click)="removeResponsabile(responsabile, i)">Cancella</button>
        </div>
      </div>
</div>