import { Injectable } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';

@Injectable({
  providedIn: 'root'
})
export class FinanziamentoPrintService {

  currentY = 0;
  currentPage = 1;
  coordinates = "";
  logo: any;
  brand: any;

  constructor() { }

  writeLine(
    doc: any,
    fontSize: number,
    y: number,
    text: string,
    left: number
  ): void {
    doc.setFontSize(fontSize);
    doc.text(text, left, y);
  }

  writeLongText(
    doc: any,
    fontSize: number,
    y: number,
    text: string,
    left: number,
    ordine?: any,
    img?: string,
    title?: string,
    currentPage?: number
  ): number {
    const pageWidth = doc.internal.pageSize.getWidth();
    const textMargin = left; 
    const maxWidth = pageWidth - 2 * textMargin; 
    doc.setFontSize(fontSize);
    const lines = doc.splitTextToSize(text, maxWidth);
    for (let i = 0; i < lines.length; i++) {
      if (y > 280) {
        this.addNewPage(doc, true, img, ordine, currentPage, title);
      }
      doc.setFontSize(fontSize);
      y = this.returnCurrentY();
      doc.text(lines[i], left, y);
      y = this.addLine(y);
      this.setCurrentY(y);
    }
    return y;
  }

  writeLongTextHeaderSection(
    doc: any,
    fontSize: number,
    y: number,
    text: string,
    left: number,
    color: number[]
  ): number {
      const pageWidth = doc.internal.pageSize.getWidth();
      const textMargin = left; // Margine sinistro del testo
      const maxWidth = pageWidth - 2 * textMargin; // Larghezza massima per il testo
      // const lineHeight = fontSize * 0.35;  // L'altezza della riga basata sulla dimensione del font
      doc.setFontSize(fontSize);
      doc.setTextColor(color[0],color[1],color[2]);
      const lines = doc.splitTextToSize(text, maxWidth);
      y = this.returnCurrentY();
      doc.text(lines, left, y);
      y = this.addLine(y);
    return y;
  }
  
  setCurrentPage(page: number): void {
    this.currentPage = page;
  }

  returnCurrentPage(): number {
    return this.currentPage;
  }

  writeTable(
    doc: any,
    y: number,
    text: string,
    left: number,
    currentPage?: number,
    xSize?: number // larghezza fissa, poi il testo va a capo
  ): number {
    const lines = doc.splitTextToSize(text, xSize);
    let baseY = y;
  
    for (let i = 0; i < lines.length; i++) {
      // Verifica se la riga attuale supera il margine inferiore della pagina
      if (y + 10 > 280) {
        // Aggiungi una nuova pagina se la riga non ci sta
        currentPage = currentPage + 1;
        doc.addPage();
        y = 28; // Riporta il cursore all'inizio della pagina
      }
  
      doc.text(lines[i], left, y);
      y += 5;
    }
  
    this.currentPage = currentPage;
    this.setCurrentY(y);
    return baseY;
  }
  
  addLine(
    value: number,
    doc: any | undefined = undefined,
    form: any | undefined = undefined,
    img: string | undefined = undefined,
    title: string | undefined = undefined
  ): number {
    if (value > 280 && doc) {
      this.addNewPage(doc, true, img, form, this.currentPage, title);
      value = 28;
    } else {
      value += 6;
    }
    return value;
  }

  addLinePrivacy(value: number): number {
    return value + 3;
  }

  formatDate(date: Date): string {
    return new Date(date).toLocaleDateString("it-IT");
  }

  createHeader(doc, image, title, brand?:string, product?:string): void {
    const pageWidth = doc.internal.pageSize.getWidth();
    
    if(brand && ( brand.toLowerCase() === constants.BRAND_CRISTALBOX || brand.toLowerCase() === constants.BRAND_ZUCCHETTI)){
      this.brand = brand;
      if( brand.toLowerCase() === constants.BRAND_CRISTALBOX || brand.toLowerCase() === constants.BRAND_ZUCCHETTI){
        doc.addImage(image, "PNG", 10, 5, 30, 12);
        this.writeLine(doc, 12, 13, title, 42);
      }
    }else{
      // se non è un logo di quelli "estesi", ripulisci this.brand se no rende tutti i loghi estesi
      // essendo un service non perde lo stato in quanto singleton 
      this.brand = null;
      doc.addImage(image, "PNG", 10, 5, 15, 15);
      this.writeLine(doc, 12, 13, title, 28);
    }
    
    doc.setDrawColor(210, 210, 210);
    doc.line(10, 23, pageWidth - 10, 23);
  }

  createDocumentInfos(
      doc,
      y,
      pagamento,
      indice,
      ordine: any,
      img: string,
      title: string,
      currentPage: number,
      color: number[]
  ): number {
    doc.setTextColor("#000000");
    this.writeLine(doc, 9, y, "G.D.L. S.p.A.", 15);
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );
    this.writeLine(doc, 9, y, "Via Orbetello, 54/D", 15);
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );
    this.writeLine(doc, 9, y, "10148 TORINO", 15);
    (y = this.addLine(y)),
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75";
    this.writeLine(doc, 9, y, "tel. 011 22.48.515 / fax 011 22.95.425", 15);
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );
    this.writeLine(doc, 9, y, "info@gdlspa.it", 15);
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );
    this.writeLine(doc, 9, y, "IBAN: IT 30 J 02008 01119 000100689984", 15);
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );

    this.setCurrentY(y);
    y = 38;

    this.writeLine(doc, 9, y, "Contratto n°: " + ordine.idordine, 120);
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );
    this.writeLine(doc, 9, y, "Consulente: " + ordine.venditoreNome, 120);
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );
    this.writeLine(
      doc,
      9,
      y,
      "Data: " + this.formatDate(ordine.dataordine),
      120
    );
    y = this.addLine(
      y,
      doc,
      ordine,
      "/assets/img/loghi/logo-bagni-italiani.jpg",
      "Modulo d'ordine Bagni Italiani Help75"
    );

    y = this.returnCurrentY();

    return y;
  }

  setCurrentY(y): void {
    this.currentY = y;
  }
  
  returnCurrentY(): number {
    return this.currentY;
  }

  writeAnagrafica(doc, anagrafica, y): number {

    doc.setTextColor(0,0,0);

    this.writeLine(doc, 10, y, "CLIENTE", 15);
    y = this.addLine(y);
    if (anagrafica.nome) {
      this.writeLine(doc, 9, y, anagrafica.nome + " " + anagrafica.cognome, 15);
      y = this.addLine(y);
    }
    if (anagrafica.email) {
      this.writeLine(doc, 9, y, anagrafica.email, 15);
      y = this.addLine(y);
    }
    if (anagrafica.tel1) {
      this.writeLine(doc, 9, y, anagrafica.tel1, 15);
      y = this.addLine(y);
    }
    if (anagrafica.tel2) {
      this.writeLine(doc, 9, y, anagrafica.tel2, 15);
      y = this.addLine(y);
    }
    if (anagrafica.cf) {
      this.writeLine(doc, 9, y, "C.F./P.Iva: " + anagrafica.cf, 15);
      y = this.addLine(y);
    }
    this.setCurrentY(y);
    return y;
  }

  writeIndirizzo(doc, anagrafica, y): number {
    // scrittura Indirizzo
    const pageWidth = doc.internal.pageSize.getWidth();
    this.writeLine(doc, 10, y, "INDIRIZZO", 120);
    y = this.addLine(y);
    if (anagrafica.citta) {
      this.writeLine(
        doc,
        9,
        y,
        anagrafica.citta +
          ", " +
          anagrafica.indirizzo +
          ", " +
          anagrafica.numero +
          ", " +
          anagrafica.cap +
          ", (" +
          anagrafica.provincia +
          ")",
        120
      );
      y = this.addLine(y);
    }
    if (anagrafica.ztl) {
      this.writeLine(doc, 9, y, "Zona traffico limitato", 120);
      y = this.addLine(y);
    }

    // DIVERSO INDIRIZZO INSTALLAZIONE
    if (anagrafica.diversoind && anagrafica.indirizzoinst) {
      // aggiunge riga orizzontale
      doc.setDrawColor(210, 210, 210);
      doc.line(100, y, pageWidth - 10, y);
      y = this.addLine(y);
      this.writeLine(doc, 10, y, "Indirizzo installazione:", 120);
      y = this.addLine(y);
      this.writeLine(doc, 9, y, anagrafica.indirizzoinst, 120);
      // y = this.addLine(y);
    }
    return y;
  }

  writeFooterHeader(doc, y, ordine): void {
    this.writeLine(
      doc,
      8,
      y,
      "G.D.L. S.p.A. (con sede in Via Orbetello, 54/D, 10148 Torino, CF: 10062800015, info@gdlspa.it, www.remail.it, iscritta alla Camera di Commercio",
      10
    );
    y = this.addLine(y);
    this.writeLine(
      doc,
      8,
      y,
      "di Torino, n° registro imprese TO 1102436) qui rappresentata dall'agente - " +
        ordine.venditoreNome +
        " - all'uopo autorizzato e munito di specifici poteri rappresentativi.",
      10
    );
    y = this.addLine(y);
    this.setCurrentY(y);
  }

  addHorizontalRGBLine(
    doc,
    y,
    pagamento,
    indice,
    ordine: any,
    img: string,
    title: string,
    currentPage: number,
    color: number[]
): number {
  if (y > 280 && doc) {
    this.addNewPage(doc, true, img, ordine, this.currentPage, title);
    y = 28;
    this.setCurrentY(y);
  }
  const pageWidth = doc.internal.pageSize.getWidth();
  doc.setDrawColor(color[0], color[1], color[2]);
  doc.line(10, y, pageWidth - 10, y);
  return y;
}

  addHorizontalGreyLine(doc, y): void {
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setDrawColor(210, 210, 210);
    doc.line(10, y, pageWidth - 10, y);
  }

  writeFooterPage(doc, idOrder, page, totalPages, noPrint?:boolean): void {
    const pageHeight = doc.internal.pageSize.getHeight(); // Ottieni l'altezza della pagina
    const margin = 5; // Imposta il margine
    
    if(!noPrint){
      this.writeLine(
        doc,
        8,
        pageHeight - margin,
        "Contratto n°: " + idOrder + " - Pagina " + doc.internal.getNumberOfPages(),
        10
      );
    }
  }

  returnCoordinates(): string {
    return this.coordinates;
  }

  addNewPage(
    doc,
    fromTerms?: boolean,
    img?: string,
    ordine?: any,
    currentPage?: number,
    title?: string
  ): number {
    let y;
    if (fromTerms) {
      doc.addPage();
      let numPage = doc.internal.getNumberOfPages();    
      this.currentPage = numPage;
      this.setCurrentPage(this.currentPage);
      this.createHeader(doc, img, title);
      y = 28;
      y = this.addLine(y);
      this.setCurrentY(y);
      this.writeFooterPage(doc, ordine, this.currentPage, 6);
    } else {
      doc.addPage();
    }
    return y;
  }

}
