<div id="accordionThree">
    <div>
        <div id="headingOne" class="text-center {{classBg}}">
            <h5 class="mb-0 row">
                <div class="col-6 text-right">
                    {{brand === 'bagniHelp75' ? 'RUBINETTERIA' : 'NUOVA RUBINETTERIA'}}
                    <mat-hint class="text-danger">*</mat-hint>
                </div>
                <div class="col-6 text-left"><mat-radio-group labelPosition="after" class="inline"
                        color="{{brand === 'bagno-pratico' ? 'accent' : 'primary'}}"
                        [(ngModel)]="optionNuovaRubinetteria.selected" (ngModelChange)="showDiv($event)">
                        <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
                        <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
                    </mat-radio-group></div>
            </h5>
        </div>

        <div *ngIf="isVisible" id="collapseThree" aria-labelledby="headingOne" data-parent="#accordionThree">
            <div>
                <div class="{{classContent}}">
                    <div class="">
                        <div [ngClass]="{'mb-2':form.sections.nuoviProdotti.nuovaRubinetteria.code}">
                            <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                (ngModelChange)="changeSerie($event); form.sections.nuoviProdotti.nuovaRubinetteria = {'docciaType': {},astaComby:{},colonne:{},miscelatoreEst:{}}"
                                [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.id">

                                <mat-radio-button class="mb-2 mr-2" [value]="tipoSerie.id"
                                    *ngFor="let tipoSerie of tipiSerieRub">
                                    {{tipoSerie.descrizione}}
                                </mat-radio-button>
                                <span class="undo material-icons" matTooltip="Cancella selezione"
                                    *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.id"
                                    (click)="form.sections.nuoviProdotti.nuovaRubinetteria = {'docciaType': {},astaComby:{},colonne:{},miscelatoreEst:{}}; resetNuovaRub($event)">
                                    backspace
                                </span>
                            </mat-radio-group>
                        </div>
                        <div class="">
                            <div *ngIf="viewSerieLime">
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="lavaboLime.id"
                                            *ngFor="let lavaboLime of subLavaboLime">
                                            {{lavaboLime.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId = null;">
                                        backspace
                                    </span>
                                </div>

                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="bidetLime.id"
                                            *ngFor="let bidetLime of subBidetLime">
                                            {{bidetLime.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId = null;">
                                        backspace
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="viewSerieTango">
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="lavaboTango.id"
                                            *ngFor="let lavaboTango of subLavaboTango">
                                            {{lavaboTango.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId = null;">
                                        backspace
                                    </span>
                                </div>
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="bidetTango.id"
                                            *ngFor="let bidetTango of subBidetTango">
                                            {{bidetTango.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId = null;">
                                        backspace
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="viewSerieStick">
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="lavaboStick.id"
                                            *ngFor="let lavaboStick of subLavaboStick">
                                            {{lavaboStick.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId = null;">
                                        backspace
                                    </span>
                                </div>
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="bidetStick.id"
                                            *ngFor="let bidetStick of subBidetStick">
                                            {{bidetStick.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId = null;">
                                        backspace
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="viewSerieLight && brand !== 'bagniHelp75'">
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.rubbicolId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="colLight.id"
                                            *ngFor="let colLight of colListLight">
                                            {{colLight.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="smallFont text-danger"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.id && !form.sections.nuoviProdotti.nuovaRubinetteria.rubbicolId">
                                        Selezione obbligatoria
                                    </div>

                                </div>
                                <div>
                                    <hr />
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="lavaboLight.id"
                                            *ngFor="let lavaboLight of subLavaboLight">
                                            {{lavaboLight.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId = null;">
                                        backspace
                                    </span>

                                </div>
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="bidetLight.id"
                                            *ngFor="let bidetLight of subBidetLight">
                                            {{bidetLight.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <div *ngIf="viewSerieGrohe">
                                <div *ngIf="brand === 'bagniHelp75' || brand === 'bagno-pratico'">
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="lavaboGrohe.id"
                                            *ngFor="let lavaboGrohe of subLavaboGrohe">
                                            {{lavaboGrohe.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId = null;">
                                        backspace
                                    </span>
                                </div>

                                <div *ngIf="brand === 'bagniHelp75'  || brand === 'bagno-pratico'">
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="bidetGrohe.id"
                                            *ngFor="let bidetGrohe of subBidetGrohe">
                                            {{bidetGrohe.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId = null;">
                                        backspace
                                    </span>
                                </div>

                                <div *ngIf="brand !== 'bagniHelp75' && brand !== 'bagno-pratico'">
                                    <mat-checkbox [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.lavabo"
                                        class="inline mb-2 mr-2" color="primary"
                                        (change)="!form.sections.nuoviProdotti.nuovaRubinetteria.lavabo"
                                        [checked]="form.sections.nuoviProdotti.nuovaRubinetteria.lavabo">Lavabo
                                    </mat-checkbox>

                                    <mat-checkbox [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.bidet"
                                        class="inline mb-2 mr-2" color="primary"
                                        (change)="!form.sections.nuoviProdotti.nuovaRubinetteria.bidet"
                                        [checked]="form.sections.nuoviProdotti.nuovaRubinetteria.bidet">Bidet
                                    </mat-checkbox>

                                    <mat-checkbox [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.vasca"
                                        class="inline mb-2 mr-2" color="primary"
                                        (change)="!form.sections.nuoviProdotti.nuovaRubinetteria.vasca"
                                        [checked]="form.sections.nuoviProdotti.nuovaRubinetteria.vasca">Vasca
                                    </mat-checkbox>

                                    <mat-checkbox [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.doccia"
                                        class="inline mb-2 mr-2" color="primary"
                                        (change)="!form.sections.nuoviProdotti.nuovaRubinetteria.doccia"
                                        [checked]="form.sections.nuoviProdotti.nuovaRubinetteria.doccia">Doccia
                                    </mat-checkbox>
                                </div>
                                <div class="smallFont text-danger"
                                    *ngIf="brand === 'bagni' && form.sections.nuoviProdotti.nuovaRubinetteria.id && !(form.sections.nuoviProdotti.nuovaRubinetteria.lavabo || form.sections.nuoviProdotti.nuovaRubinetteria.bidet 
                                || form.sections.nuoviProdotti.nuovaRubinetteria.doccia || form.sections.nuoviProdotti.nuovaRubinetteria.vasca)">
                                    Selezione obbligatoria
                                </div>
                            </div>

                            <div *ngIf="viewSerieCristPrime">
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="lavaboCristPrime.id"
                                            *ngFor="let lavaboCristPrime of subLavaboCristPrime">
                                            {{lavaboCristPrime.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId = null;">
                                        backspace
                                    </span>
                                </div>

                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="bidetCristPrime.id"
                                            *ngFor="let bidetCristPrime of subBidetCristPrime">
                                            {{bidetCristPrime.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId = null;">
                                        backspace
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="viewSerieCristElevation">
                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="lavaboCristElev.id"
                                            *ngFor="let lavaboCristElev of subLavaboCristElevation">
                                            {{lavaboCristElev.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId = null;">
                                        backspace
                                    </span>
                                </div>

                                <div>
                                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                                        aria-label="Tipo"
                                        [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId">
                                        <mat-radio-button class="mb-2 mr-2" [value]="bidetCristElev.id"
                                            *ngFor="let bidetCristElev of subBidetCristElevation">
                                            {{bidetCristElev.descrizione}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId = null;">
                                        backspace
                                    </span>
                                </div>
                            </div>

                            <div class="smallFont text-danger"
                                *ngIf="brand !== 'bagni' && form.sections.nuoviProdotti.nuovaRubinetteria.id && !(form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId || form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId)">
                                Selezione obbligatoria
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="">
                            <div *ngIf="viewSerieLime || viewSerieCristPrime">
                                <hr />
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                    (ngModelChange)="resetdocciaType($event)">
                                    <mat-radio-button class="mb-2 mr-2" [value]="modelLime.id"
                                        *ngFor="let modelLime of modelListLime">
                                        {{modelLime.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieTango">
                                <hr />
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                    (ngModelChange)="resetdocciaType($event)">
                                    <mat-radio-button class="mb-2 mr-2" [value]="modelTango.id"
                                        *ngFor="let modelTango of modelListTango">
                                        {{modelTango.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieCristElevation">
                                <hr />
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                    (ngModelChange)="resetdocciaType($event)">
                                    <mat-radio-button class="mb-2 mr-2" [value]="modelCristElev.id"
                                        *ngFor="let modelCristElev of modelListCristElevation">
                                        {{modelCristElev.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieStick">
                                <hr />
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                    (ngModelChange)="resetdocciaType($event)">
                                    <mat-radio-button class="mb-2 mr-2" [value]="modelStick.id"
                                        *ngFor="let modelStick of modelListStick">
                                        {{modelStick.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieLight">
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                    (ngModelChange)="resetdocciaType($event)">
                                    <mat-radio-button class="mb-2 mr-2" [value]="modelLight.id"
                                        *ngFor="let modelLight of modelListLight">
                                        {{modelLight.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.modelId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieLime">
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatoreId">
                                    <mat-radio-button class="mb-2 mr-2" [value]="miscLime.id"
                                        *ngFor="let miscLime of miscListLime">
                                        {{miscLime.descrizione}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieTango">
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatoreId">
                                    <mat-radio-button class="mb-2 mr-2" [value]="miscTango.id"
                                        *ngFor="let miscTango of miscListTango">
                                        {{miscTango.descrizione}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieStick">
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatoreId">
                                    <mat-radio-button class="mb-2 mr-2" [value]="miscStick.id"
                                        *ngFor="let miscStick of miscListStick">
                                        {{miscStick.descrizione}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieLight">
                                <hr />
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatoreId">
                                    <mat-radio-button class="mb-2 mr-2" [value]="miscLight.id"
                                        *ngFor="let miscLight of miscListLight">
                                        {{miscLight.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatoreId"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieCristPrime">
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatoreId">
                                    <mat-radio-button class="mb-2 mr-2" [value]="miscCrist.id"
                                        *ngFor="let miscCrist of miscCristinaPrime">
                                        {{miscCrist.descrizione}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="viewSerieCristElevation">
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatoreId">
                                    <mat-radio-button class="mb-2 mr-2" [value]="miscCrist.id"
                                        *ngFor="let miscCrist of miscCristinaElevation">
                                        {{miscCrist.descrizione}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div class="col-lg-6 mt-4">
                            <mat-checkbox class="mr-2"
                                [checked]="form.sections.nuoviProdotti.nuovaRubinetteria.astasingola"
                                (change)="setAstaSingola($event)" color="primary">
                                Asta singola
                            </mat-checkbox>
                            <mat-checkbox class="mr-2"
                                [checked]="form.sections.nuoviProdotti.nuovaRubinetteria.astadoppia"
                                (change)="setAstaDoppia($event)" color="primary">
                                Asta doppia
                            </mat-checkbox>
                        </div>
                    </div>
                    <div>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-2">
                                    Asta doccia doppia con mix integrato Comby
                                </div>
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.astaComby.id">
                                    <mat-radio-button class="mb-2 mr-2" [value]="tipoAsta.id"
                                        *ngFor="let tipoAsta of rubTipiAsta">
                                        {{tipoAsta.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.astaComby.id"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.astaComby = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-2">
                                    Colonne
                                </div>
                                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                                    [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.colonne.id">
                                    <mat-radio-button class="mb-2 mr-2" [value]="tipoCol.id"
                                        *ngFor="let tipoCol of rubTipiCol">
                                        {{tipoCol.descrizione}}
                                    </mat-radio-button>

                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                        *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.colonne.id"
                                        (click)="form.sections.nuoviProdotti.nuovaRubinetteria.colonne = {}">
                                        backspace
                                    </span>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div>
                        <div class="mb-2">Miscelatore est.</div>
                        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                            (ngModelChange)="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst = {'model':{}}"
                            [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst.typeId">
                            <mat-radio-button class="mb-2 mr-2" [value]="miscEst.id"
                                *ngFor="let miscEst of tipiMiscEst">
                                {{miscEst.descrizione}}
                            </mat-radio-button>

                            <span class="undo mr-3 material-icons" matTooltip="Cancella selezione"
                                *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst.typeId"
                                (click)="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst = {'model':{}}">
                                backspace
                            </span>
                        </mat-radio-group>

                        <mat-radio-group *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst.typeId"
                            labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                            [(ngModel)]="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst.model.id">
                            <div class="d-inline-block">
                                <mat-radio-button class="mb-2 mr-2" [value]="serieMiscEst.id"
                                    *ngFor="let serieMiscEst of serieTipiMiscEst">
                                    {{serieMiscEst.descrizione}}
                                </mat-radio-button>
                            </div>

                            <span class="undo material-icons" matTooltip="Cancella selezione"
                                *ngIf="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst.model.id"
                                (click)="form.sections.nuoviProdotti.nuovaRubinetteria.miscelatoreEst.model = {}">
                                backspace
                            </span>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="brand === 'bagno-pratico' && isVisible">
        <div class="{{classBg}} noteTitle"> NOTE </div>
        <div class="{{classContent}}">
            <app-note-sh [nota]="form.sections.nuoviProdotti.nuoviSanitari.notelibere"
                (onNotaEmit)="form.sections.nuoviProdotti.nuoviSanitari.notelibere = $event" [maxLength]="255"
                [minLine]="3">
            </app-note-sh>
        </div>
    </div>