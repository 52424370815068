import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { BrandService } from '../../../services/brand/brand.service';
import { AllegatoService } from '../../../services/allegato/allegato.service';
import { OrdersService } from '../../../services/orders/orders.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { Trascodifica } from '../../classes/trascodifica';
import { Allegato } from '../../classes/allegato';
import { Ordine } from '../../classes/ordine';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { constants } from '../../classes/constants';
import { environment } from 'src/environments/environment';

declare var jQuery: any;

@Component({
    selector: 'app-upload-document',
    templateUrl: './upload-document.component.html',
    styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnInit {

    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    fileAttr;
    allegato: Allegato;
    id;
    gdl_id;
    fileBase64;
    selectedFiles: any = [];
    displayedColumns: string[] = ['name', 'tipoDescrizione', 'actions'];
    dataSource: MatTableDataSource<any>;
    listTypeDoc: Trascodifica[];

    loading: boolean;
    allegati: Allegato[];
    isDocCli: boolean = false;
    isFotoAmb: boolean = false;
    isDocCess: boolean = false;
    isDocFin: boolean = false;
    checkFin: boolean = false;
    checkCess: boolean = false;

    showBuffer: boolean = false;
    ordine: Ordine;
    newAllegati: any[];
    isApiV3 = environment.isV3;

    public constants = constants;

    constructor(
        private brandService: BrandService,
        private route: ActivatedRoute,
        private allegatoService: AllegatoService,
        private snackBar: MatSnackBar,
        private orderService: OrdersService,
        private router: Router, 
        private paymentService: PaymentService) {
    }

    ngOnInit(): void {
        this.id = Number(this.route.snapshot.paramMap.get('id'));
        this.gdl_id = this.route.snapshot.paramMap.get('gdlid');
        this.allegato = new Allegato();
        this.newAllegati = [new Allegato()];
        this.brandService.getDataByCodice(constants.CODICE_TIPO_DOC).subscribe(res => this.listTypeDoc = res.filter(r => r.descrizione !== constants.TYPE_DOC_CERTIFICATO_FIRMA && r.descrizione !== constants.TYPE_DOC_CERTIFICATO_FIRMA_ATT_SERV && r.descrizione !== constants.TYPE_MODULO_ATTIVAZIONE_SERVIZI && r.descrizione !== constants.TYPE_DOC_FINANZIAMENTO_STEP ));
        this.getAllegati(this.id);
        this.getOrder();
    }

    save() {
        this.showBuffer = true;
        this.allegato.ordineId = this.id;
        this.allegato.gdlId = this.gdl_id;
        this.checkAndSave();
    }

    checkAndSave() {
        this.selectedFiles.forEach((f, i) => {
            this.showBuffer = true;
            f.tipoId = this.allegato.tipoId;
            f.ordineId = this.allegato.ordineId;
            f.gdlId = this.allegato.gdlId;
            this.allegato = JSON.parse(JSON.stringify(f));
            const find = this.listTypeDoc.findIndex(tr => tr.id === this.allegato.tipoId);
            this.allegato.name = this.listTypeDoc[find].note + '_' + this.ordine.idordine + '_' + f.fileAttr;
            f.loading = true;
            this.allegatoService.save(this.allegato).subscribe(res => {
                f.loading = false;
                if (res.path !== undefined && res.path !== '') {
                    this.snackBar.open('Documento ' + f.fileAttr + ' caricato con successo', '', {
                        duration: 5000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                }
                if ((i + 1) === this.selectedFiles.length) {
                    this.selectedFiles = [];
                    this.allegato = new Allegato();
                    this.fileAttr = '';
                    this.getAllegati(this.id);
                    this.showBuffer = false;
                }
            });
        });
    }

    selectFile(event) {
        this.selectedFiles = [...event.target.files];
        if (this.selectedFiles.length > 0) {
            this.selectedFiles.forEach(f => {
                f.fileAttr = f.name;
                this.readFileContent(f).then(res => {
                    f.content = res.split(',')[1];
                });
            })
        }
    }

    removeFile(i: number) {
        this.selectedFiles.splice(i, 1);
    }


    readFileContent(file: File): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!file) {
                resolve('');
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                const text = reader.result;
                resolve(text);

            };

            reader.readAsDataURL(file);
        });
    }


    checkTypeDocument(id: number): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const trascodSel = this.listTypeDoc.filter(
                tr => tr.id === id);
            const value = trascodSel[0].note;
            resolve(value);
        });
    }

    validForm(): boolean {
        if (this.allegato.tipoId && this.selectedFiles.some(f => f.content !== undefined)) {
            return true;
        } else {
            return false;
        }
    }

    uploadFromMulti() {
        this.getAllegati(this.id);
    }

    getAllegati(id) {
        this.loading = true;
        this.isFotoAmb = false;
        this.isDocCli = false;
        this.isDocCess = false;
        this.checkFin = false;
        this.isDocFin = false;
        this.allegatoService.getAllegati(id).subscribe(res => {
            this.loading = false;
            this.allegati = res;

            //recupero il pagamento ordine
            this.paymentService.findByKey(this.id, 'ordineId.equals=').subscribe(res => {
                //CHECK FINANZIAMENTO
                if (res[0] && res[0].metodopagDescrizione && res[0].metodopagDescrizione?.toUpperCase() === 'FINANZIAMENTO') {
                    this.checkFin = true;
                }

                //CHECK CESSIONE CREDITO
                if (res[0] && res[0].cessionecredito) {
                    this.checkCess = true;
                }

                this.allegati.forEach(r => {
                    let splitName = r.path.split('/');
                    r.name = splitName[splitName.length - 1];
                    if (r.tipoDescrizione === 'Documenti cliente') {
                        this.isDocCli = true;
                    } else if (r.tipoDescrizione === 'Foto ambiente') {
                        this.isFotoAmb = true;
                    } else if (r.tipoDescrizione === 'Documenti finanziamento') {
                        this.isDocFin = true;
                    }

                });

                this.dataSource = new MatTableDataSource(this.allegati);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            });

        }, error => {
            this.loading = false;
            console.error('Error => ', error);
        });
    }


    deleteDocument(id): void {

        jQuery('#confirmModalCanc').modal({ backdrop: 'static', keyboard: false });
        jQuery('#confirmModalCanc').modal('toggle');
        jQuery('#idAllegato').val(id);


    }

    confirmDelete(isConfirm: boolean) {
        if (isConfirm) {
            let id = jQuery('#idAllegato').val();
            this.allegatoService.delete(id).subscribe(res => {
                this.getAllegati(this.id);
            });
        }

        jQuery('#confirmModalCanc').modal({ backdrop: 'static', keyboard: false });
        jQuery('#confirmModalCanc').modal('toggle');

    }

    downloadDocument(name, originalName, id): void {
        this.allegatoService.downloadFile(name, id).subscribe(x => {

            if (name.includes('certificatoFirma.pdf') || name.includes('certificatoFirmaAttivazioneServizi.pdf')) {
                const blob = new Blob([x], { type: "application/pdf" });
                const data = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = data;
                let nameCert = name.split('/');
                link.download = nameCert[nameCert.length - 1];
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            } else {
                const newBlob = new Blob([x], { type: 'application/octet-stream' });

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                const data = window.URL.createObjectURL(newBlob);

                const link = document.createElement('a');
                link.href = data;
                link.download = originalName;

                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            }
        });
    }

    checkDocument(): boolean {
        let isPresent = false;
        if (this.isDocCli && this.isFotoAmb) {
            if (this.checkFin) {
                if (this.isDocFin) {
                    isPresent = true;
                }
            }
            else isPresent = true;
        }
        return isPresent;
    }

    //Servizio che si occupa di cambiare lo stato dell'ordine in documenti caricati.
    sendDocumentToGdl() {
        this.orderService.getSingleOrder(this.id).subscribe(res => {
            this.ordine = res;
            this.ordine.statopraticaId = 7;
            this.orderService.update(this.ordine).subscribe(res => {
                this.snackBar.open('Documenti inviati con successo', '', {
                    duration: 5000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
                this.router.navigate(['order-list']);
            });
        });
    }

    getOrder() {
        this.orderService.getSingleOrder(this.id).subscribe(res => {
            this.ordine = res;
        });
    }

    returnDimension(x) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

}
