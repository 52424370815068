import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
@Component({
  selector: 'app-riepilogo-vasche',
  templateUrl: './riepilogo-vasche.component.html',
  styleUrls: ['./riepilogo-vasche.component.scss']
})
export class RiepilogoVascheComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche : Trascodifica[];

  constructor(
    private formController : FormControllerService
  ) {
  }

  ngOnInit(): void {
  }
  
  returnDescription(type:string,value:number): string {
	  let res = '';
	  var tras = 	this.formController.getTrascodificaByCodice(type , this.trascodifiche).filter(tr => tr.id === value);
	  if(tras.length > 0)
		  res = tras[0].descrizione;
	  return res	
  }
}
