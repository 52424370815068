import { Component, Input, OnInit } from '@angular/core';
import { CaldaiaService } from '../services/caldaia.service';
@Component({
  selector: 'app-dettaglio-caldaia',
  templateUrl: './dettaglio-caldaia.component.html',
  styleUrls: ['./dettaglio-caldaia.component.scss']
})
export class DettaglioCaldaiaComponent implements OnInit {
  
  dettaglioResponse: any;
  
  @Input() form: any;
  
  constructor(
      private caldaiaService: CaldaiaService,
  ) { }

  ngOnInit(): void {
    this.getDettaglio();
  }

  getDettaglio(): void {
    this.caldaiaService.getDettaglioCaldaia(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.dettaglioResponse = res[0];
      if (this.dettaglioResponse?.id) {
        this.form.sections.dettaglioCaldaia = this.dettaglioResponse;
      }
    });
  }
}
