import { NuovaCassettaWc, Nuovarubinetteria } from "./../main-bagni/classes/dati-tecnici";
import { AfterContentChecked, ChangeDetectorRef, Component, DoCheck, OnInit } from "@angular/core";
import { Accessori, AccessoriDTO, DatiTecnici, DatiTecniciDTO, NuoviAccessoriDTO, NuoviProdotti, NuoviSanDTO, OpereIdraulicheDTO, PagamentoDTO, SanitariDTO } from "../../bagni-italiani/main-bagni/classes/dati-tecnici";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Ordine } from "../../../classes/ordine";
import { Trascodifica } from "../../../classes/trascodifica";
import { AnagraficaDTO, AnagraficaForm } from "../../../classes/anagrafica";
import { DatitecnicivascaDTO } from "../../../classes/remail/datitecnicivasca-dto";
import { NuovidatitecniciDTO } from "../../../classes/remail/nuovidatitecnici-dto";
import { BoxdocciaDTO } from "../../../classes/remail/boxdoccia-dto";
import { GresDto } from "../../../classes/remail/gres-dto";
import { OmaggioDto } from "../../../classes/omaggio-dto";
import { DisegnoTecnico } from "../../../classes/disegno-tecnico";
import { FormControllerService } from "../../../../services/controller/form-controller.service";
import { BrandService } from "../../../../services/brand/brand.service";
import { OrdersService } from "../../../../services/orders/orders.service";
import { AnagraficaService } from "../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service";
import { RemailService } from "../../remail/services/remail.service";
import { BoxdocciaService } from "../../bagni-italiani/main-bagni/services/boxdoccia/boxdoccia.service";
import { OpereIdraulicheService } from "../../bagni-italiani/main-bagni/services/opereIdrauliche/opereIdrauliche.service";
import { SanitariService } from "../../bagni-italiani/main-bagni/services/sanitari/sanitari.service";
import { PaymentService } from "../../../../services/payment/payment.service";
import { DisegnoTecnicoService } from "../../../../services/disegnoTecnico/disegno-tecnico.service";
import { OmaggioService } from "../../bagni-italiani/main-bagni/services/omaggio/omaggio.service";
import { DatiTecniciAttService } from "../../bagni-italiani/main-bagni/services/datiTecniciAtt/datiTecniciAtt.service";
import { OptionsAvailable } from "src/app/modules/classes/options-available";
import { OptionsAvailableService } from "src/app/services/optionsAvailable/options-available.service";
import { constants } from 'src/app/modules/classes/constants';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from "src/app/modules/classes/finanziamento/finanziamento-dto";
import { AllegatoService } from "src/app/services/allegato/allegato.service";
import * as $ from "jquery";
declare var jQuery: any;

@Component({
  selector: "app-help75",
  templateUrl: "./help75.component.html",
  styleUrls: ["./help75.component.scss"],
})
export class Help75Component implements OnInit, DoCheck, AfterContentChecked {

  openSign: boolean = false;
  mobileAttivo: boolean = true;
  isFinanziamentoPayment: boolean = false;

  currentStep: number;
  totalSteps: number[];
  form: any;
  originalForm: any;
  validAnagrafica: boolean;
  ordine: Ordine;
  trascodifiche: Trascodifica[];
  listFinanziarie: Trascodifica[];
  attivitaLavList: Trascodifica[] = [];
  anagrafica: AnagraficaDTO;
  originalNotes: string;
  obj1: any;
  obj2: any;

  sanitari: SanitariDTO;
  showModalPrint: boolean;
  datiTecnici: DatiTecniciDTO;

  pdf: any;
  error: any;

  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
    logoUrl: 'assets/img/loghi/logo-bagni-italiani.jpg', product: 'Help75', brand: 'Bagni Italiani'
  }

  hasValues = (obj) => Object.values(obj).some((v) => v !== null && typeof v !== "undefined");

  constructor(
    private route: ActivatedRoute,
    public formController: FormControllerService,
    private brandService: BrandService,
    private orderService: OrdersService,
    private anagraficaService: AnagraficaService,
    private snackbar: MatSnackBar,
    private remailServ: RemailService,
    private boxdocciaService: BoxdocciaService,
    private opIdraulicheService: OpereIdraulicheService,
    private sanitariService: SanitariService,
    private paymentService: PaymentService,
    private disegnoService: DisegnoTecnicoService,
    private omaggioService: OmaggioService,
    private datiTecniciService: DatiTecniciAttService,
    private changeDetector: ChangeDetectorRef,
    private optionService: OptionsAvailableService,
    private allegatoService: AllegatoService,
  ) {
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get("id")),
      anagrafica: new AnagraficaForm(),
      sections: {
        datitecnicivasca: new DatitecnicivascaDTO(),
        nuovidatitecnici: new NuovidatitecniciDTO(),
        boxdoccia: [new BoxdocciaDTO()],
        piattoDoccia: new OpereIdraulicheDTO(),
        gres: new GresDto(),
        nuoviProdotti: new NuoviProdotti(),
        nuoviSanitariDto: [new NuoviSanDTO()],
        disegno: [new DisegnoTecnico(), new DisegnoTecnico()],
        omaggio: [new OmaggioDto()],
        nuoviAccessori: [new Accessori()],
        accessorioToDelete: [new AccessoriDTO()],
        pagamento: [new PagamentoDTO(), new PagamentoDTO()],
        finanziamentoSections: {
          finanziamento: new Finanziamento(),
          finAnagrafica: new FinAnagrafica(),
          finDatiOccupazione: new FinDatiOccupazione(),
          finAnagraficaGarante: new FinAnagrafica(),
          finDatiOccupazioneGarante: new FinDatiOccupazione(),
          isAllegatoSaved: false
        },
        datiTecnici: new DatiTecnici(),
        optionsAvailable: [],
      },
      ordine: null,
    };

    this.currentStep = 0;
    this.totalSteps = Array(11).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.

  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));
    if (localStorage.getItem("id_client") !== null) {
      this.form.anagrafica.anagrafica.id = Number(
        localStorage.getItem("id_client")
      );
    }
    if (this.currentStep > 0) {
      $(".imgModulo").addClass("yesShow");
    } else {
      $(".imgModulo").removeClass("yesShow");
    }
    this.getOrder(this.form.idOrder);
  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0) {
      $(".imgModulo").addClass("yesShow");
    } else {
      $(".imgModulo").removeClass("yesShow");
    }
    if (this.currentStep === 2) {
      this.form.ordine.note = this.originalNotes;
    }

    if (this.form.sections.pagamento[1]!.tipopagsaldoId) {
      let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[1]!.tipopagsaldoId, this.trascodifiche);
      if (tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLowerCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC.toLowerCase())) {
        this.isFinanziamentoPayment = true;
        this.totalSteps = Array(11).fill(1).map((x, i) => i);
      } else {
        this.isFinanziamentoPayment = false;
        this.totalSteps = Array(10).fill(1).map((x, i) => i);
      }
    }

    this.scrollTop();
  }

  scrollTop(): void {
    $("html, body").animate({ scrollTop: 0 }, 300);
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe((res) => {
      this.ordine = res;
      this.form.ordine = res;
      this.originalNotes = res.note;
      localStorage.setItem("id_product", res.prodottoId);
      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem("id_client", res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(
          localStorage.getItem("id_client")
        );
      }

      localStorage.setItem("id_order", res.idordine);

      this.brandService
        .getDataByProduct(Number(localStorage.getItem("id_product")))
        .subscribe((res) => {
          this.trascodifiche = res;
          this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
        });
      this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
    });
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  stepController(): boolean {
    let disabled = false;

    if (
      (!this.validAnagrafica && this.currentStep === 0) ||
      (!this.validAnagrafica && this.currentStep === 8)
    ) {
      disabled = true;
    }

    if (
      this.currentStep === 1 &&
      (!this.formController.validMurettoSx(this.form) ||
        !this.formController.validMurettoDx(this.form) ||
        !this.formController.validMurettoDietro(this.form) ||
        !this.formController.validMurettoDavanti(this.form))
    ) {
      disabled = true;
    }

    if (this.currentStep === 2 && (!this.validTecnic() || this.formController.checkNoteLength(this.form.sections.datiTecnici.noteTecniche, 255))) {
      disabled = true;
    }

    if (
      this.currentStep === 3 &&
      (!this.validPiattoPietra() ||
        !this.validPiattoStone() ||
        !this.formController.validOption(
          this.form.sections.optionsAvailable,
          constants.TYPE_NUOVO_PIATTO_DOCCIA,
          this.trascodifiche
        ) ||
        !this.formController.validHelpNuoviDati(this.form) ||
        !this.formController.validOmaggio(this.form))
    ) {
      disabled = true;
    }

    if (
      this.currentStep === 4 &&
      !this.validPagamento(this.form.sections.pagamento[0])
    ) {
      disabled = true;
    }

    if (
      this.currentStep === 5 &&
      (!this.validNuovaRubinetteria() ||
        !this.validVasche() ||
        !this.validMobBagno() ||
        !this.validNuoviSan() ||
        !this.formController.validOption(
          this.form.sections.optionsAvailable,
          constants.TYPE_NUOVI_SANITARI,
          this.trascodifiche
        ) ||
        !this.formController.validOption(
          this.form.sections.optionsAvailable,
          constants.TYPE_NUOVA_CASSETTA,
          this.trascodifiche
        ) ||
        !this.formController.validOption(
          this.form.sections.optionsAvailable,
          constants.TYPE_NUOVA_RUBINETTERIA,
          this.trascodifiche
        ) ||
        !this.formController.validOption(
          this.form.sections.optionsAvailable,
          constants.TYPE_NUOVO_BOX_DOCCIA,
          this.trascodifiche
        ) ||
        this.formController.checkNoteLength(this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note, 255) ||
        !this.validNuovoBoxDoccia(this.form)
      )
    ) {
      disabled = true;
    }

    if (
      this.currentStep === 7 &&
      !this.validPagamento(this.form.sections.pagamento[1])
    ) {
      disabled = true;
    }
    if (this.currentStep === 8 && !this.formController.validPrivacy(this.form)) {
      disabled = false;
    }

    return disabled;
  }

  stepControllerButton(): boolean {
    let disabled = false;
    if (
      (!this.validAnagrafica && this.currentStep === 0) ||
      (!this.validAnagrafica && this.currentStep === 8)
    ) {
      disabled = true;
    }

    if (
      this.currentStep === 1 &&
      (!this.formController.validMurettoSx(this.form) ||
        !this.formController.validMurettoDx(this.form) ||
        !this.formController.validMurettoDietro(this.form) ||
        !this.formController.validMurettoDavanti(this.form))
    ) {
      disabled = true;
    }

    if (this.currentStep === 2 && (!this.validTecnic() || this.formController.checkNoteLength(this.form.sections.datiTecnici.noteTecniche, 255))) {
      disabled = true;
    }

    if (
      this.currentStep === 3 &&
      (!this.validPiattoPietra() ||
        !this.validPiattoStone() ||
        !this.formController.validOption(
          this.form.sections.optionsAvailable,
          constants.DESC_NUOVO_PIATTO_DOCCIA,
          this.trascodifiche
        ) ||
        !this.formController.validHelpNuoviDati(this.form) ||
        !this.formController.validOmaggio(this.form)
        || (this.form.ordine.note && this.form.ordine.note?.length > 255))

    ) {
      disabled = true;
    }

    if (
      this.currentStep === 4 &&
      !this.validPagamento(this.form.sections.pagamento[0])
    ) {
      disabled = true;
    }

    if (
      this.currentStep === 5 &&
      (!this.validNuovaRubinetteria() ||
        !this.validVasche() ||
        !this.validMobBagno() ||
        !this.validNuoviSan() ||
        !this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVI_SANITARI, this.trascodifiche
        ) ||
        !this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVA_CASSETTA, this.trascodifiche
        ) ||
        !this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVA_RUBINETTERIA, this.trascodifiche
        ) ||
        !this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVO_BOX_DOCCIA, this.trascodifiche
        ) ||
        this.formController.checkNoteLength(this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note, 255) ||
        !this.validNuovoBoxDoccia(this.form)
      )
    ) {
      disabled = true;
    }

    if (
      this.currentStep === 7 &&
      !this.validPagamento(this.form.sections.pagamento[1])
    ) {
      disabled = true;
    }

    if (this.currentStep === 8 && !this.formController.validPrivacy(this.form)) {
      disabled = true;
    }

    if (this.currentStep === 10) {
      disabled = this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie);
    }
    return disabled;
  }

  saveModule(): void {
    if (this.currentStep === 0) {
      this.ordine.statopraticaId = 2;
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService
          .update(JSON.stringify(this.anagrafica))
          .subscribe(
            (res) => {
              this.ordine.clienteId = res.id;
              this.ordine.cognome = res.cognome;
              this.ordine.nome = res.nome;
              this.orderService.update(JSON.stringify(this.ordine)).subscribe();
            },
            (error) => {
              console.error("Error => ", error);
              this.formController.openSnackBar(constants.ERROR_GENERIC_API);
            }
          );
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(
          (res) => {
            this.ordine.clienteId = res.id;
            this.ordine.cognome = res.cognome;
            this.ordine.nome = res.nome;
            this.form.anagrafica.anagrafica.id = res.id;
            this.orderService.update(JSON.stringify(this.ordine)).subscribe();
          },
          (error) => {
            console.error("Error => ", error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          }
        );
      }
    } else if (this.currentStep === 1) {
      if (this.hasValues(this.form.sections.datitecnicivasca)) {
        this.form.sections.datitecnicivasca.ordineId = this.form.idOrder;
        const datiVasca = this.form.sections.datitecnicivasca;
        if (datiVasca.id === undefined || datiVasca.id === null) {
          this.remailServ
            .saveDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
        } else {
          this.remailServ
            .updateDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
        }
      }
    } else if (this.currentStep === 2) {
      //Disegno tecnico
      this.form.sections.disegno[0].ordineId = this.form.idOrder;
      if (this.form.sections.disegno[0].id === undefined) {
        this.disegnoService
          .save(JSON.stringify(this.form.sections.disegno[0])).subscribe();
      } else {
        this.disegnoService
          .update(JSON.stringify(this.form.sections.disegno[0])).subscribe();
      }

      //Step salvataggio dati tecnici
      this.datiTecnici = this.formController.mappingFormToDatiTecniciDTO(
        this.form.sections.datiTecnici,
        new DatiTecniciDTO()
      );
      if (this.hasValues(this.datiTecnici)) {
        this.datiTecnici.ordineId = this.form.idOrder;
        if (this.datiTecnici.id === null || this.datiTecnici.id === undefined) {
          this.datiTecniciService
            .save(JSON.stringify(this.datiTecnici))
            .subscribe(
              (res) => { },
              (error) => {
                console.error("Error => ", error);
                this.formController.openSnackBar(constants.ERROR_GENERIC_API);
              }
            );
        } else {
          this.datiTecniciService
            .update(JSON.stringify(this.datiTecnici))
            .subscribe(
              (res) => { },
              (error) => {
                console.error("Error => ", error);
                this.formController.openSnackBar(constants.ERROR_GENERIC_API);
              }
            );
        }
      }
    } else if (this.currentStep === 3) {
      //Nuovi dati tecnici
      if (this.hasValues(this.form.sections.nuovidatitecnici)) {
        this.form.sections.nuovidatitecnici.ordineId = this.form.idOrder;
        const datiVasca = this.form.sections.nuovidatitecnici;
        if (datiVasca.id === undefined || datiVasca.id === null) {
          this.remailServ.saveNuoviDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
        } else {
          this.remailServ.updateNuoviDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
        }
      }

      let savePiattoDoccia: boolean;
      //Salvo o aggiorno l'opzione pitto doccia per l'ordine
      for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
        let option: OptionsAvailable = this.form.sections.optionsAvailable[i];

        if (savePiattoDoccia == undefined) {
          savePiattoDoccia = this.searchBool(constants.DESC_NUOVO_PIATTO_DOCCIA, option);
        }
      }

      //Piatto doccia
      if (savePiattoDoccia !== undefined) {
        if (this.hasValues(this.form.sections.piattoDoccia)) {
          if (!savePiattoDoccia) {
            this.form.sections.piattoDoccia.colorepiateffpietraId = null;
            this.form.sections.piattoDoccia.dimpiatteffpietraId = null;
            this.form.sections.piattoDoccia.colorepiatstoneId = null;
            this.form.sections.piattoDoccia.dimpiattstoneId = null;
          }
          this.form.sections.piattoDoccia.ordineId = this.form.idOrder;

          if (
            this.form.sections.piattoDoccia.id === undefined ||
            this.form.sections.piattoDoccia.id === null
          ) {
            this.opIdraulicheService
              .save(JSON.stringify(this.form.sections.piattoDoccia))
              .subscribe();
          } else {
            this.opIdraulicheService
              .update(JSON.stringify(this.form.sections.piattoDoccia))
              .subscribe();
          }
        }
      }

      //Gres
      if (this.hasValues(this.form.sections.gres)) {
        this.form.sections.gres.ordineId = this.form.idOrder;
        if (
          this.form.sections.gres.id === undefined ||
          this.form.sections.gres.id === null
        ) {
          this.remailServ
            .save("gres", JSON.stringify(this.form.sections.gres))
            .subscribe();
        } else {
          this.remailServ
            .update("gres", JSON.stringify(this.form.sections.gres))
            .subscribe();
        }
      }

      //Step salvataggio omaggioDTO
      for (var i = 0; i < this.form.sections.omaggio.length; i++) {
        var omaggio: OmaggioDto = this.form.sections.omaggio[i];
        omaggio.ordineId = this.form.idOrder;
        if (omaggio.id === undefined || omaggio.id === null) {
          this.omaggioService.save(JSON.stringify(omaggio)).subscribe();
        } else {
          this.omaggioService.update(JSON.stringify(omaggio)).subscribe();
        }
      }

      //aggiorno le note dell'ordine'
      this.ordine.note = this.form.ordine.note;
      this.originalNotes = this.form.ordine.note;
      this.orderService.update(JSON.stringify(this.ordine)).subscribe();
    } else if (this.currentStep === 4) {
      //Salvataggio pagamento step 1
      if (this.hasValues(this.form.sections.pagamento[0])) {
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe();
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe();
        }
      }
    } else if (this.currentStep === 5) {
      let saveNuoviSanitariOmaggio;
      let saveNuovNuovaCassetta;
      let saveNuovNuovaRubinetteria;
      let saveBoxDoccia: boolean;
      for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
        let option: OptionsAvailable = this.form.sections.optionsAvailable[i];
        if (saveNuoviSanitariOmaggio == undefined) {
          saveNuoviSanitariOmaggio = this.searchBool(constants.TYPE_NUOVI_SANITARI, option);
        }

        if (saveNuovNuovaCassetta == undefined) {
          saveNuovNuovaCassetta = this.searchBool(constants.TYPE_NUOVA_CASSETTA, option);
        }

        if (saveNuovNuovaRubinetteria == undefined) {
          saveNuovNuovaRubinetteria = this.searchBool(constants.TYPE_NUOVA_RUBINETTERIA, option);
        }

        if (saveBoxDoccia == undefined) {
          saveBoxDoccia = this.searchBool(constants.TYPE_NUOVO_BOX_DOCCIA, option);
        }
      }

      //Step salvataggio sanitari
      //controllo se il toogle mobileBagni è a true
      if (
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente
      ) {

        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.baseMobileId;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.cassettiMobileId;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.tipologiaLavaboMobileId;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.specchioMobile;

        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note;
      }
      if (!saveNuovNuovaCassetta) {
        this.form.sections.nuoviProdotti.nuovaCassettaWc = new NuovaCassettaWc();
      }

      if (!saveNuovNuovaRubinetteria) {
        this.form.sections.nuoviProdotti.nuovaRubinetteria = new Nuovarubinetteria();
      }
      this.sanitari = this.formController.mappingFormToSanitariDTO(this.form.sections.nuoviProdotti, new SanitariDTO(), this.form.idOrder);

      if (this.hasValues(this.sanitari)) {
        this.sanitari.idordineId = this.form.idOrder;
        if (this.sanitari.id === null || this.sanitari.id === undefined) {
          this.sanitariService.save(JSON.stringify(this.sanitari)).subscribe();
        } else {
          this.sanitariService
            .update(JSON.stringify(this.sanitari))
            .subscribe();
        }
      }

      // step salvataggio nuovi sanitari
      for (let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
        const san = this.form.sections.nuoviSanitariDto[i];
        san.ordineId = this.form.idOrder;

        if (saveNuoviSanitariOmaggio != undefined) {
          if (!saveNuoviSanitariOmaggio) {
            this.sanitariService.deleteNuoviSan(san.id).subscribe();
          } else {
            if (san.id === undefined || san.id === null) {
              this.sanitariService.saveNuoviSan(san).subscribe();
            } else {
              this.sanitariService.updateNuoviSan(san).subscribe();
            }
          }
        }
      }

      //Box Doccia
      for (let i = 0; i < this.form.sections.boxdoccia.length; i++) {
        const box = this.form.sections.boxdoccia[i];
        if (saveBoxDoccia !== undefined) {
          if (!saveBoxDoccia) {
            //se non è stato selezionato il box doccia elimino i vecchi box precedentemente aggiunti al contratto
            this.boxdocciaService.delete(box.id).subscribe();
          } else {
            if (this.hasValues(box)) {
              box.ordineId = this.form.idOrder;
              if (box.id === undefined || box.id === null) {
                this.boxdocciaService.save(JSON.stringify(box)).subscribe();
              } else {
                this.boxdocciaService.update(JSON.stringify(box)).subscribe();
              }
            }
          }
        }
      }
    } else if (this.currentStep === 6) {
      //Disegno tecnico
      this.form.sections.disegno[1].ordineId = this.form.idOrder;
      if (this.form.sections.disegno[1].id === undefined) {
        this.disegnoService
          .save(JSON.stringify(this.form.sections.disegno[1]))
          .subscribe();
      } else {
        this.disegnoService
          .update(JSON.stringify(this.form.sections.disegno[1]))
          .subscribe();
      }
    } else if (this.currentStep === 7) {
      // Step salvataggio pagamento
      if (this.hasValues(this.form.sections.pagamento[1])) {
        this.form.sections.pagamento[1].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[1];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
            this.form.sections.pagamento[1] = res;
          })
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
            this.form.sections.pagamento[1] = res;
            // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
            if (res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
              this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
                allegati.forEach((allegato: any) => {
                  if (allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)) {
                    this.allegatoService.delete(allegato.id).subscribe(() => {
                    }, error => {
                      console.error('Error => ', error);
                      this.formController.openSnackBar(constants.ERROR_GENERIC_API);
                    });
                  }
                })
              })
            }
          })
        }
      }
    } else if (this.currentStep === 8) {
      //Step salvataggio privacy
      this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
    } else if (this.currentStep === 9) {
      this.anagraficaService
        .update(JSON.stringify(this.form.anagrafica.anagrafica))
        .subscribe(
          (res) => {
            this.ordine.clienteId = res.id;
            this.ordine.cognome = res.cognome;
            this.ordine.nome = res.nome;
          },
          (error) => {
            console.error("Error => ", error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          }
        );
    }

    this.setStep(this.currentStep + 1);
  }

  toggleChange(event): void {
    this.mobileAttivo = event;
  }

  private validMobBagno(): boolean {
    if (!this.mobileAttivo) {
      if ((this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile && this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile !== 1793)) {
        if (!this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili || this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private validVasche(): boolean {
    if (!this.mobileAttivo) {
      if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  validNuovaRubinetteria(): boolean {
    if (this.form.sections.nuoviProdotti.nuovaRubinetteria.id) {
      // controllo direttamente id di serie light
      if (this.form.sections.nuoviProdotti.nuovaRubinetteria.id === 1497) {
        //verifico se è stato selezionato il colore
        if (!this.form.sections.nuoviProdotti.nuovaRubinetteria.rubbicolId) {
          return false;
        }
      }
      if (
        this.form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId ||
        this.form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  validNuoviSan(): boolean {
    let valid = true;
    if (this.form.sections.nuoviSanitariDto.length > 0) {
      for (let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
        if (valid) {
          if (
            this.form.sections.nuoviSanitariDto[i].modsanitariId &&
            this.form.sections.nuoviSanitariDto[i].tiposcaricoId &&
            this.form.sections.nuoviSanitariDto[i].tiposanitariId &&
            this.form.sections.nuoviSanitariDto[i].tipoprodottoId
          ) {
            valid = true;
          } else {
            valid = false;
          }
        }
      }
    }
    return valid;
  }

  validNuovoBoxDoccia(form: any): boolean {
		let valid = true;
		if (form.sections.boxdoccia.length > 0) {
			for (let i = 0; i < form.sections.boxdoccia.length; i++) {
				if (form.sections.boxdoccia[i].modelloId &&
					form.sections.boxdoccia[i].cristalliId &&
					form.sections.boxdoccia[i].profiloId &&
					form.sections.boxdoccia[i].ingressoId
				) {
					valid = true;
				} else {
					valid = false;
				}
			}
		}
		return valid;
	}

  validPiattoPietra(): boolean {
    if (this.form.sections.piattoDoccia.colorepiateffpietraId) {
      if (this.form.sections.piattoDoccia.dimpiatteffpietraId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  validPiattoStone(): boolean {
    if (this.form.sections.piattoDoccia.colorepiatstoneId) {
      if (this.form.sections.piattoDoccia.dimpiattstoneId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  returnIdOrdine(): string {
    return localStorage.getItem("id_order");
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery("#printModal").modal({ backdrop: "static", keyboard: false });
    jQuery("#printModal").modal("toggle");
  }

  validTecnic(): boolean {
    if (
      this.form.sections.datiTecnici.tipoAbitazioneId &&
      this.form.sections.datiTecnici.dimensioniVano &&
      this.form.sections.datiTecnici.dimensioniVano.lung &&
      this.form.sections.datiTecnici.dimensioniVano.larg
    ) {
      if (
        this.form.sections.datiTecnici.piano &&
        this.form.sections.datiTecnici.piano < 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public mappingFormToNuoviAccessoriDTO(
    form: Accessori,
    dto: NuoviAccessoriDTO
  ): NuoviAccessoriDTO {
    dto.id = form.id;
    dto.ordineId = this.form.idOrder;
    dto.tipologiaId = form.tipologiaId;

    return dto;
  }


  searchBool(type: any, option: any) {
    let optionDoccia = this.formController
      .getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche)
      .filter((op) => op.descrizione === type);

    if (optionDoccia.find((res: any) => res.id === option.typeId)) {
      if (option.id) {
        //update option
        this.optionService.update(option).subscribe();
      } else {
        //insert option
        this.optionService.save(option).subscribe();
      }

      if (option.selected == true) {
        return true;
      } else if (option.selected == false) {
        return false;
      }
    }
  }

  closeSignPdf() {
    jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
    jQuery("#signModal").modal("toggle");
  }

  validPagamento(pagamento): boolean {
    if (!pagamento?.tipodetrazioneId) return false;

    if (pagamento === undefined || pagamento === null) {
      return false;
    }
    if (
      pagamento.totaleordine < 0 ||
      pagamento.totaledovuto < 0 ||
      pagamento.caparraconf < 0 ||
      pagamento.saldoinizlav < 0 ||
      !pagamento.percivaId
    ) {
      return false;
    }

    if (pagamento.caparraconf == undefined || pagamento.caparraconf > 0) {
      return !(
        !pagamento.totaleordine ||
        !pagamento.totaledovuto ||
        pagamento.caparraconf == undefined ||
        !pagamento.metodopagId ||
        pagamento.saldoinizlav == undefined ||
        !pagamento.tipopagsaldoId
      );
    } else if (pagamento.caparraconf == 0) {
      return !(
        !pagamento.totaleordine ||
        !pagamento.totaledovuto ||
        pagamento.saldoinizlav == undefined ||
        !pagamento.tipopagsaldoId
      );
    }
  }

  edit() {
    this.currentStep = 0;
    this.openSign = false;
    this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
      res.statopraticaCodice = "A";
      res.statopraticaId = 2;
      this.orderService.update(JSON.stringify(res)).subscribe(res => {
        this.getOrder(this.form.idOrder);
      });
    })
  }

  reload(value: boolean) {
    this.openSign = value;
  }
}
