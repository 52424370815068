<div class="zucchettiMontascaleBg">FLOW X (curvilineo) STANDARD PACK</div>
<div class="zucchettiMontascaleSubTitle">Inclusi di 6 m. guida 1 curva 90 gradi, poltroncina con sistema di auto
    rotazione nelle fermate, sedile regolabile e poggiapiedi</div>
<div class="zucchettiMontascaleCont">
    <div class="row originalRow">
        <div class="col-12 mb-3"><b>FLOW X (curvilineo) STANDARD PACK - OPZIONI SISTEMA:</b></div>
        <div class="col-lg-6 mb-2 align-self-center">
            <mat-radio-group class="smalFt" [(ngModel)]="form.sections.montascale.guidaId">
                <mat-radio-button color="primary" class="mr-2"
                    *ngFor="let guida of optionalFlowXTipoGuidaTrascodifiche;" [value]="guida.id">
                    {{guida.descrizione}}
                </mat-radio-button>
                <span class="undo material-icons" matTooltip="Cancella selezione"
                    *ngIf="form.sections.montascale.guidaId" (click)="form.sections.montascale.guidaId = null">
                    backspace
                </span>
            </mat-radio-group>

            <mat-radio-group class="smalFt" [(ngModel)]="form.sections.montascale.posizionejoyId">
                <mat-radio-button color="primary" class="mr-2"
                    *ngFor="let posizionejoy of optionalFlowXposizioneJoyTrascodifiche;" [value]="posizionejoy.id">
                    POSIZIONE JOYSTICK {{posizionejoy.descrizione}}
                </mat-radio-button>
                <span class="undo material-icons" matTooltip="Cancella selezione"
                    *ngIf="form.sections.montascale.posizionejoyId"
                    (click)="form.sections.montascale.posizionejoyId = null">
                    backspace
                </span>
            </mat-radio-group>
        </div>
        <div class="col-lg-6 mb-2 align-self-center">
            <mat-form-field class="full-width">
                <mat-label>COLORE GUIDA:</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.montascale.coloreguida">
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>TIPOLOGIA RIVESTIMENTO:</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.montascale.tipologiarivestimento">
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>COLORE RIVESTIMENTO:</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.montascale.colorerivestimento">
            </mat-form-field>
        </div>
    </div>
</div>

<div class="zucchettiMontascaleCont">
    <div class="row originalRow">
        <div class="col-lg-6 mb-2 align-self-start">
            <div class="col-12 mb-3"><br><br><b>FLOW X (curvilineo) STANDARD PACK - OPTIONAL SEDILE:</b></div>
            <div *ngFor="let flowSedile of form.sections.flowXSedileOptionsAvailable; let i = index">
                <mat-checkbox class="mr-2" [checked]="flowSedile.selected" (change)="onFlowSedileOptionsChange(i)"
                    color="primary" value>
                    {{ convertTrascodifica(flowSedile.typeId) }}
                </mat-checkbox>
            </div>
        </div>

        <div class="col-lg-6 mb-2 align-self-start">
            <div class="col-12 mb-3"><br><br><b>FLOW X (curvilineo) STANDARD PACK - OPTIONAL GUIDA:</b></div>
            <mat-form-field class="full-width">
                <mat-label>CURVA 90° EXTRA N°:</mat-label>
                <input matInput type="number" [(ngModel)]="form.sections.montascale.curvaextran">
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>CURVE DA 180° N°:</mat-label>
                <input matInput type="number" [(ngModel)]="form.sections.montascale.curven">
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>GUIDA EXTRA AL METRO N°:</mat-label>
                <input matInput type="number" [(ngModel)]="form.sections.montascale.guidaextra">
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>PARCHEGGIO AGGIUNTIVO (punto carica compreso):</mat-label>
                <input matInput type="number" [(ngModel)]="form.sections.montascale.puntocarica">
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>FERMATA AGGIUNTIVA N:</mat-label>
                <input matInput type="number" [(ngModel)]="form.sections.montascale.fermatagg">
            </mat-form-field>


            <mat-checkbox class="mr-2" [checked]="form.sections.montascale.partenzavert"
                [(ngModel)]="form.sections.montascale.partenzavert" color="primary" value>
                PARTENZA VERTICALE CORTA
            </mat-checkbox>

        </div>
    </div>
</div>

<div class="zucchettiMontascaleCont">
    <div class="row originalRow">
        <div class="col-12 mb-3"><b>FLOW X (curvilineo) STANDARD PACK - VARIE:</b></div>
        <div class="col-6" *ngFor="let flowVarie of form.sections.flowXVarieOptionsAvailable; let i = index">
            <ng-container style="display: flex; align-items: center;">
                <mat-checkbox class="mr-2" [checked]="flowVarie.selected" (change)="onFlowSedileVarieOptionsChange(i)"
                    color="primary" value>
                    {{ convertTrascodifica(flowVarie.typeId) }}&nbsp;&nbsp;
                    <a *ngIf="convertTrascodifica(flowVarie.typeId) === constants.MONTASCALE_RADIOCOMANDO_CHIAMATA_E_PARCHEGGIO"
                        style="color: #000;text-decoration: none; ">N° </a>
                </mat-checkbox>
                <input
                    *ngIf="convertTrascodifica(flowVarie.typeId) === constants.MONTASCALE_RADIOCOMANDO_CHIAMATA_E_PARCHEGGIO"
                    class="customInlineInput" matInput type="number" min="0" step="0.1"
                    [(ngModel)]="form.sections.flowXVarieOptionsAvailable[i].quantity"
                    [disabled]="!form.sections.flowXVarieOptionsAvailable[i].selected">
            </ng-container>
        </div>
    </div>
</div>

<!--<div class="zucchettiMontascaleCont">
    <div class="row originalRow">
        <div class="col-12 mb-3"><b>NOTE LIBERE:</b></div>
        <mat-form-field style="padding-left: 15px; padding-right: 15px;" class="full-width" appearance="fill">
            <mat-label>Note libere</mat-label>
            <textarea matInput placeholder="Note..." [(ngModel)]="form.sections.montascale.note"></textarea>
        </mat-form-field>
    </div>
</div>-->
<div class="zucchettiMontascaleBg noteTitle"> NOTE </div>
<div class="zucchettiMontascaleCont">
    <app-note-sh [nota]="form.sections.montascale.note" (onNotaEmit)="form.sections.montascale.note = $event"
        [maxLength]="255" [minLine]="3">
    </app-note-sh>
</div>