import { environment } from "../../../../../../environments/environment";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnagraficaDTO } from "src/app/modules/classes/anagrafica";
import { constants } from "src/app/modules/classes/constants";
import { Responsabile } from "src/app/modules/classes/servizi/responsabile";
import { ServizioDettagli } from "src/app/modules/classes/servizi/servizioDettaglio";
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AnagraficaService } from "src/app/modules/seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service";
import { PreviewComponent } from "src/app/modules/shared/pdf/preview/preview.component";
import { ServiziPrintComponent } from 'src/app/modules/shared/pdf/servizi-print/servizi-print.component';
import { BrandService } from "src/app/services/brand/brand.service";
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { HttpService } from "src/app/services/http.service";
import { OrdersService } from "src/app/services/orders/orders.service";
import { ServiziPrintService } from 'src/app/services/servizi-print/servizi-print.service';
import { ServizioDettagliService } from "src/app/services/servizio-dettagli/servizio-dettagli.service";
import { ServizioResponsabileService } from "src/app/services/servizio-responsabile/servizio-responsabile.service";
declare var jQuery: any;

@Component({
  selector: 'print-depuratore-services',
  templateUrl: './print-depuratore-services.component.html',
  styleUrls: ['./print-depuratore-services.component.scss']
})
export class PrintDepuratoreServicesComponent extends ServiziPrintComponent implements OnInit {

 	@Input() form: any;
  	@Input() ordine: any;
	@Input() pdfDetails:any;
	@Input() trascodifiche: Trascodifica[];
	@Input() listTypeDoc: any;
	@Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();
  
  	idOrdine: number;
	validAnagrafica: boolean = false;
  	generatingPdf: boolean = false;
  	loadAll: boolean = false;
	showFrame: boolean = false;
	sendingPdf: boolean = false;
	openSign: boolean = false;
	showModalPrint: boolean = false;
	isLine: boolean = false;

  	timeLeft: number = 60;

  	anagrafica: AnagraficaDTO;
  	rgbColorLine: number[] = [0, 128, 0];

  	colorString: string = "#008000";
	urlFrame: string = "";
	privacyText: string = '';

  	member: any;
	responsabili: Responsabile[] = [];
	serviziDettaglio: ServizioDettagli[] = [];
  	error: any;
	typeDocModuloServizi:Trascodifica;

  	isApiV3 = environment.isV3;
  	integratedSignature = environment.integratedSignature;

  	constructor(
    	private router: Router,
		public dialog: MatDialog,
    	private anagraficaService: AnagraficaService,
		private servizioResponsabileService: ServizioResponsabileService,
    	public serviziPrintService: ServiziPrintService,
		private servizioDettaglio: ServizioDettagliService,
		private httpService: HttpService,
		private orderService: OrdersService,
		private brandService: BrandService,
    	public formController: FormControllerService, 
  	) {
      super(serviziPrintService, formController);
  	}

	ngOnInit(): void {
		this.idOrdine = this.form.idOrder;
		this.color = this.rgbColorLine;
		this.brand = constants.BRAND_ARIEL;
		this.typeDocModuloServizi = this.listTypeDoc.find((t: Trascodifica) => t.descrizione.includes(constants.DESC_MODULO_ATT_SERVIZI));
		this.getAnagrafica();
	}


	private getAnagrafica(): void {
		this.anagraficaService
			.findById(this.ordine.clienteId)
			.subscribe((res) => {
				this.anagrafica = res;
				this.checkAnagrafica();
				this.getResponsabili();
				this.getServiziDettaglio();
				this.getPrivacy();
			});
    	this.loadAll = true;
	}

  
	private checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	private getResponsabili(): void {
		this.servizioResponsabileService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe((res: Responsabile[]) => {
			this.responsabili = res;
		  })
	}

	private getServiziDettaglio(): void {
		this.servizioDettaglio.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe((res: ServizioDettagli[]) => {
			this.serviziDettaglio = res;
		})
	}

	getPrivacy(): void {
		this.httpService.getPrivacy('assets/json/attivazione_servizi/attivazione_depuratore.json').subscribe((res) => {
		  this.privacyText = res;
		});
	  }

  	private generatePdf(firma: boolean): void {
		let y = this.initPdf(
			this.ordine,
			this.pdfDetails.logoUrl,
			this.anagrafica,
			"Modulo richiesta attivazione servizi " + this.pdfDetails.product + " " + this.pdfDetails.brand,
			this.colorString,
			null,
			null
		  );

		this.serviziPrintService.setCurrentY(y);

		if (this.responsabili && this.responsabili.length) {
			const sectionTitle = 'RESPONSABILI';
			this.serviziResponsabileGenericPrint(this.doc, this.responsabili, sectionTitle, y, this.colorString);
		}

		y = this.serviziPrintService.returnCurrentY();

		if (this.serviziDettaglio && this.serviziDettaglio.length) {
			this.serviziGeneric(this.doc, this.serviziDettaglio, 'SERVIZI', y, this.trascodifiche, this.colorString )
		}
		
		y = this.serviziPrintService.addNewPage( this.doc, true, this.img, this.ordine, this.currentPdfPage, this.title );

		this.serviziPrintService.positioningPrintCoordinates(
		  this.doc,
		  y,
		  this.ordine,
		  this.img,
		  this.title,
		  this.currentPdfPage,
		  this.privacyText
		);
	  
		this.creatingPdf = false;
		if (firma) {
			if (!this.integratedSignature) {
				this.createPdf(this.doc);
			} else {
				this.toggleSignPdf(this.doc);
			}
		} else {
			const pdfBlob = this.doc.output("blob");

			// Crea un URL dall'oggetto Blob
			const pdfUrl = URL.createObjectURL(pdfBlob);
			this.openDialog(pdfUrl);
		}
	}

  	togglePrintPreview(): void {
		this.generatePdf(false);
	}

	toggleSignPdf(pdf): void {
		const interval = this.startTimer();
		const title = localStorage.getItem("id_order");
		const pages = this.serviziPrintService.returnCurrentPage();
		this.showModalPrint = !this.showModalPrint;
		this.openSign = true;
		this.showFrame = false;
		this.generatingPdf = true;
		this.urlFrame = '';
		jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
		jQuery("#signModal").modal("toggle");

		if (this.isApiV3) {
			const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
			var split = this.serviziPrintService.returnCoordinates().split(",");
			const coordinates = {
				width: 120,
				height: 37,
				x: split[0],
				y: split[3],
			}
			this.formController.manageAttivazioneServiziSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId, this.typeDocModuloServizi.note).then((res: any) => {
				this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
				setTimeout(() => {
					this.showFrame = true;
					this.sendingPdf = false;
					this.generatingPdf = false;

				}, 2000);
				clearInterval(interval);
			}, error => {
				this.generatingPdf = false;
				this.sendingPdf = false;
				this.ordine.attivazioneServizi.statorichiestaCodice = "A";
				clearInterval(interval);
				this.closeSignPdf();
				jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#errorModal").modal("toggle");
			});
		} else {
			const newPdf = btoa(pdf.output());
			const coordinates = this.serviziPrintService.returnCoordinates();
			this.formController
				.sendAttivazioneServiziSignature(
					newPdf,
					this.form.idOrder,
					this.form,
					pages,
					coordinates,
					title,
					true
				)
				.then(
					(res) => {
						this.member = res.members[0].id;
						this.urlFrame =
							environment.apiUrl +
							"/procedure/sign?members=" +
							this.member +
							"&signatureUi=" +
							environment.signAriel; 
						this.showFrame = true;
						this.sendingPdf = false;
						this.generatingPdf = false;

						clearInterval(interval);
					},
					(error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.attivazioneServizi.statorichiestaCodice = "A";
            clearInterval(interval);
						jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
						jQuery("#errorModal").modal("toggle");
					}
				);
		}
	}

  	public closeSignPdf(): void {
		jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#signModal').modal('toggle');
		this.reload.emit(this.openSign);
		this.setStateToActive();
		this.formController.removeFrame();
	}

	private setStateToActive() {

		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
		  this.ordine = res;
		  this.ordine.attivazioneServizi.statorichiestaCodice = "A";
		  this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
			this.getOrder();
		  });
		})
	}

	getOrder(): void {
		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
		  this.ordine = res;
		  this.brandService
			.getDataByProduct(Number(localStorage.getItem("id_product")))
			.subscribe((resp: any) => {
			  this.trascodifiche = resp;
			  this.getAnagrafica();
			});
		});
		this.loadAll = true;
	  }

  	returnIdOrdine(): string {
		return localStorage.getItem("id_order");
	}

  	private createPdf(pdf): void {
		this.sendingPdf = true;
		this.openSign = true;
		const title = localStorage.getItem("id_order");
		pdf.setProperties({
			title,
		});

		const newPdf = btoa(pdf.output());
		const pages = this.serviziPrintService.returnCurrentPage();
		const coordinates = this.serviziPrintService.returnCoordinates();
		this.formController
			.sendSignature(
				newPdf,
				this.idOrdine,
				this.form,
				pages,
				coordinates,
				title,
				false
			)
			.then(
				(res) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.router.navigate(["order-list"]);
				},
				(error) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.error = error;
					jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
					jQuery("#errorModal").modal("toggle");
				}
			);
	}

  	startTimer(): any {
		const intervalId = setInterval(() => {
			if (this.timeLeft === 0) {
				clearInterval(intervalId);
				// richiamo api per invio email
				//this.orderService.sendEmail(this.form.idOrder).subscribe();
				jQuery("#timeoutModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#timeoutModal").modal("toggle");

				setTimeout(() => {
					jQuery("#timeoutModal").modal("hide");
				}, 10000);

				setTimeout(() => {
					this.formController.goToDashboard();
				}, 11000);
			}
			if (this.timeLeft > 0) {
				this.timeLeft--;
			}
		}, 1000);
		return intervalId;
	}
  
	// Others method
	private openDialog(pdf: any): void {
		this.dialog.open(PreviewComponent, {
			width: "800px",
			maxWidth: "100%",
			data: {
				pdf,
			},
		});
	}
}
