<div class="anagraficaServiziBg"  [ngStyle]="{'background': productColor}"> SERVIZI </div>

<div class="anagraficaContainer">
  <div *ngIf="form.sections.moduloServizi">
    <div class="row justify-content-left align-items-left pt-4 pb-4">
      <div *ngFor="let servizio of listServizi; let i = index" class="col-12">
        <mat-checkbox class="mr-2" color="primary" 
          [checked]="isChecked(servizio.id)" (change)="onOptionsChange($event, servizio)">
          <strong *ngIf="servizio.note">{{ servizio.note }}</strong> {{ convertTrascodifica(servizio.id) }}
        </mat-checkbox>

        <div *ngIf="listServizi.length === i + 1 && brand === 'allarmeMondial'">
          <div class="custom-checkbox-wrapper">
            <mat-checkbox class="mr-2" color="primary" [disableRipple]="true" [checked]="isCustomInputChecked(form.sections.servizioInput)">
            </mat-checkbox>
              <input 
                class="customInlineInputLong" 
                [(ngModel)]="form.sections.servizioInput.descrizione" 
                matInput 
                type="text" 
                placeholder="Descrizione">
                {{constants.LABEL_MOND_ALL_ACT_SERV}}
              <input 
                class="customInlineInput" 
                [(ngModel)]="form.sections.servizioInput.costo" 
                matInput 
                type="number" 
                placeholder="Costo">
                {{constants.LABEL_MOND_ALL_ACT_SERV_IVA}}
          </div>
        </div>
      </div>
    </div>
  </div>