import { Component } from "@angular/core";
import { PrintserviceService } from "src/app/services/print/printservice.service";
import { Trascodifica } from "src/app/modules/classes/trascodifica";
import { MontascaleDto } from "src/app/modules/classes/zucchetti/montascale/montascale";
import { DepuratoreDto } from "src/app/modules/classes/depuratore-dto";
import { AllarmeDTO } from 'src/app/modules/classes/allarme/allarme.dto';
import { PoltroneDto } from "src/app/modules/classes/zucchetti/poltrone/poltrone";
import { OptionePoltronaDto } from "src/app/modules/classes/zucchetti/poltrone/options_poltrone";
import { constants } from 'src/app/modules/classes/constants';
import autoTable from 'jspdf-autotable'
import jsPDF from "jspdf";

@Component({
  selector: "app-print-shared",
  templateUrl: "./print.component.html",
  styleUrls: ["./print.component.scss"],
})
export class PrintComponent {

  doc: jsPDF;
  creatingPdf: boolean = false;
  currentPdfPage: number = 1;
  pageNumber: number = 1;
  pageWidth: number = 297;
  yStartPage: number = 28;
  img: string;
  title: string;
  ordine: any;
  pagamento: any;
  brand: string;

  color: number[];

  constructor(
    public printService: PrintserviceService
  ) { }

  initPdf(ordine: any, anagrafica: any, img: string, title: string, color?: string, brand?: string): number {

    this.creatingPdf = true;
    this.brand = brand;
    this.printService.setCurrentPage(this.pageNumber);
    this.doc = new jsPDF();
    this.pageWidth = this.doc.internal.pageSize.getWidth();

    this.img = img;
    this.title = title;
    this.printService.createHeader(this.doc, this.img, this.title, brand);

    let y = this.yStartPage;

    y = this.printService.createDocumentInfos(
      this.doc,
      y,
      this.pagamento,
      0,
      ordine,
      this.img,
      this.title,
      this.currentPdfPage,
      this.color
    );

    y = this.createLine(y);

    this.createTableAnagrafica(this.doc, anagrafica, y, color, brand);

    y = this.printService.returnCurrentY();

    this.createTableIndirizzo(this.doc, anagrafica, y, color, brand);

    y = this.printService.addLine(
      y + 5,
      this.doc,
      ordine,
      this.img,
      this.title
    );
    y = this.printService.returnCurrentY();
    this.printService.writeFooterHeader(this.doc, y, ordine);
    y = this.printService.returnCurrentY();

    y = this.printService.addLine(y, this.doc, ordine, this.img, this.title);
    return y;
  }

  createLine(y: number): number {
    y = this.printService.addLine(
      y,
      this.doc,
      this.ordine,
      this.img,
      this.title
    );
    this.printService.setCurrentY(y);
    return y;
  }

  createTableText(title?: any, content?: any, y?: number, doc?: any, color?: string): void {

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 5, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [title],
      body: [[content]],
      startY: this.printService.returnCurrentY(),
    });
    y = doc.lastAutoTable.finalY + 10
    this.printService.setCurrentY(y)
  }

  createTableTextNoheader(title?: any, content?: any, y?: number, doc?: any, color?: number[]): void {

    if (content) {
      if (typeof content === 'boolean') {
        content = content ? 'Si' : 'No';
      }
    }

    const colorString = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      body: [[`${title} : ${content}`]],
      startY: this.printService.returnCurrentY(),
      styles: {
        cellPadding: 5,
        textColor: [0, 0, 0], 
        lineColor: colorString,
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    this.printService.setCurrentY(y);
  }

  createTableTextNote(title?: any, content?: any, y?: number, doc?: any, color?: number[]): void {

    if (content) {
      if (typeof content === 'boolean') {
        content = content ? 'Si' : 'No';
      }
    }

    const colorString = `rgb(${color[0]}, ${color[1]}, ${color[2]})`; 

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      body: [[`${title} : ${content}`]],
      startY: this.printService.returnCurrentY(),
      styles: {
        cellPadding: 5,
        textColor: [0, 0, 0], 
        lineColor: colorString,
      },
      bodyStyles: {
        fontStyle: "bold"
      }
    });

    y = doc.lastAutoTable.finalY + 10;
    this.printService.setCurrentY(y);
  }

  createTableAnagrafica(doc?: any, anagrafica?: any, y?: number, color?: string, brand?: string): void {
    const rows = [
      [['CLIENTE'], [anagrafica?.nome + ' ' + anagrafica?.cognome]],
      ((anagrafica?.ragionesociale && anagrafica?.ragionesociale?.trim() !== '') ? [['RAGIONE SOCIALE'], [anagrafica.ragionesociale]] : null),
      [['EMAIL'], [anagrafica.email]],
      [[anagrafica.cf.length === 16 ? 'CODICE FISCALE' : 'P.IVA'], [anagrafica.cf]],
      [['TELEFONO'], [anagrafica.tel1]],
    ].filter(row => row !== null);

    if (anagrafica.tel2) {
      let cell = [['TELEFONO 2'], [anagrafica.tel2]];
      rows.push(cell);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 5, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      bodyStyles: brand === constants.BRAND_LOREN ?
        { lineWidth: constants.LOREN_TABLE_LINE_THICKNESS, lineColor: 'black' } : null,
      head: [[{ content: 'CAPITOLATO FORNITURA', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.printService.returnCurrentY()
    });
    y = doc.lastAutoTable.finalY + 10
    this.printService.setCurrentY(y)
  }

  createTableIndirizzo(doc?: any, anagrafica?: any, y?: number, color?: string, brand?: string): void {
    const rows = [
      [["CITTA'"], [anagrafica.citta + ', ' + anagrafica.cap + ', ' + anagrafica.provincia]],
      [['INDIRIZZO'], [(!anagrafica.indirizzo.includes("via")) ? `via ${anagrafica.indirizzo} , ${anagrafica.numero}` : (anagrafica.indirizzo + ', ' + anagrafica.numero)]],
    ]
    if (anagrafica.ztl) {
      let cell = [['ZTL'], ['SI']];
      rows.push(cell);
    }
    if (anagrafica.diversoind && anagrafica.indirizzoinst) {
      let cell = [['INDIRIZZO INSTALLAZIONE'], [anagrafica.indirizzoinst]];
      rows.push(cell);
    }
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 5, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      bodyStyles: brand === constants.BRAND_LOREN ?
        { lineWidth: constants.LOREN_TABLE_LINE_THICKNESS, lineColor: 'black' } : null,
      head: [[{ content: 'INDIRIZZO', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.printService.returnCurrentY()
    });
    y = doc.lastAutoTable.finalY + 10
    this.printService.setCurrentY(y)
  }

  createTablePagamento(doc?: any, pagamento?: any, ordine?:any, y?: number, indice?: number, color?: string, brand?: string, prodotto?: string ): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    const rows = [
      [['TOTALE'], [pagamento[indice]?.totaleordine ? pagamento[indice]?.totaleordine.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' : '']],
      [['INCLUSA IVA'], [pagamento[indice]?.percivaDescrizione]],
      ((pagamento[indice]?.tipodetrazioneDescrizione ? [['DETRAZIONE'], [pagamento[indice]?.tipodetrazioneDescrizione]] : null)),
      [['CAPARRA CONFIRMATORIA'], [pagamento[indice]?.caparraconf !== null ? pagamento[indice]?.caparraconf.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' : '']],
      [["MODALITA' DI PAGAMENTO"], [pagamento[indice]?.metodopagDescrizione]],
      [['* SALDO PRIMA DEI LAVORI'], [pagamento[indice]?.saldoinizlav ? pagamento[indice]?.saldoinizlav.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' : '']],
      [['TIPO DI SALDO'], [pagamento[indice]?.tipopagsaldoDescrizione]],
      [['FATTURA IMMEDIATA'], [(pagamento[indice]?.fatturaimmediata ? "si" : "no")]],
    ].filter(row => row !== null);

    if (pagamento[indice]?.note !== undefined && pagamento[indice]?.note !== null && pagamento[indice]?.note !== '') {
      let cell = [['NOTE DEL PAGAMENTO'], [pagamento[indice]?.note]];
      rows.push(cell);
    }

    y = this.printService.returnCurrentY();

    doc.autoTable(null, rows, {
      theme: 'grid',
      margin: { top: 30, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      bodyStyles: brand === constants.BRAND_LOREN ?
        { lineWidth: constants.LOREN_TABLE_LINE_THICKNESS, lineColor: 'black' } : null,
      head: [[{ content: 'PAGAMENTO', colSpan: 2, styles: { halign: 'center' } }]],
      columnStyles: {
        0: { cellWidth: 70 }, // Larghezza fissa per la prima colonna
        1: { cellWidth: 120 }, // Larghezza fissa per la seconda colonna
      },
      startY: y + 5,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false; 
        } else {
          isTableStartedNewPage = true; 
          this.currentPdfPage++;
        }
      },
    });

    let finalY = doc.lastAutoTable.finalY + 10;

    if (constants.LABEL_INFO_PAGAMENTO_TEMPI) {
      this.printService.setCurrentY(finalY);
      finalY = this.printService.writeLongText(doc, 7, finalY, constants.LABEL_INFO_PAGAMENTO_TEMPI, 10, ordine, this.img, this.title, this.currentPdfPage, false);
    }

    if (prodotto === constants.PRODUCT_CALDAIA && constants.LABEL_INFO_PRATICHE_EDILIZIE) {
      finalY = this.printService.writeLongText(doc, 7, finalY + 10, constants.LABEL_INFO_PRATICHE_EDILIZIE, 10, ordine, this.img, this.title, this.currentPdfPage, false);
    }

    this.printService.setCurrentY(finalY + 20);
  }

  genericPrintImages(doc?: any, y?: number, disegno?: any, disegno2?: any, tableTitle?: string, tableTitle2?: string, desiredWidth?: number, proportionalHeight?: number, colorString?: string, color?: number[]): void {

    y = this.printService.returnCurrentY();

    if ((y + proportionalHeight) > 270) {
      this.printService.addNewPage(doc, true, this.img, this.ordine, this.currentPdfPage, this.title);
      this.currentPdfPage = this.printService.returnCurrentPage();
      y = this.printService.returnCurrentY();
    }

    if (tableTitle && tableTitle2) {
      tableTitle = `${tableTitle}                              |                                ${tableTitle2}`;
    } else if (tableTitle) {
      tableTitle = `${tableTitle}`;
    } else if (tableTitle2) {
      tableTitle = `${tableTitle2}`;
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, left: 10, right: 10 },
      startY: this.printService.returnCurrentY(),
      headStyles: { halign: 'center', fillColor: colorString, fontSize: 9 },
      head: [[{
        content: tableTitle,
        colSpan: 2, styles: { halign: 'center' }
      }]],
    });

    this.doc.setDrawColor(color[0], color[1], color[2]);

    this.doc.rect(
      11,
      y + 11,
      doc.internal.pageSize.getWidth() - 22,
      proportionalHeight + 5,
      'D'
    );

    this.printService.setCurrentY(y + 10);
    let baseY = y;
    y = this.printService.addLine(y, this.doc, null, this.img, '');


    if (disegno && disegno2 && tableTitle && tableTitle2) { // nel caso in cui ci siano entrambe i disegni    

      this.doc.setDrawColor(color[0], color[1], color[2]);  //linea divisoria verticale
      this.doc.line(106, baseY + 11, 106, this.printService.returnCurrentY() + proportionalHeight + 6);

      y = this.printService.returnCurrentY() + 10;

      this.doc.addImage(
        disegno,
        'JPEG',
        11,
        y,
        desiredWidth - 2,
        proportionalHeight - 2,
        '',
        'SLOW'
      );

      this.doc.addImage(
        disegno2,
        'JPEG',
        120,
        y,
        desiredWidth - 2,
        proportionalHeight - 2,
        '',
        'SLOW'
      );

    }
    else if (disegno) { // caso in cui c'e solo il primo disegno

      y += 10;

      //((this.doc.internal.pageSize.getWidth() - desiredWidth) / 2) per centrare l'immagine se solo 1
      this.doc.addImage(
        disegno,
        'JPEG',
        11,
        y,
        desiredWidth - 2,
        proportionalHeight - 2,
        '',
        'SLOW'
      );
    } else if (disegno2) {
      y += 10;
      this.doc.addImage(
        disegno2,
        'JPEG',
        11,
        y,
        desiredWidth - 2,
        proportionalHeight - 2,
        '',
        'SLOW'
      );
    }

    y += proportionalHeight + 10;
    this.printService.setCurrentY(y);

  }


  genericFotovoltaicoProductTable(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, color?: string): void {
    if (column.length && dati.length) {

      const rows = dati.map(item => {
        return [
          { content: item.tagliakwDescrizione, styles: { halign: 'center' } },
          { content: item.battkwDescrizione, styles: { halign: 'center' } },
          { content: item.inverterkwDescrizione, styles: { halign: 'center' } },
          { content: item.potenzakwDescrizione, styles: { halign: 'center' } }
        ];
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericCondizionatoreProductTable(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, color?: string, tipologie?: any, modelliInverter?: any, promoClimaDesc?: any): void {

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      const rows = dati.map(item => {
        const tipologia = item.modelloDescrizione;
        const promozione = this.returnInverterPromo(item.modelloId, tipologie, modelliInverter, promoClimaDesc);
        const quantita = this.returnInverterQt(item.modelloId, modelliInverter);


        return [
          { content: tipologia, styles: { halign: 'center' } },
          { content: quantita, styles: { halign: 'center' } },
          { content: promozione, styles: { halign: 'center' } },
        ];
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericCondizionatoreDatiAbitazioneTable(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    let rows = [
      [["ABITAZIONE"], [dati.tipoabitazioneDescrizione]],
      [['NUMERO PIANI STABILE'], [dati.pianistabile]],
      [["PIANO INTERVENTO'"], [dati.pianointerv]],
      [['ALTEZZA LOCALE ABITAZIONE MT'], [dati.altezza]],
      [['SPESSORE MURO PERIMETRALE CM'], [this.addUnitOfMeasure(dati.murospess, 'cm')]],
      [['VOLUME TOTALE ABITAZIONE M³'], [dati.volumetot]],
      [['VOLUME CLIMATIZZABILE M³'], [dati.volumeclima]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        }
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericDettagliInstallazione(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {
    y = this.printService.returnCurrentY();

    let rows = [
      [["INSTALLAZIONE CON PREDISPOSIZIONE"], [dati.installazioneconpred ? 'Si' : null]],
      [["QUANTITÀ"], [dati.qtainstconpred]],
      [["INSTALLAZIONE SENZA PREDISPOSIZIONE"], [dati.installazionesenzapred ? 'Si' : null]],
      [['QUANTITÀ'], [dati.qtainstsenzapred]],
      [['Staffe U.E'], [dati.staffeue ? 'Si' : null]],
      [['Posizioni U.E'], [dati.posizioneueDescrizione]],
      [['QUANTITÀ'], [dati.qtaue]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY()
      });
    }

    y = doc.lastAutoTable.finalY + 10;
    this.printService.setCurrentY(y);
  }

  genericAccessoriCond(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [["SMONTAGGIO VECCHIO IMPIANTO"], [dati.smontaggiovecchioimp ? 'Si' : null]],
      [["POMPA SCARICO CONDENSA"], [dati.pompacaricocondensa ? 'Si' : null]],
      [['QUANTITÀ'], [dati.numpompecondensa]],
      [['Ponteggio da Mt.3 a Mt.6'], [dati.ponteggio ? 'Si' : null]],
      [["CESTA IDRAULICA OLTRE MT.6 (verificare sempre che le aree di accesso all'abitazione presentino degli spazi adeguati al passaggio e al posizionamento del mezzo)"], [dati.cestaidraulica ? 'Si' : null]],
      [['SUOLO PUBBLICO'], [dati.suoloPubblico ? 'Si' : null]],
      [['KIT LINEA IDRAULICA MT.3, TUBAZIONE FRIGORIFERA + CANALINA PVC: QT.À'], [dati.kitlineaidr]],
      [['AGGIUNTA GAS'], [dati.aggiuntagas ? 'Si' : null]],
      [['FORI MURO QUANTITÀ'], [dati.forimuroqta]],
      [['FORI MURO CM'], [this.addUnitOfMeasure(dati.forimurocm, 'cm')]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericOmaggio(doc?: any, dati?: any, y?: number, color?: string, isHtml?: boolean): void {
    const tableTitle = 'OMAGGIO';

    const column = [
      "DESCRIZIONE",
      "QUANTITA'"
    ]

    if (column.length && dati.length) {

      const rows = dati.map(o => {
        return [
          { content: isHtml ? this.cleanHtmlString(o.tipologiaDescrizione) : o.tipologiaDescrizione, styles: { halign: 'center' } },
          { content: o.quantita, styles: { halign: 'center' } },
        ];
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  //Caldaia 
  genericPrintCaldaiaProduct(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      const rows = dati.map(item => {

        if (item.modcaldcondecowdDescrizione && item.tipologiaDescrizione && item.coloreDescrizione) { 
          return [
            { content: item.modcaldcondecowdDescrizione, styles: { halign: 'center' } },
            { content: item.tipologiaDescrizione, styles: { halign: 'center' } },
            { content: item.coloreDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modcaldcondecowauDescrizione && item.tipologiaDescrizione) {
          return [
            { content: item.modcaldcondecowauDescrizione, styles: { halign: 'center' } },
            { content: item.tipologiaDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modcaldcondecowallDescrizione && item.tipologiaDescrizione) {
          return [
            { content: item.modcaldcondecowallDescrizione, styles: { halign: 'center' } },
            { content: item.tipologiaDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modcaldacqhwDescrizione && item.tipologiaDescrizione) {
          return [
            { content: item.modcaldacqhwDescrizione, styles: { halign: 'center' } },
            { content: item.tipologiaDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modcaldcondcamDescrizione && item.tipologiaDescrizione) {
          return [
            { content: item.modcaldcondcamDescrizione, styles: { halign: 'center' } },
            { content: item.tipologiaDescrizione, styles: { halign: 'center' } },
          ];
        } else if(item.modcaldcondEasyDescrizione && item.tipologiaDescrizione) {
          return [
            { content: item.modcaldcondEasyDescrizione, styles: { halign: 'center' } },
            { content: item.tipologiaDescrizione, styles: { halign: 'center' } }
          ];
        } else {
          return [
            { content: item.modcaldcondecowDescrizione, styles: { halign: 'center' } },
            { content: item.tipologiaDescrizione, styles: { halign: 'center' } }
          ];
        }
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  generiCaldaiaDisegnoEsistente(doc?: any, dati?: any, y?: number, color?: string): void {
    const tableTitle = 'DISEGNO CALDAIA ESISTENTE';

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows;

    y = this.printService.returnCurrentY();


    if (dati.tipoDescrizione.trim() !== 'NESSUNA') {
      rows = [
        [["TIPO CALDAIA ESISTENTE"], [dati.tipoDescrizione]],
        [["ALTEZZA"], [this.addUnitOfMeasure(dati.altezza, 'cm')]],
        [["LARGHEZZA"], [this.addUnitOfMeasure(dati.larghezza, 'cm')]],
        [["PROFONDITÀ"], [this.addUnitOfMeasure(dati.profondita, 'cm')]],
        [["COMBUSTIBILE"], [dati.combustibileDescrizione]],
        [["N° TERMOSIFONI"], [dati.numtermo]],
      ]
    } else {
      rows = [
        [["TIPO CALDAIA ESISTENTE"], [dati.tipoDescrizione]],
        [["COMBUSTIBILE"], [dati.combustibileDescrizione]],
        [["N° TERMOSIFONI"], [dati.numtermo]],
      ]
    }

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        }
      });
    }

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericCaldaiaDatiAbitazioneTable(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    let rows = [
      [["ABITAZIONE"], [dati.tipoabitazioneDescrizione]],
      [['SPESSORE MURO'], [this.addUnitOfMeasure(dati.murospess, 'cm')]],
      [["PIANO CALDAIA"], [dati.pianointerv]],
      [['LOCALE CALDAIA'], [dati.locale]],
      [['N° PIANI STABILE'], [dati.pianistabile]],
      [['METRI QUADRI'], [this.addUnitOfMeasure(dati.mq, 'm²')]],
      [['CANNA FUMARIA'], [dati.cannafumariaDescrizione]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        }
      });
    }

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericCaldaiaDettagliInstallazioneTable(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    let rows = [
      [["INSTALLAZIONE"], [dati.installazioneDescrizione]],
      [['USCITA FUMI'], [dati.uscitafumiDescrizione]],
      [["PRELIEVO D'ARIA"], [dati.prelievoariaDescrizione]],
      [['SCARICO CONDENSA'], [dati.scaricocondDescrizione]],
      ...(typeof dati.necessarioForareTop === 'boolean'
        ? [
            [
              ["NECESSARIO FORARE TOP DELLA CUCINA"],
              [this.convertBoolValueNoValue(dati.necessarioForareTop)],
            ],
          ]
        : []
      ),
    ];

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        }
      });
    }

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericCaldaiaAccessoriTable(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    let rows = [
      [["LAVAGGIO IMPIANTI"], [this.convertBoolValue(dati.lavaggioimp)]],
      [['INTUBAZIONE CANNA FUMARIA N° PIANI'], [dati.intubcanna]],
      [["ACCESSO AL TETTO"], [this.convertBoolValue(dati.accessotetto)]],
      [['CESTA IDRAULICA'], [this.convertBoolValue(dati.cestaidr)]],
      [['PONTEGGIO'], [this.convertBoolValue(dati.ponteggio)]],
      [['SUOLO PUBBLICO'], [this.convertBoolValue(dati.suoloPubblico)]],
      [['CRONOTERMOSTATO WI-FI'], [this.convertBoolValue(dati.cronowifi)]],
      [['QUANTITÀ'], [dati.numcronowifi]],
      [['CRONOTERMOSTATO STANDAR'], [this.convertBoolValue(dati.cronostand)]],
      [['QUANTITÀ'], [dati.numcronostand]],
      [['VALVOLA TERMOSTATICA N°'], [dati.valvolatermo]],
      [['POMPA PER CONDENSA'], [this.convertBoolValue(dati.pompacond)]],
      [['DISPOSITIVO NOKAL ZONE'], [this.convertBoolValue(dati.nokalzone)]],
      [['ABBATTITORE DI ACIDITÀ'], [this.convertBoolValue(dati.abbattitore)]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        }
      });
    }

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  //Ariel Stufa
  genericStufaProductTable(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, color?: string): void {

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      const rows = dati.map(item => {

        if (item.modstufepelletDescrizione && item.volriscDescrizione && item.coloreDescrizione) {
          return [
            { content: item.modstufepelletDescrizione, styles: { halign: 'center' } },
            { content: item.volriscDescrizione, styles: { halign: 'center' } },
            { content: item.quantita, styles: { halign: 'center' } },
            { content: item.coloreDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modtermopelletDescrizione && item.volriscDescrizione && item.coloreDescrizione) {
          return [
            { content: item.modtermopelletDescrizione, styles: { halign: 'center' } },
            { content: item.volriscDescrizione, styles: { halign: 'center' } },
            { content: item.quantita, styles: { halign: 'center' } },
            { content: item.coloreDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modcaldpelletDescrizione && item.volriscDescrizione && item.coloreDescrizione) {
          return [
            { content: item.modcaldpelletDescrizione, styles: { halign: 'center' } },
            { content: item.volriscDescrizione, styles: { halign: 'center' } },
            { content: item.quantita, styles: { halign: 'center' } },
            { content: item.coloreDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modstufelegnaDescrizione && item.volriscDescrizione && item.coloreDescrizione) {
          return [
            { content: item.modstufelegnaDescrizione, styles: { halign: 'center' } },
            { content: item.volriscDescrizione, styles: { halign: 'center' } },
            { content: item.quantita, styles: { halign: 'center' } },
            { content: item.coloreDescrizione, styles: { halign: 'center' } },
          ];
        }
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericStufeBioPrint(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, color?: string): void {

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      const rows = dati.map(item => {

        if (item.modstufebioDescrizione && item.volriscDescrizione && item.coloreDescrizione) {
          return [
            { content: item.modstufebioDescrizione, styles: { halign: 'center' } },
            { content: item.volriscDescrizione, styles: { halign: 'center' } },
            { content: item.quantita, styles: { halign: 'center' } },
            { content: item.coloreDescrizione, styles: { halign: 'center' } },
            { content: this.convertBoolValueNoValue(item.bollinoSconto), styles: { halign: 'center' } },
          ];
        }
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericStufaDatiAbitazioneTable(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    let rows = [
      [["ABITAZIONE"], [dati.tipoabitazioneDescrizione]],
      [['NUMERO PIANI STABILE'], [dati.pianistabile]],
      [["PIANO INTERVENTO"], [dati.pianointerv]],
      [['LOCALE STUFA / IDRO'], [dati.locale]],
      [['ALTEZZA LOCALE ABITAZIONE mt'], [this.addUnitOfMeasure(dati.altezza, 'mt')]],
      [['SPESSORE MURO PERIMETRALE CM'], [this.addUnitOfMeasure(dati.murospess, 'cm')]],
      [['VOLUME TOTALE ABITAZIONE M³'], [this.addUnitOfMeasure(dati.volumetot, 'm³')]],
      [['VOLUME RISCALDABILE M³'], [this.addUnitOfMeasure(dati.volrisc, 'm³')]],
      [['CANNA FUMARIA'], [dati.cannafumariaDescrizione]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        }
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericStufaDettagliInstallazioneTable(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    let rows = [
      [["INSTALLAZIONE STANDARD IN CANNA FUMARIA ESISTENTE"], [this.convertBoolValue(dati.installazionestand)]],
      [["INTUBAMENTO CANNA FUMARIA, PIANI"], [dati.intubamentocannapiani]],
      [["ACCESSO A TETTO DA BOTOLA/SCALA/ALTRO"], [this.convertBoolValue(dati.accessotettobotola)]],
      [["INSTALLAZIONE STANDARD CREANDO CANNA FUMARIA ESTERNA DI PIANO NUMERO"], [dati.instalstandesterna]],
      [["INSTALLAZIONE STANDARD CREANDO CANNA FUMARIA INTERNA DI PIANO NUMERO"], [dati.instalstandinterna]],
      [[constants.LABEL_NO_CANNA_FUM_BIO?.toUpperCase()], [this.convertBoolValue(dati.nocannaFumBio)]],
      [["QUANTITA' FORI SOLETTA"], [dati.forosolettaqta]],
      [["QUANTITÀ FORI CEMENTO ARMATO/PIETRA"], [dati.forocementoqta]],
      [["FORO CEMENTO ARMATO/PIETRA CM"], [this.addUnitOfMeasure(dati.forocementocm, 'cm')]],
      [["FORI MURO"], [dati.foromuroqta]],
    ]


    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        }
      });
    }

    this.printService.setCurrentY(y);

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericStufaAccessoriTable(doc?: any, dati?: any, y?: number, titleTable?: string, color?: string) {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [["PONTEGGIO DA MT.3 A MT.6"], [this.convertBoolValue(dati.ponteggio)]],
      [["CESTA IDRAULICA OLTRE MT.6"], [this.convertBoolValue(dati.cestaidr)]],
      [["SUOLO PUBBLICO"], [this.convertBoolValue(dati.suoloPubblico)]],
      [["SCAMBIATORE"], [this.convertBoolValue(dati.scambiatore)]],
      ...(dati.scambiatore
        ? [
          [
            "* Scambiatore: (da usare quanto il Cliente vuole produrre acqua calda tecnica anche con generatore di calore esistente)",
            ["-"],
          ],
        ]
        : []
      ),
      [["KIT ATTRAVERSAMENTO TETTI IN LEGNO"], [this.convertBoolValue(dati.kitAttraversamento)]],
      [["CONTRIBUTO DI TRASPORTO TERMOSTUFA"], [this.convertBoolValue(dati.contributotraspTerm)]],
      [["PUFFER"], [dati.pufferDescrizione]],
      [["ASPIRACENERE"], [this.convertBoolValue(dati.aspiracenere)]],
      [["600 KG DI PELLET IN OMAGGIO (40 SACCHI) CONSEGNATI AL PIANO STRADA"], [this.convertBoolValue(dati.pelletomaggio)]],
      [["MODULO WI-FI"], [this.convertBoolValue(dati.modulowifi)]],
    ]


    if (this.convertBoolValue(dati.pelletomaggio)) {
      rows.push([["* La consegna avviene al piano strada e non è previsto nessun servizio di facchimaggio;"], ["-"]]);
      rows.push([["* La consegna del pellet avviene ad avvenuto pagamento integrale dalla fattura e normalmente entro 7 giorni dalla data di installazione della stufa;"], ["-"]])
      rows.push([["* In caso di \"località disagiata\", strada impervia o difficilmente raggiungibile il pellet non potrà essere consegnato e l'omaggio verrà sostituito con altro omaggio di pari valore a scelta del Cliente tra quelli disponibili."], ["-"]])
    }

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });
    }

    this.printService.setCurrentY(y);

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  //Ariel PompaCalore
  genericPompaCaloreProductTable(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, color?: string): void {

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      const rows = dati.map(item => {

        return [
          { content: item.modibrepomcalDescrizione ? item.modibrepomcalDescrizione : '-', styles: { halign: 'center' } },
          { content: item.potenzaibrepomcalDescrizione ? item.potenzaibrepomcalDescrizione : '-', styles: { halign: 'center' } },
        ];
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  //VASCA IN DOCCIA

  genericNuoviDatiTecnici(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      // PIATTO DOCCIA
      [["PIATTO DOCCIA PROFONDITÀ"], [this.addUnitOfMeasure(dati.piattop, 'cm')]],
      [["PIATTO DOCCIA LARGHEZZZA"], [this.addUnitOfMeasure(dati.piattol, 'cm')]],
      [["PIATTO DOCCIA MURO SINISTRA"], [this.convertBoolValue(dati.murosx)]],
      [["PIATTO DOCCIA MURO DESTRA"], [this.convertBoolValue(dati.murodx)]],
      [["MURO SINISTRO E DESTRO"], [this.convertBoolValue(dati.muroSxDx)]],
      [["NESSUN MURO LATERALE"], [this.convertBoolValue(dati.noMuro)]],

      // BOX DOCCIA
      [["BOX PROFONDITÀ"], [this.addUnitOfMeasure(dati.boxl, 'cm')]],
      [["BOX LARGHEZZZA"], [this.addUnitOfMeasure(dati.boxp, 'cm')]],

    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length > 0) {
      rows.push([
        ["BOX Altezza (H)"],
        ["200 cm"],
      ]);
      rows.push([
        ["Escluso piatto doccia H 6,5"],
        ["-"],
      ]);
    }

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericNuovoPiattoDoccia(doc?: any, dati?: any, y?: number, titleTable?: string, trascodifiche?: Trascodifica[], color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];


    if (dati) {
      if (dati?.misurapiattostrId) {
        rows = [
          [["PIATTI A BORDO STRETTO in metacrilato colato con rinforzo in vetroresina"], ["colore bianco "]],
          [['MISURE'], [this.addUnitOfMeasure(dati?.misurapiattostrDescrizione ? dati?.misurapiattostrDescrizione : null, 'cm')]],
        ];
      } else if (dati?.misurapiattolargId) {
        rows = [
          [["PIATTO A BORDO LARGO COLORE"], [(dati.colorepiattolargDescrizione?.toString().toLowerCase() ? dati.colorepiattolargDescrizione?.toString().toLowerCase() : null)]],
          [['MISURE'], [this.addUnitOfMeasure(dati.misurapiattolargDescrizione ? dati.misurapiattolargDescrizione : null, 'cm')]],
        ];
      }

      rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericPiattoDocciaEffPietra(doc?: any, dati?: any, y?: number, titleTable?: string, trascodifiche?: Trascodifica[], color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati) {
      rows = [
        [["COLORE"], [this.getTrascodificaById(dati?.colorepiateffpietraId, trascodifiche, true)]],
        [['MISURE'], [this.addUnitOfMeasure(dati?.dimpiatteffpietraDescrizione ? dati?.dimpiatteffpietraDescrizione : null, 'cm')]],
      ];

      rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');


      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericNuovoRivestimentoGres(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      // PIATTO DOCCIA
      [["ALTEZZA PARETE"], [dati.altezzapareteDescrizione ? dati.altezzapareteDescrizione : null]],
      [["TIPO GRES PARETE"], [dati.tipo ? dati.tipo : null]],
      ...(dati.tipo
        ? [
          [
            "Il Gres viene posato in sovrapposizione*",
            [" "],
          ],
        ]
        : []
      ),
      [["PAVIMENTO"], [dati.pavimentoDescrizione ? dati.pavimentoDescrizione : null]],
      [["TIPO GRES PAVIMENTO"], [dati.tipopavimento ? dati.tipopavimento : null]],
      ...(dati.tipopavimento
        ? [
          [
            "Solo in caso di riduzione piatto doccia*",
            [" "],
          ],
        ]
        : []
      )
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericMiscelatoriVascaInDoccia(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [["DOCCIA"], [(dati.docciaDescrizione?.toString().toLowerCase() ? dati.docciaDescrizione?.toString().toLowerCase() : null)]],
      [["VASCA"], [(dati.vascaDescrizione?.toString().toLowerCase() ? dati.vascaDescrizione?.toString().toLowerCase() : null)]],
      [["LAVABO"], [(dati.lavaboDescrizione?.toString().toLowerCase() ? dati.lavaboDescrizione?.toString().toLowerCase() : null)]],
      [["BIDET"], [(dati.bidetDescrizione?.toString().toLowerCase() ? dati.bidetDescrizione?.toString().toLowerCase() : null)]],
    ];

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericDocciaSun(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati && dati.length > 0) {
      dati.forEach(item => {
        if (item.value !== 'No' && item.value.trim() !== '') {
          rows.push([[item.label.toUpperCase()], [item.value]]);
        }
      });
      rows.push([["N.B. Ingresso solo parte opposta al lato muro corto.Cristallo solo trasparente."], [""]]);
    }

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }


  //BAGNI_ITALIANI_HELP_75
  genericDatiTecniciAttuali(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [["LUOGO INSTALLAZIONE"], [dati.tipoAbitazione]],
      [["DI PROPRIETÀ"], [dati.diProprieta]],
      [["ASCENSORE"], [dati.ascensore]],
      [["ACCESSIBILITÀ CORTILE CON MEZZI"], [dati.accesscortile]],
      [["PIANO N°"], [dati.pianointervento]],
      [["LUNGHEZZA VANO DA RISTRUTTURARE"], [dati.lungvano]],
      [["LARGHEZZA VANO DA RISTRUTTURARE"], [dati.largvano]]
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  nuoviDatiTecniciBagni(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [["PIATTO DOCCIA PROFONDITA'"], [dati.piattop]],
      [["PIATTO DOCCIA LUNGHEZZA"], [dati.piattol]],
      [["MURO SINISTRO"], [this.convertBoolValue(dati.murosx)]],
      [["MURO DESTRO"], [this.convertBoolValue(dati.murodx)]],
      [["MURO SINISTRO E DESTRO"], [this.convertBoolValue(dati.muroSxDx)]],
      [["NESSUN MURO LATERALE"], [this.convertBoolValue(dati.noMuro)]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  nuovoPiattoDocciaPietra(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati.colorepiateffpietraDescrizione || dati.dimpiatteffpietraDescrizione) {
      rows = [
        [["PIATTO EFFETTO"], [dati.colorepiateffpietraDescrizione.toLowerCase()]],
        [["PIATTO DIMENSIONE"], [this.addUnitOfMeasure(dati.dimpiatteffpietraDescrizione, 'cm')]],
      ]
    }

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: y === 28 ? y : (y - 10),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  nuovoPiattoDocciaStone(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati.colorepiatstoneDescrizione || dati.dimpiattstoneDescrizione) {
      rows = [
        [["PIATTO EFFETTO"], [dati.colorepiatstoneDescrizione.toLowerCase()]],
        [["PIATTO DIMENSIONE"], [this.addUnitOfMeasure(dati.dimpiattstoneDescrizione, 'cm')]],
      ]
    }

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: (y === 28 || dati.colorepiateffpietraDescrizione || dati.dimpiatteffpietraDescrizione) ? y : (y - 10),  //se valorizzata tabella dei piatti sopra spazia.
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }


  genericNuovoRivestimentoGresBI(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      // INTERNO DOCCIA
      [["INTERNO DOCCIA"], [dati.altezzapareteDescrizione ? dati.altezzapareteDescrizione : null]],
      [["TIPO GRES INTERNO DOCCIA"], [dati.tipo ? dati.tipo : null]],
      [["FORMATO PIASTRELLE INTERNO DOCCIA"], [this.addUnitOfMeasure(dati.formatopiastrelle, 'cm')]],
      ...(dati.tipo
        ? [
          [
            "Il Gres viene posato in sovrapposizione",
            [" "],
          ],
        ]
        : []
      ),
      // PAVIMENTO
      [["PAVIMENTO"], [dati.pavimentoDescrizione ? dati.pavimentoDescrizione : null]],
      [["TIPO GRES PAVIMENTO"], [dati.tipopavimento ? dati.tipopavimento : null]],
      [["FORMATO PIASTRELLE PAVIMENTO"], [this.addUnitOfMeasure(dati.formatopareti, 'cm')]],
      ...(dati.tipopavimento
        ? [
          [
            "Il Gres viene posato in sovrapposizione. Non verranno eseguite le sostituzioni di tubazioni sottopavimento",
            [" "],
          ],
        ]
        : []
      )
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericPrintSanitariBagniHelp(doc?: any, dati?: any, tableTitle?: string, option?: boolean, column?: string[], y?: number, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      rows = dati.map(item => {

        if (item.modsanitariDescrizione && item.tiposcaricoDescrizione && item.tiposanitariDescrizione && item.tipoprodottoDescrizione) {
          return [
            { content: item.modsanitariDescrizione, styles: { halign: 'center' } },
            { content: item.tiposcaricoDescrizione, styles: { halign: 'center' } },
            { content: item.tiposanitariDescrizione, styles: { halign: 'center' } },
            { content: item.tipoprodottoDescrizione, styles: { halign: 'center' } },
          ];
        }
      })
    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    if (option && dati.length) {
      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericPrintCassettaWCBagniHelp(doc?: any, dati?: any, tableTitle?: string, option?: boolean, column?: string[], y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["CASSETTA"], [dati?.tipocasswcDescrizione]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');


    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericPrintRubinetteriaBagniHelp(doc?: any, dati?: any, tableTitle?: string, option?: boolean, column?: string[], y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["SERIE RUBINETTERIA"], [dati.rubSerie]],
      [["LAVABO"], [dati.lavabo]],
      [["BIDET"], [dati.bidet]],
      [["KIT DOCCIA"], [dati.kitDoccia]],
      [["MISCELATORE"], [dati.miscelatore]],
      [["ASTA"], [dati.asta]],
      [["ASTA DOCCIA DOPPIA CON MIX INTEGRATO COMBY"], [dati.astaForma]],
      [["COLONNE"], [dati.colonne]],
      [["MISCELATORE ESTERNO"], [dati.miscelatoreEst]],
      [["SERIE MISCELATORE"], [dati.miscelatoreSerie]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  //MONTASCALE ZUCCHETTI
  flowXCurvilineoStandarPack(doc?: any, dati?: MontascaleDto, tableTitle?: string, tableSubTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati.partenzaId && dati.partenzaDescrizione) {
      rows.push([["TIPO PARTENZA"], [this.capitalizeCase(dati.partenzaDescrizione)]]);
    }
    if (dati.arrivoId && dati.arrivoDescrizione) {
      rows.push([["TIPO ARRIVO"], [this.capitalizeCase(dati.arrivoDescrizione)]]);
    }
    if (dati.guidaId && dati.guidaDescrizione) {
      rows.push([["POSIZIONE GUIDA"], [this.capitalizeCase(dati.guidaDescrizione)]]);
    }
    if (dati.posizionejoyId && dati.posizionejoyDescrizione) {
      rows.push([["POSIZIONE JOYSTICK"], [(dati.posizionejoyDescrizione === 'DX' ? 'Destra' : 'Sinistra')]])
    }

    if (dati.coloreguida) {
      rows.push([["COLORE GUIDA"], [this.capitalizeCase(dati.coloreguida)]])
    }
    if (dati.tipologiarivestimento) {
      rows.push([["TIPOLOGIA RIVESTIMENTO"], [this.capitalizeCase(dati.tipologiarivestimento)]])
    }
    if (dati.colorerivestimento) {
      rows.push([["COLORE RIVESTIMENTO"], [this.capitalizeCase(dati.colorerivestimento)]])
    }

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: y,
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
    }

    this.printService.setCurrentY(y);
  }

  optionalGuidaMontascale(doc?: any, dati?: MontascaleDto, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati.curvaextran) {
      rows.push([["CURVA 90 EXTRA N°"], [dati.curvaextran]]);
    }
    if (dati.curven) {
      rows.push([["CURVE DA 180° N°"], [dati.curven]]);
    }
    if (dati.guidaextra) {
      rows.push([["GUIDA EXTRA AL METRO N°"], [dati.guidaextra]]);
    }
    if (dati.puntocarica) {
      rows.push([["PARCHEGGIO AGGIUNTIVO (punto carica compreso)"], [dati.puntocarica]])
    }

    if (dati.fermatagg) {
      rows.push([["FERMATA AGGIUNTIVA N°"], [dati.fermatagg]])
    }
    if (dati.partenzavert !== null) {
      rows.push([["PARTENZA VERTICALE CORTA"], [this.capitalizeCase(this.convertBoolValueNoValue(dati.partenzavert))]])
    }

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: y,
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
    }
    this.printService.setCurrentY(y);
  }

  datiTecniciAttualiMontascale(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [["LUOGO INSTALLAZIONE"], [this.capitalizeCase(dati.tipoabitazioneDescrizione)]],
      [["DI PROPRIETÀ"], [this.capitalizeCase(this.convertBoolValue(dati.proprieta))]],
      [["ASCENSORE"], [this.capitalizeCase(this.convertBoolValue(dati.ascensore))]],
      [["ACCESSIBILITÀ CORTILE CON MEZZI"], [this.capitalizeCase(this.convertBoolValue(dati.accesscortile))]],
      [["PIANO N°"], [dati.pianointervento]],
      [["N° RAMPE"], [dati.nRampe]],
      [["N° GRADINI"], [dati.nGradini]],
      [["ALZATA"], [this.addUnitOfMeasure(dati.alzata, 'cm')]],
      [["PEDATA"], [this.addUnitOfMeasure(dati.pedata, 'cm')]],
      [["LARGHEZZA SCALA"], [this.addUnitOfMeasure(dati.largScala, 'cm')]],
      [["LARGHEZZA PIANEROTTOLO"], [this.addUnitOfMeasure(dati.misuraPianerottolol, 'cm')]],
      [["ALTEZZA PIANEROTTOLO"], [this.addUnitOfMeasure(dati.misuraPianerottoloh, 'cm')]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }


  //ARIEL DEPURATORE
  modelloDepuratoreAriel(doc?: any, dati?: DepuratoreDto, tableTitle?: string, column?: string[], y?: number, trascodifiche?: Trascodifica[], color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati) {
      rows = [
        [
          { content: ('DEPURATORE ACQUA mod. ' + this.getTrascodificaById(dati.tipologiaId, trascodifiche, true)), styles: { halign: 'center' } },
          { content: dati.quantita, styles: { halign: 'center' } }
        ]
      ];

      rows.unshift(
        [
          { content: column[0], styles: { halign: 'center', fontStyle: 'bold' } },
          { content: column[1], styles: { halign: 'center', fontStyle: 'bold' } }
        ]
      );

      if (rows.length) {
        autoTable(doc, {
          theme: 'grid',
          margin: { top: 30, bottom: 10, left: 10, right: 10 },
          headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
          head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
          body: rows,
          startY: this.printService.returnCurrentY(),
          didDrawCell: (data: any) => {
            lastCellY = data.cell.y + data.cell.height;
          },
          didDrawPage: (data: any) => {
            this.printService.createHeader(this.doc, this.img, this.title, this.brand);
            this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

            if (data.pageNumber === data.pageCount) {
              isTableDrawn = true;
              isTableStartedNewPage = false;
            } else {
              isTableStartedNewPage = true;
              this.currentPdfPage++;
            }
          },
        });

        y = doc.lastAutoTable.finalY + 10;
        if (y > 265) {
          this.currentPdfPage++;
          this.printService.setCurrentPage(this.currentPdfPage);
        }
        this.printService.setCurrentY(y);
      }
    }
  }

  accessoriDepuratoreAriel(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, trascodifiche?: Trascodifica[], color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      dati.forEach(item => {

        if (item.tipologiaId && item.quantita) {
          rows.push(
            [
              { content: this.getTrascodificaById(item.tipologiaId, trascodifiche, true), styles: { halign: 'center' } },
              { content: item.quantita, styles: { halign: 'center' } }
            ]);
        }
      })
    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    if (dati.length) {
      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }


  //BAGNI ITALIANI
  rivestimentiBagniItaliani(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["PAVIMENTO"], [dati.pavimentodesc]],
      [["PARETI"], [dati.paretedesc]],
      [["PARETE A"], [dati.paretea]],
      [["PARETE B"], [dati.pareteb]],
      [["PARETE C"], [dati.paretec]],
      [["PARETE D"], [dati.pareted]],
      [["INTERNO DOCCIA"], [dati.internodoccia]],
      [["NOTE"], [dati.note]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  nuovaCassettaWc(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["TIPO"], [dati?.tipocasswcDescrizione]],
      [["MURETTO"], [dati?.murettoModelDescrizione]],
      [["CASSETTA MONOLITE VETRO BIANCO PER WC"], [this.convertBoolValue(dati?.cassettamonolitewc)]],
      [["CASSETTA MONOLITE VETRO BIANCO PER BIDET "], [this.convertBoolValue(dati?.cassettamonolitebidet)]],
      [["COLORE PLACCA"], [dati?.coloreplaccawcDescrizione]],
      [["FORMA PLACCA"], [dati?.tipoplaccawcDescrizione]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  opereIdraulicheImbScrost(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["IMBIANCATURA SOFFITTO E PARETI SOPRA LE PIASTRELLE CON PITTURA LAVABILE BIANCA"], [this.convertBoolValue(dati.imbsoffitto)]],
      [["NECESSARIA SCOSTATURA E RASATURA SOFFITTO E PARETI"], [this.convertBoolValue(dati.necscrostatura)]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: y,
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
    }
    this.printService.setCurrentY(y);
  }




  /* UTILS & REUSABLE METHODS */
  genericOnlyTitleCenter(doc?: any, y?: number, sectionTitle?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = null; // null perchè usiamo solo il titolo

    if (y > 270) {
      this.printService.addNewPage(doc, true, this.img, this.ordine, this.currentPdfPage, this.title);
      this.currentPdfPage = this.printService.returnCurrentPage();
      y = this.printService.returnCurrentY();
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 0, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: sectionTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericOnlyTitlePaddingCenter(doc?: any, y?: number, sectionTitle?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = null; // null perchè usiamo solo il titolo

    if (y > 270) {
      this.printService.addNewPage(doc, true, this.img, this.ordine, this.currentPdfPage, this.title);
      this.currentPdfPage = this.printService.returnCurrentPage();
      y = this.printService.returnCurrentY();
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: sectionTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  opereIdraulicheBagniItaliani(doc?: any, rows?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericNuovoPiattoDocciaBagniItaliani(doc?: any, dati?: any, y?: number, border?: string, titleTable?: string, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati) {
      if (border === 'LARGO') {
        rows = [
          [["PIATTO A BORDO LARGO COLORE"], [(dati.colorepiattolargDescrizione?.toString().toLowerCase() ? dati.colorepiattolargDescrizione?.toString().toLowerCase() : null)]],
          [['MISURE'], [this.addUnitOfMeasure(dati.misurapiattolargDescrizione ? dati.misurapiattolargDescrizione : null, 'cm')]],
        ];
      }
      if (border === 'STRETTO') {
        rows = [
          [["PIATTI A BORDO STRETTO in metacrilato colato con rinforzo in vetroresina"], ["colore bianco "]],
          [['MISURE'], [this.addUnitOfMeasure(dati?.misurapiattostrDescrizione ? dati?.misurapiattostrDescrizione : null, 'cm')]],
        ];
      }
      if (border === 'LARGOeSTRETTO') {
        rows = [
          [["PIATTO A BORDO LARGO COLORE"], [(dati.colorepiattolargDescrizione?.toString().toLowerCase() ? dati.colorepiattolargDescrizione?.toString().toLowerCase() : null)]],
          [['MISURE'], [this.addUnitOfMeasure(dati.misurapiattolargDescrizione ? dati.misurapiattolargDescrizione : null, 'cm')]],
          [["PIATTI A BORDO STRETTO in metacrilato colato con rinforzo in vetroresina"], ["colore bianco "]],
          [['MISURE'], [this.addUnitOfMeasure(dati?.misurapiattostrDescrizione ? dati?.misurapiattostrDescrizione : null, 'cm')]],
        ];
      }
      rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');
    } else {
      rows = [[""], [""]];
      rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: titleTable, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  mobileBagnoClienteBagniTenere(doc?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["MOBILE DEL CLIENTE"], ['SI']],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: y,
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  mobileBagnoClienteBagni(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [[constants.LABEL_MODELLO_MOBILE], [dati.modelloMobileDescrizione]],
      [[constants.LABEL_MISURA_MOBILE], [dati.misuraMobileDescrizione]],
      [[constants.LABEL_BASE_MOBILE], [dati.baseMobileDescrizione]],
      [[constants.LABEL_CASSETTI_MOBILE], [dati.cassettiMobileDescrizione]],
      [[constants.LABEL_TIPO_LAVABO_MOBILE], [dati.tipologiaLavaboMobileDescrizione]],
      [[constants.LABEL_SPECCHIO_MOBILE], [dati.specchioMobile]],
      [[constants.LABEL_n_VASCHE], [dati.numvascmobDescrizione]],
      [[constants.LABEL_COLONNA], [dati.tipocolmobDescrizione]],
      [[constants.LABEL_QTA_PENSILI], [dati.qtapensmob]],
      [[constants.LABEL_NOTE_COMPOSIZIONE_MOBILI], [dati.notecompmob]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        columnStyles: {
          0: { cellWidth: 70 },
          1: { cellWidth: 120 }, 
        },
        body: rows,
        startY: y,
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericPrintBoxDocciaBagniItaliani(doc?: any, dati?: any, tableTitle?: string, option?: boolean, column?: string[], y?: number, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      rows = dati.map(item => {

        if (item.modelloDescrizione && item.cristalliDescrizione && item.profiloDescrizione && item.larghezzaDescrizione && item.ingressoDescrizione) {
          return [
            { content: item.modelloDescrizione, styles: { halign: 'center' } },
            { content: item.cristalliDescrizione, styles: { halign: 'center' } },
            { content: item.profiloDescrizione, styles: { halign: 'center' } },
            { content: item.larghezzaDescrizione, styles: { halign: 'center' } },
            { content: item.ingressoDescrizione, styles: { halign: 'center' } },
          ];
        } else if (item.modelloDescrizione && item.cristalliDescrizione && item.profiloDescrizione && item.larghezzaDescrizione) {
          return [
            { content: item.modelloDescrizione, styles: { halign: 'center' } },
            { content: item.cristalliDescrizione, styles: { halign: 'center' } },
            { content: item.profiloDescrizione, styles: { halign: 'center' } },
            { content: item.larghezzaDescrizione, styles: { halign: 'center' } }
          ]
        }
      })
    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    if (option && dati.length) {
      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  nuoviAccessoriBagnoPratico(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    dati.forEach((element: string) => {
      rows.push([element]);
    });

    rows.unshift([{ content: tableTitle, colSpan: 1, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }


  // BAGNO_PRATICO
  portaBagnoPratico(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["LUNGHEZZA PORTA ATTUALE"], [this.addUnitOfMeasure(dati.lunghezza, 'cm')]],
      [["ALTEZZA PORTA ATTUALE"], [this.addUnitOfMeasure(dati.altezza, 'cm')]],
      [["SPESSORE MURO"], [this.addUnitOfMeasure(dati.spessore, 'cm')]],
      ...((dati.coloreDescrizione || dati.vascamobDescrizione)
        ? [
          [
            ["NUOVA PORTA"],
            ["75 cm"],
          ],
        ]
        : []
      ),
      [["NUOVA PORTA COLORE"], [dati.coloreDescrizione]],
      [["NUOVA PORTA APERTURA"], [dati.aperturaDescrizione]]
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    rows.unshift([{ content: tableTitle, colSpan: 2, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }


  //da usare quando si vuole mettere un sottotitolo.
  /*
    Es.
    ----------
    Vasca: SI
    ----------
    Modello vasca
  */
  genericOnlySubtitleCenter(doc?: any, y?: number, sectionTitle?: string, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = null; // null perchè usciamo solo il titolo.

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 10, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', lineColor: color, fillColor: color, fontSize: 9 },
      head: [[{ content: sectionTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y === 28 ? y : (y - 10),

      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericAccessoriVasca(doc?: any, dati?: any, column?: string[], y?: number, titleTable?: string, color?: string): void {
    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      const rows = dati.map(item => {

        if (item.tipologiaDescrizione && item.quantita) {
          return [
            { content: item.tipologiaDescrizione.toString(), styles: { halign: 'center' } },
            { content: item.quantita.toString(), styles: { halign: 'center' } },
          ];
        }
      })

      rows.unshift([{ content: titleTable, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  genericPrintBoxDoccia(doc?: any, dati?: any, tableTitle?: string, option?: boolean, column?: string[], y?: number, color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      rows = dati.map(item => {

        if (item.modelloDescrizione && item.cristalliDescrizione && item.profiloDescrizione && item.ingressoDescrizione) {
          return [
            { content: item.modelloDescrizione, styles: { halign: 'center' } },
            { content: item.cristalliDescrizione, styles: { halign: 'center' } },
            { content: item.profiloDescrizione, styles: { halign: 'center' } },
            { content: (item.ingressoDescrizione === 'DX' ? 'destra' : item.ingressoDescrizione === 'SX' ? 'sinistra' : 'centro'), styles: { halign: 'center' } },
          ];
        }
      })
    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    if (option && dati.length) {
      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericPrintRubinetteriaBagni(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["SERIE RUBINETTERIA"], [dati.rubSerie]],
      [["COLORE"], [dati.rubbicolDescrizione]],
      [["LAVABO"], [dati.lavabo]],
      [["BIDET"], [dati.bidet]],
      [["KIT DOCCIA"], [dati.kitDoccia]],
      [["MISCELATORE"], [dati.miscelatore]],
      [["ASTA"], [dati.asta]],
      [["ASTA DOCCIA DOPPIA CON MIX INTEGRATO COMBY"], [dati.astaForma]],
      [["COLONNE"], [dati.colonne]],
      [["MISCELATORE ESTERNO"], [dati.miscelatoreEst]],
      [["SERIE MISCELATORE"], [dati.miscelatoreSerie]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  datiTecniciAttualiBagni(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    rows = [
      [["LUOGO INSTALLAZIONE"], [dati.tipoabitazioneDescrizione]],
      [["DI PROPRIETÀ"], [this.convertBoolValue(dati.proprieta)]],
      [["ASCENSORE"], [this.convertBoolValue(dati.ascensore)]],
      [["ACCESSIBILITÀ CORTILE CON MEZZI"], [this.convertBoolValue(dati.accesscortile)]],
      [["PIANO"], [dati.pianointervento]],
      [["LUNGHEZZA VANO DA RISTRUTTURARE"], [this.addUnitOfMeasure(dati.lungvano, 'cm')]],
      [["LARGHEZZA VANO DA RISTRUTTURARE"], [this.addUnitOfMeasure(dati.largvano, 'cm')]],
      [["ALTEZZA VANO DA RISTRUTTURARE"], [this.addUnitOfMeasure(dati.altvano, 'cm')]],
      [["LARGHEZZA PORTA"], [this.addUnitOfMeasure(dati.lungporta, 'cm')]],
      [["ALTEZZA PORTA"], [this.addUnitOfMeasure(dati.altporta, 'cm')]],
      [["ALTEZZA PIASTRELLE"], [this.addUnitOfMeasure(dati.altpiastrelle, 'cm')]],
      [["TIPO"], [dati.tipobagnoDescrizione]],
      [["LUNGHEZZA"], [this.addUnitOfMeasure(dati.lungtipobagno, 'cm')]],
      [["LARGHEZZA"], [this.addUnitOfMeasure(dati.largtipobagno, 'cm')]],
      [["ALTEZZA"], [this.addUnitOfMeasure(dati.alttipobagno, 'cm')]],
      [["TERMOSIFONE PRESENTE"], [this.convertBoolValue(dati.termospres)]],
      [["LUNGHEZZA TERMOSIFONE"], [this.addUnitOfMeasure(dati.lungtermos, 'cm')]],
      [["ALTEZZA TERMOSIFONE"], [this.addUnitOfMeasure(dati.alttermo, 'cm')]],
      [["PROFONDITA' TERMOSIFONE"], [this.addUnitOfMeasure(dati.proftermos, 'cm')]],
      [["INCASSATO"], [this.convertBoolValue(dati.termosifoneIncassato)]],
      [["MISURA TERMOSIFONE INTERASSE"], [this.addUnitOfMeasure(dati.interasstermo, 'cm')]],
      [["LAVATRICE PRESENTE"], [this.convertBoolValue(dati.lavatrpres)]],
      [["ATTACCHI LAVATRICE DA CREARE"], [this.convertBoolValue(dati.attlavcreare)]],
      [["NOTE ATTACCHI LAVATRICE"], [this.convertBoolValue(dati.noteattlavcr)]],
      [["LAVATRICE DA MANTENERE"], [this.convertBoolValue(dati.lavatrmanten)]],
      [["BOILER PRESENTE"], [this.convertBoolValue(dati.boilerpresente)]],
      [["BOILER DA MANTENERE"], [this.convertBoolValue(dati.boilermanten)]],
      [["SANITARI"], [dati.tiposanitariDescrizione]],
      [["BIDET"], [this.convertBoolValue(dati.bidet)]],
      [["WC PRESENTE"], [this.convertBoolValue(dati.wcpresente)]],
      [["SCARICO WC"], [dati.tiposcaricowcDescrizione]],
      [["COMANDO SCIACQUONE A RUBINETTO (Flusso Rapido)"], [this.convertBoolValue(dati.comandosciacq)]],
      [["ADESIONE PIASTRELLE ESISTENTI"], [dati.adesionepiasDescrizione]],
      [["MURI PERIMETRALI"], [dati.muriperiDescrizione]],
      [["PUNTO LUCE IN PROSSIMITÀ DEL LAVABO"], [this.convertBoolValue(dati.puntolucelavabo)]],
      [["IMPIANTO CON TERMOSIFONE PRESENTE"], [this.convertBoolValue(dati.puntolucelavabo)]],
      [["TIPO DI RISCALDAMENTO"], [dati.tiporiscaDescrizione]],
      [["IMPIANTO"], [dati.tipoimpiantoDescrizione]],
      [["NOTE TECNICHE"], [dati.notetecniche]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: y,
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }


  optionGenericPrintZucchetti(doc?: any, dati?: any, tableTitle?: string, tableSubTitle?: string, y?: number, trascodifiche?: Trascodifica[], color?: string, constantValue?: string, subColumns?: string[]): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];
    let columnNumber = 0;

    const hasSelectedItems = dati.some(item => item.selected === true);

    if (dati.length && hasSelectedItems) {
      if (constantValue === constants.OPTIONAL_FLOW_X_VARIE) {
        dati.forEach((el) => {
          if (el.selected) {
            rows.push([
              this.getTrascodificaById(el.typeId, trascodifiche, true),
              el.quantity != null ? el.quantity.toString() : '-'
            ]);
          }
        });
        rows.unshift(subColumns.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } })));

        columnNumber = 2;

      } else {
        dati.forEach((el) => {
          if (el.selected) {
            rows.push([
              this.getTrascodificaById(el.typeId, trascodifiche, true)
            ]);
          }
        });
        columnNumber = 1;
      }
      rows = rows.filter(field => field[0] !== undefined && field[0] !== null && field[0].toString() !== '');
    }

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: columnNumber, styles: { halign: 'center' } }]],
        body: rows,
        startY: y,
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
    }
    this.printService.setCurrentY(y);
  }


  //LOREN CUCINE
  datiTecniciAttualiCucina(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [[constants.LABEL_DAT_TEC_PIANO_N?.toUpperCase()], [dati.pianointervento]],
      [[constants.LABEL_DAT_TEC_SCALE?.toUpperCase()], [this.capitalizeCase(this.convertBoolValue(dati.scale))]],
      [[constants.LABEL_DAT_TEC_ASCENSORE?.toUpperCase()], [this.capitalizeCase(this.convertBoolValue(dati.ascensore))]],
      [[constants.LABEL_DAT_TEC_OLD_CUCINA?.toUpperCase()], [dati.smontaggioCucina !== null ? this.convertBoolValueNoValue(dati.smontaggioCucina) : null]]
    ]


    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        bodyStyles: { lineWidth: constants.LOREN_TABLE_LINE_THICKNESS, lineColor: 'black' },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;

      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  printCucina(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;

    let rows = [
      [[constants.LABEL_LOREN_CUCINA_MODELLO?.toUpperCase()], [dati.modelloCucinaDescrizione]],
      [[constants.LABEL_LOREN_CUCINA_RIF_ORDINE?.toUpperCase()], [dati.rifOrdineCucina]],
    ]

    rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        bodyStyles: { lineWidth: constants.LOREN_TABLE_LINE_THICKNESS, lineColor: 'black' },
        head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.printService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.printService.createHeader(this.doc, this.img, this.title, this.brand);
          this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);

      y = this.printService.returnCurrentY();

      this.genericNoteDatiTechCucine(this.doc, y, '#000', '#F00')
    }
  }

  genericNoteDatiTechCucine(doc?: any, y?: number, color?: string, colorRed?: string): void {

    y = this.printService.returnCurrentY();

    doc.setTextColor(colorRed);
    y = this.printService.writeLongText(this.doc, 9, y, constants.NOTA_DATI_TECH_CUCNA, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);

    doc.setFont('helvetica', 'normal');
    doc.setTextColor(color);

    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }

    this.printService.setCurrentY(y + 5);
  }


  //SHARED 
  // Bagni e Vasca in doccia
  genericPromoPrint(doc?: any, promozioneId?: any, tableTitle?: string, column?: string[], y?: number, trascodifiche?: Trascodifica[], color?: string, note?:boolean): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (promozioneId) {

      if(!note){
        rows.push(
          [
            { content: this.getTrascodificaById(promozioneId, trascodifiche, true), styles: { halign: 'center' } },
          ]);
      }else{
        
        tableTitle = 'PROMOZIONE ' + this.getTrascodificaById(promozioneId, trascodifiche, true, false) as string;

        rows.push(
          [
            { content: this.getNoteTrascodificaById(promozioneId, trascodifiche), styles: { halign: 'center' } },
          ]);
      }

    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  genericNoteSpostamentoImpiantoEletPrint(doc?: any, y?: number, color?: string, colorRed?: string): void {

    y = this.printService.returnCurrentY();

    doc.setFont('helvetica', 'bold');

    y = this.printService.writeLongText(this.doc, 9, y, constants.NOTA_IMP_ELETTRICO_1, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);

    doc.setTextColor(colorRed);
    y = this.printService.writeLongText(this.doc, 9, y, constants.NOTA_IMP_ELETTRICO_2, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);

    doc.setFont('helvetica', 'normal');
    doc.setTextColor(color);

    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }

    this.printService.setCurrentY(y + 5);
  }

  // Utils functions
  returnPromoTipo(id, modelliInverter, promoClimaDesc): string {
    const parent = modelliInverter.findIndex(r => r.id === id);
    const parentId = modelliInverter[parent]?.parentId;
    const index = promoClimaDesc.findIndex(r => r.id === parentId);
    return promoClimaDesc[index]?.descrizione;
  }

  returnInverterPromo(id, tipologie, modelliInverter, promoClimaDesc): string {
    const parent = modelliInverter.findIndex(r => r.id === id);
    const parentId = modelliInverter[parent]?.parentId;
    const index = promoClimaDesc.findIndex(r => r.id === parentId);
    return promoClimaDesc[index].descrizione;
  }

  returnInverterQt(id, modelliInverter): number {
    const index = modelliInverter.findIndex(r => r.id === id);
    return modelliInverter[index]?.qta;
  }

  convertBoolValue(value: boolean): string {
    return value ? 'Si' : null;
  }

  convertBoolValueNoValue(value: boolean): string {
    return value ? 'Si' : 'No';
  }

  addUnitOfMeasure(value: number, unit: string): string {
    return value ? `${value} ${unit}` : null;
  }

  capitalizeCase(value: string): string {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    }
  }

  getTrascodificaById(id: number, trascodifiche: Trascodifica[], descrizione: boolean, codice?: boolean): Trascodifica | string {
    if (descrizione) {
      const tr = trascodifiche.find(tr => tr.id === id);
      return tr ? tr.descrizione : {} as Trascodifica;
    } else if (codice) {
      const tr = trascodifiche.find(tr => tr.id === id);
      return tr ? tr.codice : {} as Trascodifica;
    } else {
      return trascodifiche.find(tr => tr.id === id);
    }
  }

  getNoteTrascodificaById(id: number, trascodifiche: Trascodifica[]):  string | null {
    const tr = trascodifiche.find(tr => tr.id === id);
    return tr ? tr.note : null;
  }

  // START PRINT ZUCCHETTI POLTRONA

  prodottoPoltronaPrint(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, trascodifiche?: Trascodifica[], color?: string): void {

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      const rows = dati.map((item: PoltroneDto) => {

        let option: string = '';
        if (item.opzionePoltronas && item.opzionePoltronas.length > 0) {
          option = item.opzionePoltronas
            .map((el: OptionePoltronaDto) => this.getTrascodificaById(el.tipologiaId, trascodifiche, true))
            .join(", ");
        }

        if (item.trascodificaId) {
          return [
            { content: this.cleanHtmlString(this.getTrascodificaById(item.trascodificaId, trascodifiche, true).toString()), styles: { halign: 'center' } },
            { content: item?.coloreId ? this.getTrascodificaById(item.coloreId, trascodifiche, true).toString() : ' - ', styles: { halign: 'center' } },
            { content: item?.linea ? item?.linea : ' - ', styles: { halign: 'center' } },
            { content: option.toString(), styles: { halign: 'center' } },
          ];
        }
      });

      rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);

      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        body: rows,
        startY: this.printService.returnCurrentY(),
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.printService.setCurrentPage(this.currentPdfPage);
      }
      this.printService.setCurrentY(y);
    }
  }

  poltronePromoPrint(doc?: any, modelloId?: any, tableTitle?: string, column?: string[], y?: number, trascodifiche?: Trascodifica[], color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (modelloId) {
      const selectedModel = trascodifiche.find((res: Trascodifica) => res.id === modelloId);

      let promozione;
      if (selectedModel) {
        promozione = selectedModel.parentId;
      }

      if (promozione !== null && promozione !== undefined) {
        rows.push(
          [
            { content: (this.getTrascodificaById(promozione, trascodifiche, true) + " - " + this.getTrascodificaById(modelloId, trascodifiche, true)), styles: { halign: 'center' } },
          ]);
      }
    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }


  //START PRINT MONDIAL ALLARME 
  modelloKitBaseAllarme(doc?: any, dati?: AllarmeDTO, tableTitle?: string, column?: string[], y?: number, descKit?: string, color?: string): void {
    y = this.printService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    if (dati) {
      rows = [
        [
          { content: (descKit), styles: { halign: 'center' } },
          { content: dati.qta, styles: { halign: 'center' } }
        ]
      ];

      rows.unshift(
        [
          { content: column[0], styles: { halign: 'center', fontStyle: 'bold' } },
          { content: column[1], styles: { halign: 'center', fontStyle: 'bold' } }
        ]
      );

      if (rows.length) {
        autoTable(doc, {
          theme: 'grid',
          margin: { top: 30, bottom: 10, left: 10, right: 10 },
          headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
          head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
          body: rows,
          startY: this.printService.returnCurrentY(),
          didDrawCell: (data: any) => {
            lastCellY = data.cell.y + data.cell.height;
          },
          didDrawPage: (data: any) => {
            this.printService.createHeader(this.doc, this.img, this.title, this.brand);
            this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

            if (data.pageNumber === data.pageCount) {
              isTableDrawn = true;
              isTableStartedNewPage = false;
            } else {
              isTableStartedNewPage = true;
              this.currentPdfPage++;
            }
          },
        });

        y = doc.lastAutoTable.finalY + 10;
        if (y > 265) {
          this.currentPdfPage++;
          this.printService.setCurrentPage(this.currentPdfPage);
        }
        this.printService.setCurrentY(y);
      }
    }
  }

  accessoriMondialAllarme(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, trascodifiche?: Trascodifica[], color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      dati.forEach(item => {
        let posizione = item.posizione ? item.posizione : '';
        if (item.tipologiaId && item.qta) {
          rows.push(
            [
              { content: this.getTrascodificaById(item.tipologiaId, trascodifiche, true), styles: { halign: 'center' } },
              { content: item.qta, styles: { halign: 'center' } },
              { content: posizione, styles: { halign: 'center' } }
            ]);
        }
      })
    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    if (dati.length) {
      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  omaggiMondialAllarme(doc?: any, dati?: any, tableTitle?: string, column?: string[], y?: number, trascodifiche?: Trascodifica[], color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.printService.returnCurrentY();

    if (column.length && dati.length) {
      dati.forEach(item => {

        if (item.tipologiaId && item.quantita) {
          rows.push(
            [
              { content: this.getTrascodificaById(item.tipologiaId, trascodifiche, true), styles: { halign: 'center' } },
              { content: item.quantita, styles: { halign: 'center' } },
            ]);
        }
      })
    }

    rows.unshift([{ content: tableTitle, colSpan: column.length, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

    if (dati.length) {
      const headerRow = column.map(item => ({ content: item, styles: { halign: 'center', fontStyle: 'bold' } }));
      rows.splice(1, 0, headerRow);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      body: rows,
      startY: this.printService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.printService.createHeader(this.doc, this.img, this.title, this.brand);
        this.printService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
          isTableDrawn = true;
          isTableStartedNewPage = false;
        } else {
          isTableStartedNewPage = true;
          this.currentPdfPage++;
        }
      },
    });

    y = doc.lastAutoTable.finalY + 10;
    if (y > 265) {
      this.currentPdfPage++;
      this.printService.setCurrentPage(this.currentPdfPage);
    }
    this.printService.setCurrentY(y);
  }

  cleanHtmlString(htmlString: string): string {
    return htmlString.replace(/<[^>]*>/g, '');
  }
}