import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { retry, catchError } from 'rxjs/operators';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends HttpService {//extends HttpService

  endpoint: string = 'uploadFile';

  constructor(
    public router: Router,
    public http: HttpClient
  ) {
    super(router, http);
  }

  uploadFile(formData: FormData) {
    return this.http.post<{ path: string }>(
      this.apiUrl + this.endpoint,
      formData,
      this.setHttpOptionsForS3('text')
    ).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
}
