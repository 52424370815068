<div class="text-center blueBg row">
    <div class="col-6 text-right">NUOVO PIATTO DOCCIA
        <mat-hint class="text-danger">*</mat-hint>
    </div>
    <div class="col-6 text-left">
        <mat-radio-group labelPosition="after" class="inline" color="primary" [(ngModel)]="optionPiattoDoccia.selected"
            (ngModelChange)="showDivPiattoDoccia($event)">
            <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
            <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  <br>
  <div class="" *ngIf="isVisible">
    <app-form-piatto-doccia-largo [form]="form" [trascodifiche]="trascodifiche"></app-form-piatto-doccia-largo>
  </div>
  <br>
  <div class="" *ngIf="isVisible">
    <app-form-piatto-doccia-stretto [form]="form" [trascodifiche]="trascodifiche"></app-form-piatto-doccia-stretto>
  </div>
  <br>
   <app-form-piatto-effetto-pietra [form]="form" [trascodifiche]="trascodifiche" *ngIf="isVisible"></app-form-piatto-effetto-pietra>
  <br>
