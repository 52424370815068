import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MainComponent } from 'src/app/main/main.component';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { SignatureService } from 'src/app/services/signature/signature.service';
import { constants } from '../../classes/constants';

@Component({
  selector: 'app-orders-frozen',
  templateUrl: './orders-frozen.component.html',
  styleUrls: ['./orders-frozen.component.scss']
})
export class OrdersFrozenComponent implements OnInit {

  displayedColumns: string[] =  ['idOrdine', 'nomeProdotto', 'brandDescrizione',  'dataordine', 'clienteCf', 'statopraticaDescrizione' ,'actions'];
  dataSource: MatTableDataSource<any>;
  loading: boolean;
  contracts: any;
  nameUser:any;
  listStatoPratica: any;
  clonedList:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public constants = constants;

  constructor(
      private router: Router,
      private orderService : OrdersService,
      private mainCompo : MainComponent,
	    private formController: FormControllerService,
 	    private signatureService: SignatureService,
  ) {
  
  }

  ngOnInit(): void{
	  if (this.mainCompo.loggedUser) {
		  this.getContracts();
	  }
  }
	
	getContracts(): any{
    this.loading = true;


    this.orderService.getOrders(this.mainCompo.loggedUser.id , 'idfile.specified=true&size=1000&statopraticaCodice.equals=D&venditoreId.equals=').subscribe(res => {
      this.loading = false;
      this.contracts = res;
      this.clonedList = [...this.contracts];
      this.nameUser = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
      this.contracts.forEach(r => {
        r.statopraticaDescrizione = this.capitalize(r.statopraticaDescrizione);
	      r.brandDescrizione = this.capitalize(r.brandDescrizione);
	      r.nomeProdotto = this.returnProdName(r.prodottoCommessa);
      });
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }


  capitalize(value): string {
	return this.formController.capitalize(value);
  }

  	downloadPdf(idordine, id):void{
		this.signatureService.downoloadFile(id).subscribe(res => {
			if (res !== undefined){
				//var content = res.replace('"');
				//window.atob(content);
				//const blob = new Blob([content], { type: "application/pdf" });
			    const link = document.createElement("a");
			    link.href = 'data:application/octet-stream;base64,' + res; // window.URL.createObjectURL(blob);
			    link.download = idordine + ".pdf";
			    link.click();
			}
		})
			
	}

  disabledDownload(element):boolean{
	 if(element !== 'D'){
		return true;
	}else{
		return false;
	}

  }
  
  returnProdName(opt): string {
		return this.formController.returnProdName(opt);
  }
  
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    const filterIng = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (event) {
      this.clonedList = [];
      this.contracts.forEach(c => {
        if (
            c.cognome?.toLowerCase().includes(filterIng) ||
            c.nomeProdotto?.toLowerCase().includes(filterIng) ||
            c.brandDescrizione?.toLowerCase().includes(filterIng) ||
			      c.idordine?.toLowerCase().includes(filterIng)
        ) {
          this.clonedList.push(c);
        }
      });
      this.dataSource = new MatTableDataSource(this.clonedList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

}
