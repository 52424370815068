export class AttivazioneServizi {

	errorjson: string;
	idfile: string;
	statorichiestaDescrizione: string;
	statorichiestaId: number;
	statorichiestaCodice: string;
	idProcedure: string;
	idMember: string;
	idWebhook: string;
	ordineId: number;
}