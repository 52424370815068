<div class="row mb-3">
    <div class="col-6">
        <div class="row smallFont">
            <div class="col-12">
                <div><b>G.D.L. S.p.A.</b></div>
                <div>
                    Via Orbetello, 54/D<br />
                    10148 TORINO<br />
                    tel. 011 22.48.515 / fax 011 22.95.425<br />
                    {{email}}<br />
                    IBAN: IT 30 J 02008 01119 000100689984
                </div>
            </div>
        </div>
    </div>
    <div class="col-3">
        <mat-form-field class="mb-2 full-width" appearance="standard">
            <mat-label>Contratto N.</mat-label>
            <input matInput disabled placeholder="N° contratto" [ngModel]="form.ordine?.idordine"
                (ngModelChange)="form.ordine.idordine=$event">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Data</mat-label>
            <input (click)="picker.open()" [disabled]="readOnly" [(ngModel)]="form.anagrafica.infoContratto.data" matInput
                [matDatepicker]="picker" [min]="minDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-form-field class="mb-2 full-width" appearance="standard">
            <mat-label>Consulente</mat-label>
            <input matInput [(ngModel)]="form.anagrafica.infoContratto.consulente" [disabled]="readOnly" placeholder="consulente" required>
            <mat-hint class="text-danger" *ngIf="!form.anagrafica.infoContratto.consulente">Nome consulente
                richiesto</mat-hint>
        </mat-form-field>
    </div>
</div>


<div *ngIf="form.sections.moduloServizi">
    <div class="anagraficaServiziBg"  [ngStyle]="{'background': productColor}"> CAPITOLATO FORNITURA </div>
</div>

<div class="text-center mb-3" *ngIf="!form.sections.moduloServizi">
    CAPITOLATO FORNITURA
</div>
<div class="anagraficaContainer mb-4" [ngClass]="{'onlyRedBorder': !validAnagrafica && !readOnly}">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Nome</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.nome" [disabled]="readOnly" placeholder="Nome" required tabindex="1">
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Comune</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.citta" [disabled]="readOnly" placeholder="Comune" required tabindex="5">
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Cognome</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.cognome" [disabled]="readOnly" placeholder="Cognome" required tabindex="2">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Via</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo" [disabled]="readOnly" placeholder="Via" required tabindex="6">
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Civico</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.numero" [disabled]="readOnly" placeholder="Civico" required tabindex="7">
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.email" [disabled]="readOnly" placeholder="Email" required tabindex="3">
                <mat-hint class="text-danger" *ngIf="!formController.checkMail(form.anagrafica.anagrafica.email)">Email
                    valida richiesta</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-4 col-md-2">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Cap</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.cap" [disabled]="readOnly" type="text" placeholder="Cap" tabindex="8"
                    required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.cap">Cap richiesto</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-4 col-md-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Provincia</mat-label>
                <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput tabindex="9"
                    [(ngModel)]="form.anagrafica.anagrafica.provincia" [disabled]="readOnly" [matAutocomplete]="auto"
                    (ngModelChange)="filter(form.anagrafica.anagrafica.provincia)">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                        {{provincia.nome}}
                    </mat-option>
                </mat-autocomplete>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.provincia">Provincia
                    richiesta</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-4 col-md-1">
            <br/>
            <mat-checkbox class="mt-2" color="primary" [(ngModel)]="form.anagrafica.anagrafica.ztl" [disabled]="readOnly"
                [checked]="form.anagrafica.anagrafica.ztl">
                ZTL.
            </mat-checkbox>
        </div>
    </div>
    
    <div class="row" style="margin-top: 15px;">
        <div class="col-12 col-md-6">
            <form #f="ngForm" [formGroup]="phoneForm">
                <ngx-intl-tel-input
                    [cssClass]="'custom'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectedCountryISO]="CountryISO.Italy"
                    [separateDialCode]="false"
                    [numberFormat]="PhoneNumberFormat.International"
                    name="phone"
                    formControlName="phone"
                    [ngModel]="form.anagrafica.anagrafica?.tel1"
                    (ngModelChange)="updatePhoneNumber($event)">
                </ngx-intl-tel-input>
                <mat-hint class="text-danger" *ngIf="!formController.checkPhone(form.anagrafica.anagrafica.tel1)">
                    Telefono valido richiesto
                </mat-hint>
            </form>
        </div>
        <div class="col-12 col-md-6">
            <mat-radio-group class="full-width" appearance="standard" [(ngModel)]="selectedCFoIVA" [disabled]="readOnly" (ngModelChange)="resetInput()">
                <br/>
                <mat-radio-button value="cf" tabindex="11">Codice Fiscale</mat-radio-button>
                <mat-radio-button value="piva" tabindex="12">Partita IVA</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    
    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Telefono 2</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.tel2" [disabled]="readOnly" placeholder="Telefono 2" tabindex="4">
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>{{ selectedCFoIVA === 'cf' ? 'Codice Fiscale  ' : 'Partita IVA  ' }}</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica[selectedCFoIVA]" [disabled]="readOnly" (input)="onChangeValue(form.anagrafica.anagrafica[selectedCFoIVA])"
                  [required]="selectedCFoIVA === 'cf'" tabindex="15"
                  placeholder="{{ selectedCFoIVA === 'cf' ? 'Codice Fiscale' : 'Partita IVA' }}">
                <mat-hint class="text-danger" *ngIf="!isValid(selectedCFoIVA)">{{ getValidationMessage(selectedCFoIVA) }}</mat-hint>
                <mat-hint align="end" *ngIf="form.anagrafica.anagrafica.cf"> {{selectedCFoIVA === 'cf' ? form.anagrafica.anagrafica.cf.length + ' / 16' : form.anagrafica.anagrafica.cf.length + ' / 11' }}</mat-hint>
            </mat-form-field>
        </div>
    </div>
        
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="full-width" appearance="standard" *ngIf="selectedCFoIVA === 'piva'">
                <mat-label>{{ 'Ragione sociale ' }}</mat-label>
                <input matInput type="text" [(ngModel)]="form.anagrafica.anagrafica.ragionesociale"
                  [required]="selectedCFoIVA === 'piva'" [disabled]="readOnly"
                  placeholder="Ragione sociale">
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.ragionesociale && selectedCFoIVA === 'piva'">Ragione sociale
                    richiesta
                </mat-hint>
            </mat-form-field>

            <div *ngIf="selectedCFoIVA === 'cf'">
                <ng-container *ngTemplateOutlet="indirizzoInstallazione"></ng-container>
            </div>
        </div>

        <div class="col-12 col-md-6">
            <div *ngIf="selectedCFoIVA === 'piva'">
                <ng-container *ngTemplateOutlet="indirizzoInstallazione"></ng-container>
            </div>
        </div>
    </div>

    <div class="row mb-3" *ngIf="form.anagrafica.anagrafica.diversoind">
        <div class="col-12 col-md-12">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Indirizzo installazione</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzoinst" [disabled]="readOnly"
                    [required]="form.anagrafica.anagrafica.diversoind" placeholder="Indirizzo installazione" tabindex="17">
            </mat-form-field>
        </div>
    </div>    
</div>

<div class="text-center"  *ngIf="!form.sections.moduloServizi">
    G.D.L. S.p.A. (con sede in Via Orbetello, 54/D, 10148 Torino, CF: 10062800015, {{this.email}}, {{this.sito}},
    iscritta alla Camera di Commercio di Torino, n° registro imprese TO 1102436) qui rappresentata dall'agente
    <mat-form-field class="mr-2 ml-2" appearance="standard">
        <input matInput [(ngModel)]="form.anagrafica.agente" [disabled]="readOnly" required placeholder="Agente">
    </mat-form-field>
    all'uopo autorizzato e munito di specifici poteri rappresentativi
</div>

<div *ngIf="form.sections.moduloServizi">
    <div *ngIf="form.sections.moduloServizi">
        <div class="anagraficaServiziBg" [ngStyle]="{'background': productColor}"> RESPONSABILI</div>
    </div>
    <div class="anagraficaContainer mb-4" [ngClass]="{'onlyRedBorder': !validAnagrafica && !readOnly}">
        <sh-servizio-responsabile [form]="form"></sh-servizio-responsabile>
    </div>
</div>

<ng-template #indirizzoInstallazione>
    <div>
        <br><br>
        <mat-checkbox class="mt-2" color="primary" [(ngModel)]="form.anagrafica.anagrafica.diversoind" [disabled]="readOnly"
            (change)="resetNewAddress($event.checked)" [checked]="form.anagrafica.anagrafica.diversoind"  tabindex="16">
            Diverso indirizzo installazione
        </mat-checkbox>
    </div>
</ng-template>
