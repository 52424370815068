<div class="row originalRow align-items-stretch">
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                STUFE A PELLET
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4 text-center subTitle">MODELLO</div>
                    <div class="col-2 text-center subTitle">VOL. RISC. MAX.</div>
                    <div class="col-3 text-center subTitle">QUANTITA'</div>
                    <div class="col-3 text-center subTitle">COLORE</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let pellet of stufePellet; let i = index"
                    [ngClass]="{'onlyRedBorder': form.sections.stufe[i].checked && (!this.form.sections.stufe[i].quantita || !this.form.sections.stufe[i].coloreId)}">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt"
                            (ngModelChange)="removeColor($event, i);removeQuantita($event, i)"
                            [(ngModel)]="form.sections.stufe[i].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-2 smalFt text-right">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-3 smalFt text-right">
                        <app-input-number (onNumChange)="form.sections.stufe[i].quantita = $event" [step]="1"
                            [num]="form.sections.stufe[i].quantita" [decimal]="false"
                            [disabled]="!form.sections.stufe[i].checked" [label]="">
                        </app-input-number>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i].checked && !form.sections.stufe[i].quantita">
                            selezione obbligatoria
                        </div>
                    </div>
                    <div class="col-3">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnColDescr($event, i)"
                            [(ngModel)]="form.sections.stufe[i].coloreId" [disabled]="!form.sections.stufe[i].checked">
                            <mat-radio-button color="primary" class="mr-2"
                                *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i].checked && !form.sections.stufe[i].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-4">

        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                STUFE A BIOETANOLO
                <br>
                <br>
                <div class="text-danger mb-2">
                    {{constants.NOTA_STUFE_BIOETANOLO}}
                </div>
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4 subTitle">MODELLO</div>
                    <div class="col-2 subTitle">VOL. RISC. MAX.</div>
                    <div class="col-3 text-center subTitle">QUANTITA'</div>
                    <div class="col-3 subTitle">COLORE</div>
                </div>
                <div class="row originalRow mb-1"
                    [ngClass]="{'onlyRedBorder': form.sections.stufe[i + stufePellet.length].checked && (!this.form.sections.stufe[i + stufePellet.length].quantita || ( this.form.sections.stufe[i + stufePellet.length].bollinoSconto == null)  ||  !this.form.sections.stufe[i + stufePellet.length].coloreId)}"
                    *ngFor="let pellet of stufeBio; let i = index">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt"
                            (ngModelChange)="removeColor($event, (i + stufePellet.length));removeQuantita($event, (i + stufePellet.length)); removeBollinoSconto($event, (i + stufePellet.length));"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-2">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-3 smalFt text-right">
                        <app-input-number (onNumChange)="form.sections.stufe[i + stufePellet.length].quantita = $event"
                            [step]="1" [num]="form.sections.stufe[i + stufePellet.length].quantita" [decimal]="false"
                            [disabled]="!form.sections.stufe[i + stufePellet.length].checked" [label]="">
                        </app-input-number>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length].checked && !form.sections.stufe[i + stufePellet.length].quantita">
                            selezione obbligatoria
                        </div>
                    </div>
                    <div class="col-3">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnColDescr($event, i + stufePellet.length)"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length].coloreId"
                            [disabled]="!form.sections.stufe[i + stufePellet.length].checked">
                            <mat-radio-button color="primary" class="mr-2"
                                *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length].checked && !form.sections.stufe[i + stufePellet.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="accordion">
                            <div class="accordion-item">
                                <div class="accordion-header" (click)="toggle('collapseBollinoBio' + i)">
                                    <div class="row justify-content-center">
                                        <div class="col-auto subTitle">
                                            BOLLINO SCONTO
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-body"
                                    [@detailExpand]="expandedElement === 'collapseBollinoBio' + i ? 'expanded' : 'collapsed'">
                                    <div class="body-content">
                                        <div class="row justify-content-center">
                                            <div class="col-auto">
                                                <mat-radio-group class="smalFt"
                                                    [(ngModel)]="form.sections.stufe[i + stufePellet.length].bollinoSconto"
                                                    [disabled]="!form.sections.stufe[i + stufePellet.length].checked">

                                                    <mat-radio-button color="primary" class="mr-2" [value]="false">
                                                        No
                                                        &nbsp;&nbsp;
                                                    </mat-radio-button>

                                                    <mat-radio-button color="primary" class="mr-2" [value]="true">
                                                        Sì
                                                        &nbsp;&nbsp;
                                                    </mat-radio-button>

                                                    <span class="undo material-icons" matTooltip="Cancella selezione"
                                                        *ngIf="form.sections.stufe[i + stufePellet.length].bollinoSconto"
                                                        (click)="form.sections.stufe[i + stufePellet.length].bollinoSconto = null">
                                                        backspace
                                                    </span>
                                                </mat-radio-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="smallFont text-danger text-align-right"
                        *ngIf="form.sections.stufe[i + stufePellet.length].checked && form.sections.stufe[i + stufePellet.length].bollinoSconto == null ">
                        selezione obbligatoria
                    </div>
                    <hr class="my-4">
                </div>
            </div>
        </div>

        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                STUFE A LEGNA
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4 text-center subTitle">MODELLO</div>
                    <div class="col-2 text-center subTitle">VOL. RISC. MAX.</div>
                    <div class="col-3 text-center subTitle">QUANTITA'</div>
                    <div class="col-3 text-center subTitle">COLORE</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let legna of stufeLegna; let i = index"
                    [ngClass]="{'onlyRedBorder': form.sections.stufe[i + stufePellet.length + stufeBio.length].checked && (!this.form.sections.stufe[i + stufePellet.length + stufeBio.length].quantita || !this.form.sections.stufe[i + stufePellet.length + stufeBio.length].coloreId)}">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt"
                            (ngModelChange)="removeColor($event, i + stufePellet.length + stufeBio.length); removeQuantita($event, i + stufePellet.length + stufeBio.length)"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length].checked">{{legna.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-2 smalFt text-right">
                        {{returnVolDesc(legna.id)}}
                    </div>
                    <div class="col-3 smalFt text-right">
                        <app-input-number
                            (onNumChange)="form.sections.stufe[i + stufePellet.length + stufeBio.length].quantita = $event"
                            [step]="1" [num]="form.sections.stufe[i + stufePellet.length + stufeBio.length].quantita"
                            [decimal]="false"
                            [disabled]="!form.sections.stufe[i + stufePellet.length + stufeBio.length ].checked"
                            [label]="">
                        </app-input-number>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length].quantita">
                            selezione obbligatoria
                        </div>
                    </div>
                    <div class="col-3">
                        <mat-radio-group class="smalFt"
                            (ngModelChange)="returnColDescr($event, i + stufePellet.length + stufeBio.length)"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length].coloreId"
                            [disabled]="!form.sections.stufe[i + stufePellet.length + stufeBio.length].checked">
                            <mat-radio-button color="primary" class="mr-2"
                                *ngFor="let color of returnColors(legna.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                TERMOSTUFE A PELLET
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4 subTitle">MODELLO</div>
                    <div class="col-2 subTitle">VOL. RISC. MAX.</div>
                    <div class="col-3 text-center subTitle">QUANTITA'</div>
                    <div class="col-3 subTitle">COLORE</div>
                </div>
                <div class="row originalRow mb-1"
                    [ngClass]="{'onlyRedBorder': form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].checked && (!this.form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].quantita || !this.form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].coloreId)}"
                    *ngFor="let pellet of termoPellet; let i = index">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt"
                            (ngModelChange)="removeColor($event, (i + stufePellet.length + stufeBio.length + stufeLegna.length)); removeQuantita($event, (i + stufePellet.length + stufeBio.length + stufeLegna.length))"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-2">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-3 smalFt text-right">
                        <app-input-number
                            (onNumChange)="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].quantita = $event"
                            [step]="1"
                            [num]="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].quantita"
                            [decimal]="false"
                            [disabled]="!form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].checked"
                            [label]="">
                        </app-input-number>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].quantita">
                            selezione obbligatoria
                        </div>
                    </div>
                    <div class="col-3">
                        <mat-radio-group class="smalFt"
                            (ngModelChange)="returnColDescr($event, i + stufePellet.length + stufeBio.length + stufeLegna.length)"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].coloreId"
                            [disabled]="!form.sections.stufe[i + stufePellet.length + stufeLegna.length + stufeBio.length].checked">
                            <mat-radio-button color="primary" class="mr-2"
                                *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                CALDAIE A PELLET
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4 subTitle">MODELLO</div>
                    <div class="col-2 subTitle">VOL. RISC. MAX.</div>
                    <div class="col-3 text-center subTitle">QUANTITA'</div>
                    <div class="col-3 subTitle">COLORE</div>
                </div>
                <div class="row originalRow mb-1"
                    [ngClass]="{'onlyRedBorder': form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].checked && (!this.form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].quantita || !this.form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].coloreId)}"
                    *ngFor="let pellet of caldaiePellet; let i = index">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt"
                            (ngModelChange)="removeColor($event, (i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length));removeQuantita($event, (i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length));"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-2">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-3 smalFt text-right">
                        <app-input-number
                            (onNumChange)="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].quantita = $event"
                            [step]="1"
                            [num]="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].quantita"
                            [decimal]="false"
                            [disabled]="!form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].checked"
                            [label]="">
                        </app-input-number>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].quantita">
                            selezione obbligatoria
                        </div>
                    </div>
                    <div class="col-3">
                        <mat-radio-group class="smalFt"
                            (ngModelChange)="returnColDescr($event, i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length)"
                            [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].coloreId"
                            [disabled]="!form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].checked">
                            <mat-radio-button color="primary" class="mr-2"
                                *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length + stufeLegna.length + termoPellet.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="anagraficaContainer">
            <div>
                <div class="text-danger mb-2">
                    ATTENZIONE LE TERMOSTUFE E LE CALDAIE PRODUCONO ACQUA SANITARIA SOLO CON L'INSTALLAZIONE DEL PUFFER
                </div>
                <div class="d-inline-block">MANTENERE GENERATORE DI CALORE ESISTENTE PER ACQUA CALDA SANITARIA</div>
                <mat-radio-group class="d-inline-block smalFt" [(ngModel)]="form.sections.dettaglioDto.manteneregen">
                    <mat-radio-button color="primary" class="mr-2" [value]="true">
                        SI
                    </mat-radio-button>
                    <mat-radio-button color="primary" class="mr-2" [value]="false">
                        NO
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</div>
<div class="arielBg noteTitle"> NOTE </div>
<div class="anagraficaContainer">
    <app-note-sh [nota]="form.sections.dettaglioDto.note" (onNotaEmit)="form.sections.dettaglioDto.note = $event"
        [maxLength]="255" [minLine]="3">
    </app-note-sh>
</div>