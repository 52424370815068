<div class="text-center blueBg">
    RIVESTIMENTI
</div>
<div class="blueCont">
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pavimento</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pavimento">
        </mat-form-field>
    </div>
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pareti</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareti">
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>A</mat-label>
        	 	<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.A">
        	 </mat-form-field>	
        </div>
        <div class="col-3">
            <app-input-number
                (onNumChange)="form.sections.rivestimenti.Ah = $event"
                [step]="0.1"
                [num]="form.sections.rivestimenti.Ah"
                [decimal]="true"
                [payment]="false"
                [small]="false"
                label="h">
            </app-input-number>
        </div>
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>B</mat-label>
        		<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.B">
        	</mat-form-field>	
        </div>
        <div class="col-3">
            <app-input-number
                (onNumChange)="form.sections.rivestimenti.Bh = $event"
                [step]="0.1"
                [num]="form.sections.rivestimenti.Bh"
                [decimal]="true"
                [payment]="false"
                [small]="false"
                label="h">
            </app-input-number>
        </div>
    </div>
    
    <div class="row">
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>C</mat-label>
        		<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareteC">
        	</mat-form-field>
        </div>
        <div class="col-3">
            <app-input-number
                (onNumChange)="form.sections.rivestimenti.pareteCh = $event"
                [step]="0.1"
                [num]="form.sections.rivestimenti.pareteCh"
                [decimal]="true"
                [payment]="false"
                [small]="false"
                label="h">
            </app-input-number>
        </div>
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>D</mat-label>
        		<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareteD">
        	</mat-form-field>	
        </div>
        <div class="col-3">
            <app-input-number
                (onNumChange)="form.sections.rivestimenti.pareteDh = $event"
                [step]="0.1"
                [num]="form.sections.rivestimenti.pareteDh"
                [decimal]="true"
                [payment]="false"
                [small]="false"
                label="h">
            </app-input-number>
        </div>
    </div>
</div>

<div class="blueBg noteTitle">NOTE </div>
<div class="blueCont">
    <app-note-sh [nota]="form.sections.rivestimenti.note" (onNotaEmit)="form.sections.rivestimenti.note = $event" [maxLength]="255"
        [minLine]="3">
    </app-note-sh>
</div> 