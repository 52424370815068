import { environment } from 'src/environments/environment';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { AnagraficaDTO, AnagraficaForm } from '../../../../classes/anagrafica';
import { UserService } from '../../../../../services/user/user.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { BrandService } from '../../../../../services/brand/brand.service';
import { Trascodifica } from '../../../../classes/trascodifica';
import { Ordine } from '../../../../classes/ordine';
import { ActivatedRoute, Router } from '@angular/router';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { StufeService } from './services/stufe.service';
import { DettaglioStufe } from '../../../../classes/dettaglio-stufe';
import { AbitazioneDTO } from '../../../ariel/ariel-main/classes/abitazione-dto';
import { AccessoristufaDto } from '../../../../classes/accessoristufa-dto';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { Signature } from '../../../../classes/signature';
import { AbitazioneService } from '../../ariel-main/services/abitazione.service';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { constants } from 'src/app/modules/classes/constants';
import { OmaggioDto } from '../../../../classes/omaggio-dto';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import * as $ from 'jquery';

declare var jQuery: any;
@Component({
	selector: 'app-stufe',
	templateUrl: './stufe.component.html',
	styleUrls: ['./stufe.component.scss']
})
export class StufeComponent implements OnInit, DoCheck, AfterContentChecked {

	validAnagrafica: boolean = false;
	showModalPrint: boolean = false;
	confirmStufe: boolean = false;
	generatingPdf: boolean = false;
	openSign: boolean = false;
	isFinanziamentoPayment: boolean = false;

	idCliente: number;
	currentStep: number = 0;
	valueProgBar: number = 0;

	obj1: any;
	obj2: any;
	form: any;

	signature: Signature;
	anagrafica: AnagraficaDTO;
	trascodifiche: Trascodifica[];
	listFinanziarie: Trascodifica[];
	stufePellet: Trascodifica[];
	termoPellet: Trascodifica[];
	caldaiePellet: Trascodifica[];
	attivitaLavList: Trascodifica[] = [];
	stufeSelected: any[] = [];
	totalSteps: number[];

	ordine: Ordine;

	sendingPdf: boolean = false;
	pdf: any;
	error: any;
	member: any;
	formDiffer: any;
	originalForm: any;
	showFrame = false;
	urlFrame = "";

	finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/ARIEL.jpg', product: 'stefe', brand: 'Ariel'
	}

	hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");
	integratedSignature = environment.integratedSignature;

  constructor(
      public formController: FormControllerService,
      public userService: UserService,
      private anagraficaService: AnagraficaService,
      private brandService: BrandService,
      private route: ActivatedRoute,
      private orderService: OrdersService,
      private stufeService: StufeService,
      private paymentService: PaymentService,
      private abitazioneService:AbitazioneService,
	  private router: Router,
	  private omaggioService: OmaggioService,
	  private allegatoService: AllegatoService,
	  private changeDetector:ChangeDetectorRef
  ) {
  	this.showModalPrint = false;
    this.validAnagrafica = false;
    this.form = {
      	idOrder: Number(this.route.snapshot.paramMap.get('id')),
      	anagrafica: new AnagraficaForm(),
      	sections: {
        stufe: [],
		hiddenSectionCondition: {
			dettagliInstallazione: true
		},
        dettaglioDto: new DettaglioStufe(),
        casaStufa: new AbitazioneDTO(),
        accStufa: new AccessoristufaDto(),
        pagamento: [new PagamentoDTO()],
		finanziamentoSections: {
			finanziamento: new Finanziamento(),
			finAnagrafica: new FinAnagrafica(),
			finDatiOccupazione: new FinDatiOccupazione(),
			finAnagraficaGarante: new FinAnagrafica(),
			finDatiOccupazioneGarante: new FinDatiOccupazione(),
			isAllegatoSaved: false
		},
		ordine: null,
		omaggio : [new OmaggioDto()],
      }
	
    };
	
	this.totalSteps = Array(9).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
    // richiamo il serivizio di trascodifica che mi restituisce i dati per popolare i form
  }

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);
			if (res.clienteId !== undefined && res.clienteId !== null) {
				localStorage.setItem('id_client', res.clienteId);
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}

			localStorage.setItem('id_order', res.idordine);
		});
		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
			this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
		});
		this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
	}

	ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}

	ngOnInit(): void {
		this.originalForm = JSON.parse(JSON.stringify(this.form));
		if (localStorage.getItem('id_client') !== null) {
			this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		this.getOrder(this.form.idOrder);
	}


	getOrdine(): void {
		this.orderService.getSingleOrder(this.form.idOrder).subscribe(res => {
			this.form.anagrafica.infoContratto.consulente = res.venditoreLogin;
			this.form.anagrafica.agente = res.venditoreLogin;
		}, error => {
			console.error('Error => ', error);
		});
	}


	stepController(): boolean {
		switch (this.currentStep) {
			case 0:
			case 7:
				return this.generatingPdf || !this.validAnagrafica;
			case 1:
				return this.generatingPdf || !this.validStufe() || this.formController.checkNoteLength(this.form.sections.dettaglioDto.note, 255);
			case 2:
				return this.generatingPdf || !this.form.sections.casaStufa.disegnotecnico || !this.validStabile();
			case 3:
				return this.generatingPdf || !this.validInstallazione() || this.formController.checkNoteLength(this.form.sections.accStufa.note, 80);
			case 4:
				return this.generatingPdf || !this.formController.validOmaggio(this.form) || this.form.sections.omaggio.length == 0;
			case 5:
				return this.generatingPdf || !this.formController.validPagamento(this.form, false);
			case 6:
				return this.generatingPdf || !this.formController.validPrivacy(this.form);
			case 8:
				return this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie);
			default:
				return this.generatingPdf;
		}
	}

	setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		if (this.form.sections.pagamento[0]!.tipopagsaldoId) {
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if (tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(9).fill(1).map((x, i) => i);
			} else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(8).fill(1).map((x, i) => i);
			}
		}

		this.scrollTop();
	}

	checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	toggleJson(): void {
		$('.formJson').toggleClass('visibleJson');
	}

	scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

	async saveModule(value): Promise<void> {
		$('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;
			if (this.anagrafica.id) {
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					});
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			} else {
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.form.anagrafica.anagrafica.id = res.id;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					});
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			}
		} else if (this.currentStep === 1) {
			this.confirmStufe = value;

		if (!this.confirmStufe && this.moreThanOneStufa()) {
			this.openConfirmStufe(false);
		} else {
			this.confirmStufe = true;
		}
	
		if (this.confirmStufe) {
			// Step salvataggio stufe
			const savingStufe = [];
			this.form.sections.stufe.forEach(r => {
				if (r.checked) {
					savingStufe.push(r);
				}
				if (!r.checked && r.id !== undefined) {
					this.removeStufa(r.id);
				}
			});
	
			try {
				let allSuccess = true;
	
				const cloneStufeSeleted = JSON.parse(JSON.stringify(savingStufe));
				this.stufeSelected = cloneStufeSeleted.filter(el => el.modstufebioId !== null && el.modstufebioId !== undefined);
	
				if (this.stufeSelected.length === savingStufe.length) {
					this.form.sections.hiddenSectionCondition.dettagliInstallazione = false;
				} else {
					this.form.sections.hiddenSectionCondition.dettagliInstallazione = true;
				}
	
				for (const s of savingStufe) {
					delete s.checked;
					delete s.coloreDescrizione;
	
					try {
						if (s.id !== undefined) {
							await this.updateStufa(s);
						} else {
							await this.saveStufa(s);
						}
					} catch (error) {
						console.error('Errore nel salvataggio della stufa:', error);
						allSuccess = false;
						break; 
					}
				}
	
				if (allSuccess && this.hasValues(this.form.sections.dettaglioDto)) {
					this.form.sections.dettaglioDto.ordineId = this.form.idOrder;
	
					try {
						if (this.form.sections.dettaglioDto.id) {
							await this.updateNote();
						} else {
							await this.saveNote();
						}
					} catch (error) {
						console.error('Errore nel salvataggio dei dettagli delle stufe:', error);
						allSuccess = false;
					}
				}
	
				if (allSuccess) {
					this.setStep(this.currentStep + 1);
				} else {
					console.error('Processo fallito, step non incrementato.');
				}
			} catch (error) {
				console.error('Errore generale durante il processo di salvataggio delle stufe:', error);
			}
		}
    } else if (this.currentStep === 2){
      // Step salvataggio casaStufa
	  if(this.hasValues(this.form.sections.casaStufa)){
			this.form.sections.casaStufa.ordineId = this.form.idOrder;
  		if (this.form.sections.casaStufa.id) {
	        this.abitazioneService.update(JSON.stringify(this.form.sections.casaStufa)).subscribe(res => {
				this.setStep(this.currentStep + 1);
	            }, error => {
	              console.error('Error => ', error);
				  this.formController.openSnackBar(constants.ERROR_GENERIC_API);
	            }
	        );
	      } else {
	        this.abitazioneService.save(JSON.stringify(this.form.sections.casaStufa)).subscribe(res => {
				this.setStep(this.currentStep + 1);
	            }, error => {
	              console.error('Error => ', error);
				  this.formController.openSnackBar(constants.ERROR_GENERIC_API);
	            }
	        );
	      }
	   }else{
		this.setStep(this.currentStep + 1);
	   }
    } else if (this.currentStep === 3){
      // Step salvataggio accStufa
		const hasValuesAcc = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined" && v !== false);
		if(hasValuesAcc(this.form.sections.accStufa)){
				this.form.sections.accStufa.ordineId = this.form.idOrder;
				if (this.form.sections.accStufa.id) {
					this.stufeService.updateAccStufa(JSON.stringify(this.form.sections.accStufa)).subscribe(res => {
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					}
					);
				} else {
					this.stufeService.saveAccStufa(JSON.stringify(this.form.sections.accStufa)).subscribe(res => {
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					}
					);
				}
			} else {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 4) {
			//Step salvataggio omaggioDTO

			let allSuccess = true;

			for (let i = 0; i < this.form.sections.omaggio.length; i++) {
				const omaggio: OmaggioDto = this.form.sections.omaggio[i];
				omaggio.ordineId = this.form.idOrder;

				try {
					if (omaggio.id === undefined || omaggio.id === null) {
						// Save new omaggio
						await this.omaggioService.save(JSON.stringify(omaggio)).toPromise();
					} else {
						// Update existing omaggio
						await this.omaggioService.update(JSON.stringify(omaggio)).toPromise();
					}
				} catch (error) {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					allSuccess = false;
					break;
				}
			}

			if (allSuccess) {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 5) {
			// Step salvataggio pagamento
			if (this.hasValues(this.form.sections.pagamento[0])) {
				this.form.sections.pagamento[0].ordineId = this.form.idOrder;
				const payment = this.form.sections.pagamento[0];
				if (payment.id === undefined || payment.id === null) {
					this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				} else {
					this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
						// se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
						if (res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
							this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
								allegati.forEach((allegato: any) => {
									if (allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)) {
										this.allegatoService.delete(allegato.id).subscribe(() => {
										}, error => {
											console.error('Error => ', error);
											this.formController.openSnackBar(constants.ERROR_GENERIC_API);
										});
									}
								})
							})
						}
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				}
			}
		} else if (this.currentStep === 6) {
			//Step salvataggio privacy
			this.orderService.update(JSON.stringify(this.form.ordine)).subscribe(
				() => {
					this.setStep(this.currentStep + 1);
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
		} else if (this.currentStep === 7) {
			this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
				this.ordine.clienteId = res.id;
				this.ordine.cognome = res.cognome;
				this.ordine.nome = res.nome;
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			});
		} else if (this.currentStep === 8 && this.form.sections.finanziamentoSections.isAllegatoSaved) {
			// SOLO NEXT-STEP | PARTE FINANZIAMENTO
			this.setStep(this.currentStep + 1);
		}
	}

	openConfirmStufe(value): void {
		this.confirmStufe = value;
		jQuery('#confirmModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#confirmModal').modal('toggle');
		if (this.confirmStufe) {
			this.saveModule(true);
		}
	}

	moreThanOneStufa(): boolean {
		let count = 0;
		this.form.sections.stufe.forEach(r => {
			if (r.checked) {
				count += 1;
			}
		});
		if (count > 1) {
			return true;
		} else {
			return false;
		}
	}

	saveStufa(stufa): Promise<any> {
		return new Promise((resolve, reject) => {
			this.stufeService.save(JSON.stringify(stufa)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	updateStufa(stufa): Promise<any> {
		return new Promise((resolve, reject) => {
			this.stufeService.update(JSON.stringify(stufa)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	saveNote(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.stufeService.saveDt(JSON.stringify(this.form.sections.dettaglioDto)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	updateNote(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.stufeService.updateDt(JSON.stringify(this.form.sections.dettaglioDto)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	removeStufa(id): void {
		this.stufeService.delete(JSON.stringify(id)).subscribe(res => {
		}, error => {
			console.error('Error => ', error);
		}
		);
	}

	validStufe(): boolean {
		let valid = true;

		if (this.form.sections.stufe.length > 0) {
			for (let i = 0; i < this.form.sections.stufe.length; i++) {
				const stufa = this.form.sections.stufe[i];

				// Controllo base per tutte le stufe selezionate: quantita e coloreId devono essere presenti
				if (stufa.checked && (!stufa.quantita || !stufa.coloreId)) {
					valid = false;
					break;
				}

				// Controllo per stufe bio, bollino si | no obbligatorio
				if (stufa.modstufebioId && stufa.checked && (stufa.bollinoSconto == null)) {
					valid = false;
					break;
				}
			}
		}

		return valid;
	}


	createPdf(pdf, intervalId): void {
		this.sendingPdf = true;
		const title = localStorage.getItem('id_order') + '.pdf';
		pdf.setProperties({
			title,
		});
		this.valueProgBar = 80;
		const newPdf = btoa(pdf.output());

		this.formController.sendSignature(newPdf, this.form.idOrder, this.form, 10, "477,57,583,86", title, false).then(res => {
			this.valueProgBar = 100;
			this.sendingPdf = false;
			this.generatingPdf = false;
			clearInterval(intervalId);
			this.router.navigate(['order-list']);
		}, error => {
			this.generatingPdf = false;
			this.sendingPdf = false;
			this.ordine.statopraticaCodice = 'A';
			clearInterval(intervalId);
			this.error = error;
			jQuery('#errorModal').modal({ backdrop: 'static', keyboard: false });
			jQuery('#errorModal').modal('toggle');
		});
	}

	togglePrintPreview(show: boolean): void {
		this.showModalPrint = show;
		jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#printModal').modal('toggle');
	}

	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	validStabile(): boolean {
		if (
			this.form.sections.casaStufa.pianistabile === undefined ||
			this.form.sections.casaStufa.pianistabile == null ||
			this.form.sections.casaStufa.pianointerv === undefined ||
			this.form.sections.casaStufa.pianointerv === null ||
			!this.form.sections.casaStufa.cannafumariaId) {
			return false;
		} else {
			return true;
		}
	}

	validInstallazione(): boolean {
		if (this.form.sections.hiddenSectionCondition.dettagliInstallazione) {
			if (
				this.form.sections.accStufa.intubamentocannapiani === undefined ||
				this.form.sections.accStufa.intubamentocannapiani == null ||
				this.form.sections.accStufa.instalstandesterna === undefined ||
				this.form.sections.accStufa.instalstandinterna === null ||
				this.form.sections.accStufa.instalstandesterna === undefined ||
				this.form.sections.accStufa.instalstandinterna === null ||
				this.form.sections.accStufa.forosolettaqta === undefined ||
				this.form.sections.accStufa.forosolettaqta === null ||
				this.form.sections.accStufa.forosolettaqta < 0 ||
				this.form.sections.accStufa.forocementoqta === undefined ||
				this.form.sections.accStufa.forocementoqta === null ||
				this.form.sections.accStufa.forocementoqta < 0 ||
				this.form.sections.accStufa.forocementocm === undefined ||
				this.form.sections.accStufa.forocementocm === null ||
				this.form.sections.accStufa.forocementocm < 0 ||
				this.form.sections.accStufa.foromuroqta === undefined ||
				this.form.sections.accStufa.foromuroqta === null ||
				this.form.sections.accStufa.foromuroqta < 0) {
				return false;
			} {
				return true;
			}
		} else {
			return true;
		}
	}
			
	closeSignPdf(){
		jQuery('#signModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#signModal').modal('toggle');
	}


	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe(res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

	reload(value: boolean) {
		this.openSign = value;
	}
}
