<div class="text-center fotoBg">ARIEL SUN LIGHT IN KIT</div>
<div class="fotoCont">
    <div class="row originalRow">
        <div class="col-lg-12">
            <div *ngIf="trascodifiche && tipoProdottoLight">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-3 font-weight-bold">TAGLIA IMPIANTO</div>
                    <div class="col-3 font-weight-bold">BATTERIE</div>
                    <div class="col-3 font-weight-bold">INVERTER</div>
                    <div class="col-3 font-weight-bold">POTENZA MINIMA CONTATORE*</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let tipologia of tipoProdottoLight; let i = index">
                    <div class="col-3">
                        <mat-checkbox value="{{tipologia.tagliakwId}}" class="smalFt" color="primary"
                            (change)="update($event,tipologia.tagliakwId, tipologia)"
                            [checked]="checked(tipologia.tagliakwId)">
                            {{tipologia.tagliakwDescrizione}} </mat-checkbox>
                    </div>
                    <div class="col-3 smalFt">
                        {{tipologia.battkwDescrizione}}
                    </div>
                    <div class="col-3 smalFt">
                        {{tipologia.inverterkwDescrizione}}
                    </div>
                    <div class="col-3 smalFt">
                        {{tipologia.potenzakwDescrizione}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row text-danger smallFont mb-2">
    <div class="col-6">Dimensione pannello (AxLxP) 1762x1134x30 mm</div>
    <div class="col-6">* Necessaria richiesta aumento taglia contatore a cura del cliente prima dell'installazione</div>
</div>
<div class="text-center fotoBg">ARIEL SUN FULL IN KIT</div>
<div class="fotoCont">
    <div class="row originalRow">
        <div class="col-lg-12">
            <div *ngIf="trascodifiche && tipoProdottoFull">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-3 font-weight-bold">TAGLIA IMPIANTO</div>
                    <div class="col-3 font-weight-bold">BATTERIE</div>
                    <div class="col-3 font-weight-bold">INVERTER</div>
                    <div class="col-3 font-weight-bold">POTENZA MINIMA CONTATORE*</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let tipologia of tipoProdottoFull; let i = index">
                    <div class="col-3">
                        <mat-checkbox value="{{tipologia.tagliakwId}}" class="smalFt" color="primary"
                            (change)="update($event,tipologia.tagliakwId, tipologia)"
                            [checked]="checked(tipologia.tagliakwId)">
                            {{tipologia.tagliakwDescrizione}} </mat-checkbox>
                    </div>
                    <div class="col-3 smalFt">
                        {{tipologia.battkwDescrizione}}
                    </div>
                    <div class="col-3 smalFt">
                        {{tipologia.inverterkwDescrizione}}
                    </div>
                    <div class="col-3 smalFt">
                        {{tipologia.potenzakwDescrizione}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row text-danger smallFont mb-2">
    <div class="col-6">Dimensione pannello (AxLxP) 1722x1134x30 mm</div>
    <div class="col-6">* Necessaria richiesta aumento taglia contatore a cura del cliente prima dell'installazione</div>
</div>
<div class="row originalRow">
    <div class="col-lg-6 mb-4">
        <div class="fotovoltaicoContainer">
            <mat-checkbox [(ngModel)]="form.sections.fotovoltaico.praticaallacciamento" class="smalFt" color="primary"
                [checked]="true">
                PRATICA ALLACCIAMENTO PRESSO DISTRIBUTORE </mat-checkbox>
        </div>
    </div>
    <div class="col-lg-6 mb-4">
        <div class="fotovoltaicoContainer">
            <div class="text-left font-weight-bold mb-2">
                COLONNINA DI RICARICA AUTO
            </div>
            <mat-checkbox [(ngModel)]="form.sections.fotovoltaico.smartcharger" class="smalFt" color="primary"
                [checked]="true">
                SMART EV CHARGER</mat-checkbox>
        </div>
    </div>
</div>

<div class="fotoBg noteTitle"> NOTE </div>
<div class="fotovoltaicoContainer">
    <app-note-sh [nota]="form.sections.fotovoltaico.note" (onNotaEmit)="form.sections.fotovoltaico.note = $event"
        [maxLength]="255" [minLine]="3">
    </app-note-sh>
</div>