<div class="zucchettiMontascaleBg">ARRIVO IN ALTO DELLA GUIDA </div>
<div class="zucchettiMontascaleCont"
    *ngIf="listFirstLineArrivoInAltoTrasc.length && listSecondLineArrivoInAltoTrasc.length">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col mb-3" *ngFor="let arrivoInAlto of listFirstLineArrivoInAltoTrasc;">
                <app-arrivo-item [arrivoGuidaType]="'arrivoAlto'" [element]="arrivoInAlto" [form]="form"
                    [listTrasc]="listFirstLineArrivoInAltoTrasc" [showInputLine]="showIsInput(arrivoInAlto)"
                    [unitOfMeasure]="showMeasurementInput(arrivoInAlto)"
                    [underDescription]="filtedUnderBoxDescription(arrivoInAlto)"></app-arrivo-item>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-4 col mb-3" *ngFor="let arrivoInAlto of listSecondLineArrivoInAltoTrasc;">
                <app-arrivo-item [arrivoGuidaType]="'arrivoAlto'" [element]="arrivoInAlto" [form]="form"
                    [listTrasc]="listSecondLineArrivoInAltoTrasc" [showInputLine]="showIsInput(arrivoInAlto)"
                    [unitOfMeasure]="showMeasurementInput(arrivoInAlto)"
                    [underDescription]="filtedUnderBoxDescription(arrivoInAlto)"></app-arrivo-item>
            </div>
        </div>
    </div>
</div>

<div class="zucchettiMontascaleBg noteTitle"> NOTE </div>
<div class="zucchettiMontascaleCont">
    <app-note-sh [nota]="form.sections.montascale.noteliberetipoarrivo"
        (onNotaEmit)="form.sections.montascale.noteliberetipoarrivo = $event" [maxLength]="255" [minLine]="3">
    </app-note-sh>
</div>

<!--<div class="zucchettiMontascaleCont">
    <div class="row originalRow">
        <div class="col-12 mb-3"><b>NOTE LIBERE:</b></div>
        <mat-form-field style="padding-left: 15px; padding-right: 15px;" class="full-width" appearance="fill">
            <mat-label>Note libere</mat-label>
            <textarea matInput placeholder="Note..." [(ngModel)]="form.sections.montascale.noteliberetipoarrivo"></textarea>
        </mat-form-field>
    </div>
</div>-->