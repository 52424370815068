import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PaintableComponent } from 'paintablejs/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { NuoviDatiTecniciService } from '../services/nuovi-dati-tecnici/nuovi-dati-tecnici.service';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { NuoviDatiDTO } from '../classes/dati-tecnici';
import * as _ from 'lodash';
import * as $ from 'jquery';
@Component({
  selector: 'app-form-piantina-nuovo',
  templateUrl: './form-piantina-nuovo.component.html',
  styleUrls: ['./form-piantina-nuovo.component.scss']
})
export class FormPiantinaNuovoComponent implements OnInit {

  @Input() form: any;
  @ViewChild(PaintableComponent)
  paintable: PaintableComponent;
  @Input() prodotto: any;
  active = false;
  useEraser = false;
  thickness = 2;
  color = '#000000';
  savedImage: any;
  drawing = true;
  manualUpload: any;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  isLoadingImage: boolean = true;
  width: number;
  height: number;
  classBg: string = 'blueBg';
  classContent: string = 'blueCont';
  
  constructor(
      private sanitizer: DomSanitizer,
      private nuoviDatiTecService:NuoviDatiTecniciService,
      private formController: FormControllerService
  ) { }

  ngOnInit(): void {
    if(this.prodotto === 'bagno-pratico'){
      this.classBg = 'bagnoPraticoBg';
      this.classContent = 'bagnoPraticoCont';
    }
		this.callImage();
		this.height = 300;
    	this.width = document.getElementById('partTwo').offsetWidth - 40;
  }
  clear(): void {
    this.paintable?.clear();
    this.isImageSaved = false;
    delete this.cardImageBase64;
  }
  undo(): void {
    this.paintable?.undo();
  }

  redo(): void {
    this.paintable?.redo();
  }

  toggleEdit(): void {
    this.useEraser = false;
    this.active = !this.active;
	  if (this.active) {
		  $('body').addClass('noScr');
		  document.body.requestFullscreen();
		  $('html,body').animate({
			  scrollTop: $('#partTwo').offset().top - 80
		  },'slow');
	  } else {
		  $('body').removeClass('noScr');
		  document.exitFullscreen();
		  $('html,body').animate({
			  scrollTop: $('#partTwo').offset().top - 80
		  },'slow');
	  }
  }

  toggleUseEraser(): void {
    this.useEraser = !this.useEraser;
  }

  get image(): string {
	return this.cardImageBase64 || undefined;
  }
  
  onSave(image: string): void {
    delete this.cardImageBase64;
    var arr = image.split(";");
    this.form.sections.nuoviDati.nuovaPiantinaType = 'image/png';
    var byte = arr[1].split(",");
    this.form.sections.nuoviDati.nuovaPiantina = byte[1];
	  var dtoNuovo:NuoviDatiDTO = this.formController.mappingFormToNuoviDatiTecDTO(this.form.sections.nuoviDati , new NuoviDatiDTO(),this.form.idOrder ); 
	  dtoNuovo.nuovapiantina = this.form.sections.nuoviDati.nuovaPiantina;
	  dtoNuovo.nuovapiantinaContentType = this.form.sections.nuoviDati.nuovaPiantinaType;
 
  if(dtoNuovo.id === undefined){
		    this.nuoviDatiTecService.save(JSON.stringify(dtoNuovo)).subscribe(res => {
        this.form.sections.nuoviDati=res.id;
        this.form.sections.nuoviDati.nuovaPiantinaType =  res.nuovapiantinaContentType;
				this.form.sections.nuoviDati.nuovaPiantina = res.nuovapiantina;
       
        this.cardImageBase64 ="";
				this.cardImageBase64 = "data:"+res.nuovapiantinaContentType+";base64,"+res.nuovapiantina;
				this.isLoadingImage = false;
				this.active = false;
				this.isImageSaved = true;
		});	
	}else{
		this.nuoviDatiTecService.update(JSON.stringify(dtoNuovo)).subscribe(res => {
			this.callImage();
		});
	}
    this.isImageSaved = true;
  }
  
  onLongPress(): void {
  }
  
  fileChangeEvent(fileInput: any): any {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 5242880;
      const allowed_types = [
        'image/jpg',
        'image/jpeg',
        'image/png',
      ];
      const max_height = 15200;
      const max_width = 25600;
      
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
            'Maximum size allowed is ' + max_size / 1000000 + 'Mb';
        return false;
      }
      
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
	      this.imageError = 'Sono consentite solo immagini ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          
          if (img_height > max_height && img_width > max_width) {
            this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
            return false;
          } else {
              const imgBase64Path = e.target.result;
              this.cardImageBase64 = imgBase64Path;
              this.isImageSaved = true;
              this.formController.compressImage(this.cardImageBase64, this.width, this.height).then(compressed => {
              this.cardImageBase64 = compressed;
				      let img = this.cardImageBase64;
	            var arr = img.split(";");
	            this.form.sections.nuoviDati.nuovaPiantinaType = 'image/png';
	            var byte = arr[1].split(",");
	            this.form.sections.nuoviDati.nuovaPiantina = byte[1];
            }, err => console.error('this.err => ', err));
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
      this.active = false;
    }
  }

  removeImage(): void {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  callImage(){
		this.nuoviDatiTecService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      if(res[0] !== undefined){
			  if(res[0].nuovapiantina !== null && res[0].nuovapiantina != undefined){
				  this.form.sections.nuoviDati.nuovaPiantinaType =  res[0].nuovapiantinaContentType;
				  this.form.sections.nuoviDati.nuovaPiantina = res[0].nuovapiantina;
				  this.cardImageBase64 = "data:"+res[0].nuovapiantinaContentType+";base64,"+res[0].nuovapiantina;
				  this.isLoadingImage = false;
				  this.active = false;
				  this.isImageSaved = true;
			  }else{
				  this.isLoadingImage = false;
			  }	
		  }else{
				this.isLoadingImage = false;
			}
	  });
  }
}
