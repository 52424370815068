<div class="row originalRow">
    <mat-form-field style="padding-left: 15px; padding-right: 15px;" class="full-width" appearance="fill">
        <mat-label>Note</mat-label>
        <textarea matInput placeholder="Note..." [attr.rows]="minLine" [(ngModel)]="nota"
            (ngModelChange)="emit()"></textarea>
    </mat-form-field>
    <div class="col-6 smallFont text-danger">
        <span *ngIf="nota?.length > maxLength">Il testo inserito supera il limite di {{maxLength}} caratteri</span>
    </div>
    <div class="col-6 d-flex justify-content-end pr-3">
        <span>{{nota?.length || 0}} / {{maxLength}}</span>
    </div>
</div>