<div id="headingOne">
	<h5 class="mb-0">
		<div class="text-center bluBg row">
			<div class="col-6 text-right">VASCA
				<mat-hint >*</mat-hint>
			</div>
			<div class="col-6 text-left">
				<mat-radio-group labelPosition="after" class="inline" color="primary" [(ngModel)]="optionVasca.selected"
					(ngModelChange)="showDiv($event)">
					<mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
					<mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
				</mat-radio-group>
			</div>
		</div>
	</h5>
</div>
<br>

<div class="text-center bluBg" *ngIf="isVisible">VASCA OPEN</div>
<div class="row mb-3 bluBorder" *ngIf="isVisible">
	<div class="col-lg-6">
		<div class="text-center mb-4">
			<img src="assets/img/remail/vascaopen.jpg" alt="vasca open" />
			<mat-radio-group aria-labelledby="example-radio-group-label"
				[(ngModel)]="form.sections.vasca.openposportestrId" color="primary" class="mt-2">
				<div class="d-inline-block mr-4">
					<mat-radio-button class="d-inline-block mr-4" *ngFor="let sportello of listSportello"
						[value]="sportello.id">
						{{sportello.descrizione}}
					</mat-radio-button>
					<span class="undo material-icons text-center" matTooltip="Cancella selezione"
						*ngIf="form.sections.vasca.openposportestrId"
						(click)="form.sections.vasca.openposportestrId = null">
						backspace
					</span>
				</div>
			</mat-radio-group>
			<div class="mt-2 font-bold">POSIZIONE SPORTELLO ESTRAIBILE</div>
			<div class="mt-2 font-bold">* SPORTELLO COLORE "TRASPARENTE"</div>
		</div>
		<div class="d-lg-none d-xl-none">
			<hr />
		</div>
	</div>
	<div class="col-lg-6 text-center">
		<div class="mr-2">SEDILE INTEGRATO ( SOLO 170x70 )</div>
		<mat-radio-group labelPosition="after" color="primary" aria-label="Tipo"
			[(ngModel)]="form.sections.vasca.opensedileint">
			<mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
			<mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
		</mat-radio-group>
		<div class="mt-2 text-left font-bold colorLabel">NOTA:</div>
		<div class="mt-2 text-left font-bold">IN CASO DI SEGGIOLINO
			REMOVIBILE, LO SPORTELLINO DOVR&#192; SEMPRE ESSERE POSIZIONATO IN
			POSIZIONE LATERALE ( 1 O 3 ) E MAI IN POSIZIONE CENTRALE ( 2 )</div>
	</div>
</div>


<div class="text-center bluBg" *ngIf="isVisible">VASCA E-MOTION</div>

<div class="row mb-3 bluBorder" *ngIf="isVisible">
	<div class="col-lg-6 text-center">
		<img src="assets/img/remail/vascaemotion.jpg" alt="vasca emotion" />
		<div class="mt-2 font-bold mb-3">*SPORTELLO COLORE "BIANCO"</div>
		<hr />
		<div class="mb-4">
			<mat-radio-group labelPosition="before" color="primary" aria-label="Tipo"
				[(ngModel)]="form.sections.vasca.emotiondimId" (ngModelChange)="changeModel($event)">
				<mat-radio-button class="d-inline-block mr-2 colorLabel" [value]="dim.id"
					*ngFor="let dim of listDimEmotion">{{dim.descrizione}}
				</mat-radio-button>

				<span class="undo material-icons" matTooltip="Cancella selezione"
					*ngIf="form.sections.vasca.emotiondimId" (click)="form.sections.vasca.emotiondimId = null">
					backspace
				</span>
			</mat-radio-group>
		</div>
	</div>
	<div class="col-lg-6">
		<mat-radio-group *ngIf="form.sections.vasca.emotiondimId" class="" color="primary"
			[(ngModel)]="form.sections.vasca.emotionaperturaId">

			<mat-radio-button [value]="dim.id" class="d-inline-block mr-2" *ngFor="let dim of listApeEmotion">
				<img src="{{dim.note}}" />
			</mat-radio-button>
			<span class="undo material-icons" matTooltip="Cancella selezione"
				*ngIf="form.sections.vasca.emotionaperturaId" (click)="form.sections.vasca.emotionaperturaId = null">
				backspace
			</span>
		</mat-radio-group>
	</div>
</div>

<div class="row mb-3 bluBorder" *ngIf="isVisible"> 
	<div class="col-6">
		<app-input-number 
			(onNumChange)="form.sections.vasca.emotionlarghporta = $event" [step]="0.1"
			[num]="form.sections.vasca.emotionlarghporta" 
			[decimal]="true" 
			[payment]="false" 
			[small]="false"
			label="Larghezza porta bagno cm:">
		</app-input-number>
	</div>
	<div class="col-6">
		<div class="smallFont text-danger mt-4">In caso di larghezza porta inferiore a 70 cm &egrave;
			necessario lo smontaggio della porta o il taglio del muro.</div>
	</div>
</div>

<div class="text-center bluBg" *ngIf="isVisible">VASCA EXPRESS</div>

<div class="row mb-3 bluBorder" *ngIf="isVisible">
	<div class="col-6 text-center">
		<img src="assets/img/remail/vascaexpress.jpg" alt="vasca express" />
	</div>
	<div class="col-6">
		<div class="mb-2">Erogazione Acqua:</div>
		<mat-radio-group labelPosition="after" color="primary" aria-label="Tipo"
			[(ngModel)]="form.sections.vasca.expresserogazioneId">
			<mat-radio-button class="example-radio-button" [value]="erog.id" *ngFor="let erog of listExpErog">
				{{erog.descrizione}}</mat-radio-button>

			<span class="undo material-icons" matTooltip="Cancella selezione"
				*ngIf="form.sections.vasca.expresserogazioneId"
				(click)="form.sections.vasca.expresserogazioneId = null"> backspace
			</span>
		</mat-radio-group>

		<div>
			<app-input-number (onNumChange)="form.sections.vasca.expresslung = $event" [step]="0.1"
				[num]="form.sections.vasca.expresslung" [decimal]="true" [payment]="false" [small]="false"
				label="Lunghezza (L) cm:">
			</app-input-number>
		</div>

		<div>
			<app-input-number (onNumChange)="form.sections.vasca.expresslargh = $event" [step]="0.1"
				[num]="form.sections.vasca.expresslargh" [decimal]="true" [payment]="false" [small]="false"
				label="Larghezza (P) cm:">
			</app-input-number>
		</div>
		<div>
			<app-input-number (onNumChange)="form.sections.vasca.expressalt = $event" [step]="0.1"
				[num]="form.sections.vasca.expressalt" [decimal]="true" [payment]="false" [small]="false"
				label="Altezza (H) cm:">
			</app-input-number>
		</div>
	</div>
</div>
<br>