<h2 mat-dialog-title>Anteprima contratto</h2>
<mat-dialog-content>
    <div *ngIf="!showpdf">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
    <pdf-viewer [autoresize]="true" [original-size]="false" [src]="data.pdf"
                [ngStyle]="{'height.px': screenHeight}" style="display: block"></pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Chiudi</button>
</mat-dialog-actions>
