export class AccessoricaldaiaDTO {
	abbattitore: boolean;
	accessotetto: boolean;
	cestaidr: boolean;
	cronostand: boolean;
	cronowifi: boolean;
	id: number;
	installazioneId: number;
	intubcanna: number;
	lavaggioimp: boolean;
	nokalzone: boolean;
	note: string;
	ordineId: number;
	pompacond: boolean;
	prelievoariaId: number;
	scaricocondId: number;
	necessarioForareTop: boolean;
	uscitafumiId: number;
	valvolatermo: number;
	numcronostand: number;
	numcronowifi: number;
	ponteggio: boolean;
	suoloPubblico: boolean;
}
