import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { constants } from '../../../classes/constants';
import { ServizioDettagliService } from 'src/app/services/servizio-dettagli/servizio-dettagli.service';
import { ServizioDettagli } from 'src/app/modules/classes/servizi/servizioDettaglio';

@Component({
  selector: 'sh-servizio-dettaglio',
  templateUrl: './servizio-dettaglio.component.html',
  styleUrls: ['./servizio-dettaglio.component.scss']
})
export class ServizioDettaglioComponent implements OnInit {

  @Input()
  form:any;

  @Input()
  brand: string;

  @Input()
  productColor: string;

  @Input()
  trascodifiche: Trascodifica[] = [];
  
  listServizi: Trascodifica[] = []

  public constants = constants;

  constructor(
    private formController: FormControllerService,
    private servizioDettaglio: ServizioDettagliService
  ) { }

  ngOnInit(): void {
    this.formController.getTrascodificaByCodicPromise(constants.COD_SERVIZI_DEPURATORE, this.trascodifiche).then((data) => {
      this.listServizi = data;
    });

    this.servizioDettaglio.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe((res: ServizioDettagli[]) => {
      this.form.sections.serviziDettaglio = res;

      let element = this.form.sections.serviziDettaglio.find((el: ServizioDettagli) => {
        return el.ordineId && !el.trascodificaId
      });

      if(element && element.id){
        this.form.sections.servizioInput = element;
      }
    })
  }

  onOptionsChange(event: any, opzione: any): void {  
    const elemento = this.form.sections.serviziDettaglio.find(
      (e: any) => e.trascodificaId === opzione.id
    );
  
    if (event.checked) {
      if (!elemento) {
        this.form.sections.serviziDettaglio.push({ trascodificaId: opzione.id });
      }
    } else {
      const index = this.form.sections.serviziDettaglio.findIndex(
        (op: any) => op.trascodificaId === opzione.id
      );
      if (index !== -1) {
        this.form.sections.serviziDettaglio.splice(index, 1);

        if(elemento.id){
          this.form.sections.delServiziDettaglio.push(elemento);
        }
      }
    }
  }
  
  isChecked(optionId: number): boolean {
    return this.form.sections.serviziDettaglio.some(
      (servizio: any) => servizio.trascodificaId === optionId
    );
  }

  isCustomInputChecked(value): boolean {
    return value?.descrizione || value?.costo
  }
  
  convertTrascodifica(id: number) {
    const matchingTrascodifica = this.trascodifiche.find(el => el.id === id);
    if (matchingTrascodifica) {
      return matchingTrascodifica.descrizione;
    }
  
    return ''; 
  }
}
