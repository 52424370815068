import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from '../../material/material.module';
import { PaintableModule } from 'paintablejs/angular';
import { SharedModule } from '../../shared/shared.module';
import { CristalBoxMainComponent } from './cristalbox-main/cristalbox-main.component';
import { CristalBoxVascaInDocciaComponent } from './cristalbox-main/vasca-in-doccia/vasca-in-doccia.component';
import { CristalBoxDatitecnicivascaComponent } from  './cristalbox-main/vasca-in-doccia/datitecnicivasca/datitecnicivasca.component'; 
import { CristalBoxOmaggioComponent } from './cristalbox-main/vasca-in-doccia/omaggio/omaggio.component'; 
import { CristalBoxAccessoriComponent } from './cristalbox-main/vasca-in-doccia/accessori/accessori.component'; 
import { CristalBoxNuovidatitecniciComponent } from './cristalbox-main/vasca-in-doccia/nuovidatitecnici/nuovidatitecnici.component'; 
import { CristalBoxdocciaComponent } from './cristalbox-main/vasca-in-doccia/boxdoccia/boxdoccia.component';
import { CristalBoxNuovopiattodocciaComponent } from './cristalbox-main/vasca-in-doccia/nuovopiattodoccia/nuovopiattodoccia.component';
import { CristalBoxGresComponent } from './cristalbox-main/vasca-in-doccia/gres/gres.component';
import { CristalBoxMiscelatoriComponent } from './cristalbox-main/vasca-in-doccia/miscelatori/miscelatori.component';
import { CristalBoxVascaComponent } from './cristalbox-main/vasca-in-doccia/vasca/vasca.component';
import { CristalBoxVascaInDocciaPrintComponent } from './cristalbox-main/vasca-in-doccia/print/vasca-in-doccia-print.component';
@NgModule({
  declarations: [
    CristalBoxMainComponent,
    CristalBoxVascaInDocciaComponent, 
    CristalBoxOmaggioComponent, 
    CristalBoxDatitecnicivascaComponent, 
    CristalBoxAccessoriComponent, 
    CristalBoxNuovidatitecniciComponent, 
    CristalBoxdocciaComponent, 
    CristalBoxNuovopiattodocciaComponent, 
    CristalBoxGresComponent, 
    CristalBoxMiscelatoriComponent, 
    CristalBoxVascaComponent, 
    CristalBoxVascaInDocciaPrintComponent
  ],
  imports: [
		CommonModule,
		MatFormFieldModule,
		MaterialModule,
		PaintableModule,
		SharedModule,
  ],
  exports:[
    CristalBoxVascaInDocciaComponent,
    CristalBoxDatitecnicivascaComponent,
    CristalBoxNuovidatitecniciComponent,
    CristalBoxdocciaComponent,
    CristalBoxGresComponent,
    CristalBoxOmaggioComponent,
    CristalBoxAccessoriComponent
  ]
})
export class CristalBoxModule { }
