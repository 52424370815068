class InfoContratto {
	consulente: string;
	data = new Date();
	tel: string;
}

class Contatti {
	email: string;
	tel1: string;
	tel2: string;
}

class Indirizzo {
	comune: string;
	via: string;
	civico: string;
	provincia: string;
	cap: string;
	ztl: boolean;
	altroIndirizzo:boolean;
	indirizzoInstallazione: string;
}

class Anagrafica {
	id: number;
	nome: string;
	cognome: string;
	contatti: Contatti = new Contatti();
	indirizzo: Indirizzo = new Indirizzo();
	cf_piva: string;
}

export class AnagraficaForm {
	infoContratto = new InfoContratto();
	anagrafica = new AnagraficaDTO();
	agente: string;
}

export class AnagraficaDTO{
	id:number;
	nome: string;
	cognome: string;
	email: string;
	tel1: string;
	tel2: string;
	citta: string;
	indirizzo: string;
	indirizzoinst:string;
	numero: string;
	provincia: string;
	cap: string;
	ztl: boolean;
	cf: string;
	piva:string;
	ragionesociale: string;
	centrostorico: boolean;
	piano:number;
	ascensore: boolean;
	ritirousato:boolean;
	scalachiocc:boolean;
	portonestr:boolean;
	scalastr:boolean;
	diversoind:boolean;
}
