import { Component, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from 'src/app/services/brand/brand.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { Ordine } from 'src/app/modules/classes/ordine';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { AnagraficaDTO, AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { ServizioResponsabileService } from 'src/app/services/servizio-responsabile/servizio-responsabile.service';
import { ServizioDettagliService } from 'src/app/services/servizio-dettagli/servizio-dettagli.service';
import * as $ from 'jquery';
import { AttivazioneServizi } from 'src/app/modules/classes/attivazione_servizi/attivazioneServizi';
import { constants } from 'src/app/modules/classes/constants';
declare var jQuery: any;

@Component({
  selector: 'activation-extra-depuratore',
  templateUrl: './servizi_depuratore.component.html',
  styleUrls: ['./servizi_depuratore.component.scss']
})
export class ActivationExtraDepuratoreComponent implements OnInit {

  currentStep:number = 0;

  generatingPdf: boolean  = false;
  showModalPrint: boolean = false;
  showPrint: boolean = false;
  validAnagrafica: boolean = false;
  openSign:boolean=false;
  isLoaded: boolean = false;
  changeData: boolean = false;

  anagrafica: AnagraficaDTO;
  ordine: Ordine;
  trascodifiche: Trascodifica[] = [];
  listServizi: Trascodifica[] = [];

  totalSteps: number[];

  form: any;
  statoPratiche:any;
  statoAttivo:any;
  listTypeDoc:any;
  
  attivazioneServiziPdfHeader: { logoUrl: string, product: string, brand: string, } = {
	logoUrl: 'assets/img/loghi/ARIEL.jpg', product: 'depuratore', brand: 'Ariel'}

  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  	constructor(
    	public formController: FormControllerService,
    	private route: ActivatedRoute,
		private brandService: BrandService,
    	private orderService: OrdersService,
    	private servizioResponsabileService: ServizioResponsabileService,
		private servizioDettaglioService: ServizioDettagliService
  	) {
	  	this.totalSteps = Array(1).fill(1).map((x, i) => i);
   }

   	ngOnInit(): void {
		this.initializeComponent();
  	}

	async initializeComponent(): Promise<void> {

		this.form = {
			idOrder: Number(this.route.snapshot.paramMap.get('ordineId')),
			anagrafica: new AnagraficaForm(),
      		sections: {
        		moduloServizi: true,
        		responsabileList: [],
        		delResponsabili: [],
        		serviziDettaglio: [],
				delServiziDettaglio: []
      		},
      	ordine: null
      }

	  
		if (localStorage.getItem('id_client') !== null) {
		  this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
		}
	  
		if (this.currentStep > 0) {
		  $('.imgModulo').addClass('yesShow');
		} else {
		  $('.imgModulo').removeClass('yesShow');
		}
	  
		await this.getOrder(this.form.idOrder);
	}

	checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}
   	
	async getOrder(idOrder: number): Promise<void> {
		const resPratiche = await this.orderService.getAllStatoPratica().toPromise();
		this.statoPratiche = resPratiche;
	  
		if (this.statoPratiche.length) {
		  this.statoAttivo = this.statoPratiche.find((el: any) => el.codice == 'A');
	  
		  const ordine = await this.orderService.getSingleOrder(idOrder).toPromise();
		  this.ordine = ordine;
		  this.form.ordine = ordine;
	  
		  localStorage.setItem('id_product', ordine.prodottoId);
	  
		  if (ordine.clienteId !== undefined && ordine.clienteId !== null) {
			localStorage.setItem('id_client', ordine.clienteId);
			this.form.anagrafica.anagrafica.id = Number(ordine.clienteId);
		  }
	  
		  if (!this.form.ordine.attivazioneServizi?.id) {
			this.ordine.attivazioneServizi = new AttivazioneServizi();
			this.ordine.attivazioneServizi.statorichiestaId = this.statoAttivo.id;
	
			const updatedOrder = await this.orderService.update(JSON.stringify(this.ordine)).toPromise();
			this.ordine = updatedOrder;
		  }

		  this.brandService.getDataByCodice(constants.CODICE_TIPO_DOC).subscribe((res => this.listTypeDoc = res));
	  
		  localStorage.setItem('id_order', ordine.idordine);
	  
		  const trascodifiche = await this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).toPromise();
		  this.trascodifiche = trascodifiche;
		  this.isLoaded = true;
		}
	}

	setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0){
		  $('.imgModulo').addClass('yesShow');
		} else {
		  $('.imgModulo').removeClass('yesShow');
		}
	
		this.scrollTop();
	}

	
	stepController():boolean {
		let disabled = false;
		if(!this.validAnagrafica && this.currentStep === 0 && !this.isLoaded && !this.form.ordine?.id){
		  disabled = true;
		}
	   return disabled;
	}

	saveModule(): void {
		$('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
	
		if (this.currentStep === 0) {
			// SAVE RESPONSABILI
			const responsabileDepuratore = this.form.sections.responsabileList;
			const delResponsabileDepuratore = this.form.sections.delResponsabili;
	
			const deleteResPromises = delResponsabileDepuratore
				.filter(responsabile => responsabile.id)
				.map(responsabile => this.servizioResponsabileService.delete(responsabile.id).toPromise());
	
			this.form.sections.delResponsabili = [];
	
			const saveOrUpdateResPromises = responsabileDepuratore
				.filter(el => el.nome && el.tel)
				.map(el => {
					el.ordineId = this.form.idOrder;
					return el.id 
						? this.servizioResponsabileService.update(el).toPromise() 
						: this.servizioResponsabileService.save(el).toPromise();
				});
	
			// SAVE SERVIZI
			const serviziDepuratore = this.form.sections.serviziDettaglio;
			const delServizi = this.form.sections.delServiziDettaglio;
	
			const deleteServPromises = delServizi
				.filter(servizio => servizio.id)
				.map(servizio => this.servizioDettaglioService.delete(servizio.id).toPromise());
	
			this.form.sections.delServiziDettaglio = [];
	
			const saveOrUpdateServiziPromises = serviziDepuratore.map(el => {
				el.ordineId = this.form.idOrder;
				return el.id 
					? this.servizioDettaglioService.update(el).toPromise() 
					: this.servizioDettaglioService.save(el).toPromise();
			});
	
			Promise.all([
				...deleteResPromises, 
				...saveOrUpdateResPromises, 
				...deleteServPromises, 
				...saveOrUpdateServiziPromises
			])
			.then(() => {
				this.setStep(this.currentStep + 1);
			})
			.catch(error => { console.error(error) });
		}
	}
	  	
  	togglePrintPreview(show:boolean): void {
		this.showModalPrint = show;
			jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
			jQuery('#printModal').modal('toggle');
  	}	
	
	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	
	scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}
	  

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.attivazioneServizi.statorichiestaCodice = "A";
			res.attivazioneServizi.statorichiestaId = this.statoAttivo.id;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}
	
	reload(value: boolean) {
		this.openSign = value;
	}
}
