import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllegatoService } from '../../../../services/allegato/allegato.service';
import { Allegato } from '../../../classes/allegato';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../environments/environment';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';

import { UploadService } from 'src/app/services/uploadFiles/upload.service';

declare var jQuery: any;

@Component({
    selector: 'app-upload-area',
    templateUrl: './upload-area.component.html',
    styleUrls: ['./upload-area.component.scss']
})
export class UploadAreaComponent implements OnInit {

    constructor(
        private dialogRef: MatDialog,
        private allegatoService: AllegatoService,
        private formController: FormControllerService,
        private snackBar: MatSnackBar,
        private uploadFilesService: UploadService
    ) {
    }

    private static MAX_FILE_SIZE_BYTES = 15 * 1000 * 1000;
    arrayFileExceeded: any[] = [];
    arrayFiles: any[] = [];
    arrayBool: boolean[] = [];

    upload = false;
    @Input() listTypeDoc?: any;
    @Input() modello: any;
    @Input() date?: any;
    @Output() callbackUpload = new EventEmitter<any>();
    @ViewChild('fileDropRef', { static: false }) fileDropEl!: ElementRef;
    @Input() gdl_id?: any;
    @Input() id?: any;
    @Input() idordine?: any;
    @Input() listAllegati: any[];
    files: any[] = [];
    loadingFiles = false;
    fileName = '';
    fileType = '';
    FOLDER = environment.awsFolder;  // prod ;
    BUCKET = environment.awsBucket; // 'ordinigdlfileupload';
    allegatoToSave: Allegato;
    aws: any;

    ngOnInit(): void {
    }


    uploadFile(file, idOrdineGdl, name, originalName): Promise<Allegato> {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            formData.append('file', file);
            formData.append('gdlId', file.gdlId);
            formData.append('idOrdineGdl', idOrdineGdl);

            this.uploadFilesService.uploadFile(formData).subscribe(
                (response: any) => {
                    const allegato = new Allegato();
                    allegato.ordineId = file.ordineId;
                    allegato.tipoId = file.tipoId;
                    allegato.path = response;
                    allegato.size = file.size;
                    allegato.originalName = originalName;
                    resolve(allegato);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }


    /**
     * handle file from browsing
     */
    handleFileDropped(files: any) {
        if (!files) {
            return;
        } else {
            this.upload = false;
            if (this.isValidNameFile(files) && this.isValidSize(files)) {
                this.arrayFiles = [];
                for (const item of files) {
                    if (this.hasExtension(item)) {
                        if (item.size <= UploadAreaComponent.MAX_FILE_SIZE_BYTES) {
                            this.arrayFiles.push(item);
                        }
                    }

                }

                this.prepareFilesList(this.arrayFiles);
            }
        }

    }

    hasExtension(file: File): boolean {
        let valid = true;

        const fileNameParts = file.name.split('.');
        if (fileNameParts.length < 2) {
            valid = false;
            this.snackBar.open('Impossibile caricare il file ' + file.name + ', estensione mancante', '', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }

        return valid;
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        if (this.arrayFiles) {
            if (this.arrayFiles[index]?.progress < 100) {
                return;
            }
            this.arrayFiles.splice(index, 1);
        } else {
            return;
        }

    }

    uploadToS3(files: File[]) {
        this.modello.ordineId = this.id;
        this.modello.gdlId = this.gdl_id;
        this.checkAndSave();
    }

    /**
     * Simulate the upload process
     */
    uploadFilesSimulator(index: number) {
        setTimeout(() => {
            if (this.files) {
                if (index === this.files.length) {
                    return;
                } else {
                    const progressInterval = setInterval(() => {
                        if (this.files && this.files[index].progress === 100) {
                            clearInterval(progressInterval);
                            this.uploadFilesSimulator(index + 1);
                        } else {
                            if (this.files) {
                                this.files[index].progress += 5;
                            } else {
                                return;
                            }

                        }
                    }, 100);
                }
            } else {
                return;
            }

        }, 200);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>): void {
        for (const item of files) {
            item.progress = 0;
            if (this.files) {
                this.files.push(item);
            }
            item.fileAttr = item.name;
            this.readFileContent(item).then(res => {
                item.content = res.split(',')[1];
            });
        }
        this.fileDropEl.nativeElement.value = '';
        this.uploadFilesSimulator(0);

    }

    readFileContent(file: File): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!file) {
                resolve('');
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = reader.result;
                resolve(text);
            };
            reader.readAsDataURL(file);
        });
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    filesReady(): boolean {
        return this.files[0].progress === 100;
    }

    abort() {
        this.dialogRef.closeAll();
    }

    isUploadDisabled() {
        return !this.modello.tipoId || this.arrayFiles.length === 0 || this.arrayFiles.find(f => f.progress !== 100);

    }

    checkAndSave() {
        this.loadingFiles = true;
        this.arrayBool = [];
        this.arrayFiles.forEach((res) => {
            this.arrayBool.push(false);
        });
        this.arrayFiles.forEach((f, i) => {
            f.tipoId = this.modello.tipoId;
            f.ordineId = this.modello.ordineId;
            f.gdlId = this.modello.gdlId;

            const find = this.listTypeDoc.findIndex(tr => tr.id === this.modello.tipoId);
            this.modello.originalName = this.listTypeDoc[find].note + '_' + this.idordine + '_' + f.fileAttr;
            let indAllegato = Number(i) + Number(this.listAllegati.length);
            let listEstensione = this.modello.originalName.split(".");
            let estensione = listEstensione[listEstensione.length - 1];
            this.modello.name = this.listTypeDoc[find].note + '_' + this.idordine + '_allegato_' + indAllegato + '.' + estensione;
            f.loading = true;

            // controllo che il nome file non esista giÃ¯Â¿Â½
            if (this.listAllegati.length > 0) {
                // se ci sono vecchi allegati salvati controllo prima su quelli
                this.modello.name = this.checkFileExist(this.modello.name, this.listAllegati);
            }

            this.uploadFile(f, this.idordine, this.modello.name, this.modello.originalName).then((allSave: Allegato) => {
                allSave.dataCreazione = this.formController.createDateAsUTC(new Date());
                // Salvo l'allegato
                this.allegatoService.save(allSave).subscribe(res => {
                    this.arrayBool[i] = true;
                    if (this.arrayBool.every((res: any) => res == true)) {
                        this.arrayFiles = [];
                        this.modello = new Allegato();
                        this.loadingFiles = false;

                        this.snackBar.open('Documento caricato con successo', '', {
                            duration: 5000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                        });

                        this.callbackUpload.emit();
                    }
                });
            }, error => {
                this.snackBar.open('Errore nel caricamente del documento. Riprovare piÃ¹ tardi.', '', {
                    duration: 5000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                });
            });
        });
    }

    checkFileExist(name: any, files: any[]): string {
        if (files?.find(a => a.name === name) !== undefined) {
            // rinomino il file prima di salvarlo
            const nameSplit = name.split('.');
            const number = this.generateNumberRandom();
            name = nameSplit[0] + '_' + number + '.' + nameSplit[1];
            // faccio un controllo ricorsivo per vedere che il nuovo nome non sia giÃ¯Â¿Â½ presente nella lista file
            name = this.checkFileExist(name, files);
        }
        return name;
    }

    generateNumberRandom(): string {
        return Math.floor(Math.random() * 10).toString();
    }

    isValidNameFile(files: any): boolean {
        const fileList = [];
        for (const item of files) {
            fileList.push(item.name);
        }
        const invalidCharacters = /[€%$?/|èéòàùÀÈÌÒÙÁÉÍÓÚÂÊÎÔÛÃËÏÕÜÄÖÅÈÉÊËÝüáâäåêëïîìôöûùÿõÝý]/i;
        const containsInvalidChars = (value: any) => invalidCharacters.test(value);

        const invalidName = fileList.some(containsInvalidChars);
        if (invalidName) {
            this.openModal();
        }
        return fileList.length > 0 && !invalidName;
    }

    openModal(): void {
        this.upload = true;
        jQuery('#confirmModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#confirmModal').modal('toggle');
    }

    openSizeModal(): void {
        if (this.arrayFiles.length == 0) {
            this.upload = true;
        } else {
            this.upload = false;
        }
        jQuery('#sizeExceededModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#sizeExceededModal').modal('toggle');
    }


    private isValidSize(files: any): boolean {
        const fileList = [];
        this.arrayFileExceeded = [];
        for (const item of files) {
            fileList.push(item.size);
            if (item.size > UploadAreaComponent.MAX_FILE_SIZE_BYTES) {
                this.arrayFileExceeded.push(item);
            }
        }
        const sizeExceeded = fileList.filter(fsize => fsize <= UploadAreaComponent.MAX_FILE_SIZE_BYTES).length > 0;
        if (this.arrayFileExceeded.length > 0) {
            this.openSizeModal();
        }
        return sizeExceeded;
    }
}
