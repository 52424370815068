import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { DatiTecniciAttService } from '../../bagni-italiani/main-bagni/services/datiTecniciAtt/datiTecniciAtt.service';
import { AnagraficaService } from '../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { OmaggioService } from '../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { DatiTecniciLorenDTO } from 'src/app/modules/classes/loren/dati-tecnici-loren';
import { AnagraficaDTO, AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { PagamentoDTO } from '../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { CucinaService } from 'src/app/services/cucina/cucina.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { CucinaDto } from 'src/app/modules/classes/loren/cucina-dto';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { BrandService } from 'src/app/services/brand/brand.service';
import { OmaggioDto } from 'src/app/modules/classes/omaggio-dto';
import { Ordine } from 'src/app/modules/classes/ordine';
import { constants } from '../../../classes/constants';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

declare var jQuery: any;

@Component({
  selector: 'app-cucina',
  templateUrl: './cucina.component.html',
  styleUrls: ['./cucina.component.scss']
})
export class CucinaComponent implements OnInit {

  isLoaded: boolean = false;
  isFinanziamentoPayment: boolean = false;
  validAnagrafica: boolean = false;
  preventivoFound: boolean = true;
  showAnagrafica: boolean = false;
  openSign: boolean = false;
  checkContractNumber: boolean = false;
  showModalPrint: boolean = false;

  idCliente: number;
  currentStep: number = 0;
  timeLeft: any = 60;

  form: any;
  obj1: any;
  obj2: any;
  error: any;
  originalForm: any; 


  totalSteps: number[];
  trascodifiche: Trascodifica[];
  listFinanziarie: Trascodifica[];
  attivitaLavList: Trascodifica[] = [];
  datiTecnici: DatiTecniciLorenDTO;
  
  idOptionalHomeGlideTrascodifiche: number[] = [];  
  idOptionalFlowXOptSedileTrascodifiche: number[] = []; 
  idOptionalFlowXVarieTrascodifiche: number[] = []; 
  ordine: Ordine;
  anagrafica: AnagraficaDTO;

  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/LOREN.jpg', product: 'cucine', brand: 'Loren'}

  //utilizzata per verificare se almeno uno dei valori nelle proprietà di un oggetto è diverso da null e undefined.
  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  constructor(
    private route: ActivatedRoute,
    private anagraficaService: AnagraficaService,
    private orderService: OrdersService,
    private formController: FormControllerService,
    private brandService: BrandService,
    private paymentService: PaymentService,
    private datiTecniciService: DatiTecniciAttService,
    private cucinaService: CucinaService,
    private omaggioService: OmaggioService,
    private allegatoService: AllegatoService,
  ) {
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        cucina: new CucinaDto(),
        datiTecnici: new DatiTecniciLorenDTO(),
        omaggio : [new OmaggioDto()],
        pagamento: [new PagamentoDTO()],
        finanziamentoSections: {
					finanziamento: new Finanziamento(),
					finAnagrafica: new FinAnagrafica(),
					finDatiOccupazione: new FinDatiOccupazione(),
					finAnagraficaGarante: new FinAnagrafica(),
					finDatiOccupazioneGarante: new FinDatiOccupazione(),
					isAllegatoSaved: false
				},
      },
      ordine: null
    }

    this.totalSteps = Array(7).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
  }

  ngOnInit(): void {
    
    this.originalForm = JSON.parse(JSON.stringify(this.form));
    if (localStorage.getItem('id_client') !== null) {
      this.idCliente = Number(localStorage.getItem('id_client'));
      this.form.anagrafica.anagrafica.id = this.idCliente;
    }

    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    this.getOrder(this.form.idOrder);
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      localStorage.setItem('id_product', res.prodottoId);

      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem('id_client', res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }

      localStorage.setItem('id_order', res.idordine);
    });

    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
    });
    this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
    this.isLoaded = true;
  }

  async saveModule(): Promise<void> {
    $('body').removeClass('noScr');
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (this.currentStep === 0) {
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
            this.setStep(this.currentStep + 1);
          });
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar(constants.ERROR_GENERIC_API);
        });
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
            this.setStep(this.currentStep + 1);
          });
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar(constants.ERROR_GENERIC_API);
        });
      }
    } else if (this.currentStep === 1) {

      const saveOrUpdateRequests = [];
      
      this.datiTecnici = this.formController.mappingFormToDatiTecniciLorenDTO(this.form.sections.datiTecnici, new DatiTecniciLorenDTO());
      if (this.hasValues(this.datiTecnici)) {
        this.datiTecnici.ordineId = this.form.idOrder;
      
        const request = (this.datiTecnici.id === null || this.datiTecnici.id === undefined)
          ? this.datiTecniciService.save(JSON.stringify(this.datiTecnici))
          : this.datiTecniciService.update(JSON.stringify(this.datiTecnici));
      
        saveOrUpdateRequests.push(request);
      }
      
      if (saveOrUpdateRequests.length > 0) {
        forkJoin(saveOrUpdateRequests).subscribe({
          next: (results) => {
            this.setStep(this.currentStep + 1);
          },
          error: (error) => {
            console.error('Errore durante il salvataggio o aggiornamento:', error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          }
        });
      } else {
        this.setStep(this.currentStep + 1);
      }
    } else if (this.currentStep === 2) {
      let allSuccess = true;  // Variabile per verificare il successo di tutte le operazioni

      // Crea un array per raccogliere le richieste asincrone
      const saveOrUpdateRequests = [];
      
      // Salvataggio o aggiornamento di cucina
      const cucina = this.form.sections.cucina;
      cucina.ordineId = this.form.idOrder;
      if (cucina.id === undefined || cucina.id === null) {
        // Aggiungi la richiesta di salvataggio all'array
        saveOrUpdateRequests.push(
          this.cucinaService.save(cucina).pipe(
            catchError(error => {
              console.error('Error during cucina save:', error);
              allSuccess = false;
              return of(null);  // Restituisci un valore fittizio per evitare che la catena fallisca
            })
          )
        );
      } else {
        // Aggiungi la richiesta di aggiornamento all'array
        saveOrUpdateRequests.push(
          this.cucinaService.update(cucina).pipe(
            catchError(error => {
              console.error('Error during cucina update:', error);
              allSuccess = false;
              return of(null);
            })
          )
        );
      }
      
      // Step salvataggio omaggioDTO
      if (this.form.sections.omaggio && this.form.sections.omaggio.length) {
        for (let i = 0; i < this.form.sections.omaggio.length; i++) {
          const omaggio: OmaggioDto = this.form.sections.omaggio[i];
          omaggio.ordineId = this.form.idOrder;
          if (omaggio.id === undefined || omaggio.id === null) {
            // Aggiungi la richiesta di salvataggio all'array
            saveOrUpdateRequests.push(
              this.omaggioService.save(JSON.stringify(omaggio)).pipe(
                catchError(error => {
                  console.error('Error during omaggio save:', error);
                  allSuccess = false;
                  return of(null);
                })
              )
            );
          } else {
            // Aggiungi la richiesta di aggiornamento all'array
            saveOrUpdateRequests.push(
              this.omaggioService.update(JSON.stringify(omaggio)).pipe(
                catchError(error => {
                  console.error('Error during omaggio update:', error);
                  allSuccess = false;
                  return of(null);
                })
              )
            );
          }
        }
      }
      
      // Usa forkJoin per eseguire tutte le chiamate asincrone
      if (saveOrUpdateRequests.length > 0) {
        forkJoin(saveOrUpdateRequests).subscribe({
          next: (results) => {
            // Se tutte le operazioni sono andate a buon fine, prosegui al prossimo step
            if (allSuccess) {
              this.setStep(this.currentStep + 1);
            } else {
              this.formController.openSnackBar(constants.ERROR_GENERIC_API);
            }
          },
          error: (error) => {
            console.error('Errore durante il salvataggio:', error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          }
        });
      } else {
        // Se non ci sono chiamate da eseguire, prosegui comunque
        this.setStep(this.currentStep + 1);
      }
    } else if (this.currentStep === 3) {
        // Step salvataggio pagamento
        if(this.hasValues(this.form.sections.pagamento[0])){
              this.form.sections.pagamento[0].ordineId = this.form.idOrder;
          const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
                this.paymentService.save(JSON.stringify(payment)).subscribe((res:any) => {
             this.form.sections.pagamento[0] = res;
             this.setStep(this.currentStep + 1);
            }, error => {
              console.error('Error => ', error);
              this.formController.openSnackBar(constants.ERROR_GENERIC_API);
            });	
        } else {
            this.paymentService.update(JSON.stringify(payment)).subscribe((res:any) => {
                 this.form.sections.pagamento[0] = res;
                 this.setStep(this.currentStep + 1);
                  // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
                  if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
                    this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
                     allegati.forEach((allegato:any) => {
                  if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
                    this.allegatoService.delete(allegato.id).subscribe(() => {
                    }, error => {
                      console.error('Error => ', error);
                      this.formController.openSnackBar(constants.ERROR_GENERIC_API);
                  });	
									}
								})
							})
						}
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});	
				}
			}
    } else if (this.currentStep === 4) {
      //Step salvataggio privacy
			this.orderService.update(JSON.stringify( this.form.ordine)).subscribe(() => {
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
		  });	
    } else if (this.currentStep === 5) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        this.anagrafica = res;
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
        this.setStep(this.currentStep + 1);
      }, error => {
        console.error('Error => ', error);
        this.formController.openSnackBar(constants.ERROR_GENERIC_API);
      });
    } else if (this.currentStep === 6 && this.form.sections.finanziamentoSections.isAllegatoSaved) {
      // SOLO NEXT-STEP | PARTE FINANZIAMENTO
      this.setStep(this.currentStep + 1);
    }
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0){
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    if(this.form.sections.pagamento[0]!.tipopagsaldoId){
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(7).fill(1).map((x, i) => i);
			}else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(6).fill(1).map((x, i) => i);
			}
		}

    this.scrollTop();
  }

  stepController():boolean {
    let disabled = false;

    if(!this.validAnagrafica && this.currentStep === 0){
      disabled = true;
    }
    if(this.currentStep === 2 && ( !this.formController.validCucina(this.form) || !this.formController.validOmaggio(this.form) || this.form.sections.omaggio.length == 0 || (this.form.sections.cucina.note && this.form.sections.cucina.note.length > 1000))){
      disabled = true;
    }
    if(this.currentStep === 3 && !this.formController.validPagamento(this.form, true)){
      disabled = true;
    }
    if(this.currentStep === 4 && !this.formController.validPrivacy(this.form)){
      disabled = true;
    }
    if(!this.validAnagrafica && this.currentStep === 5){
      disabled = true;
    }
    if (this.currentStep === 6) {
          disabled =  this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
    }
   return disabled;
  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  convertTrascodifica(id: number) {
    const matchingTrascodifica = this.trascodifiche.find(el => el.id === id);
    if (matchingTrascodifica) {
      return matchingTrascodifica.descrizione;
    }
  
    return ''; 
  }
  
  returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#printModal').modal('toggle');
  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft == 0 ){
    clearInterval(intervalId);
    // richiamo api per invio email
    this.orderService.sendEmail(this.form.idOrder).subscribe();
    jQuery('#timeoutModal').modal({backdrop: 'static', keyboard: false });
    jQuery('#timeoutModal').modal('toggle');

    setTimeout(function(){
      jQuery('#timeoutModal').modal('hide');
    }, 10000);

    setTimeout( () => {
           this.formController.goToDashboard();
      }, 11000);
    }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
    this.openSign = value;
  }
}
