export class CaldaiaDto {
	checked:boolean = false;
	coloreId: number;
	id: number;
	modcaldacqhwId: number;
	modcaldcondcamId: number;
	modcaldcondecowId: number;
	modcaldcondecowauId: number;
	modcaldcondecowdId: number;
	modcaldcondecowallId: number;
	modcaldcondEasyId: number;
	ordineId: number;
	tipologiaId: number;
	modibrepomcalId:number;
	potenzaibrepomcalId:number;
	modibrepomcalDescrizione:string;
	potenzaibrepomcalDescrizione:string;
	potenzaChecked:boolean = false;
	note:string;
}