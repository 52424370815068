<div class="row">
    <div class="col-12" >
        <div class="text-center {{classBg}}">
            NUOVI DATI TECNICI
        </div>
        <div class="{{classContent}}">
            <div class="row">
                <div class="col-6">
                    <p class="text-center">Piatto doccia</p>
                    <div class="row">
                        <div class="col-6 mb-2 align-self-center text-center">
                            <img src="assets/img/piatto-doccia.png"/>
                        </div>
                        <div class="col-6 mb-2 align-self-center">
                            <app-input-number
                                (onNumChange)="form.sections.nuoviDati.prof = $event"
                                [step]="0.1"
                                [num]="form.sections.nuoviDati.prof"
                                [decimal]="true"
                                [payment]="false"
                                [small]="false"
                                label="P cm:">
                            </app-input-number>
                        </div>
                        <div class="col-6 mb-2 align-self-center">
                            <app-input-number
                                (onNumChange)="form.sections.nuoviDati.larghezza = $event"
                                [step]="0.1"
                                [num]="form.sections.nuoviDati.larghezza"
                                [decimal]="true"
                                [payment]="false"
                                [small]="false"
                                label="L cm:">
                            </app-input-number>
                        </div>
                        <div class="col-6 mb-2 align-self-center">
                            <mat-radio-group labelPosition="after" class="d-block" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviDati.muro">
                                <mat-radio-button class="mb-2 mr-2" value="MUROSX">MURO SX</mat-radio-button>
                                <mat-radio-button class="mr-2" value="MURODX">MURO DX</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="MUROENTRAMBE">MURO SX + MURO DX</mat-radio-button>
                                <mat-radio-button class="mr-2" value="NOMURO">NESSUN MURO LATERALE</mat-radio-button>
                                <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.nuoviDati.muro" (click)="form.sections.nuoviDati.muro = ''">
                                   backspace
                                </span>
                            </mat-radio-group>
                            <div class="text-danger smallFont" *ngIf="isMuroRequired() && !form.sections.nuoviDati.muro">
                                campo obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <p class="text-center">Box</p>
                    <app-input-number
                        (onNumChange)="form.sections.nuoviDati.box.lunghezza = $event"
                        [step]="0.1"
                        [num]="form.sections.nuoviDati.box.lunghezza"
                        [decimal]="true"
                        [payment]="false"
                        [small]="false"
                        label="Lunghezza (L) cm:">
                    </app-input-number>
  
                    <app-input-number
                        (onNumChange)="form.sections.nuoviDati.box.larghezza = $event"
                        [step]="0.1"
                        [num]="form.sections.nuoviDati.box.larghezza"
                        [decimal]="true"
                        [payment]="false"
                        [small]="false"
                        label="Larghezza (P) cm:">
                    </app-input-number>
                              
                    <app-input-number *ngIf="prodotto === 'bagni'"
                        (onNumChange)="form.sections.nuoviDati.box.altezza = $event"
                        [step]="0.1"
                        [num]="form.sections.nuoviDati.box.altezza"
                        [decimal]="true"
                        [payment]="false"
                        [small]="false"
                        label="Altezza (H) cm:">
                    </app-input-number>                                                        
                </div>
            </div>
        </div>
    </div>
</div>