import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CaldaiaService } from '../services/caldaia.service';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { CaldaiaDto } from '../../../../../classes/caldaie/caldaia-dto';
import { constants } from 'src/app/modules/classes/constants';

@Component({
  selector: 'app-tipi-caldaie',
  templateUrl: './tipi-caldaie.component.html',
  styleUrls: ['./tipi-caldaie.component.scss']
})
export class TipiCaldaieComponent implements OnInit {
  
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];

  caldaie: [];
  caldaiaResponse: any;

  MODCALDECOW: Trascodifica[];
  MODCALDECOWD: Trascodifica[];
  MODCALDECOWAU: Trascodifica[];
  MODCALDECOWWALL: Trascodifica[];
  MODCALDHOTWIND: Trascodifica[];
  MODCALDCAMAP: Trascodifica[];
  MODCALDCONDENSEASY: Trascodifica[];

  availablePromo:Trascodifica[];

  isPromoSelected: boolean = false;

  constructor(
      private caldaiaService: CaldaiaService,
      private cdr: ChangeDetectorRef,
      private formController: FormControllerService,
  ) {
  }

  ngOnInit(): void {
    this.MODCALDECOW = undefined;
    this.form.sections.caldaie = [];
    this.getCaldaie();

    let idProduct = Number(localStorage.getItem("id_product"));
    if(idProduct !== null || idProduct !== undefined){
      this.availablePromo = this.trascodifiche.filter(trascodifica => trascodifica.codice === constants.COD_PROMO && trascodifica.prodottoId === idProduct)
    }

    this.isPromoSelected = !!this.form.ordine.promoId;
  }

  getCaldaie(): void {
    this.caldaiaService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.caldaiaResponse = res;
      this.getMODCALDECOW();
    });
  }

  onPromoChange(event: any): void {
    const promoId = event?.value || null; 
    this.form.ordine.promoId = promoId;
    this.isPromoSelected = !!promoId; 

    this.cdr.detectChanges();
  }
  
  onPromoSelectedChange(isSelected: boolean): void {
    if (!isSelected) {
      this.form.ordine.promoId = null;
      this.cdr.detectChanges(); 
    }
  }

  returnPromoNote(): string {
    if(this.form.ordine.promoId){
      return this.formController.getTrascodificaById(this.form.ordine.promoId, this.trascodifiche)?.note || '';
    }else{
      return '';
    }
  }

  getMODCALDECOW(): void {
    this.MODCALDECOW = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND, this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND);
      this.MODCALDECOW = data;
      this.getMODCALDECOWD();
    });
  }

  getMODCALDECOWD(): void {
    this.MODCALDECOWD = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_DESIGN, this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_DESIGN);
      this.MODCALDECOWD = data;
      this.getMODCALDECOWAU();
    });
  }

  getMODCALDECOWAU(): void {
    this.MODCALDECOWAU = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_ARUM, this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_ARUM);
      this.MODCALDECOWAU = data;
      this.getMODCALDECOWWALL();
    });
  }

  getMODCALDECOWWALL(): void {
    this.MODCALDECOWWALL = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_WALL, this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_WALL);
      this.MODCALDECOWWALL = data;
      this.getMODCALDHOTWIND();
    });
  }

  getMODCALDHOTWIND(): void {
    this.MODCALDHOTWIND = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_MOD_SCALDA_ACQUA_HOT_WIND, this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, constants.COD_MOD_SCALDA_ACQUA_HOT_WIND);
      this.MODCALDHOTWIND = data;
      this.getMODCALDCAMAP();
    });
  }

  getMODCALDCAMAP(): void {
    this.MODCALDCAMAP = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_CALDAIA_CAMERA_APERTA, this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, constants.COD_CALDAIA_CAMERA_APERTA);
      this.MODCALDCAMAP = data;
      this.getMODCALDCONDENSEASY();
    });
  }

  getMODCALDCONDENSEASY(): void {
    this.MODCALDCONDENSEASY = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_MOD_CALDAIA_CONDENSAZIONE_EASY, this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, constants.COD_MOD_CALDAIA_CONDENSAZIONE_EASY);
      this.MODCALDCONDENSEASY = data;
    })
  }

  managestufe(element, mod): void{
    element.forEach(s => {
      s.checked = false;
      this.form.sections.caldaie.push(this.mapCaldaiaToForm(s, mod, new CaldaiaDto()));
      this.checkResponse();
    });
  }

  checkResponse(): void {
    if (this.caldaiaResponse.length > 0) {
      this.caldaiaResponse.forEach(st => {
        if (st.modcaldacqhwId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldacqhwId === st.modcaldacqhwId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondcamId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondcamId === st.modcaldcondcamId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowId === st.modcaldcondecowId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowauId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowauId === st.modcaldcondecowauId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowdId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowdId === st.modcaldcondecowdId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowallId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowallId === st.modcaldcondecowallId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondEasyId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondEasyId === st.modcaldcondEasyId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
      });
    }
  }

  mapCaldaiaToForm(dto: CaldaiaDto, mod , form: CaldaiaDto): CaldaiaDto{
    if (dto !== undefined) {
      form.checked = dto.checked || false;
      form.id = undefined;
      form.ordineId = this.form.idOrder;
      form.coloreId = dto.coloreId || undefined;
    
      if (mod === constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND) {
        form.modcaldcondecowId = dto.id;
      } else {
        form.modcaldcondecowId = undefined;
      }
      if (mod === constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_DESIGN) {
        form.modcaldcondecowdId = dto.id;
      } else {
        form.modcaldcondecowdId = undefined;
      }
      if (mod === constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_ARUM) {
        form.modcaldcondecowauId = dto.id;
      } else {
        form.modcaldcondecowauId = undefined;
      }
      if (mod === constants.COD_CALDAIA_CAMERA_APERTA) {
        form.modcaldcondcamId = dto.id;
      } else {
        form.modcaldcondcamId = undefined;
      }
      if (mod === constants.COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_WALL) {
        form.modcaldcondecowallId = dto.id;
      } else {
        form.modcaldcondecowallId = undefined;
      }
      if (mod === constants.COD_MOD_SCALDA_ACQUA_HOT_WIND) {
        form.modcaldacqhwId = dto.id;
      } else {
        form.modcaldacqhwId = undefined;
      }
      if (mod === constants.COD_MOD_CALDAIA_CONDENSAZIONE_EASY) {
        form.modcaldcondEasyId = dto.id;
      } else {
        form.modcaldcondEasyId = undefined;
      }

      form.tipologiaId = undefined;
    }
    return form;
  }

  returnTypes(id): any {
    return this.formController.returnTypeCald(id, this.trascodifiche);
  }

  returnTypeDescr(event, ind): string {
    const index = this.trascodifiche.findIndex(r => r.codice === constants.COD_TIPO_ALIMETANTAZIONE_CALDAIA && r.id === event );
    if (index !== -1) {
      if (ind !== undefined) {
        this.form.sections.caldaie[ind].descrizioneTipo = this.trascodifiche[index].descrizione;
        if (this.form.sections.caldaie[ind].coloreId) {
          delete this.form.sections.caldaie[ind].coloreId;
          delete this.form.sections.caldaie[ind].descrizioneColore;
        }
      }
      return this.trascodifiche[index].descrizione;
    } else {
      return undefined;
    }
  }

  returnColorDescr(event, ind): string {
    const index = this.trascodifiche.findIndex(r => r.codice === constants.COD_COLORECALDAIA && r.id === event );
    if (index !== -1) {
      if (ind !== undefined) {
        this.form.sections.caldaie[ind].descrizioneColore = this.trascodifiche[index].descrizione;
      }
      return this.trascodifiche[index].descrizione;
    } else {
      return undefined;
    }
  }

  returnColors(id): any {
    return this.formController.returnColorsCald(id, this.trascodifiche);
  }

  removeType(event, i): void{
    if (!event) {
      delete this.form.sections.caldaie[i].tipologiaId;
      delete this.form.sections.caldaie[i].descrizioneTipo;
      delete this.form.sections.caldaie[i].coloreId;
      delete this.form.sections.caldaie[i].descrizioneColore;
    }
  }
}
