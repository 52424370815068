<div class="zucchettiMontascaleBg">DATI TECNICI ATTUALI</div>
<div class="zucchettiMontascaleCont">
    <div class="container">
        <div class="row mb-3">
            <div class="col-md-4 mb-2 col-12">
                <b> Luogo Installazione: </b>
            </div>
            <div class="col-md-4 col-12">
                <mat-radio-group labelPosition="before" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.tipoAbitazioneId">
                    <mat-radio-button class="d-block mb-2 mr-2" [value]="tipo.id"  *ngFor="let tipo of tipiAbitazione">{{tipo.descrizione}}</mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.tipoAbitazioneId" (click)="form.sections.datiTecnici.tipoAbitazioneId = null">
                        backspace
                    </span>
                </mat-radio-group>
                
                <div class="smallfield">
                    <span class="mr-2">Piano n°</span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.piano = $event"
                        [step]="1"
                        [num]="form.sections.datiTecnici.piano"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <mat-checkbox class="full-width d-block mb-2"
                        color="primary"
                        [(ngModel)]="form.sections.datiTecnici.diProprieta">
                    Di proprietà
                </mat-checkbox>
                <mat-checkbox class="full-width d-block mb-2"
                        color="primary"
                        [(ngModel)]="form.sections.datiTecnici.ascensore">
                    Ascensore
                </mat-checkbox>
                <mat-checkbox class="full-width d-block mb-2"
                        color="primary"
                        [(ngModel)]="form.sections.datiTecnici.accessibilita_cortile">
                    Accessibilità cortile con mezzi
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 mb-2 col-12">
                <b> Caratteristiche Scale: </b>
                <br>
                <div class="smallfield">
                    <span class="mr-2">N° Rampe</span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.nRampe = $event"
                        [step]="1"
                        [num]="form.sections.datiTecnici.nRampe"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>

                <div class="smallfield">
                    <span class="mr-2">N° Gradini </span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.nGradini = $event"
                        [step]="1"
                        [num]="form.sections.datiTecnici.nGradini"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <b></b>
                <br>
                <div class="smallfield">
                    <span class="mr-2">Alzata cm </span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.alzata = $event"
                        [step]="0.1"
                        [num]="form.sections.datiTecnici.alzata"
                        [decimal]="true"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
                <div class="smallfield">
                    <span class="mr-2">Pedata cm </span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.pedata = $event"
                        [step]="0.1"
                        [num]="form.sections.datiTecnici.pedata"
                        [decimal]="true"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="smallfield">
                    <b></b>
                    <br>
                    <span class="mr-2">Largh. Scala cm  </span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.largScala = $event"
                        [step]="0.1"
                        [num]="form.sections.datiTecnici.largScala"
                        [decimal]="true"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
                <div class="smallfield">
                    <span class="mr-2">Larghezza pianerottolo cm  </span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.misuraPianerottolol = $event"
                        [step]="0.1"
                        [num]="form.sections.datiTecnici.misuraPianerottolol"
                        [decimal]="true"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
                <div class="smallfield">
                    <span class="mr-2">Altezza pianerottolo cm  </span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.misuraPianerottoloh = $event"
                        [step]="0.1"
                        [num]="form.sections.datiTecnici.misuraPianerottoloh"
                        [decimal]="true"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
            </div>
        </div>
    </div>
</div>