import { environment } from '../../../../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { PrintComponent } from '../../../../shared/pdf/print/print.component';
import { Router } from '@angular/router';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { PrintserviceService } from '../../../../../services/print/printservice.service';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../../../../services/http.service';
import { PreviewComponent } from '../../../../shared/pdf/preview/preview.component';
import { DatiTecniciAttService } from "../../main-bagni/services/datiTecniciAtt/datiTecniciAtt.service";
import { RivestimentiService } from '../../main-bagni/services/rivestimenti/rivestimenti.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { SanitariService } from '../../main-bagni/services/sanitari/sanitari.service';
import { OpereIdraulicheService } from '../../main-bagni/services/opereIdrauliche/opereIdrauliche.service';
import { TermoarrediService } from '../../main-bagni/services/termoarredi/termoarredi.service';
import { PortaService } from 'src/app/services/porta/porta.service';
import { NuoviDatiTecniciService } from '../../main-bagni/services/nuovi-dati-tecnici/nuovi-dati-tecnici.service';
import { OmaggioService } from '../../main-bagni/services/omaggio/omaggio.service';
import { BoxdocciaService } from '../../main-bagni/services/boxdoccia/boxdoccia.service';
import { SicurezzaService } from 'src/app/services/sicurezza/sicurezza.service';
import { NuoviAccessoriService } from '../../main-bagni/services/nuoviAccessori/nuoviAccessori.service';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { constants } from 'src/app/modules/classes/constants';
declare var jQuery: any;

@Component({
  selector: 'app-print-pratico',
  templateUrl: './print-pratico.component.html',
  styleUrls: ['./print-pratico.component.scss']
})
export class PrintPraticoComponent extends PrintComponent implements OnInit {

  @Input() idOrder: number;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() anagrafica: any;
  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();

  generatingPdf: boolean = false;
  creatingPdf: boolean = false;
  validAnagrafica: boolean = false;
  loadAll: boolean = false;
  sendingPdf: boolean = false;
  openSign: boolean = false;
  showFrame: boolean = false;
  showModalPrint: boolean = false;
  optionNuovaRubinetteria: boolean = false;
  optionNuovaCassetta: boolean = false;
  optionNuoviSanitari: boolean = false;
  optionNuovoBoxDoccia: boolean = false;
  optionNuovoPiattoDoccia: boolean = false;

  idOrdine: number;
  desiredWidth: number;
  proportionalHeight: number;
  timeLeft: number = 60;
  hTotalPageSpace: number = 280;
  imgHeight: number;
  imgHeight2: number;

  urlFrame: string = '';
  colorString: string = '#2c539e';
  rgbColorLine: number[] = [44, 83, 158];

  pagamento: any;
  privacyText: any;
  error: any;
  member: any;
  ordine: any;
  datiTecniciAtt: any;
  rivestimenti: any;
  sanitari: any;
  listSanitariNew: any;
  opereIdrauliche: any;
  opereIdrauliche2: any; // imbiancatura soffitto, necessaria scrostatura.
  termoArredi: any;
  portaList: any;
  optionsAviable: any;
  nuoviDatiTecnici: any;
  omaggio: any;
  disegnoBagnoAttuale: any;
  disegnoNuovoBagno: any;
  newBoxDoccia: any;
  kitSicurezzaDoccia: any;
  nuoviAccessoriBagno: any;
  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;

  constructor(
    private router: Router,
    public formController: FormControllerService,
    private paymentService: PaymentService,
    public printService: PrintserviceService,
    private orderService: OrdersService,
    private rivestimentiService: RivestimentiService,
    private sanitariService: SanitariService,
    private opereIdraulicheService: OpereIdraulicheService,
    private termoArrediService: TermoarrediService,
    private portaService: PortaService,
    private nuoviDatiTecniciService: NuoviDatiTecniciService,
    private omaggioService: OmaggioService,
    private boxDocciaService: BoxdocciaService,
    private sicurezzaService: SicurezzaService,
    private optionsAviableService: OptionsAvailableService,
    private nuoviAccessoribagnoService: NuoviAccessoriService,
    public dialog: MatDialog,
    private httpService: HttpService,
    private datiTecniciService: DatiTecniciAttService,
  ) {
    super(printService);
  }

  ngOnInit(): void {
    this.idOrdine = this.form.idOrder;
    this.getOrder(this.idOrdine);
    this.getPagamento();
    this.checkAnagrafica();
    this.getPrivacy();
    this.setLineColor(this.rgbColorLine);
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.getDatiTecnici();
      this.getOptionsAviable();
      this.getRivestimenti();
      this.getSanitari();
      this.getNuoviSanitari(); //tab 4 - sezione 1
      this.getOpereIdrauliche();
      this.getOmaggio();
    });
    this.loadAll = true;
  }

  generatePdf(firma: boolean): void {
    let y = this.initPdf(this.ordine, this.anagrafica, '/assets/img/loghi/logoPratico.jpg', 'Modulo d\'ordine bagno Pratico', this.colorString);

    this.printService.writeFooterPage(this.doc, this.ordine, this.currentPdfPage, 6);

    if (this.datiTecniciAtt) {
      y = this.printDatiTecniciAttuali(y);
    }

    if (this.rivestimenti && this.checkRivestimenti()) {
      y = this.printRivestimenti(y);
    }

    // mobili bagni quelli del cliente 
    if (this.checkMobiliClienteFieldsEmpty()) {
      const tableTitle = constants.LABEL_PRINT_MOBILE;
      this.mobileBagnoClienteBagniTenere(this.doc, tableTitle, y, this.colorString);
    }

    if (this.sanitari) {
      const tableTitle = constants.LABEL_PRINT_MOBILE;
      this.mobileBagnoClienteBagni(this.doc, this.sanitari, tableTitle, y, this.colorString);
    }

    y = this.printNewSanitari(y);

    let tableTitle = `NUOVA CASSETTA WC: ${this.formatYesNo(this.optionNuovaCassetta)}`;
    this.nuovaCassettaWc(this.doc, this.sanitari, tableTitle, y, this.colorString)

    const sanitari = this.sanitari;
    const obj = { rubSerie: '', lavabo: '', bidet: '', kitDoccia: '', miscelatore: '', asta: '', astaForma: null, colonne: null, miscelatoreEst: '', miscelatoreSerie: '' }

    if (sanitari) {
      if (sanitari?.rubserieDescrizione) {
        obj.rubSerie = sanitari.rubserieDescrizione;

        if (sanitari.rubserieDescrizione.trim() === constants.DESC_RUB_SERIE_LAVABO) {
          obj.lavabo = 'SI';
        } else if (
          (sanitari.rubserieDescrizione === constants.DESC_RUB_SERIE_TANGO ||
            sanitari.rubserieDescrizione === constants.DESC_RUB_SERIE_STICK) &&
          sanitari.rubprodDescrizione === constants.DESC_RUB_INCASSO
        ) {
          const lavaboNote = this.getNoteTrascodificaById(
            sanitari.rubprodId,
            this.trascodifiche
          );
          obj.lavabo = lavaboNote || sanitari.rubprodDescrizione;
        } else {
          obj.lavabo = sanitari.rubprodDescrizione;
        }
      }

      if (sanitari?.rubbidetDescrizione) {
        obj.bidet = 'SI';
      }

      if (sanitari?.rubkitDescrizione) {
        obj.kitDoccia = sanitari.rubkitDescrizione;
      }

      if (sanitari?.rubmiscDescrizione) {
        obj.miscelatore = sanitari.rubmiscDescrizione
      }

      if (sanitari?.astasingola) {
        obj.asta = 'singola';
      } else if (sanitari.astadoppia) {
        obj.asta = 'doppia';
      }

      if (sanitari?.rubtipoastaDescrizione) {
        obj.astaForma = sanitari.rubtipoastaDescrizione.toLowerCase();
      }

      if (sanitari?.rubtipocolDescrizione) {
        obj.colonne = sanitari.rubtipocolDescrizione;
      }

      if (sanitari?.rubmiscestDescrizione) {
        obj.miscelatoreEst = sanitari.rubmiscestDescrizione.toLowerCase();
      }

      if (sanitari?.rubmiscserieDescrizione) {
        obj.miscelatoreSerie = sanitari.rubmiscserieDescrizione;
      }
    }

    tableTitle = `NUOVA RUBINETTERIA ${this.formatYesNo(this.optionNuovaRubinetteria)}`
    this.genericPrintRubinetteriaBagni(this.doc, obj, tableTitle, y, this.colorString);

    if (this.sanitari?.notelibere && this.sanitari?.notelibere.trim() !== '') {
      let sectionTitle = constants.LABEL_PRINT_NOTE_LIBERE;
      this.createTableTextNote(sectionTitle, this.sanitari?.notelibere, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
    }

    tableTitle = constants.LABEL_PRINT_OPERE_IDRAULICHE;
    if (this.opereIdrauliche.length) {
      let row = [];
      let tipologieInserite = new Set();

      this.opereIdrauliche.forEach((field) => {
        if (field.tipologiaDescrizione?.trim() !== '' && !tipologieInserite.has(field.tipologiaDescrizione)) {
          row.push([[field.tipologiaDescrizione?.toUpperCase()], ['Si']]);
          tipologieInserite.add(field.tipologiaDescrizione);
        }
      });

      this.opereIdraulicheBagniItaliani(this.doc, row, tableTitle, y, this.colorString);
    }

    tableTitle = ``;
    if (this.opereIdrauliche2.length) {

      const obj = { necscrostatura: null, imbsoffitto: null }
      const opereIdraulicheExtra = this.opereIdrauliche2[0];

      if (this.opereIdrauliche2.length) {
        if ((opereIdraulicheExtra?.necscrostatura && opereIdraulicheExtra?.necscrostatura !== null && opereIdraulicheExtra?.necscrostatura !== undefined)
          || (opereIdraulicheExtra?.imbsoffitto && opereIdraulicheExtra?.imbsoffitto !== null && opereIdraulicheExtra?.imbsoffitto !== undefined)) {

          if (opereIdraulicheExtra?.imbsoffitto !== null && opereIdraulicheExtra?.imbsoffitto !== undefined) {
            obj.imbsoffitto = opereIdraulicheExtra?.imbsoffitto;
          }

          if (opereIdraulicheExtra?.necscrostatura !== null && opereIdraulicheExtra?.necscrostatura !== undefined) {
            obj.necscrostatura = opereIdraulicheExtra?.necscrostatura;
          }
        }
      }
      this.opereIdraulicheImbScrost(this.doc, obj, tableTitle, y, this.colorString);
    }


    if (this.termoArredi.length) {
      y = this.printTermoArredi(y);
    }

    if (this.opereIdrauliche2.length) {
      y = this.printPiattiDoccia(y);
    }

    if (this.portaList) {
      y = this.printPorta(y);
    }

    if (this.nuoviDatiTecnici) {
      y = this.printNuoviDatiTecnici(y);
    }

    if (this.omaggio.length > 0) {
      this.genericOmaggio(this.doc, this.omaggio, this.printService.returnCurrentY(), this.colorString);
    }

    y = this.printNewBoxDoccia(y);

    if (this.nuoviAccessoriBagno.length) {
      y = this.printNuoviAccessoriBagno(y);
    }

    if (this.kitSicurezzaDoccia.length) {
      y = this.printKitDiSicurezzaDoccia(y);
    }

    if (this.disegnoBagnoAttuale || this.disegnoNuovoBagno) {
      let tableTitle1 = '';
      let tableTitle2 = '';

      if (this.disegnoBagnoAttuale) {
        tableTitle1 = constants.LABEL_PRINT_PIANTINA_BAGNO_ATTUALE
      }

      if (this.disegnoNuovoBagno) {
        tableTitle2 = constants.LABEL_PRINT_PIANTINA_NUOVO_BAGNO;
      }

      this.genericPrintImages(this.doc, y, this.disegnoBagnoAttuale, this.disegnoNuovoBagno, tableTitle1, tableTitle2, this.desiredWidth, this.proportionalHeight, this.colorString, this.color);
    }

    if (this.pagamento) {
      this.createTablePagamento(this.doc, this.pagamento, this.ordine, y, 0, this.colorString)
    }

    if (this.privacyText !== '')
      y = this.printPrivacy(y);

    this.creatingPdf = false;

    if (firma) {
      if (!this.integratedSignature) {
        this.createPdf(this.doc);
      } else {
        this.toggleSignPdf(this.doc);
      }
    } else {
      const pdfBlob = this.doc.output('blob');

      // Crea un URL dall'oggetto Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      this.openDialog(pdfUrl);
    }
  }

  //print methods
  private printDatiTecniciAttuali(y: number): number {
    this.printService.setCurrentY(y + 5);
    y = this.printService.returnCurrentY();
    const datiTecniciFields = [
      { label: 'Luogo installazione', value: this.datiTecniciAtt.tipoabitazioneDescrizione || 'non selezionato' },
      { label: 'Di proprietà', value: this.formatYesNo(this.datiTecniciAtt.proprieta) || 'non selezionato' },
      { label: 'Ascensore', value: this.formatYesNo(this.datiTecniciAtt.ascensore) || 'non selezionato' },
      { label: 'Accessibilità cortile con mezzi', value: this.formatYesNo(this.datiTecniciAtt.accesscortile) || 'non selezionato' },
      { label: 'Piano', value: this.datiTecniciAtt.pianointervento || 'non selezionato' },
      { label: 'Dimensione vano da ristrutturare', value: ` Lunghezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.lungvano, 'cm') || 'non selezionato'} - Larghezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.largvano, 'cm') || 'non selezionato'} - Altezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.altvano, 'cm') || 'non selezionato'}` },
      { label: 'Dimensione porta', value: ` Larghezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.lungporta, 'cm') || 'non selezionato'} - Altezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.altporta, 'cm') || 'non selezionato'} - Altezza piastrelle: ${this.addUnitOfMeasure(this.datiTecniciAtt.altpiastrelle, 'cm') || 'non selezionato'}` },
      { label: 'Tipo', value: this.datiTecniciAtt.tipobagnoDescrizione || 'non selezionato' },
      { label: 'Misure', value: ` Lunghezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.lungtipobagno, 'cm') || 'non selezionato'} - Larghezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.largtipobagno, 'cm') || 'non selezionato'} - Altezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.alttipobagno, 'cm') || 'non selezionato'}` },
      { label: 'Termosifone presente', value: this.formatYesNo(this.datiTecniciAtt.termospres) || 'non selezionato' },
      { label: 'Misura termosifone', value: `Lunghezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.lungtermos, 'cm') || 'non selezionato'} - Altezza: ${this.addUnitOfMeasure(this.datiTecniciAtt.alttermo, 'cm') || 'non selezionato'} - Profondità: ${this.addUnitOfMeasure(this.datiTecniciAtt.proftermos, 'cm') || 'non selezionatoNo'}` },
      { label: 'Incassato', value: this.formatYesNo(this.datiTecniciAtt.termosifoneIncassato) || 'non selezionato' },
      { label: 'Misura termosifone interasse', value: this.addUnitOfMeasure(this.datiTecniciAtt.interasstermo, 'cm') || 'non selezionato' },
      { label: 'Lavatrice presente', value: this.formatYesNo(this.datiTecniciAtt.lavatrpres) || 'non selezionato' },
      { label: 'Attacchi lavatrice da creare', value: this.formatYesNo(this.datiTecniciAtt.attlavcreare) || 'non selezionato' },
      { label: 'Note attacchi lavatrice', value: this.datiTecniciAtt?.noteattlavcr || 'non selezionato' },
      { label: 'Lavatrice da mantenere', value: this.formatYesNo(this.datiTecniciAtt.lavatrmanten) },
      { label: 'Boiler presente', value: this.formatYesNo(this.datiTecniciAtt?.boilerpresente) || 'non selezionato' },
      { label: 'Boiler da mantenere', value: this.formatYesNo(this.datiTecniciAtt?.boilermanten) || 'non selezionato' },
      { label: 'Sanitari', value: this.datiTecniciAtt?.tiposanitariDescrizione || 'non selezionato' },
      { label: 'Bidet', value: this.formatYesNo(this.datiTecniciAtt.bidet) || 'non selezionato' },
      { label: 'WC presente', value: this.formatYesNo(this.datiTecniciAtt.wcpresente) || 'non selezionato' },
      { label: 'Scarico WC', value: this.datiTecniciAtt?.tiposcaricowcDescrizione || 'non selezionato' },
      { label: 'Cassetta scarico WC', value: this.datiTecniciAtt?.tipocasscwcDescrizione || 'non selezionato' },
      { label: 'Comando sciacquone a rubinetto (flusso rapido)', value: this.formatYesNo(this.datiTecniciAtt.comandosciacq) || 'non selezionato' },
      { label: 'Adesione piastrelle esistenti', value: this.datiTecniciAtt?.adesionepiasDescrizione || 'non selezionato' },
      { label: 'Muri perimetrali', value: this.datiTecniciAtt?.muriperiDescrizione || 'non selezionato' },
      { label: 'Punto luce in prossimità del lavabo', value: this.formatYesNo(this.datiTecniciAtt.puntolucelavabo) || 'non selezionato' },
      { label: 'Impianto con termosifone presente', value: this.formatYesNo(this.datiTecniciAtt.imptermpres) || 'non selezionato' },
      { label: 'Tipo di riscaldamento', value: this.datiTecniciAtt?.tiporiscaDescrizione || 'non selezionato' },
      { label: 'Impianto', value: this.datiTecniciAtt?.tipoimpiantoDescrizione || 'non selezionato' },
      { label: 'Note tecniche', value: this.datiTecniciAtt?.notetecniche || 'non selezionato' },
    ];

    const hasSi = datiTecniciFields.filter(field => (field.value !== 'non selezionato'));

    if (hasSi) {
      const sectionTitle = constants.LABEL_DATI_TECNICI_ATTUALI;
      this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);

      y = this.printService.returnCurrentY();
      hasSi.forEach(field => {
        const isBold = field.label.trim() === "Note tecniche";
        y = this.printService.writeLongText(
          this.doc, 9, y, `${field.label?.toUpperCase()}: ${field.value}`, 10, this.ordine, this.img, this.title, this.currentPdfPage, isBold);
      });
    }
    this.printService.setCurrentY(y += 5);
    return y;
  }

  private printRivestimenti(y: number): number {
    y = this.printService.returnCurrentY();
    const rivestimentiFields = [
      { label: "PAVIMENTO ", value: this.rivestimenti?.pavimento !== null && this.rivestimenti?.pavimento !== undefined ? this.rivestimenti.pavimento.toString() : 'No' },
      { label: "PARETI ", value: this.rivestimenti?.pareti !== null && this.rivestimenti?.pareti !== undefined ? this.rivestimenti.pareti.toString() : 'No' },
      { label: "PARETE A ", value: (this.rivestimenti?.paretea !== null && this.rivestimenti?.paretea !== undefined && this.rivestimenti?.paretea !== '') ? (this.addUnitOfMeasure(this.rivestimenti.paretea.toString(), 'cm') + ', h: ' + (this.addUnitOfMeasure(this.rivestimenti?.pareteah, 'cm') !== null && this.rivestimenti?.pareteah !== undefined ? this.addUnitOfMeasure(this.rivestimenti.pareteah.toString(), 'cm') : ' non selezionato ')) : 'No' },
      { label: "PARETE B ", value: (this.rivestimenti?.pareteb !== null && this.rivestimenti?.pareteb !== undefined && this.rivestimenti?.pareteb !== '') ? (this.addUnitOfMeasure(this.rivestimenti.pareteb.toString(), 'cm') + ', h: ' + (this.addUnitOfMeasure(this.rivestimenti?.paretebh, 'cm') !== null && this.rivestimenti?.paretebh !== undefined ? this.addUnitOfMeasure(this.rivestimenti.paretebh.toString(), 'cm') : ' non selezionato ')) : 'No' },
      { label: "PARETE C ", value: (this.rivestimenti?.paretec !== null && this.rivestimenti?.paretec !== undefined && this.rivestimenti?.paretec !== '') ? (this.addUnitOfMeasure(this.rivestimenti.paretec.toString(), 'cm') + ', h: ' + (this.addUnitOfMeasure(this.rivestimenti?.paretech, 'cm') !== null && this.rivestimenti?.paretech !== undefined ? this.addUnitOfMeasure(this.rivestimenti.paretech.toString(), 'cm') : ' non selezionato ')) : 'No' },
      { label: "PARETE D ", value: (this.rivestimenti?.pareted !== null && this.rivestimenti?.pareted !== undefined && this.rivestimenti?.pareted !== '') ? (this.addUnitOfMeasure(this.rivestimenti.pareted.toString(), 'cm') + ', h: ' + (this.addUnitOfMeasure(this.rivestimenti?.paretedh, 'cm') !== null && this.rivestimenti?.paretedh !== undefined ? this.addUnitOfMeasure(this.rivestimenti.paretedh.toString(), 'cm') : ' non selezionato ')) : 'No' },
      { label: "INTERNO DOCCIA", value: (this.rivestimenti?.internodoccia !== null && this.rivestimenti?.internodoccia !== undefined && this.rivestimenti?.internodoccia !== '') ? (this.addUnitOfMeasure(this.rivestimenti.internodoccia.toString(), 'cm') + ', h: ' + (this.rivestimenti?.internodocciah !== null && this.rivestimenti?.internodocciah !== undefined ? this.addUnitOfMeasure(this.rivestimenti.internodocciah.toString(), 'cm') : ' non selezionato')) : 'No' },
      { label: '', value: '' },
      { label: "NOTE ", value: this.rivestimenti?.note !== null && this.rivestimenti?.note !== undefined ? this.rivestimenti.note.toString() : 'No' },
    ];

    const hasSi = rivestimentiFields.filter(field => (field.value !== 'No' && field.value !== ''));

    if (hasSi) {
      const sectionTitle = 'RIVESTIMENTI';
      this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);

      y = this.printService.returnCurrentY();
      hasSi.forEach(field => {
        const isBold = field.label === "NOTE ";
        y = this.printService.writeLongText(
          this.doc, 9, y, field.value === '' ? `${field.label} ${field.value}` : `${field.label}: ${field.value}`, 10, this.ordine, this.img, this.title, this.currentPdfPage, isBold);
      });
    }
    this.printService.setCurrentY(y += 5);
    return y;
  }

  private printNewSanitari(y: number): number {
    const defineColumn = [
      "MODELLO",
      "TIPO SCARICO",
      "TIPO SANITARI",
      "TIPO PRODOTTO"
    ];

    let tableTitle = `NUOVI SANITARI: ${this.formatYesNo(this.optionNuoviSanitari)}`
    this.genericPrintSanitariBagniHelp(this.doc, this.listSanitariNew, tableTitle, this.optionNuoviSanitari, defineColumn, y, this.colorString);

    return y;
  }


  private printTermoArredi(y: number): number {

    const sectionTitle = 'NUOVI TERMOSIFONI E TERMOARREDI';

    y = this.printService.returnCurrentY();
    if (this.termoArredi.length) {
      this.printService.setCurrentY(y);
      this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);

      this.termoArredi.forEach(field => {
        y = this.printService.writeLongText(this.doc, 9, this.printService.returnCurrentY(), field.tipologiaDescrizione ? `${field.tipologiaDescrizione?.toUpperCase()}: Si` : '', 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
      });
      this.printService.setCurrentY(y + 10);
    }
    return y;
  }


  private printPiattiDoccia(y): number {
    y = this.printService.returnCurrentY();

    if (this.opereIdrauliche2[0]) {
      y = this.printService.returnCurrentY();
      let tableTitle = `NUOVO PIATTO DOCCIA: ${this.formatYesNo(this.optionNuovoPiattoDoccia)}`;

      this.genericOnlyTitlePaddingCenter(this.doc, y, tableTitle, this.colorString);

      tableTitle = 'PIATTI EFFETTO PIETRA h 3 cm';
      this.nuovoPiattoDocciaPietra(this.doc, this.opereIdrauliche2[0], tableTitle, y, this.colorString);
    }
    return y;
  }

  private printPorta(y: number): number {
    y = this.printService.returnCurrentY();

    if (this.portaList) {
      let sectionTable = "PORTA";

      this.portaBagnoPratico(this.doc, this.portaList, sectionTable, y, this.colorString);

      if (this.portaList?.note && this.portaList?.note.trim() !== '') {
        let sectionTitle = constants.LABEL_PRINT_NOTE_LIBERE;
        this.createTableTextNote(sectionTitle, this.portaList?.note, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
      }
    }
    return y;
  }

  private printNuoviDatiTecnici(y: number): number {

    y = this.printService.returnCurrentY();
    let baseY = 0;
    let calculateY = 0;

    const piattoDocciaFields = [
      { label: "LUNGHEZZA", value: this.nuoviDatiTecnici?.piattol ? this.addUnitOfMeasure(this.nuoviDatiTecnici?.piattol, 'cm') : 'No' },
      { label: "PROFONDITA'", value: this.nuoviDatiTecnici?.piattop ? this.addUnitOfMeasure(this.nuoviDatiTecnici?.piattop, 'cm') : 'No' },
      { label: "MURO", value: this.nuoviDatiTecnici?.murosx ? ' sinistro' : 'No' }, // per muro sx
      { label: "MURO", value: this.nuoviDatiTecnici.murodx ? ' destro' : 'No' }, // per muro dx
      { label: "MURO", value: this.nuoviDatiTecnici?.muroSxDx ? ' sinistro e destro' : 'No' }, // per muro sx e dx
      { label: "MURO", value: this.nuoviDatiTecnici.noMuro ? ' nessun muro laterale' : 'No' }, // per noMuro
    ];

    const boxDocciaFields = [
      { label: "LUNGHEZZA (L)", value: this.nuoviDatiTecnici?.boxl ? this.addUnitOfMeasure(this.nuoviDatiTecnici?.boxl, 'cm') : 'No' },
      { label: "LARGHEZZA (P)", value: this.nuoviDatiTecnici?.boxp ? this.addUnitOfMeasure(this.nuoviDatiTecnici?.boxp, 'cm') : 'No' },
      { label: "ALTEZZA (H)", value: this.nuoviDatiTecnici?.boxh ? this.addUnitOfMeasure(this.nuoviDatiTecnici?.boxh, 'cm') : 'No' },
    ]

    const hasPiattoDocciaSi = piattoDocciaFields.filter(field => field.value !== 'No');
    const hasBoxDocciaSi = boxDocciaFields.filter(field => field.value !== 'No');

    const checkSpazio: number = ((((hasPiattoDocciaSi.length) + (hasBoxDocciaSi.length)) * 9) + this.printService.returnCurrentY() + 20);
    if (checkSpazio > this.hTotalPageSpace) {  //Simulo la scrittura della tabella, per vedere se c'e spazio per inserirla in questa pagina
      this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage + 1, '');
      this.printService.setCurrentY(28);
    }

    y = this.printService.returnCurrentY();

    if (hasPiattoDocciaSi.length || hasBoxDocciaSi.length) {
      let sectionTitle = `NUOVI DATI TECNICI`;
      this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);
      baseY = y;
    }

    y = this.printService.returnCurrentY();
    if (hasPiattoDocciaSi.length) {
      y = this.printService.writeLongText(this.doc, 11, y, `PIATTO DOCCIA`, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
      hasPiattoDocciaSi.forEach(field => {
        y = this.printService.writeLongText(
          this.doc, 9, y, field.value === '' ? `${field.label} ${field.value}` : `${field.label}: ${field.value}`, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
      });
    }

    y = this.printService.returnCurrentY();
    if (hasBoxDocciaSi.length) {
      y = this.printService.writeLongText(this.doc, 11, y, `BOX`, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
      hasBoxDocciaSi.forEach(field => {
        y = this.printService.writeLongText(
          this.doc, 9, y, field.value === '' ? `${field.label} ${field.value}` : `${field.label}: ${field.value}`, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
      });
    }

    y = this.printService.returnCurrentY();
    if (hasPiattoDocciaSi.length || hasBoxDocciaSi.length) {	//Se ci sono datiTecnici essendo un campo facoltativo, stampa riga divisoria
      this.doc.addImage("/assets/img/piatto-doccia.png", "PNG", 65, baseY + 10, 40, 25, "", "SLOW");
      calculateY = ((hasBoxDocciaSi.length + hasPiattoDocciaSi.length) * 10);   //10 e l'altezza del testo

      ((calculateY) < 25) ? (y = baseY + 25) : (y = baseY + calculateY)
      this.printService.setCurrentY(y += 10);
    }
    return y;
  }

  private printNewBoxDoccia(y: number): number {

    y = this.printService.returnCurrentY();

    const defineColumn = [
      "MODELLO",
      "CRISTALLI",
      "PROFILO",
      "LARGHEZZA",
      "INGRESSO"
    ];

    let tableTitle = `NUOVO BOX DOCCIA: ${this.formatYesNo(this.optionNuovoBoxDoccia)}`;
    this.genericPrintBoxDocciaBagniItaliani(this.doc, this.newBoxDoccia, tableTitle, this.optionNuovoBoxDoccia, defineColumn, y, this.colorString);

    return y;
  }

  private printNuoviAccessoriBagno(y: number): number {
    y = this.printService.returnCurrentY();

    let sectionTable = "NUOVI ACCESSORI BAGNO: la posizione degli accessori verrà decisa in corso d'opera";
    let accessori: string[] = [];

    this.nuoviAccessoriBagno.forEach(field => {
      accessori.push(field.tipologiaDescrizione);
    });

    this.nuoviAccessoriBagnoPratico(this.doc, accessori, sectionTable, y, this.colorString);
    return y;
  }

  private printKitDiSicurezzaDoccia(y: number): number {
    y = this.printService.returnCurrentY();

    let sectionTable = "KIT DI SICUREZZA";
    let kitSicurezza: string[] = [];

    this.kitSicurezzaDoccia.forEach(field => {
      kitSicurezza.push(field.tipologiaDescrizione);
    });

    this.nuoviAccessoriBagnoPratico(this.doc, kitSicurezza, sectionTable, y, this.colorString);
    return y;
  }

  private printPrivacy(y: number): number {
    this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, this.title)
    y = this.printService.returnCurrentY();
    this.printService.writePrivacyOne(this.doc, y, this.ordine, this.img, this.title, this.currentPdfPage, this.privacyText);
    return y;
  }

  //Get method
  private getDatiTecnici(): void {
    this.datiTecniciService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.datiTecniciAtt = res[0];
    });
  }

  private getOptionsAviable(): void {
    this.optionsAviableService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.optionsAviable = res;
      this.optionNuoviSanitari = this.checkOptionSelected(constants.TYPE_NUOVI_SANITARI);
      this.optionNuovaCassetta = this.checkOptionSelected(constants.TYPE_NUOVA_CASSETTA);
      this.optionNuovaRubinetteria = this.checkOptionSelected(constants.TYPE_NUOVA_RUBINETTERIA);
      this.optionNuovoPiattoDoccia = this.checkOptionSelected(constants.TYPE_NUOVO_PIATTO_DOCCIA);
      this.optionNuovoBoxDoccia = this.checkOptionSelected(constants.TYPE_NUOVO_BOX_DOCCIA);
    });
  }

  private getRivestimenti(): void {
    this.rivestimentiService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.rivestimenti = res[0];
    })
  }

  private getSanitari(): void {
    this.sanitariService.findByKey(this.idOrdine, 'idordineId.equals=').subscribe(res => {
      this.sanitari = res[0];
    })
  }

  private getNuoviSanitari(): void {
    this.sanitariService.getNuoviSan(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.listSanitariNew = res;
    })
  }

  private getOpereIdrauliche(): void {
    this.opereIdraulicheService.findByKey(this.idOrdine, 'ordineId.equals=', 'opereidrsosts').subscribe(res => {
      this.opereIdrauliche = res;
    })

    this.opereIdraulicheService.findByKey(this.idOrdine, 'ordineId.equals=', 'opereidrauliches').subscribe(res => {
      this.opereIdrauliche2 = res;
    })
    this.geTermoArrediList();
  }

  private geTermoArrediList(): void {
    this.termoArrediService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.termoArredi = res;
    });
    this.getPortaDetails();
    this.getNewBoxDoccia();
  }

  private getPortaDetails(): void {
    this.portaService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.portaList = res[0];
    });

    this.getNuoviDatiTecnici();
  }

  private getNuoviDatiTecnici(): void {
    this.nuoviDatiTecniciService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.nuoviDatiTecnici = res[0];
      this.getDisegni(res[0]);
    });
  }

  private getOmaggio(): void {
    this.omaggioService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.omaggio = res;
    })
  }

  private getNewBoxDoccia(): void {
    this.boxDocciaService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.newBoxDoccia = res;
    });
    this.getAccesoriBagno();
    this.getKitDiSicurezza();
  }

  private getAccesoriBagno(): void {
    this.nuoviAccessoribagnoService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.nuoviAccessoriBagno = res;
    })
  }

  private getKitDiSicurezza(): void {
    this.sicurezzaService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.kitSicurezzaDoccia = res;
    })
  }

  private getPagamento(): void {
    this.paymentService.findByKey(this.idOrder, 'ordineId.equals=').subscribe((res) => {
      this.pagamento = res;
    });
  }

  private getPrivacy(): void {
    this.httpService.getPrivacy('assets/json/bagnoPratico.json').subscribe((res) => {
      this.privacyText = res;
    });
  }

  //Olther method
  private toggleSignPdf(pdf): void {
    const interval = this.startTimer();
    const title = localStorage.getItem('id_order');
    const pages = this.printService.returnCurrentPage();
    this.showModalPrint = !this.showModalPrint;
    this.openSign = true;
    this.showFrame = false;
    this.generatingPdf = true;
    this.urlFrame = '';
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');

    if (this.isApiV3) {
      const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
      var split = this.printService.returnCoordinates().split(",");
      const coordinates = {
        width: 120,
        height: 37,
        x: split[0],
        y: split[3],
      }
      this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
        this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
        setTimeout(() => {
          this.showFrame = true;
          this.sendingPdf = false;
          this.generatingPdf = false;

        }, 2000);
        clearInterval(interval);
      }, error => {
        this.generatingPdf = false;
        this.sendingPdf = false;
        this.ordine.statopraticaCodice = "A";
        clearInterval(interval);
        this.closeSignPdf();
        jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#errorModal").modal("toggle");
      });
    } else {
      const newPdf = btoa(pdf.output());
      const coordinates = this.printService.returnCoordinates();
      this.formController
        .sendSignature(
          newPdf,
          this.idOrdine,
          this.form,
          pages,
          coordinates,
          title,
          true
        )
        .then(
          (res) => {
            this.member = res.members[0].id;
            this.urlFrame =
              environment.apiUrl +
              '/procedure/sign?members=' +
              this.member +
              '&signatureUi=' +
              environment.signBagnoPratico; // "https://staging-app.yousign.com/procedure/sign?members="+this.member;
            this.showFrame = true;
            this.sendingPdf = false;
            this.generatingPdf = false;

            clearInterval(interval);
          },
          (error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.statopraticaCodice = 'A';
            clearInterval(interval);
            jQuery('#errorModal').modal({ backdrop: 'static', keyboard: false });
            jQuery('#errorModal').modal('toggle');
          }
        );
    }
  }

  private checkMobiliClienteFieldsEmpty(): boolean {
    return !this.sanitari?.modelloMobileDescrizione &&
      !this.sanitari?.misuraMobileDescrizione &&
      !this.sanitari?.baseMobileDescrizione &&
      !this.sanitari?.cassettiMobileDescrizione &&
      !this.sanitari?.tipologiaLavaboMobileDescrizione &&
      !this.sanitari?.numvascmobDescrizione &&
      !this.sanitari?.tipocolmobDescrizione &&
      !this.sanitari?.qtapensmob &&
      !this.sanitari?.notecompmob;
  }

  private setLineColor(rgbColorLine): void {
    this.color = rgbColorLine;
  }

  private getDisegni(obj): void {

    if (obj.piantina && obj.piantinaContentType) {
      this.disegnoBagnoAttuale = "data:" + obj.piantinaContentType + ";base64," + obj.piantina
      const img = new Image();
      img.src = this.disegnoBagnoAttuale;
      img.onload = () => {
        const originalWidth = img.width;
        this.imgHeight = img.height;
        this.desiredWidth = 70; // Larghezza desiderata in unità del PDF
        this.proportionalHeight = (this.desiredWidth * this.imgHeight) / originalWidth;
      };
    }

    if (obj.nuovapiantina && obj.nuovapiantinaContentType) {
      this.disegnoNuovoBagno = "data:" + obj.nuovapiantinaContentType + ";base64," + obj.nuovapiantina
      const img2 = new Image();
      img2.src = this.disegnoNuovoBagno;
      img2.onload = () => {
        const originalWidth = img2.width;
        this.imgHeight2 = img2.height;
        this.desiredWidth = 70; // Larghezza desiderata in unità del PDF
        this.proportionalHeight = (this.desiredWidth * this.imgHeight2) / originalWidth;
      };
    }
  }

  private checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  checkRivestimenti() {
    return this.rivestimenti?.pavimentodesc || this.rivestimenti?.paretedesc || this.rivestimenti?.paretea || this.rivestimenti?.pareteb || this.rivestimenti?.paretec || this.rivestimenti?.pareted || this.rivestimenti?.internodoccia || this.rivestimenti?.note;
  }

  private checkOptionSelected(optionDescription: string): boolean {
    return this.optionsAviable.some(option => this.returnOptionsDescriptionById(option.typeId) === optionDescription && option.selected);
  }

  private returnOptionsDescriptionById(id: number): string {
    return this.trascodifiche.find(el => el.id === id).descrizione;
  }

  private openDialog(pdf: any): void {
    this.dialog.open(PreviewComponent, {
      width: '800px',
      maxWidth: '100%',
      data: {
        pdf,
      },
    });
  }

  private formatYesNo(value: boolean): string {
    return value ? 'Si' : 'No';
  }

  public returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  public closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    this.reload.emit(this.openSign);
    this.setStateToActive();
    this.formController.removeFrame();
  }

  private setStateToActive() {
    this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
      this.ordine = res;
      this.ordine.statopraticaCodice = "A";
      this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
        this.getOrder(this.form.idOrder);
      });
    })
  }

  public togglePrintPreview(): void {
    this.generatePdf(false);
  }

  private createPdf(pdf): void {
    this.sendingPdf = true;
    this.openSign = true;
    const title = localStorage.getItem('id_order');
    pdf.setProperties({
      title,
    });

    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.formController
      .sendSignature(
        newPdf,
        this.idOrder,
        this.form,
        pages,
        coordinates,
        title,
        false
      )
      .then(
        (res) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.router.navigate(['order-list']);
        },
        (error) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.ordine.statopraticaCodice = 'A';
          this.error = error;
          jQuery('#errorModal').modal({ backdrop: 'static', keyboard: false });
          jQuery('#errorModal').modal('toggle');
        }
      );
  }

  private startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft === 0) {
        clearInterval(intervalId);
        // richiamo api per invio email
        this.orderService.sendEmail(this.idOrdine).subscribe();
        jQuery('#timeoutModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#timeoutModal').modal('toggle');

        setTimeout(() => {
          jQuery('#timeoutModal').modal('hide');
        }, 10000);

        setTimeout(() => {
          this.formController.goToDashboard();
        }, 11000);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } /* else {
        this.timeLeft = 10;
      }*/
    }, 1000);
    return intervalId;
  }
}
