<div class="text-center bagnoPraticoBg">
    RIVESTIMENTI
</div>
<div class="bagnoPraticoCont" *ngIf="form.sections.rivestimenti">
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pavimento (esclusa Linea Lux)</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pavimento">
        </mat-form-field>
    </div>
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pareti</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareti">
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>A</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.paretea">
            </mat-form-field>
        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.pareteah = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.pareteah" [decimal]="true" [payment]="false" [small]="false"
                label="h">
            </app-input-number>
        </div>
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>B</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareteb">
            </mat-form-field>
        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.paretebh = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.paretebh" [decimal]="true" [payment]="false" [small]="false"
                label="h">
            </app-input-number>
        </div>
    </div>

    <div class="row">
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>C</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.paretec">
            </mat-form-field>

        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.paretech = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.paretech" [decimal]="true" [payment]="false" [small]="false"
                label="h">
            </app-input-number>
        </div>
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>D</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareted">
            </mat-form-field>
        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.paretedh = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.paretedh" [decimal]="true" [payment]="false" [small]="false"
                label="h">
            </app-input-number>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Interno doccia</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.internodoccia">
            </mat-form-field>
        </div>
        <div class="col-6">
            <app-input-number (onNumChange)="form.sections.rivestimenti.internodocciah = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.internodocciah" [decimal]="true" [payment]="false" [small]="false"
                label="h">
            </app-input-number>
        </div>
    </div>
   
    <div class="bagnoPraticoBg noteTitle">NOTE </div>
        <div class="bagnoPraticoCont">
            <app-note-sh [nota]="form.sections.rivestimenti.note" (onNotaEmit)="form.sections.rivestimenti.note = $event" [maxLength]="255"
                [minLine]="3">
            </app-note-sh>
    </div>
    
    <hr>
    <!--MOBILE BAGNO-->
    <div class="mb-3">
        <mat-slide-toggle class="example-margin" [(ngModel)]="mobileAttivo" [checked]="mobileAttivo"
            (ngModelChange)="toggleAttivo($event)">
            Mobile del cliente
        </mat-slide-toggle>
    </div>

    <div class="" *ngIf="!mobileAttivo">

        <div class="row mb-2" *ngIf="this.modelloMobile.length > 0">

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Modello </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId" (ngModelChange)="changeMobileModel($event)">
                        <mat-option [value]="null">-- Modello --</mat-option>
                        <mat-option *ngFor="let modello of modelloMobile" [value]="modello.id">
                            {{modello.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Misura </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId" 
                        [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId" 
                        (ngModelChange)="changeMisura()">
                            <mat-option [value]="null">-- Misura  --</mat-option>
                            <mat-option *ngFor="let misura of misuraMobile" [value]="misura.id">
                                {{misura.descrizione}}
                            </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Base </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.baseMobileId" [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId">
                        <mat-option [value]="null">-- Base --</mat-option>
                        <mat-option *ngFor="let base of baseMobile" [value]="base.id">
                            {{base.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Cassetti </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.cassettiMobileId" [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId">
                        <mat-option [value]="null">-- Cassetti --</mat-option>
                        <mat-option *ngFor="let cassettoMobile of cassettiMobile" [value]="cassettoMobile.id">
                            {{cassettoMobile.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Tipologia lavabo </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.tipologiaLavaboMobileId" [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId">
                        <mat-option [value]="null">-- Tipologia lavabo --</mat-option>
                        <mat-option *ngFor="let tipoLavabo of tipoLavaboMobile" [value]="tipoLavabo.id">
                            {{tipoLavabo.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <hr/>

        <div class="row mb-2">
            <div class="col-12 col-md-6 col-lg-4 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label>Specchio</mat-label>
                    <input matInput type="text"
                        [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.specchioMobile">
                </mat-form-field>
            </div>
    
            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-radio-group labelPosition="after" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche">
                    <mat-radio-button class="mb-2 mr-2" [value]="vasche.id" *ngFor="let vasche of tipiVasche">
                        {{vasche.descrizione}}
                    </mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche" (click)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche = null">
                        backspace
                    </span>
                </mat-radio-group>
                <div class="mb-4 smallFont text-danger" *ngIf="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche">
                    Campo obbligatorio
                </div>
            </div>
        </div>

        <hr/>

        <div class="row mb-2">
            <div class="col-md-4 col-12 align-self-center">
                <mat-radio-group labelPosition="after" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile" (ngModelChange)="changeColonne()">
                    <mat-radio-button class="mb-2 mr-2" [value]="colonna.id" *ngFor="let colonna of tipiCol?.slice()?.reverse()"> {{colonna.descrizione}} </mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile" (click)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile = null; changeColonne()">
                          backspace
                    </span>
                </mat-radio-group>
            </div>

            <div class="col-md-4 col-12 align-self-center" *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile && form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile != 2954">
                <app-input-number
                    (onNumChange)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili = $event"
                    [step]="1"
                    [num]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili"
                    [decimal]="false"
                    [payment]="false"
                    [small]="false"
                    label="Quantità pensili">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili || !form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili != 0">
                Campo obbligatorio
            </div>
          </div>
        </div>

        <hr/>

        <div class="bagnoPraticoBg noteTitle">NOTE COMPOSIZIONE </div>
            <div class="bagnoPraticoCont">
                <app-note-sh [nota]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note" (onNotaEmit)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note = $event" [maxLength]="255"
                    [minLine]="3">
                </app-note-sh>
            </div>
    </div>
</div>