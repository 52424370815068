export class ServizioPassword {
	id?: number;
	passwOk: string; 
	passwMinaccia: string; 
	ordineId: number; 

	constructor() {
		this.passwOk = '';
		this.passwMinaccia = '';
	  }
  }
  