<div class="text-center blueBg">GROHE</div>
<div class="blueCont">
	<div class="">
		<div class="d-inline-block">
			<mat-checkbox value="{{grohe.id}}" class="inline" class="mb-2 mr-2"
				*ngFor="let grohe of tipiGrohe;let i = index;" color="primary"
				(change)="updateGrohe($event,grohe.id)"
				[checked]="checked(grohe.id)"> {{grohe.descrizione}}
			</mat-checkbox>
		</div>
	</div>

	<div class="row">
		<div class="col-12">
			<mat-form-field class="full-width" appearance="fill">
			<mat-label>Note</mat-label> <textarea matInput
				[(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.notelibere"></textarea> </mat-form-field>
		</div>
	</div>
</div>
