// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  awsFolder: 'dev',
  awsBucket: 'ordinigdlfileupload',
  awsRegion: 'eu-central-1',

  apiUrl: 'https://staging-app.yousign.com',
  apiUrlV2: 'https://staging-api.yousign.com',
  apiKeyV2: '7862c63ec63d209a21e2d6c82a73ce5b',

  apiUrlV3: 'https://api-sandbox.yousign.app/v3',
  apiKeyV3: '922s8iR3DWSlWCMD6N4QBNxZmJlxVz6O',

  signHelp75: '/signature_uis/3c1603a4-38df-4bed-b040-1bc9b3154cdd',
  signBagni: '/signature_uis/4de8d123-1b48-4b0a-940e-5260efc125a1',
  signAriel: '/signature_uis/f1b36ab8-5b74-48c4-8f9a-7a2c745eaf56',
  signFotovoltaico: '/signature_uis/b3370d67-e3e7-4790-a556-972be7455c50',
  signBagnoPratico: '/signature_uis/a2262996-f4b1-448b-9f6a-d59ce91a347b',
  signArielFotovoltaicoUt: '/signature_uis/a4a7f0eb-60d7-40fc-8aad-7324d9743613',

  signMontascale: '/signature_uis/0a6eaed0-e1c2-4def-b41a-5724bc8e6961',
  signDepuratore: '/signature_uis/6cfc46e8-a2c1-47e5-91b5-1ae1e3275024',
  signCBVascaInDoccia: '/signature_uis/258c3812-b43f-4610-ab58-4de90f048e8c',
  integratedSignature: true,
  isV3: true,
  signGenericId: '56b06766-bd1d-426b-8317-c52b4aeaec4d',
  signWebhookId: 'c688aa6abc0335f351886c7a25741dd2',
  signWebhookAttServId: '26c8e70bca0aac6922402a42e7f06875'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.