import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { RivestimentiService } from '../../main-bagni/services/rivestimenti/rivestimenti.service';
import { SanitariService } from '../../main-bagni/services/sanitari/sanitari.service';
import { constants } from 'src/app/modules/classes/constants';

@Component({
  selector: 'app-pratico-rivestimenti',
  templateUrl: './pratico-rivestimenti.component.html',
  styleUrls: ['./pratico-rivestimenti.component.scss']
})
export class PraticoRivestimentiComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() mobileAttivo: boolean;
  @Output('update') change: EventEmitter<any> = new EventEmitter<any>();
  
  tipiVasche: Trascodifica[];
  tipiCol: Trascodifica[];
  vascaMob: Trascodifica[];
  noCol: Trascodifica[];
  modelloMobile: Trascodifica[] = [];
	misuraMobile: Trascodifica[] = [];
	baseMobile: Trascodifica[] = [];
	cassettiMobile: Trascodifica[] = [];
	tipoLavaboMobile: Trascodifica[] = [];

  constructor(
    private rivestimentiService: RivestimentiService,
    public sanitariService: SanitariService,
    public formController: FormControllerService) { }

  ngOnInit(): void {
    this.rivestimentiService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res[0] !== undefined) {
        this.form.sections.rivestimenti = res[0];
      }
    });

    this.sanitariService.findByKey(this.form.idOrder, 'idordineId.equals=').subscribe(res => {
      this.formController.mappingSanitariDTOtoForm(res[0], this.form.sections.nuoviProdotti);
      this.tipiVasche = this.formController.getTrascodificaByCodice(constants.COD_NUM_VASCHE_MOB, this.trascodifiche);
      this.vascaMob = this.formController.getTrascodificaByCodice(constants.COD_VASCAMOB, this.trascodifiche);
      this.tipiCol = this.formController.getTrascodificaByCodice(constants.COD_TIPO_COLONNA_MOB, this.trascodifiche);
      this.modelloMobile = this.formController.getTrascodificaByCodice(constants.COD_MODELLO_MOBILE, this.trascodifiche);
      this.noCol = this.tipiCol.filter((c: any) => c.descrizione === constants.DESC_MOBILI_NO_COL);
      if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione ||
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore ||
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche ||
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile) {
        this.toggleAttivo(false);

        let modelloMobileId = this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId;

        if(modelloMobileId){
          this.misuraMobile = this.trascodifiche.filter(
            (tr) => tr.codice === constants.COD_MISURA_MOBILE && tr.parentId === modelloMobileId
          );
        
          this.baseMobile = this.trascodifiche.filter(
            (tr) => tr.codice === constants.COD_BASE_MOBILE && tr.parentId === modelloMobileId
          );
        
          this.cassettiMobile = this.trascodifiche.filter(
            (tr) => tr.codice === constants.COD_CASSETTI_MOBILE && tr.parentId === modelloMobileId
          );
        
          this.tipoLavaboMobile = this.trascodifiche.filter(
            (tr) => tr.codice === constants.COD_TIPOLOGIA_LAVABO && tr.parentId === modelloMobileId
          );
  
          this.changeMisura()
        }

        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = false;
      } else {
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = true;
      }
    });
  }

  changeMobileModel(event: any): void {
    // Aggiorna i vettori in base al modello selezionato
    this.misuraMobile = this.trascodifiche.filter(
        (tr) => tr.codice === constants.COD_MISURA_MOBILE && tr.parentId === event
    );

    this.baseMobile = this.trascodifiche.filter(
        (tr) => tr.codice === constants.COD_BASE_MOBILE && tr.parentId === event
    );

    this.cassettiMobile = this.trascodifiche.filter(
        (tr) => tr.codice === constants.COD_CASSETTI_MOBILE && tr.parentId === event
    );

    this.tipoLavaboMobile = this.trascodifiche.filter(
        (tr) => tr.codice === constants.COD_TIPOLOGIA_LAVABO && tr.parentId === event
    );

    this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId = null;
    this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.baseMobileId = null;
    this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.cassettiMobileId = null;
    this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.tipoLavaboMobileId = null;
  }

  changeMisura(): void {
		
		if(this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId && this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId ){
			let element: Trascodifica = this.trascodifiche.find(
				(tr) => tr.id === this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId
			);

      let elementMisura: Trascodifica = this.trascodifiche.find(
        (tr) => tr.id === this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId);

			if(element && element.descrizione == constants.DESC_MOBILE_TAVOLONE && elementMisura.descrizione == constants.DESC_MISURA_FILTER_TAVOLONE){
					this.tipoLavaboMobile = this.trascodifiche.filter(
						(tr) => (
							tr.codice === constants.COD_TIPOLOGIA_LAVABO && 
							tr.parentId === this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId && 
							tr.descrizione == constants.DESC_BIANCO_TONDO_40
						)
					);
			}else{
        this.tipoLavaboMobile = this.trascodifiche.filter(
          (tr) => tr.codice === constants.COD_TIPOLOGIA_LAVABO && tr.parentId == this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId
      );
      }
		}
	}

  changeColonne(): void {
		this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili = null;
	}

  toggleAttivo(event): void {
    this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = event;
    this.change.emit(event);
  }
}
