import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { PaintableComponent } from 'paintablejs/angular';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { AbitazioneService } from '../../services/abitazione.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-dati-abitazione',
  templateUrl: './dati-abitazione.component.html',
  styleUrls: ['./dati-abitazione.component.scss']
})
export class DatiAbitazioneComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @ViewChild(PaintableComponent)

  tipiAbitazione: any;
  tipiCannaFumaria: any;
  casaStufa: any;
  paintable: PaintableComponent;
  active = false;
  useEraser = false;
  thickness = 2;
  color = '#000000';
  savedImage: any;
  drawing = true;
  manualUpload: any;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  isLoadingImage: boolean = true;
  width: number;
  height: number;
 
  constructor(
      private formController: FormControllerService,
      private abitazioneService:AbitazioneService,
  ) { }

  ngOnInit(): void {
    this.getTranscodifiche();
    this.getCasaStufa();
    this.height = 300;
    this.width = document.getElementById('part').offsetWidth - 60;
  }

  getCasaStufa(): void {
    this.abitazioneService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.casaStufa = res[0];

      if (this.casaStufa !== undefined) {
        this.form.sections.casaStufa = this.casaStufa;
        if (this.form.sections.casaStufa.disegnotecnico !== null && this.form.sections.casaStufa.disegnotecnico !== undefined) {
          this.cardImageBase64 = 'data:' + this.form.sections.casaStufa.disegnotecnicoContentType + ';base64,' + this.form.sections.casaStufa.disegnotecnico;
          this.isLoadingImage = false;
          this.active = false;
        }else{
          this.isLoadingImage = false;
        }
      } else{
        this.isLoadingImage = false;
      }
    });
  }

  getTranscodifiche(): void {
	this.tipiAbitazione = this.formController.getTrascodificaByCodice(constants.COD_TIPO_ABITAZIONE_STUFA,this.trascodifiche);
    this.tipiCannaFumaria = this.formController.getTrascodificaByCodice(constants.COD_CANNA_FUMARIA,this.trascodifiche);
  }

  clear(): void {
    this.paintable?.clear();
    this.isImageSaved = false;
    delete this.cardImageBase64;
    delete this.form.sections.casaStufa.disegnotecnico;
  }
  undo(): void {
    this.paintable?.undo();
  }

  redo(): void {
    this.paintable?.redo();
  }

  toggleEdit(): void {
    this.useEraser = false;
    this.active = !this.active;
    if (this.active) {
      $('body').addClass('noScr');
      document.body.requestFullscreen();
      $('html,body').animate({
        scrollTop: $('#part').offset().top - 80
      },'slow');
    } else {
      $('body').removeClass('noScr');
      document.exitFullscreen();
      $('html,body').animate({
        scrollTop: $('#part').offset().top - 80
      },'slow');
    }
  }

  toggleUseEraser(): void {
    this.useEraser = !this.useEraser;
  }

  get image(): string {
    return this.cardImageBase64 || undefined;
  }

  onSave(image: string): void {
    delete this.cardImageBase64;
    this.form.sections.casaStufa.disegnotecnico = image;
    const arr = this.form.sections.casaStufa.disegnotecnico.split(";");
    this.form.sections.casaStufa.disegnotecnicoContentType = 'image/png';
    const byte = arr[1].split(",");
    this.form.sections.casaStufa.disegnotecnico = byte[1];
    this.isImageSaved = true;
    this.form.sections.casaStufa.ordineId = this.form.idOrder;
    if(this.form.sections.casaStufa.id === undefined){
      this.abitazioneService.save(JSON.stringify(this.form.sections.casaStufa)).subscribe( res => {
        this.getCasaStufa();
      });
    } else {
      this.abitazioneService.update(JSON.stringify(this.form.sections.casaStufa)).subscribe(res => {
        this.getCasaStufa();
      });
    }
  }

  fileChangeEvent(fileInput: any): any {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = [
        'image/jpg',
        'image/jpeg',
        'image/png',
      ];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
            'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.formController.compressImage(this.cardImageBase64, this.width, this.height).then(compressed => {
              this.cardImageBase64 = compressed;
              this.form.sections.casaStufa.disegnotecnico = this.cardImageBase64;
              const arr = this.form.sections.casaStufa.disegnotecnico.split(";");
              this.form.sections.casaStufa.disegnotecnicoContentType = 'image/png';
              const byte = arr[1].split(",");
              this.form.sections.casaStufa.disegnotecnico = byte[1];
            }, err => console.error('this.err => ', err));
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
      this.active = false;
    }
  }

  removeImage(): void {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    delete this.form.sections.casaStufa.disegnotecnico;
  }
}
