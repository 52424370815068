<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="stepController()" class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">
            Avanti >
        </button>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    <div class="col-6 text-right">
    </div>
</div>

  <div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/ARIEL.jpg"/>
            </span>
            Modulo richiesta attivazione servizi Ariel depuratore 
        </h4>
    </div>
    <hr/>
    <div class="moduleContainer mb-3" *ngIf="isLoaded && form.ordine?.id">
        <div *ngIf="currentStep == 0">
            <app-anagrafica-sh [readOnly]="true" [validAnagrafica]="validAnagrafica" [productColor]="'green'" [form]="form"></app-anagrafica-sh>

            <div *ngIf="trascodifiche && trascodifiche.length > 0">
                <sh-servizio-dettaglio [form]="form" [trascodifiche]="trascodifiche" [brand]="'depuratoreAriel'" [productColor]="'green'">
                </sh-servizio-dettaglio>
            </div>
        </div>

        <div *ngIf="currentStep == 1">
            <div class="text-center">
                <div class="digitalSignatureButtons" *ngIf="openSign" style="height: 124px">
                    <div class="signButton" (click)="edit()">
                        <span class="material-icons d-block">edit</span>
                        <span class="d-block mb-2">Modifica Contratto</span>
                    </div>
                </div>
                <div class="" *ngIf="form.idOrder && ordine?.id">
                    <print-depuratore-services [trascodifiche]="trascodifiche" [pdfDetails]="attivazioneServiziPdfHeader" [listTypeDoc]="listTypeDoc" [form]="form" [ordine]="ordine" (reload)="reload($event)">
                    </print-depuratore-services>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="stepController()" class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">
            Avanti >
        </button>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    <div class="col-6 text-right">
    </div>
</div>


<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="
            {
            'currentStep': currentStep == totalSteps.length
            }">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>
  
