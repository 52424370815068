<!--<div>
    <div class="anagraficaServiziBg"  [ngStyle]="{'background': productColor}"> SERVIZI </div>
</div>-->

<div class="anagraficaContainer mt-4">
    <div class="row justify-content-center align-items-center">
        <h5 style="color:red">Da compilare in caso di collegamento COB</h5>
        <span style="text-decoration: underline">IN CASO DI COLLEGAMENTO ALLA CENTRALE OPERATIVA VERRANNO CONTATTATI I RESPONSABILI SOPRAINDICATI</span>
    
            <div class="col-md-4 col-12">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label>Password tutto Ok</mat-label>
                    <input matInput [(ngModel)]="form.sections.servizioPassword.passwOk" placeholder="Password tutto Ok" type="text">
                    <!--<mat-hint class="text-danger">Password tutto Ok</mat-hint>-->
                </mat-form-field>
            </div>
            <div class="col-md-4 col-12">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label>Password sottominaccia</mat-label>
                    <input matInput [(ngModel)]="form.sections.servizioPassword.passwMinaccia" placeholder="Password sottominaccia" type="text">
                    <!--<mat-hint class="text-danger">Password sottominaccia</mat-hint>-->
                </mat-form-field>
            </div>

            <span>   
                Si autorizza la Società Beta Elettronica S.r.l. e la Centrale Operativa di Vigilanza alla visione delle Telecamere installate nella propria
                attività solo ed esclusivamente a fronte di un allarme generato e nel rispetto delle norme in vigore con il garante della Privacy.
            </span>
    </div>
</div>