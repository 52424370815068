import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Help75PrintService } from "./help75-print.service";
import { BrandService } from "../../../../../services/brand/brand.service";
import { FormControllerService } from "../../../../../services/controller/form-controller.service";
import { DisegnoTecnicoService } from "../../../../../services/disegnoTecnico/disegno-tecnico.service";
import { PrintserviceService } from "../../../../../services/print/printservice.service";
import { environment } from "../../../../../../environments/environment";
import { Router } from "@angular/router";
import { OrdersService } from "../../../../../services/orders/orders.service";
import { MatDialog } from "@angular/material/dialog";
import { PreviewComponent } from "../../../../shared/pdf/preview/preview.component";
import { PrintComponent } from "src/app/modules/shared/pdf/print/print.component";
import { HttpService } from "../../../../../services/http.service";
import { constants } from "src/app/modules/classes/constants";
declare var jQuery: any;

@Component({
  selector: "app-help75-print",
  templateUrl: "./print-help75.component.html",
  styleUrls: ["./print-help75.component.scss"],
})
export class PrintHelp75Component extends PrintComponent implements OnInit {

  @Input() idOrder: number;
  @Input() form: any;
  @Output("reload") reload: EventEmitter<any> = new EventEmitter<any>();

  generatingPdf: boolean = false;
  showModalPrint: boolean = false;
  creatingPdf: boolean = false;
  showFrame: boolean = false;
  sendingPdf: boolean = false;
  openSign: boolean = false;
  validAnagrafica: boolean = false;
  loadAll: boolean = false;

  desiredWidth: number;
  desiredWidthDue: number;
  proportionalHeight: number = 0;
  proportionalHeightDue: number = 0;
  altezzaPagina: number = 297;
  timeLeft: number = 60;

  urlFrame: string = "";
  privacyText: string = "";

  ordine: any;
  anagrafica: any;
  trascodifiche: any;
  datiAttuali: any;
  datiTecniciAttuali: any;
  datiTecniciNuovi: any;
  tipiAbitazione: any;
  disegno: any;
  disegnoDue: any;
  piatto: any;
  nuoviGres: any;
  omaggio: any;
  pagamento: any;
  options: any;
  sanitari: any;
  nuoviSanitari: any;
  boxDoccia: any;
  pdf: any;
  error: any;
  member: any;
  opzioni: any;
  opzioniWc: any;
  opzioniRubinetteria: any;
  opzioniBox: any;
  opzioniPiatto: any;

  sanitariColumn: string[];
  nuovoBoxDocciaColumn: string[];
  rgbColorLine: number[] = [228, 157, 53];
  colorString: string = '#e49d35';
  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;
  constructor(
    private router: Router,
    private help75print: Help75PrintService,
    private brandService: BrandService,
    public formController: FormControllerService,
    private disegnoService: DisegnoTecnicoService,
    public printService: PrintserviceService,
    private orderService: OrdersService,
    public dialog: MatDialog,
    private httpService: HttpService,
  ) {
    super(printService);
  }

  ngOnInit(): void {
    this.getOrder();
    this.getPrivacy();
    this.setLineColor(this.rgbColorLine);

    this.sanitariColumn = [
      "MODELLO SANITARI",
      "TIPO SCARICO",
      "TIPO SANITARI",
      "TIPO PRODOTTO",
    ];

    this.nuovoBoxDocciaColumn = [
      "MODELLO",
      "CRISTALLI",
      "PROFILO",
      "INGRESSO",
    ];
  }

  getPrivacy(): void {
    this.httpService.getPrivacy('assets/json/help75privacy.json').subscribe((res) => {
      this.privacyText = res;
    });
  }

  getOrder(): void {
    this.help75print.getSingleOrder(this.idOrder).subscribe((res) => {
      this.ordine = res;
      this.form.ordine = res;
      this.brandService
        .getDataByProduct(Number(localStorage.getItem("id_product")))
        .subscribe((resp: any) => {
          this.trascodifiche = resp;
          this.tipiAbitazione = this.formController.getTrascodificaByCodice(constants.COD_TIPO_ABITAZIONE, this.trascodifiche);
          this.opzioni = this.formController
            .getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche)
            .filter((op) => op.descrizione === "NUOVI SANITARI");
          this.opzioniWc = this.formController
            .getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche)
            .filter((op) => op.descrizione === "NUOVA CASSETTA WC");
          this.opzioniRubinetteria = this.formController
            .getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche)
            .filter((op) => op.descrizione === "NUOVA RUBINETTERIA");
          this.opzioniBox = this.formController
            .getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche)
            .filter((op) => op.descrizione === "NUOVO BOX DOCCIA");
          this.opzioniPiatto = this.formController
            .getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche)
            .filter((op) => op.descrizione === "NUOVO PIATTO DOCCIA");
          this.getAnagrafica();
          this.getDisegno();
        });
    });
  }

  getDisegno(): void {
    this.disegnoService
      .findByKey(this.idOrder, "ordineId.equals=")
      .subscribe((res) => {
        if (res[0]) {
          if (res[0].disegno) {
            this.disegno =
              "data:" + res[0].disegnoContentType + ";base64," + res[0].disegno;
            const img = new Image();
            img.src = this.disegno;
            img.onload = () => {
              const originalWidth = img.width;
              const originalHeight = img.height;

              this.desiredWidth = 70; // Larghezza desiderata in unità del PDF
              this.proportionalHeight =
                (this.desiredWidth * originalHeight) / originalWidth;
            };
          }
        }
        if (res[1]) {
          if (res[1].disegno) {
            this.disegnoDue =
              "data:" + res[1].disegnoContentType + ";base64," + res[1].disegno;
            const imgDue = new Image();
            imgDue.src = this.disegnoDue;
            imgDue.onload = () => {
              const originalWidthDue = imgDue.width;
              const originalHeightDue = imgDue.height;

              this.desiredWidthDue = 70; // Larghezza desiderata in unità del PDF
              this.proportionalHeightDue =
                (this.desiredWidthDue * originalHeightDue) / originalWidthDue;
            };
          }
        }
      });
  }

  getAnagrafica(): void {
    this.help75print
      .getAnagraficaCliente(this.ordine.clienteId)
      .subscribe((res) => {
        this.anagrafica = res;
        this.checkAnagrafica();
        this.getDatiAttuali();
      });
  }

  getDatiAttuali(): void {
    this.help75print.getDatiAttuali(this.ordine.id).subscribe((res) => {
      this.datiAttuali = res[0];
      this.getDatiTecniciAttuali();
    });
  }

  getDatiTecniciAttuali(): void {
    this.help75print.getDatiTecniciAttuali(this.ordine.id).subscribe((res) => {
      this.datiTecniciAttuali = res[0];
      this.getDatiTecniciNuovi();
    });
  }

  getDatiTecniciNuovi(): void {
    this.help75print.getDatiTecniciNuovi(this.ordine.id).subscribe((res) => {
      this.datiTecniciNuovi = res[0];
      this.getPiattoDoccia();
    });
  }

  getPiattoDoccia(): void {
    this.help75print
      .getNuovoPiattoDocciaEPiattiPietra(this.ordine.id)
      .subscribe((res) => {
        this.piatto = res[0];
        this.getNuoviGres();
      });
  }

  getNuoviGres(): void {
    this.help75print.getNuovoRivGres(this.ordine.id).subscribe((res) => {
      this.nuoviGres = res[0];
      this.getOmaggio();
    });
  }

  getOmaggio(): void {
    this.help75print.getOmaggio(this.ordine.id).subscribe((res) => {
      this.omaggio = res;
      this.getPagamento();
    });
  }

  getPagamento(): void {
    this.help75print.getPagamento(this.ordine.id).subscribe((res) => {
      this.pagamento = res;
      this.getOptions();
    });
  }

  getOptions(): void {
    this.help75print.getOptionsAvailable(this.ordine.id).subscribe((res) => {
      this.options = res;
      this.getSanitari();
    });
  }

  getSanitari(): void {
    this.help75print.getSanitari(this.ordine.id).subscribe((res) => {
      this.sanitari = res;
      this.getNuoviSanitari();
    });
  }

  getNuoviSanitari(): void {
    this.help75print.getNuoviSanitari(this.ordine.id).subscribe((res) => {
      this.nuoviSanitari = res;
      this.getBoxDoccia();
    });
  }

  getBoxDoccia(): void {
    this.help75print.getBoxDoccia(this.ordine.id).subscribe((res) => {
      this.boxDoccia = res;
      this.loadAll = true;
    });
  }

  addLine(value: number): number {
    return value + 6;
  }

  generatePdf(firma: boolean): void {
    let y = this.initPdf(this.ordine, this.anagrafica, "/assets/img/loghi/logo-bagni-italiani.jpg", "Modulo d'ordine Bagni Italiani Help75", this.colorString);

    // DATI ATTUALI
    if (this.datiAttuali) {
      y = this.printNuoviDatiAttuali(y);
    }

    if (this.datiTecniciAttuali.tipoabitazioneId) {
      this.printDatiTecniciAttuali(y);
    }

    if (this.disegno) {
      const tableTitle = 'DISEGNO TECNICO';
      this.genericPrintImages(this.doc, this.printService.returnCurrentY(), this.disegno, null, tableTitle, null, this.desiredWidth, this.proportionalHeight, this.colorString, this.color);
    }

    let optionPiattoDoccia = this.options.filter(
      (op) => op.typeId === this.opzioniPiatto[0].id
    );

    if (optionPiattoDoccia !== undefined && optionPiattoDoccia.length > 0) {
      optionPiattoDoccia = optionPiattoDoccia[0];
    }

    if (this.datiTecniciNuovi) {
      const tableTitle = 'NUOVI DATI TECNICI';
      this.nuoviDatiTecniciBagni(this.doc, this.datiTecniciNuovi, tableTitle, y, this.colorString);
    }

    if (this.piatto) {
      y = this.printService.returnCurrentY();
      let tableTitle = `NUOVO PIATTO DOCCIA ${optionPiattoDoccia.selected ? 'SI' : 'NO'}`;
      this.genericOnlyTitleCenter(this.doc, y, tableTitle, this.colorString);

      tableTitle = 'PIATTI EFFETTO PIETRA';
      this.nuovoPiattoDocciaPietra(this.doc, this.piatto, tableTitle, y, this.colorString);

      tableTitle = 'PIATTI STONE 3D';
      this.nuovoPiattoDocciaStone(this.doc, this.piatto, tableTitle, y, this.colorString);
    }

    if (this.nuoviGres) {
      const tableTitle = constants.LABEL_PRINT_RIVESTIMENTO_GRES;
      this.genericNuovoRivestimentoGresBI(this.doc, this.nuoviGres, tableTitle, y, this.colorString);
    }

    if (this.omaggio.length > 0) {
      this.genericOmaggio(this.doc, this.omaggio, this.printService.returnCurrentY(), this.colorString);
    }

    if (this.ordine && this.ordine.note && this.ordine.note !== '') {
      const title = constants.LABEL_PRINT_NOTE;
      this.createTableTextNote(title, this.ordine.note, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
    }

    // pagamento 1
    if (this.pagamento) {
      this.createTablePagamento(this.doc, this.pagamento, this.ordine, this.printService.returnCurrentY(), 0, this.colorString);
    }

    //Sezione sempre presente
    const title = "ARREDO BAGNO";
    this.createTableTextNoheader(title, `capitolato contratto d'appalto di fornitura e installazione senza applicazione di sconto in fattura`, y, this.doc, this.rgbColorLine);

    // mobili bagni quelli del cliente 
    if (!this.sanitari[0]?.mobilebagno && !this.sanitari[0]?.mobilecolore && !this.sanitari[0]?.mobilecolore2 && !this.sanitari[0]?.numvascmobDescrizione
      && !this.sanitari[0]?.vascamobDescrizione && !this.sanitari[0]?.tipocolmobDescrizione && !this.sanitari[0]?.qtapensmob && !this.sanitari[0]?.notecompmob) {
      const tableTitle = 'MOBILE BAGNO'
      this.mobileBagnoClienteBagniTenere(this.doc, tableTitle, y, this.colorString);
    }

    if (this.sanitari[0]) {
      const tableTitle = constants.LABEL_PRINT_MOBILE_BAGNO;
      this.mobileBagnoClienteBagni(this.doc, this.sanitari[0], tableTitle, y, this.colorString);
    }

    let optionNuoviSanitari = this.options.filter(
      (op) => op.typeId === this.opzioni[0].id
    );
    if (
      optionNuoviSanitari !== undefined &&
      optionNuoviSanitari.length > 0
    ) {
      optionNuoviSanitari = optionNuoviSanitari[0];
    }

    let tableTitle = `SANITARI ${optionNuoviSanitari.selected ? 'SI' : 'NO'}`;
    this.genericPrintSanitariBagniHelp(this.doc, this.nuoviSanitari, tableTitle, optionNuoviSanitari, this.sanitariColumn, y, this.colorString);

    let optionNuovaCassetta = this.options.filter(
      (op) => op.typeId === this.opzioniWc[0].id
    );

    if (optionNuovaCassetta !== undefined && optionNuovaCassetta.length > 0) {
      optionNuovaCassetta = optionNuovaCassetta[0];
    }

    tableTitle = `CASSETTA WC ${optionNuovaCassetta.selected ? 'SI' : 'NO'}`;


    this.genericPrintCassettaWCBagniHelp(this.doc, this.sanitari[0], tableTitle, optionNuovaCassetta, this.sanitariColumn, y, this.colorString);


    let optionNuovaRubinetteria = this.options.filter(
      (op) => op.typeId === this.opzioniRubinetteria[0].id
    );

    if (
      optionNuovaRubinetteria !== undefined &&
      optionNuovaRubinetteria.length > 0
    ) {
      optionNuovaRubinetteria = optionNuovaRubinetteria[0];
    }

    this.printNuovaRubinetteria(y, optionNuovaRubinetteria);

    let optionBoxDoccia = this.options.filter(
      (op) => op.typeId === this.opzioniBox[0].id
    );

    if (optionBoxDoccia !== undefined && optionBoxDoccia.length > 0) {
      optionBoxDoccia = optionBoxDoccia[0];
    }

    tableTitle = `BOX DOCCIA ${optionBoxDoccia.selected ? 'SI' : 'NO'}`;
    this.genericPrintBoxDoccia(this.doc, this.boxDoccia, tableTitle, optionBoxDoccia, this.nuovoBoxDocciaColumn, y, this.colorString);


    if (this.disegnoDue) {
      tableTitle = 'DISEGNO TECNICO'
      this.genericPrintImages(this.doc, this.printService.returnCurrentY(), this.disegnoDue, null, tableTitle, null, this.desiredWidth, this.proportionalHeight, this.colorString, this.color);
    }

    // pagamento 2
    if (this.pagamento) {
      this.createTablePagamento(this.doc, this.pagamento, this.ordine, this.printService.returnCurrentY(), 1, this.colorString);
    }

    // PAGINA STATICA PRIVACY
    y = this.printService.addNewPage(
      this.doc,
      true,
      this.img,
      this.ordine,
      this.currentPdfPage,
      this.title
    );

    this.printService.writePrivacyOne(
      this.doc,
      y,
      this.ordine,
      this.img,
      this.title,
      this.currentPdfPage,
      this.privacyText
    );
    y = this.printService.returnCurrentY();

    this.creatingPdf = false;
    if (firma) {
      if (!this.integratedSignature) {
        this.createPdf(this.doc);
      } else {
        this.toggleSignPdf(this.doc);
      }
    } else {
      const pdfBlob = this.doc.output("blob");

      // Crea un URL dall'oggetto Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      this.openDialog(pdfUrl);
    }
  }


  private printNuoviDatiAttuali(y: number): number {

    if (this.someDatiAttuali()) {
      y = this.printDatiAttuali(y);
    }

    if (this.checkDatiTecniciMuretti()) {
      y = this.printDatiTecniciMurettiAttuali(y);
    }

    (this.someDatiAttuali() || this.checkDatiTecniciMuretti()) ? this.printService.setCurrentY(y += 5) : y;
    return y;
  }

  private printDatiTecniciMurettiAttuali(y: number): number {

    y = this.addLine(y);
    this.printService.setCurrentY(y);
    let sectionTitle = 'DATI TECNICI MURETTI ATTUALI:';
    this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);
    y = this.addLine(y - 2);
    let murettiY = y;
    this.doc.addImage("/assets/img/remail/murosx.png", "PNG", 55, y + 5, 35, 20, "", "SLOW");
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "MURETTO SX", 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmurosx ? this.datiAttuali.lungmurosx : "non definita"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmurosx ? this.datiAttuali.largmurosx : "non definita"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmurosx ? this.datiAttuali.altmurosx : "non definita"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(
      this.doc, 9, y, "Eliminare muretto: " + (this.datiAttuali.eliminamurosx ? "si" : "no"), 10);
    this.printService.writeLine(this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppo ? "si" : "no"), 45);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremurosxdentro ? "si" : "no"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(
      this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremurosxfuori ? "si" : "no"), 10);

    this.printService.setCurrentY(y);

    this.doc.line(100, murettiY + 5, 100, this.printService.returnCurrentY());
    y = murettiY;
    this.doc.addImage("/assets/img/remail/murodx.png", "PNG", 155, y + 5, 35, 20, "", "SLOW");
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "MURETTO DX", 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmurodx ? this.datiAttuali.lungmurodx : "non definita"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(
      this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmurodx ? this.datiAttuali.largmurodx : "non definita"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmurodx ? this.datiAttuali.altmurodx : "non definita"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Eliminare muretto: " + (this.datiAttuali.eliminamurodx ? "si" : "no"), 110);
    this.printService.writeLine(
      this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppomurodx ? "si" : "no"), 145);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremurodxdentro ? "si" : "no"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremurodxfuori ? "si" : "no"), 110);

    y = this.addLine(y + 5);
    murettiY = y;
    this.printService.writeLine(this.doc, 9, (y - 2), "MURO DIETRO tenere sempre", 10);
    this.doc.addImage("/assets/img/remail/murettodietro.png", "PNG", 55, y, 35, 20, "", "SLOW");
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmurodietro ? this.datiAttuali.lungmurodietro : "non definita"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmurodietro ? this.datiAttuali.largmurodietro : "non definita"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmurodietro ? this.datiAttuali.altmurodietro : "non definita"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppomurodietro ? "si" : "no"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremurodietrodentro ? "si" : "no"), 10);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremurodietrofuori ? "si" : "no"), 10);
    this.printService.setCurrentY(y);
    this.doc.line(100, murettiY, 100, this.printService.returnCurrentY());
    y = murettiY;

    this.printService.writeLine(this.doc, 9, (y - 2), "MURO AVANTI eliminare sempre", 110);
    this.doc.addImage("/assets/img/remail/murettoavanti.png", "PNG", 155, y, 35, 20, "", "SLOW");
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmuroavanti ? this.datiAttuali.lungmuroavanti : "non definita"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmuroavanti ? this.datiAttuali.largmuroavanti : "non definita"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmuroavanti ? this.datiAttuali.altmuroavanti : "non definita"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppomuroavanti ? "si" : "no"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremuroavantidentro ? "si" : "no"), 110);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremuroavantifuori ? "si" : "no"), 110);

    y = this.printService.addLine(y + 2);
    this.printService.writeLine(this.doc, 7, y, "Nota: ", 98);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 7, y, "PER \"ZOPPO\" SI INTENDE UN'ANTA DI BOX IN ACRILICO CHE VA POSIZIONATA SUL MURETTO", 45);

    y = this.printService.addLine(y + 5);
    this.printService.setCurrentY(y + 5);
    return y;
  }

  private checkDatiTecniciMuretti(): boolean {
    let isMurettoSx = false;
    let isMurettoDx = false;
    let isMuroDietro = false;
    let isMuroDavanti = false;
    if (this.datiAttuali.lungmurosx || this.datiAttuali.lungmurosx || this.datiAttuali.largmurosx || this.datiAttuali.altmurosx || this.datiAttuali.eliminamurosx || this.datiAttuali.zoppo || this.datiAttuali.teneremurosxdentro || this.datiAttuali.teneremurosxfuori) {
      isMurettoSx = true;
    }

    if (this.datiAttuali.lungmurodx || this.datiAttuali.largmurodx || this.datiAttuali.altmurodx || this.datiAttuali.eliminamurodx || this.datiAttuali.zoppomurodx || this.datiAttuali.teneremurodxdentro || this.datiAttuali.teneremurodxfuori) {
      isMurettoDx = true;
    }

    if (this.datiAttuali.lungmurodietro || this.datiAttuali.largmurodietro || this.datiAttuali.altmurodietro || this.datiAttuali.zoppomurodietro || this.datiAttuali.teneremurodietrodentro || this.datiAttuali.teneremurodietrofuori) {
      isMuroDietro = true;
    }

    if (this.datiAttuali.lungmuroavanti || this.datiAttuali.largmuroavanti || this.datiAttuali.altmuroavanti || this.datiAttuali.zoppomuroavanti || this.datiAttuali.teneremuroavantidentro || this.datiAttuali.teneremuroavantifuori) {
      isMuroDavanti = true;
    }

    return (isMurettoSx || isMurettoDx || isMuroDietro || isMuroDavanti);
  }

  private someDatiAttuali(): boolean {
    let isDatiAttuali = false;
    if (this.datiAttuali.lungvasca || this.datiAttuali.largvasca || this.datiAttuali.altvasca || this.datiAttuali.murodietro || this.datiAttuali.murosx || this.datiAttuali.murodx || this.datiAttuali.scaricosx || this.datiAttuali.scaricodx) {
      isDatiAttuali = true;
    }
    return isDatiAttuali;
  }

  private printDatiAttuali(y: number): number {
    let sectionTitle = 'DATI ATTUALI VASCA/PIATTO:';
    this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);
    y = this.printService.addLine(y);

    this.doc.addImage("/assets/img/remail/vasca1.png", "PNG", 10, y, 40, 25, "", "SLOW");

    y = this.printService.addLine(y);
    const attualiY = y;
    this.printService.writeLine(this.doc, 9, y, "Lunghezza (L) cm: " + (this.datiAttuali.lungvasca ? this.datiAttuali.lungvasca : "non definita"), 65);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Larghezza (P) cm: " + (this.datiAttuali.largvasca ? this.datiAttuali.largvasca : "non definita"), 65);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Altezza (H) cm: " + (this.datiAttuali.altvasca ? this.datiAttuali.altvasca : "non definita"), 65);
    y = attualiY;
    this.printService.writeLine(this.doc, 9, y, "Muro dietro: " + (this.datiAttuali.murodietro ? "si" : "no"), 115);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Muro sx: " + (this.datiAttuali.murosx ? "si" : "no"), 115);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Muro dx: " + (this.datiAttuali.murodx ? "si" : "no"), 115);
    y = this.printService.addLine(y);
    y = attualiY;
    this.printService.writeLine(this.doc, 9, y, "Scarico sx: " + (this.datiAttuali.scaricosx ? "si" : "no"), 155);
    y = this.printService.addLine(y);
    this.printService.writeLine(this.doc, 9, y, "Scarico dx: " + (this.datiAttuali.scaricodx ? "si" : "no"), 155);
    y = this.addLine(y + 10);
    return y;
  }

  private printDatiTecniciAttuali(y: number): void {
    const tableTitle = constants.LABEL_DATI_TECNICI_ATTUALI;
    const place = this.tipiAbitazione.findIndex((r) => r.id === this.datiTecniciAttuali.tipoabitazioneId);
    const obj: any = { tipoAbitazione: '', diProprieta: '', ascensore: '', accesscortile: '', pianointervento: '', lungvano: '', largvano: '' };
    const tipoAbitazione = this.tipiAbitazione[place]?.descrizione;

    obj.tipoAbitazione = tipoAbitazione;
    obj.diProprieta = (this.datiTecniciAttuali.proprieta ? "si" : "no");
    obj.ascensore = (this.datiTecniciAttuali.ascensore ? "si" : "no");
    obj.accesscortile = (this.datiTecniciAttuali.accesscortile ? "si" : "no")
    obj.pianointervento = (this.datiTecniciAttuali.pianointervento ? this.datiTecniciAttuali.pianointervento : "")
    obj.lungvano = (this.datiTecniciAttuali.lungvano ? this.addUnitOfMeasure(this.datiTecniciAttuali.lungvano, 'cm') : "")
    obj.largvano = (this.datiTecniciAttuali.largvano ? this.addUnitOfMeasure(this.datiTecniciAttuali.largvano, 'cm') : "")
    this.genericDatiTecniciAttuali(this.doc, obj, tableTitle, y, this.colorString);
  }


  private printNuovaRubinetteria(y: number, optionNuovaRubinetteria: any) {

    const tableTitle = `RUBINETTERIA ${optionNuovaRubinetteria.selected ? 'SI' : 'NO'}`;
    const sanitari = this.sanitari[0];
    const obj = { rubSerie: '', lavabo: '', bidet: '', kitDoccia: '', miscelatore: '', asta: '', astaForma: null, colonne: null, miscelatoreEst: '', miscelatoreSerie: '' }

    if (sanitari) {
      if (sanitari?.rubserieDescrizione) {
        obj.rubSerie = sanitari.rubserieDescrizione;
      }

      if (sanitari?.rubprodDescrizione) {
        if (sanitari.rubprodDescrizione.trim() === 'LAVABO') {
          obj.lavabo = 'SI';
        } else {
          obj.lavabo = sanitari.rubprodDescrizione
        }
      }

      if (sanitari?.rubbidetDescrizione) {
        obj.bidet = 'SI';
      }

      if (sanitari?.rubkitDescrizione) {
        obj.kitDoccia = sanitari.rubkitDescrizione;
      }

      if (sanitari?.rubmiscDescrizione) {
        obj.miscelatore = sanitari.rubmiscDescrizione
      }

      if (sanitari?.astasingola) {
        obj.asta = 'singola';
      } else if (sanitari.astadoppia) {
        obj.asta = 'doppia';
      }

      if (sanitari?.rubtipoastaDescrizione) {
        obj.astaForma = sanitari.rubtipoastaDescrizione.toLowerCase();
      }

      if (sanitari?.rubtipocolDescrizione) {
        obj.colonne = sanitari.rubtipocolDescrizione;
      }

      if (sanitari?.rubmiscestDescrizione) {
        obj.miscelatoreEst = sanitari.rubmiscestDescrizione.toLowerCase();
      }

      if (sanitari?.rubmiscserieDescrizione) {
        obj.miscelatoreSerie = sanitari.rubmiscserieDescrizione;
      }
    }
    this.genericPrintRubinetteriaBagniHelp(this.doc, obj, tableTitle, optionNuovaRubinetteria, this.sanitariColumn, y, this.colorString);
  }

  openDialog(pdf: any): void {
    this.dialog.open(PreviewComponent, {
      width: "800px",
      maxWidth: "100%",
      data: {
        pdf,
      },
    });
  }

  togglePrintPreview(): void {
    this.generatePdf(false);
  }

  createPdf(pdf): void {
    this.sendingPdf = true;
    this.openSign = true;
    const title = localStorage.getItem("id_order");
    pdf.setProperties({
      title,
    });

    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.formController
      .sendSignature(
        newPdf,
        this.idOrder,
        this.form,
        pages,
        coordinates,
        title,
        false
      )
      .then(
        (res) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.router.navigate(["order-list"]);
        },
        (error) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.ordine.statopraticaCodice = "A";
          this.error = error;
          jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
          jQuery("#errorModal").modal("toggle");
        }
      );
  }

  returnIdOrdine(): string {
    return localStorage.getItem("id_order");
  }

  public closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    this.reload.emit(this.openSign);
    this.setStateToActive();
    this.formController.removeFrame();
  }

  private setStateToActive() {
    this.orderService.getSingleOrder(this.idOrder).subscribe((res) => {
      this.ordine = res;
      this.ordine.statopraticaCodice = "A";
      this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
        this.getOrder();
      });
    })
  }

  toggleSignPdf(pdf): void {
    const interval = this.startTimer();
    const title = localStorage.getItem("id_order");
    const pages = this.printService.returnCurrentPage();
    this.showModalPrint = !this.showModalPrint;
    this.openSign = true;
    this.showFrame = false;
    this.generatingPdf = true; 3
    this.urlFrame = '';
    jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
    jQuery("#signModal").modal("toggle");

    if (this.isApiV3) {
      const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
      var split = this.printService.returnCoordinates().split(",");
      const coordinates = {
        width: 120,
        height: 37,
        x: split[0],
        y: split[3],
      }
      this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
        this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
        setTimeout(() => {
          this.showFrame = true;
          this.sendingPdf = false;
          this.generatingPdf = false;

        }, 2000);
        clearInterval(interval);
      }, error => {
        this.generatingPdf = false;
        this.sendingPdf = false;
        this.ordine.statopraticaCodice = "A";
        clearInterval(interval);
        this.closeSignPdf();
        jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#errorModal").modal("toggle");
      });
    } else {
      const newPdf = btoa(pdf.output());
      const coordinates = this.printService.returnCoordinates();
      this.formController
        .sendSignature(
          newPdf,
          this.form.idOrder,
          this.form,
          pages,
          coordinates,
          title,
          true
        )
        .then(
          (res) => {
            this.member = res.members[0].id;
            this.urlFrame =
              environment.apiUrl +
              "/procedure/sign?members=" +
              this.member +
              "&signatureUi=" +
              environment.signHelp75; // "https://staging-app.yousign.com/procedure/sign?members="+this.member;
            this.showFrame = true;
            this.sendingPdf = false;
            this.generatingPdf = false;

            clearInterval(interval);
            // this.router.navigate(['order-list']);
          },
          (error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.statopraticaCodice = "A";
            clearInterval(interval);
            jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
            jQuery("#errorModal").modal("toggle");
          }
        );
    }

  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft === 0) {
        clearInterval(intervalId);
        // richiamo api per invio email
        this.orderService.sendEmail(this.form.idOrder).subscribe();
        jQuery("#timeoutModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#timeoutModal").modal("toggle");

        setTimeout(() => {
          jQuery("#timeoutModal").modal("hide");
        }, 10000);

        setTimeout(() => {
          this.formController.goToDashboard();
        }, 11000);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }

  setLineColor(rgbColorLine) {
    this.color = rgbColorLine;
  }
}
