<div class="{{brand === 'bagniHelp75' ? 'help75Bg' : 'blueBg'}}">
    MOBILE {{brand === 'bagniHelp75' ? 'BAGNO' : ''}}
</div>

<div class="{{brand === 'bagniHelp75' ? 'hepl75Cont' : 'blueCont'}}">

    <div class="mb-3">
        <mat-slide-toggle class="example-margin" [(ngModel)]="mobileAttivo" [checked]="mobileAttivo" (ngModelChange)="toggleAttivo($event)">
            Mobile del cliente
        </mat-slide-toggle>
    </div>

    <div class="" *ngIf="!mobileAttivo">
        <div class="row mb-2" *ngIf="this.modelloMobile.length > 0">

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Modello </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId" (ngModelChange)="changeMobileModel($event)">
                        <mat-option [value]="null">-- Modello --</mat-option>
                        <mat-option *ngFor="let modello of modelloMobile" [value]="modello.id">
                            {{modello.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Misura </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId" 
                        [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId" 
                        (ngModelChange)="changeMisura()">
                            <mat-option [value]="null">-- Misura  --</mat-option>
                            <mat-option *ngFor="let misura of misuraMobile" [value]="misura.id">
                                {{misura.descrizione}}
                            </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Base </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.baseMobileId" [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId">
                        <mat-option [value]="null">-- Base --</mat-option>
                        <mat-option *ngFor="let base of baseMobile" [value]="base.id">
                            {{base.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Cassetti </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.cassettiMobileId" [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId">
                        <mat-option [value]="null">-- Cassetti --</mat-option>
                        <mat-option *ngFor="let cassettoMobile of cassettiMobile" [value]="cassettoMobile.id">
                            {{cassettoMobile.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label> Tipologia lavabo </mat-label>
                    <mat-select [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.tipologiaLavaboMobileId" [disabled]="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId">
                        <mat-option [value]="null">-- Tipologia lavabo --</mat-option>
                        <mat-option *ngFor="let tipoLavabo of tipoLavaboMobile" [value]="tipoLavabo.id">
                            {{tipoLavabo.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <hr/>

        <div class="row mb-2">
            <div class="col-12 col-md-6 col-lg-4 mb-2">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label>Specchio</mat-label>
                    <input matInput type="text"
                        [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.specchioMobile">
                </mat-form-field>
            </div>
    
            <div class="col-12 col-md-6 col-lg-3 mb-2">
                <mat-radio-group labelPosition="after" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche">
                    <mat-radio-button class="mb-2 mr-2" [value]="vasche.id" *ngFor="let vasche of tipiVasche">
                        {{vasche.descrizione}}
                    </mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche" (click)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche = null">
                        backspace
                    </span>
                </mat-radio-group>
                <div class="mb-4 smallFont text-danger" *ngIf="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche">
                    Campo obbligatorio
                </div>
            </div>
        </div>

        <hr/>

        <div class="row mb-2">
            <div class="col-md-4 col-12 align-self-center">
                <mat-radio-group labelPosition="after" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile" (ngModelChange)="changeColonne()">
                    <mat-radio-button class="mb-2 mr-2" [value]="colonna.id" *ngFor="let colonna of tipiCol?.slice()?.reverse()"> {{colonna.descrizione}} </mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile" (click)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile = null; changeColonne()">
                          backspace
                    </span>
                </mat-radio-group>
            </div>

            <div class="col-md-4 col-12 align-self-center" *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile && form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile != 53 && form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile != 1793">
                    <app-input-number
                        (onNumChange)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili = $event"
                        [step]="1"
                        [num]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili"
                        [decimal]="false"
                        [payment]="false"
                        [small]="false"
                        label="Quantità pensili">
                    </app-input-number>
                <div class="smallFont text-danger" *ngIf="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili || !form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili != 0">
                    Campo obbligatorio
                </div>
            </div>
        </div>

        <hr/>

        <div class="{{brand === 'bagniHelp75' ? 'help75Bg' : 'blueBg'}} noteTitle">NOTE COMPOSIZIONE </div>
        <div class="{{brand === 'bagniHelp75' ? 'hepl75Cont' : 'blueCont'}}">
            <app-note-sh [nota]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note" (onNotaEmit)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note = $event" [maxLength]="255"
                [minLine]="3">
            </app-note-sh>
        </div>
    </div>
</div>


<div id="accordionFive">
  <div>
    <div  id="headingOne"  class="text-center {{brand === 'bagniHelp75' ? 'help75Bg' : 'blueBg'}}">
      <h5 class="mb-0 row">
        <div class="col-6 text-right">
        {{brand === 'bagniHelp75' ? 'SANITARI' : 'NUOVI SANITARI'}}
            <mat-hint class="text-danger">*</mat-hint>
        </div>
        <div class="col-6 text-left">
            <mat-radio-group labelPosition="after" class="inline" color="primary" [(ngModel)]="optionNuoviSanitariOmaggio.selected" (ngModelChange)="showDiv($event)">
                <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
            </mat-radio-group>
        </div>        
      </h5>
    </div>

    <div *ngIf="isVisible" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionFive">  
      <div>
        <div class="{{brand === 'bagniHelp75' ? 'hepl75Cont' : 'blueCont'}}" [ngClass]="{'onlyRedBorder': !validNuoviSan()}">
            <div class="">
                <div class="row mb-2" *ngFor="let san of form.sections.nuoviSanitariDto; let i = index;">
                    <div class="col-3">
                        <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaModel[i] && this.avaModel[i].length > 0">
                            <mat-label>Modello sanitari</mat-label>
                            <mat-select (ngModelChange)="changeModel($event, i)" [(ngModel)]="san.modsanitariId">
                                <mat-option [value]="null">-- Modello sanitari --</mat-option>
                                <mat-option *ngFor="let option of avaModel[i]" [value]="option.id">
                                    {{option.descrizione}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
        
                    <div class="col-3">
                        <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaScarico[i] && this.avaScarico[i].length>0 && san.modsanitariId">
                            <mat-label>Tipo scarico</mat-label>
                            <mat-select (ngModelChange)="changeScarico($event, i)" [(ngModel)]="san.tiposcaricoId">
                                <mat-option [value]="null">-- Tipo scarico --</mat-option>
                                <mat-option *ngFor="let option of avaScarico[i]" [value]="option.id">
                                    {{option.descrizione}}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="text-danger" *ngIf="san.modsanitariId && !san.tiposcaricoId">
                                Campo obbligatorio
                            </mat-hint>
                        </mat-form-field>
                    </div>
        
                    <div class="col-3">
                        <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaSanitari[i] && this.avaSanitari[i].length>0 && san.tiposcaricoId">
                            <mat-label>Tipo sanitari</mat-label>
                            <mat-select (ngModelChange)="changeSanitari($event, i)" [(ngModel)]="san.tiposanitariId">
                                <mat-option [value]="null">-- Tipo sanitari --</mat-option>
                                <mat-option *ngFor="let option of avaSanitari[i]" [value]="option.id">
                                    {{option.descrizione}}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="text-danger" *ngIf="san.tiposcaricoId && !san.tiposanitariId">
                                Campo obbligatorio
                            </mat-hint>
                        </mat-form-field>
                    </div>
        
                    <div class="col-2">
                        <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaTipo[i] && this.avaTipo[i].length>0 && san.tiposanitariId">
                            <mat-label>Tipo prodotto</mat-label>
                            <mat-select [(ngModel)]="san.tipoprodottoId">
                                <mat-option [value]="null">-- Tipo prodotto --</mat-option>
                                <mat-option *ngFor="let option of avaTipo[i]" [value]="option.id">
                                    {{option.descrizione}}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="text-danger" *ngIf="san.tiposanitariId && !san.tipoprodottoId">
                                Campo obbligatorio
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-1 align-self-center" *ngIf="form.sections.nuoviSanitariDto.length>=1">
                        <button mat-icon-button color="warn" (click)="removeSan(i)">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
        
                </div>
                <div>
                    <button mat-flat-button class="{{brand === 'bagniHelp75' ? 'ochre' : 'azur'}}" (click)="addSan()">Aggiungi sanitario</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



