export class ServizioDettagli {
	id?: number;
	descrizione: string;
	costo?: number;
	ordineId: number;
	trascodificaId?: number;
  
	constructor() {
	  this.descrizione = '';
	}
  }
  