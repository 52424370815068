import { Component, Input, OnInit } from '@angular/core';
import { VascaDTO,Sections } from '../classes/dati-tecnici';
import { Trascodifica } from '../../../../classes/trascodifica'
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { VascheService } from '../services/vasche/vasce.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-vasche',
  templateUrl: './form-vasche.component.html',
  styleUrls: ['./form-vasche.component.scss']
})
export class FormVascheComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];

	listDimVascaVenezia : Trascodifica[];
	listDimEcube : Trascodifica[];
  listDimVascaRoma : Trascodifica[];
  listDimVascaVerona : Trascodifica[];
  listDimVascaFirenze : Trascodifica[]; 
  veronaSportello : Trascodifica[];
  firenzeSportello : Trascodifica[];
  firenzePannello : Trascodifica[];
  viewSedutaVerona:boolean = false;
  isVisible  = false;
  options: Trascodifica[];
  optionVasca: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};

  constructor(
    private vascheService : VascheService, 
    private formController : FormControllerService,
    private optionsService: OptionsAvailableService
  ) {
  }

  ngOnInit(): void {
		this.vascheService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {	
			this.mappingVascaDTOtoForm(res[0] , this.form.sections);
			this.listDimVascaVenezia = this.formController.getTrascodificaByCodice(constants.COD_VENEZIA_MISURA , this.trascodifiche);	
			this.listDimVascaRoma = this.formController.getTrascodificaByCodice( constants.COD_ROMA_MISURA, this.trascodifiche);	
			this.listDimVascaVerona = this.formController.getTrascodificaByCodice( constants.COD_VERONA_MISURA , this.trascodifiche);	
			this.listDimVascaFirenze = this.formController.getTrascodificaByCodice( constants.COD_FIRENZE_MISURA , this.trascodifiche);		
			this.veronaSportello = this.formController.getTrascodificaByCodice( constants.COD_VERONA_SPORTELLO , this.trascodifiche);
			this.firenzeSportello = this.formController.getTrascodificaByCodice( constants.COD_FIRENZE_SPORTELLO , this.trascodifiche);
			this.firenzePannello = this.formController.getTrascodificaByCodice( constants.COD_FIRENZE_PANNELLO, this.trascodifiche);
			this.listDimEcube = this.formController.getTrascodificaByCodice(constants.COD_ECUBE_MISURA , this.trascodifiche);

      this.options = this.formController.getTrascodificaByCodice( constants.TYPE_OPTIONS, this.trascodifiche).filter(op => op.descrizione === constants.TYPE_VASCA);

      this.getOption(this.options[0].id).then(res => {
        if (res !== undefined && res.length > 0) {
          this.isVisible = res[0].selected;
          this.form.sections.optionsAvailable.push(res[0])
  
          this.form.sections.optionsAvailable = this.form.sections.optionsAvailable.filter((res: any) => res.id != undefined && res.id != null)
          this.optionVasca = res[0];
        } else {
          this.isVisible = false;
          this.optionVasca = new OptionsAvailable();
          this.optionVasca.ordineId = this.form.idOrder;
          this.optionVasca.typeId = this.options[0].id;
          this.optionVasca.selected = undefined;
          this.form.sections.optionsAvailable.push(this.optionVasca);
        }
      })

		})

  }
  removeVascaVenezia(): void {
    delete this.form.sections.vascaVenezia.hydro;
    this.form.sections.vascaVenezia = {};
  }
	removeVascaEcube(): void {
		delete this.form.sections.vascaEcube.seduta;
		this.form.sections.vascaEcube = {};
	}
  setCodeVenezia(event): void {
    this.form.sections.vascaVenezia.code = event;
    if (this.form.sections.vascaVenezia.hydro) {
      this.form.sections.vascaVenezia.code = this.form.sections.vascaVenezia.code + this.form.sections.vascaVenezia.hydro;
    }
  }
  setCodeHVenezia(event): void {
    if (event === null) {
      this.form.sections.vascaVenezia.code = this.form.sections.vascaVenezia.dimensions;
    } else {
      this.form.sections.vascaVenezia.code = this.form.sections.vascaVenezia.dimensions + event;
    }
  }

  setCodeRoma(event): void {
    this.form.sections.vascaRoma.code = event;
    if (this.form.sections.vascaRoma.seduta) {
      this.form.sections.vascaRoma.code = this.form.sections.vascaRoma.code + '.' + this.form.sections.vascaRoma.seduta;
    }
  }
  setCodeSVenezia(event): void {
    this.form.sections.vascaRoma.code = event;
    if (event === null) {
      this.form.sections.vascaRoma.code = this.form.sections.vascaRoma.dimensions;
    } else {
      this.form.sections.vascaRoma.code = this.form.sections.vascaRoma.dimensions + '.' + event;
    }
  }

  setCodeVerona(event): void {
    this.form.sections.vascaVerona.code = event;
    if (this.form.sections.vascaVerona.scarico) {
      this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.dimensions + '.' + this.form.sections.vascaVerona.scarico;
    }
    if (this.form.sections.vascaVerona.sportello) {
      // tslint:disable-next-line:max-line-length
      this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.dimensions + '.' + this.form.sections.vascaVerona.scarico + '.'  + this.form.sections.vascaVerona.sportello;
    }
	if(this.form.sections.vascaVerona.seduta){
		this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.dimensions + '.' + this.form.sections.vascaVerona.scarico + '.'  + this.form.sections.vascaVerona.sportello+'.'+this.form.sections.vascaVerona.seduta;
	}
	this.checkSedutaVerona(event);
  }
  setCodeSVerona(event): void {
    this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.dimensions + '.' + event;
    if (this.form.sections.vascaVerona.sportello) {
      this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.code + '.'  + this.form.sections.vascaVerona.sportello;
    }
  }
  setCodeSpVerona(event): void {
    // tslint:disable-next-line:max-line-length
    this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.dimensions + '.' + this.form.sections.vascaVerona.scarico + '.' + event;
  }

  setCodeSedVerona(event):void {
		this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.dimensions + '.' + this.form.sections.vascaVerona.sportello + '.' + this.form.sections.vascaVerona.scarico + '.' + event;
   }
  
  removeCodeSedVerona(event): void {
    if (!event) {
      this.form.sections.vascaVerona.seduta = '';
      this.form.sections.vascaVerona.code = this.form.sections.vascaVerona.dimensions + '.' + this.form.sections.vascaVerona.sportello + '.' + this.form.sections.vascaVerona.scarico
    }
  }

  setCodeFirenze(event): void {
    this.form.sections.vascaFirenze.code = event;
    if (this.form.sections.vascaFirenze.sportello) {
      this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.code + '.' + this.form.sections.vascaFirenze.sportello;
    }
    if (this.form.sections.vascaFirenze.pannello) {
      this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.code + '.' + this.form.sections.vascaFirenze.pannello;
    }
    if (this.form.sections.vascaFirenze.hydro) {
      this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.code + '.' + this.form.sections.vascaFirenze.hydro;
    }
  }
  setCodeSpFirenze(event): void {
    this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.dimensions + '.' + event;
    if (this.form.sections.vascaFirenze.pannello) {
      this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.code + '.' + this.form.sections.vascaFirenze.pannello;
    }
    if (this.form.sections.vascaFirenze.hydro) {
      this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.code + '.' + this.form.sections.vascaFirenze.hydro;
    }
  }

  setCodePnFirenze(event): void {
    this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.dimensions + '.' +
        this.form.sections.vascaFirenze.sportello + '.' +
        event;
    if (this.form.sections.vascaFirenze.hydro) {
      this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.code + '.' + this.form.sections.vascaFirenze.hydro;
    }
  }
  setCodeIFirenze(event): void {
    this.form.sections.vascaFirenze.code = this.form.sections.vascaFirenze.dimensions + '.' +
        this.form.sections.vascaFirenze.sportello + '.' +
        this.form.sections.vascaFirenze.pannello + '.' +
        event;
  }


  public mappingVascaDTOtoForm(dto:VascaDTO , form:Sections):Sections{
		if(dto !== undefined){
			form.vascaVenezia.id = dto.id;
			form.vascaRoma.id = dto.id;
			form.vascaVerona.id = dto.id;
			form.vascaFirenze.id = dto.id;
			
			//venezia 
			form.vascaVenezia.dimensions = dto.veneziamisuraId;
			form.vascaVenezia.hydro = dto.veneziaidro; // === true ? 'IDROSI' : 'IDRONO';
			
			//roma
			form.vascaRoma.dimensions = dto.romamisuraId;
			if(dto.romasedutasx === true){
				form.vascaRoma.seduta = constants.TYPE_SEDUTASX
			}else if(dto.romasedutadx === true){
				form.vascaRoma.seduta = constants.TYPE_SEDUTADX
			}
			//vascaEcube
			form.vascaEcube.dimensions = dto.ecubemisuraId;
			if(dto.ecubesedutadx === true){
				form.vascaEcube.seduta = constants.TYPE_SEDUTADX
			}else if(dto.ecubesedutasx === true){
				form.vascaEcube.seduta = constants.TYPE_SEDUTASX;
			}
			
			//verona
      form.vascaVerona.veronaseduta = dto.veronaseduta;
			form.vascaVerona.dimensions = dto.veronamisuraId;
			if(dto.veronascaricosx === true){
				form.vascaVerona.scarico = constants.TYPE_SCARICOSX;
			}else if(dto.veronascaricodx === true){
				form.vascaVerona.scarico = constants.TYPE_SCARICODX;
			}
			form.vascaVerona.sportello = dto.veronasportelloId;
			if(dto.veronasedutasx === true){
              form.vascaVerona.veronaseduta = true;
              form.vascaVerona.seduta = constants.TYPE_SEDUTASX;
			}else if (dto.veronasedutadx === true){
              form.vascaVerona.veronaseduta = true;
              form.vascaVerona.seduta = constants.TYPE_SEDUTADX ;
			}

			this.checkSedutaVerona(form.vascaVerona.dimensions);
			//firenze 
			form.vascaFirenze.dimensions = dto.firenzemisuraId;
			form.vascaFirenze.sportello = dto.firenzesportelloId;
			form.vascaFirenze.pannello = dto.firenzepannelloId;
      form.vascaFirenze.firenzepannellocentro = dto.firenzepannellocentro;
      form.vascaFirenze.firenzepannellodx = dto.firenzepannellodx;
      form.vascaFirenze.firenzepannellosx = dto.firenzepannellosx;
      form.vascaFirenze.firenzepannelloattr = dto.firenzepannelloattr;
      form.vascaFirenze.misurapassaggioporta = dto.misurapassaggioporta;
			form.vascaFirenze.hydro = dto.firenzeidro; // === true // ? 'IDROSI' : 'IDRONO';
		}

		
		return form;
  }

	checkSedutaVerona(event){
		this.viewSedutaVerona = this.formController.checkDimensionsVerona(event , this.trascodifiche);
		if(this.viewSedutaVerona === false){
			this.form.sections.vascaVerona.seduta = {};
		}
	}
  
  validVascaRoma(): boolean {
    if (this.form.sections.vascaRoma.dimensions) {
      if (this.form.sections.vascaRoma.dimensions === 107 ||
          this.form.sections.vascaRoma.dimensions === 101 ||
          this.form.sections.vascaRoma.dimensions === 109 ||
          this.form.sections.vascaRoma.dimensions === 110){
        return true;
      }
      if (this.form.sections.vascaRoma.dimensions === 1337 ||
          this.form.sections.vascaRoma.dimensions === 1339 ||
          this.form.sections.vascaRoma.dimensions === 100 ||
          this.form.sections.vascaRoma.dimensions === 102){
        if(this.form.sections.vascaRoma.seduta){
          return true;
        }else{
          return false;
        }
      }
      if (this.form.sections.vascaRoma.dimensions === 1338 ||
          this.form.sections.vascaRoma.dimensions === 1340 ||
          this.form.sections.vascaRoma.dimensions === 103 ||
          this.form.sections.vascaRoma.dimensions === 104 ||
          this.form.sections.vascaRoma.dimensions === 105 ||
          this.form.sections.vascaRoma.dimensions === 106 ||
          this.form.sections.vascaRoma.dimensions === 108){
        if (this.form.sections.vascaRoma.seduta){
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  showDiv(value) {
    if (value) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }

    this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;
  }

  getOption(idOption:any):Promise<any>{

    return new Promise((resolve, reject) => {
      this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
        if(res !== undefined){
            let option = res.filter(op => op.typeId === idOption)  
            resolve(option);				          
          }
      });
      });
    }
}
