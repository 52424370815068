import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { ServiziPrintService } from 'src/app/services/servizi-print/servizi-print.service';
import { Component, OnInit } from '@angular/core';
import autoTable from 'jspdf-autotable'
import jsPDF from "jspdf";
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';

@Component({
  selector: 'app-servizi-print',
  templateUrl: './servizi-print.component.html',
  styleUrls: ['./servizi-print.component.scss']
})
export class ServiziPrintComponent  {

  doc: jsPDF;
  creatingPdf: boolean = false;
  currentPdfPage: number = 1;
  pageNumber: number = 1;
  pageWidth: number = 297;
  yStartPage: number = 28; 
  img: string;
  title: string;
  ordine: any;
  pagamento: any;
  brand: string;

  color: number[];

  constructor(
    public serviziPrintService: ServiziPrintService,
    public formController: FormControllerService, 
  ) { }

  initPdf(ordine:any, img: string, anagrafica: any, title: string, color?: string, brand?:string, product?:string): number {
    this.creatingPdf = true;

    this.serviziPrintService.setCurrentPage(this.pageNumber);
    this.doc = new jsPDF();
    this.pageWidth = this.doc.internal.pageSize.getWidth();

    this.img = img;
    this.title = title;
    this.brand = brand;
    this.serviziPrintService.createHeader(this.doc, this.img, this.title, this.brand);
    
    let y = this.yStartPage;

    y = this.serviziPrintService.createDocumentInfos(
      this.doc,
      y,
      this.pagamento,
      0,
      ordine,
      this.img,
      this.title,
      this.currentPdfPage,
      this.color
    );

    y = this.createLine(y);

    this.createTableAnagrafica(this.doc, anagrafica, y, color, brand);

    y = this.serviziPrintService.returnCurrentY();

    this.createTableIndirizzo(this.doc, anagrafica, y, color, brand);

    y = this.serviziPrintService.addLine(
      y + 5,
      this.doc,
      ordine,
      this.img,
      this.title
    );
    y = this.serviziPrintService.returnCurrentY();
    this.serviziPrintService.writeFooterHeader(this.doc, y, ordine);
    y = this.serviziPrintService.returnCurrentY();

    y = this.serviziPrintService.addLine(y, this.doc, ordine, this.img, this.title);
    return y;
  }


  createLine(y: number): number {
    y = this.serviziPrintService.addLine(
      y,
      this.doc,
      this.ordine,
      this.img,
      this.title
    );
    this.serviziPrintService.setCurrentY(y);
    return y;
  }

  createTableAnagrafica(doc?: any, anagrafica?: any, y?: number, color?: string, brand?: string): void {
    const rows = [
      [['CLIENTE'], [anagrafica?.nome + ' ' + anagrafica?.cognome]],
      ((anagrafica?.ragionesociale && anagrafica?.ragionesociale?.trim() !== '') ? [['RAGIONE SOCIALE'], [anagrafica.ragionesociale]] : null),
      [['EMAIL'], [anagrafica.email]],
      [[anagrafica.cf.length === 16 ? 'CODICE FISCALE' : 'P.IVA'], [anagrafica.cf]],
      [['TELEFONO'], [anagrafica.tel1]],
    ].filter(row => row !== null);

    if (anagrafica.tel2) {
      let cell = [['TELEFONO 2'], [anagrafica.tel2]];
      rows.push(cell);
    }

    autoTable(doc, {
      theme: 'grid',
      margin: { top: 5, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      bodyStyles: brand === constants.BRAND_LOREN ? 
        { lineWidth: constants.LOREN_TABLE_LINE_THICKNESS, lineColor: 'black' } : null,
      head: [[{ content: 'CAPITOLATO FORNITURA', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.serviziPrintService.returnCurrentY()
    });
    y = doc.lastAutoTable.finalY + 10
    this.serviziPrintService.setCurrentY(y)
  }

  createTableIndirizzo(doc?: any, anagrafica?: any, y?: number, color?: string, brand?: string): void {
    const rows = [
      [["CITTA'"], [anagrafica.citta + ', ' + anagrafica.cap + ', ' + anagrafica.provincia]],
      [['INDIRIZZO'], [(!anagrafica.indirizzo.includes("via")) ? `via ${anagrafica.indirizzo} , ${anagrafica.numero}` : (anagrafica.indirizzo + ', ' + anagrafica.numero)]],
    ]
    if (anagrafica.ztl) {
      let cell = [['ZTL'], ['SI']];
      rows.push(cell);
    }
    if (anagrafica.diversoind && anagrafica.indirizzoinst) {
      let cell = [['INDIRIZZO INSTALLAZIONE'], [anagrafica.indirizzoinst]];
      rows.push(cell);
    }
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 5, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      bodyStyles: brand === constants.BRAND_LOREN ? 
      { lineWidth: constants.LOREN_TABLE_LINE_THICKNESS, lineColor: 'black' } : null,
      head: [[{ content: 'INDIRIZZO', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.serviziPrintService.returnCurrentY()
    });
    y = doc.lastAutoTable.finalY + 10
    this.serviziPrintService.setCurrentY(y)
  }

  serviziResponsabileGenericPrint(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
    y = this.serviziPrintService.returnCurrentY();

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];
    let columnNumber = 0;

    if (dati.length) {
        dati.forEach((el) => {
            rows.push([
              el.nome,
              el.tel
            ]);
        });

      columnNumber = 2;
      rows = rows.filter(field => field[0] !== undefined && field[0] !== null && field[0].toString() !== '');
    }

    if (rows.length) {
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: tableTitle, colSpan: columnNumber, styles: { halign: 'center' } }]],
        body: rows,
        startY: y,
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
        },
        didDrawPage: (data: any) => {
          this.serviziPrintService.createHeader(this.doc, this.img, this.title, this.brand);
          this.serviziPrintService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);

          if (data.pageNumber === data.pageCount) {
            isTableDrawn = true;
            isTableStartedNewPage = false;
          } else {
            isTableStartedNewPage = true;
            this.currentPdfPage++;
          }
        },
      });

      y = doc.lastAutoTable.finalY + 10;
      if (y > 265) {
        this.currentPdfPage++;
        this.serviziPrintService.setCurrentPage(this.currentPdfPage);
      }
    }
    this.serviziPrintService.setCurrentY(y);
  }

  serviziGeneric(doc?: any, dati?: any, tableTitle?: string, y?: number, trascodifiche?: Trascodifica[], color?: string): void {

    let lastCellY;
    let isTableDrawn = false;
    let isTableStartedNewPage = false;
    let rows = [];

    y = this.serviziPrintService.returnCurrentY();

      dati.forEach(item => {
        if (item.trascodificaId && item.ordineId ) {
          rows.push(
            [
              { content: this.getTrascodificaById(item.trascodificaId, trascodifiche, true), styles: { halign: 'center' } },
            ]);
        }else if(!item.trascodificaId && item.ordineId && item.descrizione?.trim() !== '' && item.costo){
          rows.push(
            [
              { content: `${item.descrizione} ${constants.LABEL_MOND_ALL_ACT_SERV} ${item.costo} ${constants.LABEL_MOND_ALL_ACT_SERV_IVA}`, styles: { halign: 'center' } },
            ]
           )        
          }
      })
    
    rows.unshift([{ content: tableTitle, colSpan: 1, styles: { halign: 'center', fillColor: color, textColor: 'white' } }]);

      if (rows.length > 1) {
        autoTable(doc, {
          theme: 'grid',
          margin: { top: 30, bottom: 10, left: 10, right: 10 },
          headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
          body: rows,
          startY: this.serviziPrintService.returnCurrentY(),
          didDrawCell: (data: any) => {
            lastCellY = data.cell.y + data.cell.height;
          },
          didDrawPage: (data: any) => {
            this.serviziPrintService.createHeader(this.doc, this.img, this.title, this.brand);
            this.serviziPrintService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);
    
            if (data.pageNumber === data.pageCount) {
              isTableDrawn = true;
              isTableStartedNewPage = false;
            } else {
              isTableStartedNewPage = true;
              this.currentPdfPage++;
            }
          },
        });

        y = doc.lastAutoTable.finalY + 10;
        if (y > 265) {
          this.currentPdfPage++;
          this.serviziPrintService.setCurrentPage(this.currentPdfPage);
        }
      }

    this.serviziPrintService.setCurrentY(y);
  }

    servizioPasswordAllarme(doc?: any, dati?: any, tableTitle?: string, y?: number, color?: string): void {
      y = this.serviziPrintService.returnCurrentY();
  
      let lastCellY;
      let isTableDrawn = false;
      let isTableStartedNewPage = false;
  
      let rows = [
        [["PASSWORD TUTTO OK"], [dati.passwOk]],
        [["PASSWORD SOTTOMINACCIA"], [dati.passwMinaccia]],
      ]
  
      rows = rows.filter(field => field[1] !== undefined && field[1] !== null && field[1].toString() !== '');
  
      if (rows.length) {
        autoTable(doc, {
          theme: 'grid',
          margin: { top: 30, bottom: 10, left: 10, right: 10 },
          headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
          head: [[{ content: tableTitle, colSpan: 2, styles: { halign: 'center' } }]],
          body: rows,
          startY: this.serviziPrintService.returnCurrentY(),
          didDrawCell: (data: any) => {
            lastCellY = data.cell.y + data.cell.height;
          },
          didDrawPage: (data: any) => {
            this.serviziPrintService.createHeader(this.doc, this.img, this.title, this.brand);
            this.serviziPrintService.writeFooterPage(doc, this.ordine, doc.internal.getNumberOfPages(), 6);
  
            if (data.pageNumber === data.pageCount) {
              isTableDrawn = true;
              isTableStartedNewPage = false;
            } else {
              isTableStartedNewPage = true;
              this.currentPdfPage++;
            }
          },
        });
  
        y = doc.lastAutoTable.finalY + 10;
        if (y > 265) {
          this.currentPdfPage++;
          this.serviziPrintService.setCurrentPage(this.currentPdfPage);
        }
        this.serviziPrintService.setCurrentY(y);
      }
    }


  getTrascodificaById(id: number, trascodifiche: Trascodifica[], descrizione: boolean, codice?: boolean): Trascodifica | string {
    
    if (descrizione) {
      const tr = trascodifiche.find(tr => tr.id === id);
      if(tr.note && tr.note?.trim() !== ''){
        return tr ? ( tr.note + tr.descrizione ) : {} as Trascodifica;
      }else{
        return tr ? tr.descrizione : {} as Trascodifica;
      }
    } else if (codice) {
      const tr = trascodifiche.find(tr => tr.id === id);
      return tr ? tr.codice : {} as Trascodifica;
    }else {
      return trascodifiche.find(tr => tr.id === id);
    }
  }
}
