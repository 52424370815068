export class Responsabile {
	id?: number;
	nome: string; 
	tel: string; 
	ordineId: number; 
  
	constructor(nome: string = '', tel: string = '') {
	  this.nome = nome;
	  this.tel = tel;
	}
  }
  