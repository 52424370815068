import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArielMainComponent } from './ariel-main/ariel-main.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from '../../material/material.module';
import { StufeComponent } from './ariel-main/stufe/stufe.component';
import { StufeTermoCaldaieComponent } from './ariel-main/stufe/stufe-termo-caldaie/stufe-termo-caldaie.component';
import { DatiAbitazioneComponent } from './ariel-main/stufe/dati-abitazione/dati-abitazione.component';
import { PaintableModule } from 'paintablejs/angular';
import { AccessoriStufaComponent } from './ariel-main/stufe/accessori-stufa/accessori-stufa.component';
import { SharedModule } from '../../shared/shared.module';
import { StufePrintComponent } from './ariel-main/stufe/print/print.component';
import { CaldaiaComponent } from './ariel-main/caldaia/caldaia.component';
import { CondizionatoreComponent } from './ariel-main/condizionatore/condizionatore.component';
import { PrintCaldaiaComponent } from './ariel-main/caldaia/print-caldaia/print-caldaia.component';
import { PrintCondComponent } from './ariel-main/condizionatore/print-cond/print-cond.component';
import { TipiCaldaieComponent } from './ariel-main/caldaia/tipi-caldaie/tipi-caldaie.component';
import { DettaglioCaldaiaComponent } from './ariel-main/caldaia/dettaglio-caldaia/dettaglio-caldaia.component';
import { DisegnoCaldaiaComponent } from './ariel-main/caldaia/disegno-caldaia/disegno-caldaia.component';
import { AbitazioneComponent } from './ariel-main/condizionatore/abitazione/abitazione.component';
import { InstallazioneAccessoriComponent } from './ariel-main/condizionatore/installazione-accessori/installazione-accessori.component';
import { DatiAbitazioneCaldComponent } from './ariel-main/caldaia/dati-abitazione-cald/dati-abitazione-cald.component';
import { AccessoriCaldComponent } from './ariel-main/caldaia/accessori-cald/accessori-cald.component';
import { PromoComponent } from './ariel-main/condizionatore/promo/promo.component';
import { SafePipe } from 'src/app/safe.pipe';
import { FotovoltaicoComponent } from './ariel-main/fotovoltaico/fotovoltaico.component';
import { TipologiaProdottoComponent } from './ariel-main/fotovoltaico/tipologia-prodotto/tipologia-prodotto.component';
import { PrintFotovoltaicoComponent } from './ariel-main/fotovoltaico/print-fotovoltaico/print-fotovoltaico.component';
import { PompacaloreComponent } from './ariel-main/pompacalore/pompacalore.component';
import { TipologiaComponent } from './ariel-main/pompacalore/tipologia/tipologia.component';
import { PrintPompacaloreComponent } from './ariel-main/pompacalore/print-pompacaolore/print-pompacalore.component';
import { FotovoltaicoUTComponent } from './ariel-main/fotovoltaico-ut/fotovoltaico-ut.component';
import { PrintFotovoltaicoUtComponent } from './ariel-main/fotovoltaico-ut/print-fotovoltaico-ut/print-fotovoltaico-ut.component';
import { DepuratoreAcquaComponent } from './ariel-main/depuratore-acqua/depuratore-acqua.component';
import { ModelloDepuratoreComponent } from './ariel-main/depuratore-acqua/modello-depuratore/modello-depuratore.component';
import { AccessoriDepuratoreComponent } from './ariel-main/depuratore-acqua/accessori-depuratore/accessori-depuratore.component';
import { PrintDepuratoreComponent } from './ariel-main/depuratore-acqua/print-depuratore/print-depuratore.component';

@NgModule({
  declarations: 
  	[ 
		ArielMainComponent, 
		StufeComponent, 
		StufeTermoCaldaieComponent, 
		DatiAbitazioneComponent, 
		AccessoriStufaComponent, 
		StufePrintComponent,
		CaldaiaComponent, 
		CondizionatoreComponent, 
		PrintCaldaiaComponent, 
		PrintCondComponent, 
		TipiCaldaieComponent, 
		DettaglioCaldaiaComponent, 
		DisegnoCaldaiaComponent, 
		AbitazioneComponent, 
		InstallazioneAccessoriComponent, 
		DatiAbitazioneCaldComponent, 
		AccessoriCaldComponent, 
		PromoComponent, 
		FotovoltaicoComponent, 
		TipologiaProdottoComponent,
		PrintFotovoltaicoComponent, 
		PompacaloreComponent, 
		TipologiaComponent, 
		PrintPompacaloreComponent, 
		FotovoltaicoUTComponent, 
		PrintFotovoltaicoUtComponent, 
		DepuratoreAcquaComponent,
		ModelloDepuratoreComponent, 
		AccessoriDepuratoreComponent,
		PrintDepuratoreComponent
	],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MaterialModule,
		PaintableModule,
		SharedModule,
	],
	providers: [
		SafePipe,
	  ]
})
export class ArielModule { }
