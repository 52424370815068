<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign"
            (click)="setStep(currentStep-1)"
            class="'btnMainColor' {{(currentStep==0 || currentStep==1 || currentStep==2|| currentStep==4|| currentStep==5|| currentStep==6|| currentStep==7|| currentStep==8|| currentStep==9|| currentStep==10) ? 'btnColorBlue':'btnColorRed'}}"
            mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="stepController()"
            class="'btnMainColor' {{(currentStep==0 || currentStep==1 || currentStep==2 || currentStep==4 || currentStep==5 || currentStep==6 || currentStep==7 || currentStep==8 || currentStep==9) ? 'btnColorBlue':'btnColorRed'}}"
            mat-flat-button (click)="saveModule()">Avanti >
        </button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(stepController()) || (!validMurettoSx() && currentStep === 1) || (!validMurettoDx() && currentStep === 1) || (!validMurettoDietro() && currentStep === 1) || (!validMurettoDavanti() && currentStep === 1) || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO BOX DOCCIA' , this.trascodifiche))
                || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO PIATTO DOCCIA' , this.trascodifiche))  || (currentStep === 6 && !this.formController.validPagamento(this.form, false))
                || (currentStep === 3 && !formController.validOption(this.form.sections.optionsAvailable,'VASCA' , this.trascodifiche)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 7 && !formController.validPrivacy(this.form))">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" src="assets/img/loghi/REMAIL.jpg" />
            </span>
            Modulo d'ordine vasca in doccia Remail
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3">
        <div id="toPrint">
            <div *ngIf="currentStep == 0">
                <app-anagrafica-sh [brand]="'remail'" [validAnagrafica]="validAnagrafica" [form]="form">
                </app-anagrafica-sh>
            </div>

            <div *ngIf="currentStep == 1">
                <div class="text-center mb-3">

                </div>
                <div>
                    <app-promo-bagni [brand]="'remail'" [form]="form" [trascodifiche]="trascodifiche"
                        [brand]="'remail'"></app-promo-bagni>
                    <app-datitecnicivasca [form]="form" [trascodifiche]="trascodifiche"></app-datitecnicivasca>
                </div>
            </div>

            <div *ngIf="currentStep == 2">
                <div class="text-center mb-3">

                </div>
                <div>
                    <div class="row originalRow">
                        <div class="col-lg-6">
                            <app-nuovidatitecnici [form]="form" [trascodifiche]="trascodifiche"></app-nuovidatitecnici>
                            <app-boxdoccia [form]="form" [trascodifiche]="trascodifiche"></app-boxdoccia>
                            <br>
                        </div>
                        <div class="col-lg-6">
                            <app-nuovopiattodoccia [form]="form" [trascodifiche]="trascodifiche">
                            </app-nuovopiattodoccia>
                        </div>
                    </div>

                    <div class="row originalRow">
                        <div class="col-lg-6">
                            <app-gres [form]="form" [trascodifiche]="trascodifiche"></app-gres>
                        </div>
                        <div class="col-lg-6">
                            <app-miscelatori [form]="form" [trascodifiche]="trascodifiche"></app-miscelatori>
                        </div>
                    </div>

                    <div class="row originalRow">
                        <div class="col-lg-12">
                            <div class="blueBg noteTitle"> NOTE </div>
                            <div class="anagraficaContainer">
                                <app-note-sh [nota]="form.ordine.note" (onNotaEmit)="form.ordine.note = $event"
                                    [maxLength]="255" [minLine]="3">
                                </app-note-sh>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="currentStep == 3">
                <div class="text-center mb-3">

                </div>
                <div class="row originalRow">
                    <div class="col-12">
                        <app-vasca [form]="form" [trascodifiche]="trascodifiche"></app-vasca>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <div class="text-center mb-3">

                </div>
                <div class="row">
                    <div class="col-12">
                        <app-disegno-tecnico [form]="form"></app-disegno-tecnico>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-omaggio [form]="form" [trascodifiche]="trascodifiche"></app-omaggio>


                        <div class="blueBg noteTitle"> NOTE </div>
                        <div class="anagraficaContainer">
                            <app-note-sh [nota]="form.sections.piattoDoccia.notelibere"
                                (onNotaEmit)="form.sections.piattoDoccia.notelibere = $event" [maxLength]="255"
                                [minLine]="3">
                            </app-note-sh>
                        </div>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 5">
                <div class="row">
                    <div class="col-12">
                        <app-accessori [form]="form" [trascodifiche]="trascodifiche"></app-accessori>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 6">
                <app-pay-one [form]="form" [brand]="'remail'" [trascodifiche]="trascodifiche"></app-pay-one>
            </div>

            <div class="lastStep" *ngIf="currentStep == 7">
                <app-privacy [form]="form" [brand]="'vasca'"></app-privacy>
            </div>

            <div *ngIf="currentStep == 8">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="" *ngIf="currentStep == 9 && isFinanziamentoPayment">
                <app-finanziamento [form]="form" [listFinanziarie]="listFinanziarie" [productColor]="'#00c0ff'"
                    [pdfDetails]="finanziamentoPdfHeader"></app-finanziamento>
            </div>

            <ng-container *ngIf="currentStep == 9 && !isFinanziamentoPayment; else nextStepWithFinance">
                <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
            </ng-container>

            <ng-template #nextStepWithFinance>
                <ng-container *ngIf="currentStep == 10">
                    <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
                </ng-container>
            </ng-template>

            <ng-template #signatureAndPrint>
                <div class="lastStep">
                    <div class="text-center">
                        <div class="digitalSignatureButtons" *ngIf="openSign" style="height: 124px">
                            <div class="signButton" (click)="edit()">
                                <span class="material-icons d-block">edit</span>
                                <span class="d-block mb-2">Modifica Contratto</span>
                            </div>
                        </div>
                        <div class="" *ngIf="form.idOrder">
                            <vasca-in-doccia-print [trascodifiche]="trascodifiche" [form]="form"
                                (reload)="reload($event)"></vasca-in-doccia-print>
                        </div>
                    </div>
                </div>
            </ng-template>

        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign"
            (click)="setStep(currentStep-1)"
            class="'btnMainColor' {{(currentStep==0 ||currentStep==1 || currentStep==2|| currentStep==4|| currentStep==5|| currentStep==6|| currentStep==7|| currentStep==8|| currentStep==9|| currentStep==10) ? 'btnColorBlue':'btnColorRed'}}"
            mat-flat-button>
            < Indietro </button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button
            [disabled]="generatingPdf ||  (stepController()) || (!validMurettoSx() && currentStep === 1) || (!validMurettoDx() && currentStep === 1) || (!validMurettoDietro() && currentStep === 1) || (!validMurettoDavanti() && currentStep === 1) || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO BOX DOCCIA' , this.trascodifiche)) 
            || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO PIATTO DOCCIA' , this.trascodifiche)) 
            || (currentStep === 7 && !formController.validPrivacy(this.form)) || (currentStep === 6 && !this.formController.validPagamento(this.form, false))
            || (currentStep === 3 && !formController.validOption(this.form.sections.optionsAvailable,'VASCA' , this.trascodifiche)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))"
            class="'btnMainColor' {{(currentStep==0 ||currentStep==1 || currentStep==2|| currentStep==4|| currentStep==5|| currentStep==6|| currentStep==7|| currentStep==8|| currentStep==9) ? 'btnColorBlue':'btnColorRed'}}"
            mat-flat-button (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(stepController()) || (!validMurettoSx() && currentStep === 1) || (!validMurettoDx() && currentStep === 1) || (!validMurettoDietro() && currentStep === 1) || (!validMurettoDavanti() && currentStep === 1) || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO BOX DOCCIA' , this.trascodifiche))
                || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO PIATTO DOCCIA' , this.trascodifiche))  || (currentStep === 6 && !this.formController.validPagamento(this.form, false))
                || (currentStep === 3 && !formController.validOption(this.form.sections.optionsAvailable,'VASCA' , this.trascodifiche)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 7 && !formController.validPrivacy(this.form))">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>

</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (!validAnagrafica && currentStep === step) || (!validMurettoSx() && currentStep === step) || (!validMurettoDx() && currentStep === step) || (!validMurettoDietro() && currentStep === step) || (!validMurettoDavanti() && currentStep === step) || (!validAccessori() && currentStep === step) || (currentStep === step && !formController.validPrivacy(this.form))
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="
            {
            'currentStep': currentStep == totalSteps.length
            }">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>

<div class="modal fade" id="timeoutModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Tempo limite generazione contratto</h3>
                <button type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Richiesta presa in carico, il nostro Customer Care provveder&agrave; ad inviare al cliente il
                    contratto per la firma.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-5  fidexPdf" *ngIf="trascodifiche && generatingPdf">
    <vasca-in-doccia-print [trascodifiche]="trascodifiche" [form]="form"></vasca-in-doccia-print>
</div>