<div class="row">
    <div class="col-12">
        <div class="text-center {{classBg}}">
            {{brand === 'bagniHelp75' ? 'ACCESSORI' : 'NUOVI ACCESSORI BAGNO la posizione degli accessori verrà decisa in corso d\'opera'}}
        </div>
        <div class="{{classContent}}">
            <div class="row">
            	<div class="col-12">
                     <mat-checkbox value="{{acc.id}}" class="inline" class="mb-2 mr-2"  *ngFor="let acc of availableAccessories;let i = index;" color="primary" (change)="updateAccessori($event,acc.id)"  
                     		[checked]="checked(acc.id)">
                     		{{acc.descrizione}}
                     </mat-checkbox>
                 </div>   
            </div>
        </div>
    </div>
</div>
