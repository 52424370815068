<ng-container>
    <div class="zucchettiMontascaleBg">KIT BASE ALLARME</div>
    <div class="zucchettiMontascaleCont">
        <div class="row align-items-center">
            <div class="col-sm">
                <span *ngFor="let desc of trascodificheModello">{{desc.descrizione}}<br></span>
            </div>
            <div class="col-sm">
                <span class="mr-2">Quantità </span>
                <app-input-number (onNumChange)="form.sections.allarme.qta = $event" [step]="1"
                    [num]="form.sections.allarme.qta" [decimal]="false" [payment]="false" [small]="true" label="">
                </app-input-number>
            </div>
        </div>
    </div>
</ng-container>