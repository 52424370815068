import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from "../../../../../services/controller/form-controller.service";
import { OrdersService } from 'src/app/services/orders/orders.service';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { PrintComponent } from 'src/app/modules/shared/pdf/print/print.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PreviewComponent } from 'src/app/modules/shared/pdf/preview/preview.component';
import { MatDialog } from '@angular/material/dialog';
import { constants } from 'src/app/modules/classes/constants';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { MontascaleDto } from 'src/app/modules/classes/zucchetti/montascale/montascale';
import { MontascaleService } from 'src/app/services/montascale/montascale.service';
import { DisegnoTecnicoService } from 'src/app/services/disegnoTecnico/disegno-tecnico.service';
import { DisegnoTecnico } from 'src/app/modules/classes/disegno-tecnico';
import { DatiTecniciAttService } from '../../../bagni-italiani/main-bagni/services/datiTecniciAtt/datiTecniciAtt.service';
import { DatiTecniciDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { MontascaleMisureService } from 'src/app/services/montascale-misure/montascale-misure.service';
import { MontascaleMisureDto } from 'src/app/modules/classes/zucchetti/montascale/montascaleMisure';
import { HttpService } from 'src/app/services/http.service';
declare var jQuery: any;

@Component({
  selector: 'app-print-montascale',
  templateUrl: './print-montascale.component.html',
  styleUrls: ['./print-montascale.component.scss']
})
export class PrintMontascaleComponent extends PrintComponent implements OnInit {

  @Input() idOrder: number;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() anagrafica: any;
  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();

  generatingPdf: boolean = false;
  creatingPdf: boolean = false;
  validAnagrafica: boolean = false;
  loadAll: boolean = false;
  sendingPdf: boolean = false;
  openSign: boolean = false;
  showFrame: boolean = false;
  showModalPrint: boolean = false;

  privacyText: string = '';
  urlFrame: string = '';
  colorString: string = '#0a2e5e';

  desiredWidth: number;
  proportionalHeight: number;
  timeLeft: number = 60;
  hTotalPageSpace: number = 280;

  rgbColorLine: number[] = [10, 46, 94];
  idOptionalHomeGlideTrascodifiche: number[] = [];
  idOptionalFlowXOptSedileTrascodifiche: number[] = [];
  idOptionalFlowXVarieTrascodifiche: number[] = [];

  montascale: MontascaleDto;
  montascaleMisure: MontascaleMisureDto;
  disegnoTecnico: DisegnoTecnico;
  datiTecnici: DatiTecniciDTO;

  ordine: any;
  pagamento: any;
  error: any;
  member: any;
  disegno: any;
  optionalHomeGlide: any;
  optionalFlowXOptSedile: any;
  optionalFlowXVarie: any;

  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;
  constructor(
    private router: Router,
    public formController: FormControllerService,
    private orderService: OrdersService,
    public dialog: MatDialog,
    private optionsAvailableService: OptionsAvailableService,
    private montascaleService: MontascaleService,
    private montascaleMisureService: MontascaleMisureService,
    private datiTecniciAttService: DatiTecniciAttService,
    private disegnoTecnicoService: DisegnoTecnicoService,
    private paymentService: PaymentService,
    public printService: PrintserviceService,
    private httpService: HttpService,
  ) {
    super(printService);
  }

  ngOnInit(): void {
    this.getOrder(this.idOrder);
    this.checkAnagrafica();
    this.getMontascale();
    this.getTranscodingDivision();
    this.getPagamento();
    this.getPrivacy();
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
    });
  }

  generatePdf(firma: boolean): void {
    let y = this.initPdf(this.ordine, this.anagrafica, '/assets/img/loghi/ZUCCHETTI.jpg', 'Modulo d\'ordine montascale Zucchetti', this.colorString, constants.BRAND_ZUCCHETTI);

    this.printService.writeFooterPage(
      this.doc,
      this.ordine,
      this.currentPdfPage,
      6
    );

    if (this.optionalHomeGlide.length) {
      const sectionTitle = 'HOME GLIDE (rettilineo) STANDARD PACK - OPTIONAL';
      const sectionSubtitle = 'Inclusi di 5 m. guida poltroncina, rivestimento ariaio, iovstick su bracciolo e 2 stazioni di chiamata e invio infrarossi';
      this.optionGenericPrintZucchetti(this.doc, this.optionalHomeGlide, sectionTitle, sectionSubtitle, y, this.trascodifiche, this.colorString);
    }

    if (this.montascale) {
      const sectionTitle = 'FLOW XoptionGenericPrintZucchetti (curvilineo) STANDARD PACK - OPZIONI SISTEMA';
      const sectionSubtitle = 'Inclusi di 6 m. guida 1 curva 90 gradi, poltroncina con sistema di auto rotazione nelle fermate, sedile regolabile e poggiapiedi';
      this.flowXCurvilineoStandarPack(this.doc, this.montascale, sectionTitle, sectionSubtitle, y, this.colorString);
    }

    if (this.optionalHomeGlide.length) {
      const sectionTitle = 'FLOW X (curvilineo) STANDARD PACK - OPTIONAL SEDILE';
      this.optionGenericPrintZucchetti(this.doc, this.optionalFlowXOptSedile, sectionTitle, null, y, this.trascodifiche, this.colorString);
    }

    if (this.montascale) {
      const sectionTitle = 'FLOW X (curvilineo) STANDARD PACK - OPTIONAL GUIDA';
      this.optionalGuidaMontascale(this.doc, this.montascale, sectionTitle, y, this.colorString);
    }

    if (this.optionalHomeGlide.length) {
      const sectionTitle = 'FLOW X (curvilineo) STANDARD PACK - VARIE';
      let subColumns = ['Descrizione', 'Quantità'];
      this.optionGenericPrintZucchetti(this.doc, this.optionalFlowXVarie, sectionTitle, null, y, this.trascodifiche, this.colorString, constants.OPTIONAL_FLOW_X_VARIE, subColumns);
    }

    if (this.montascale && this.montascale.note && this.montascale.note.trim() !== '') {
      const title = [constants.LABEL_PRINT_NOTE_LIBERE]
      this.createTableTextNote(title, this.montascale.note, y, this.doc, this.rgbColorLine);
    }

    y = this.printService.returnCurrentY();
    if (this.montascale && this.montascale.arrivobassoguidaId) {
      this.printArriviDallaGuida(y, this.montascale, 'DOWN', Number(this.montascale.arrivobassoguidaId))
    }

    y = this.printService.returnCurrentY();
    if (this.montascale && this.montascale.arrivoaltoguidaId) {
      this.printArriviDallaGuida(y, this.montascale, 'TOP', Number(this.montascale.arrivoaltoguidaId))
    }

    if (this.montascale && this.montascale.noteliberetipoarrivo && this.montascale.noteliberetipoarrivo.trim() !== '') {
      const title = [constants.LABEL_PRINT_NOTE_LIBERE]
      this.createTableTextNote(title, this.montascale.noteliberetipoarrivo, y, this.doc, this.rgbColorLine);
    }

    y = this.printService.returnCurrentY();
    if (this.datiTecnici) {
      const sectionTitle = constants.LABEL_DATI_TECNICI_ATTUALI;
      this.datiTecniciAttualiMontascale(this.doc, this.datiTecnici, sectionTitle, y, this.colorString);
    }

    if (this.montascaleMisure) {
      this.printRilievoMisuraSchienaESedile(y);
    }

    if (this.checkDisegnoTecnico() && this.disegno) {
      const tableTitle = 'DISEGNO TECNICO'
      this.genericPrintImages(this.doc, this.printService.returnCurrentY(), this.disegno, null, tableTitle, null, this.desiredWidth, this.proportionalHeight, this.colorString, this.rgbColorLine);
    }

    y = this.printService.returnCurrentY();

    if (this.pagamento) {
      this.createTablePagamento(this.doc, this.pagamento, this.ordine, y, 0, this.colorString);
    }

    y = this.printService.addNewPage(
      this.doc,
      true,
      this.img,
      this.ordine,
      this.currentPdfPage,
      this.title
    );

    this.printService.writePrivacyOne(
      this.doc,
      y,
      this.ordine,
      this.img,
      this.title,
      this.currentPdfPage,
      this.privacyText
    );
    y = this.printService.returnCurrentY();

    this.creatingPdf = false;
    if (firma) {
      if (!this.integratedSignature) {
        this.createPdf(this.doc);
      } else {
        this.toggleSignPdf(this.doc);
      }
    } else {
      const pdfBlob = this.doc.output('blob');

      // Crea un URL dall'oggetto Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      this.openDialog(pdfUrl);
    }
  }


  // HTTP Methods
  async getTranscodingDivision(): Promise<void> {
    try {
      const filterAndFetch = async (ids: number[], constant: string) => {
        if (ids.length > 0 && constant === constants.OPTIONAL_HOME_GLIDE) {
          const response = await this.optionsAvailableService
            .findByKey(' ', `typeId.in=${ids.join(',')}&ordineId.equals=${this.form.idOrder}`)
            .toPromise();

          this.optionalHomeGlide = response;
        }
        if (ids.length > 0 && constant === constants.OPTIONAL_FLOW_X_OPT_SEDILE) {
          const response = await this.optionsAvailableService
            .findByKey(' ', `typeId.in=${ids.join(',')}&ordineId.equals=${this.form.idOrder}`)
            .toPromise();

          this.optionalFlowXOptSedile = response;
        }
        if (ids.length > 0 && constant === constants.OPTIONAL_FLOW_X_VARIE) {
          const response = await this.optionsAvailableService
            .findByKey(' ', `typeId.in=${ids.join(',')}&ordineId.equals=${this.form.idOrder}`)
            .toPromise();

          this.optionalFlowXVarie = response;
        }
      };

      for (const el of this.trascodifiche) {
        switch (el.codice) {
          case constants.OPTIONAL_HOME_GLIDE:
            this.idOptionalHomeGlideTrascodifiche.push(el.id);
            break;
          case constants.OPTIONAL_FLOW_X_OPT_SEDILE:
            this.idOptionalFlowXOptSedileTrascodifiche.push(el.id);
            break;
          case constants.OPTIONAL_FLOW_X_VARIE:
            this.idOptionalFlowXVarieTrascodifiche.push(el.id);
            break;
        }
      }

      await Promise.all([
        filterAndFetch(this.idOptionalHomeGlideTrascodifiche, constants.OPTIONAL_HOME_GLIDE),
        filterAndFetch(this.idOptionalFlowXOptSedileTrascodifiche, constants.OPTIONAL_FLOW_X_OPT_SEDILE),
        filterAndFetch(this.idOptionalFlowXVarieTrascodifiche, constants.OPTIONAL_FLOW_X_VARIE),
      ]);

    } catch (error) {
      console.error('Errore durante il recupero delle opzioni:', error);
    }
    this.getDatiTecniciAttuali();
    this.getDisegnoTecnico();
  }

  private getDatiTecniciAttuali(): void {
    this.datiTecniciAttService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      this.datiTecnici = res[0];
    });
  }

  private getDisegnoTecnico(): void {
    this.disegnoTecnicoService.findByKey(this.idOrder, 'ordineId.equals=').subscribe((res) => {
      this.disegnoTecnico = res[0];
      if (this.disegnoTecnico)
        this.disegno = "data:" + this.disegnoTecnico.disegnoContentType + ";base64," + this.disegnoTecnico.disegno;

      const img = new Image();
      img.src = this.disegno;
      img.onload = () => {
        const originalWidth = img.width;
        const originalHeight = img.height;

        this.desiredWidth = 70; // Larghezza desiderata in unità del PDF
        this.proportionalHeight = (this.desiredWidth * originalHeight) / originalWidth;
      };
    });
  }

  private getMontascale(): void {
    this.montascaleService.findByKey(this.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res[0] !== undefined) {
        this.montascale = res[0];
      }
    });
    this.getMontascaleMisure();
  }

  private getMontascaleMisure(): void {
    this.montascaleMisureService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res[0]) {
        this.montascaleMisure = res[0];
      }
    });
  }

  private getPagamento(): void {
    this.paymentService.findByKey(this.idOrder, 'ordineId.equals=').subscribe((res) => {
      this.pagamento = res;
    });
    this.loadAll = true;
  }

  private getPrivacy(): void {
    this.httpService.getPrivacy('assets/json/montascale.json').subscribe((res) => {
      this.privacyText = res;
    });
  }

  // Print methods
  private printArriviDallaGuida(y: number, montascale: MontascaleDto, positionGuida: string, idTrascodifica: number): void {

    let baseY: number = 0;
    let calculateY: number = 0;
    let hImage: number = 60;
    let wImage: number = 40;
    let padding: number = 10;
    let miniPadding: number = 5;
    let objTrascodifica = this.formController.getTrascodificaById(idTrascodifica, this.trascodifiche);
    let obj: { value: number, descrizione: string } = { value: 0, descrizione: '' };

    if (positionGuida === 'DOWN') {
      obj.value = montascale.arrivobassevalue;
      obj.descrizione = objTrascodifica.descrizione;
    } else if (positionGuida === 'TOP') {
      obj.value = montascale.arrivoaltoguidavalue;
      obj.descrizione = objTrascodifica.descrizione;
    }

    const checkSpazio: number = (this.printService.returnCurrentY() + hImage + padding);
    if (checkSpazio > this.hTotalPageSpace) {  //Simulo la scrittura della sezione, per vedere se c'e spazio per inserirla in questa pagina
      this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage + 1, '');
      this.printService.setCurrentY(28);
    }

    y = this.printService.returnCurrentY();
    let sectionTitle = positionGuida === 'TOP' ? `ARRIVO IN ALTO DELLA GUIDA` : `ARRIVO IN BASSO DELLA GUIDA`;
    this.genericOnlyTitleCenter(this.doc, (y - miniPadding), sectionTitle, this.colorString);
    baseY = y;

    y = this.printService.returnCurrentY();

    this.printService.writeLine(
      this.doc, 9, y + 5, `TIPOLOGIA: ${this.insertValueToTextUnitMeasure(obj.value, obj.descrizione)}`, 110);
    this.printService.addLine(y);

    this.doc.addImage(objTrascodifica.note, "PNG", 48, (baseY + miniPadding), wImage, hImage, "", "SLOW");
    calculateY += (hImage + miniPadding);
    ((calculateY) < 25) ? (y = baseY + 25) : (y = baseY + calculateY)
    this.printService.setCurrentY(y += 20);
  }

  private printRilievoMisuraSchienaESedile(y: number): void {
    let baseY = 0;
    let calculateY = 0;
    let hImages = 55;
    let wImages = 40;
    let padding = 10;

    const hasDataMisureSchienaESedile = [
      { label: "Schiena al ginocchio", value: this.montascaleMisure?.distanzaschienaginocchio ? this.addUnitOfMeasure(this.montascaleMisure?.distanzaschienaginocchio, 'cm') : 'non definita', urlImg: "assets/img/zucchetti/schiena1.jpg", xPositionText: 15, xPositionImage: 18 },
      { label: "Sedile alla testa", value: this.montascaleMisure?.distanzasediletesta ? this.addUnitOfMeasure(this.montascaleMisure?.distanzasediletesta, 'cm') : 'non definita', urlImg: "assets/img/zucchetti/schiena2.jpg", xPositionText: 85, xPositionImage: 85 },
      { label: "Schiena alla fronte", value: this.montascaleMisure?.distanzaschienafronte ? this.addUnitOfMeasure(this.montascaleMisure?.distanzaschienafronte, 'cm') : 'non definita', urlImg: "assets/img/zucchetti/schiena3.jpg", xPositionText: 145, xPositionImage: 150 },
    ]

    const checkSpazio: number = (this.printService.returnCurrentY() + hImages + padding);
    if (checkSpazio > this.hTotalPageSpace) {  //Simulo la scrittura della sezione, per vedere se c'e spazio per inserirla in questa pagina
      this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage + 1, '');
      this.printService.setCurrentY(28);
    }

    y = this.printService.returnCurrentY();

    // controllo che ci sia almeno 1 campo valido per stampare il titolo sezione
    if (this.checkValueMontascaleMisureRequiredField()) {

      let sectionTitle = `RILIEVO MISURE SCHIENA E SEDILE`;
      this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);
      baseY = y;
      y = this.printService.returnCurrentY();


      // controllo se i campi con l'immagine sono validi per essere stampati
      if (this.checkValueMontascaleMisureImg()) {

        y += 20;
        hasDataMisureSchienaESedile.forEach(field => {
          this.doc.addImage(field.urlImg, "PNG", field.xPositionImage, baseY + 5, wImages, hImages, "", "SLOW");
          this.printService.addLine(baseY);
          this.printService.writeLine(
            this.doc, 9, (baseY + hImages + padding), `${field.label}: ${field.value}`, field.xPositionText);
        });

        y = this.printService.returnCurrentY();
        calculateY += 60; // + hImages
        y = baseY + calculateY;
        this.printService.setCurrentY(y += 20);
      }

      if (this.montascaleMisure?.pesocliente) {
        let xPositionText = 15
        y = this.printService.returnCurrentY();
        this.printService.addLine(y);
        this.printService.writeLine(
          this.doc, 9, y, `Peso del cliente: ${this.addUnitOfMeasure(this.montascaleMisure?.pesocliente, 'Kg')}`, xPositionText);
      }
      this.printService.setCurrentY(y += 20);
    }
  }

  private checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  private insertValueToTextUnitMeasure(value: number, text: string): string {
    if (text === constants.MONTASCALE_DRITTO_SUL_PIANEROTTOLO) {
      text = 'Dritto sul pianerottolo';
      let returnText = text + ' ' + (value ? value + ' mm' + ' ' : '');
      return this.capitalizeCase(returnText);
    }
    if (text === constants.MONTASCALE_STANDAR_SUL_PIANEROTTOLO_MINIMO_200MM) {
      text = 'Standard sul pianerottolo';
      let returnText = text + ' ' + (value ? value + ' mm' + ' ' : '');
      return this.capitalizeCase(returnText);
    }
    if (text === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ || text === constants.MONTASCALE_TIPO_ARRIVO_ALTO_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ) {
      text = 'Con curva di parcheggio';
      let text2 = 'orizzontale';
      let returnText = text + ' ' + (value ? (' a ' + value + ' °' + ' ') : '') + text2;
      return this.capitalizeCase(returnText);
    }
    if (text === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC) {
      text = 'Con curva di parcheggio';
      let text2 = 'verticale';
      let returnText = text + ' ' + (value ? (' a ' + value + ' °' + ' ') : '') + text2;
      return this.capitalizeCase(returnText);
    }
    return this.capitalizeCase(text);
  }

  private createPdf(pdf): void {
    this.sendingPdf = true;
    this.openSign = true;
    const title = localStorage.getItem("id_order");
    pdf.setProperties({
      title,
    });

    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.formController
      .sendSignature(
        newPdf,
        this.idOrder,
        this.form,
        pages,
        coordinates,
        title,
        false
      )
      .then(
        (res) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.router.navigate(["order-list"]);
        },
        (error) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.ordine.statopraticaCodice = "A";
          this.error = error;
          jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
          jQuery("#errorModal").modal("toggle");
        }
      );
  }

  togglePrintPreview(): void {
    this.generatePdf(false);
  }

  openDialog(pdf: any): void {
    this.dialog.open(PreviewComponent, {
      width: '800px',
      maxWidth: '100%',
      data: {
        pdf,
      },
    });
  }

  private toggleSignPdf(pdf): void {
    const interval = this.startTimer();
    const title = localStorage.getItem('id_order');
    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.showModalPrint = !this.showModalPrint;
    this.openSign = true;
    this.showFrame = false;
    this.generatingPdf = true;
    this.urlFrame = '';
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');

    if (this.isApiV3) {
      const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
      var split = this.printService.returnCoordinates().split(",");
      const coordinates = {
        width: 120,
        height: 37,
        x: split[0],
        y: split[3],
      }
      this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
        this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
        setTimeout(() => {
          this.showFrame = true;
          this.sendingPdf = false;
          this.generatingPdf = false;

        }, 2000);
        clearInterval(interval);
      }, error => {
        this.generatingPdf = false;
        this.sendingPdf = false;
        this.ordine.statopraticaCodice = "A";
        clearInterval(interval);
        this.closeSignPdf();
        jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#errorModal").modal("toggle");
      });
    } else {
      this.formController
        .sendSignature(
          newPdf,
          this.idOrder,
          this.form,
          pages,
          coordinates,
          title,
          true
        )
        .then(
          (res) => {
            this.member = res.members[0].id;
            this.urlFrame =
              environment.apiUrl +
              '/procedure/sign?members=' +
              this.member +
              '&signatureUi=' +
              environment.signMontascale; // "https://staging-app.yousign.com/procedure/sign?members="+this.member;
            this.showFrame = true;
            this.sendingPdf = false;
            this.generatingPdf = false;

            clearInterval(interval);
            // this.router.navigate(['order-list']);
          },
          (error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.statopraticaCodice = 'A';
            clearInterval(interval);
            jQuery('#errorModal').modal({ backdrop: 'static', keyboard: false });
            jQuery('#errorModal').modal('toggle');
          }
        );
    }

  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft === 0) {
        clearInterval(intervalId);
        // richiamo api per invio email
        this.orderService.sendEmail(this.form.idOrder).subscribe();
        jQuery('#timeoutModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#timeoutModal').modal('toggle');

        setTimeout(() => {
          jQuery('#timeoutModal').modal('hide');
        }, 10000);

        setTimeout(() => {
          this.formController.goToDashboard();
        }, 11000);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }

  returnIdOrdine(): string {
    return localStorage.getItem("id_order");
  }

  private checkDisegnoTecnico(): boolean {
    if (this.disegnoTecnico && this.disegnoTecnico?.disegno && this.disegnoTecnico.disegnoContentType) {
      return true;
    }
    return false;
  }

  private checkValueMontascaleMisureImg(): boolean {
    if (this.montascaleMisure.distanzaschienafronte || this.montascaleMisure.distanzaschienaginocchio || this.montascaleMisure.distanzasediletesta) {
      return true
    } else {
      return false;
    }
  }

  private checkValueMontascaleMisureRequiredField(): boolean {
    if (this.montascaleMisure.distanzaschienafronte || this.montascaleMisure.distanzaschienaginocchio || this.montascaleMisure.distanzasediletesta || this.montascaleMisure.pesocliente) {
      return true
    } else {
      return false;
    }
  }

  public closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    this.reload.emit(this.openSign);
    this.setStateToActive();
    this.formController.removeFrame();
  }

  private setStateToActive() {
    this.orderService.getSingleOrder(this.idOrder).subscribe((res) => {
      this.ordine = res;
      this.ordine.statopraticaCodice = "A";
      this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
        this.getOrder(this.idOrder);
      });
    })
  }
}
