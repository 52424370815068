<div class="row originalRow">
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                DETTAGLI INSTALLAZIONE
            </div>

            <div *ngIf="trascodifiche">
                <div class="mb-2">
                    <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.installazioneconpred"
                        [checked]="true" (ngModelChange)="controlloInstPred($event)">Installazione con
                        predisposizione</mat-checkbox>
                    <app-input-number (onNumChange)="form.sections.installazioneAcc.qtainstconpred = $event"
                        *ngIf="form.sections.installazioneAcc.installazioneconpred" [step]="1"
                        [num]="form.sections.installazioneAcc.qtainstconpred" [decimal]="false" [payment]="false"
                        label="Qt.à">
                    </app-input-number>
                    <div class="smallFont text-danger"
                        *ngIf="form.sections.installazioneAcc.installazioneconpred && !form.sections.installazioneAcc.qtainstconpred">
                        Campo obbligatorio
                    </div>
                </div>
                <div class="mb-5">
                    <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.installazionesenzapred"
                        [checked]="true" (ngModelChange)="controlloInstSenza($event)">Installazione senza
                        predisposizione</mat-checkbox>
                    <app-input-number (onNumChange)="form.sections.installazioneAcc.qtainstsenzapred = $event"
                        *ngIf="form.sections.installazioneAcc.installazionesenzapred" [step]="1"
                        [num]="form.sections.installazioneAcc.qtainstsenzapred" [decimal]="false" [payment]="false"
                        label="Qt.à">
                    </app-input-number>
                    <div class="smallFont text-danger"
                        *ngIf="form.sections.installazioneAcc.installazionesenzapred && !form.sections.installazioneAcc.qtainstsenzapred">
                        Campo obbligatorio
                    </div>

                </div>
                <div class="mb-2">
                    <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.staffeue"
                        (ngModelChange)="controlloStaffe($event)" [checked]="true">Staffe U.E</mat-checkbox>
                </div>

                <div class="row originalRow mt-3" *ngIf="form.sections.installazioneAcc.staffeue">
                    <div class="col-8">
                        <mat-form-field class="full-width">
                            <mat-label>Posizione U.E</mat-label>
                            <mat-select [(ngModel)]="form.sections.installazioneAcc.posizioneueId">
                                <mat-option>-- Posizione U.E --</mat-option>
                                <mat-option *ngFor="let option of listaPosizioneUE"
                                    [value]="option.id">{{option.descrizione}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-4">
                        <app-input-number (onNumChange)="form.sections.installazioneAcc.qtaue = $event" [step]="1"
                            [num]="form.sections.installazioneAcc.qtaue" [decimal]="false" [payment]="false"
                            label="Qt.à">
                        </app-input-number>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.installazioneAcc.posizioneueId && !form.sections.installazioneAcc.qtaue">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                ACCESSORI
            </div>
            <div *ngIf="trascodifiche">
                <div class="">
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.smontaggiovecchioimp"
                            [checked]="true">Smontaggio vecchio impianto</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" (ngModelChange)="controlloPompaSenza($event)"
                            [(ngModel)]="form.sections.installazioneAcc.pompacaricocondensa" [checked]="true">Pompa
                            scarico condensa</mat-checkbox>
                    </div>
                    <div class="mb-3" *ngIf="form.sections.installazioneAcc.pompacaricocondensa">
                        <app-input-number (onNumChange)="form.sections.installazioneAcc.numpompecondensa = $event"
                            [step]="1" [num]="form.sections.installazioneAcc.numpompecondensa" [decimal]="false"
                            [payment]="false" [small]="true" label="Qt.à">
                        </app-input-number>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.installazioneAcc.pompacaricocondensa && !form.sections.installazioneAcc.numpompecondensa">
                            Campo obbligatorio
                        </div>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.ponteggio"
                            [checked]="true">Ponteggio da Mt.3 a Mt.6</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.cestaidraulica"
                            [checked]="true">Cesta idraulica oltre Mt.6</mat-checkbox>
                        <div class="smallFont mb-3">
                            (verificare sempre che le aree di accesso all'abitazione presentino degli spazi adeguati al
                            passaggio e al posizionamento del mezzo)
                        </div>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.suoloPubblico"
                            [checked]="true">Suolo pubblico</mat-checkbox>
                    </div>

                    <div class="d-inline-block mr-2">Kit linea idraulica Mt.3, tubazione frigorifera + canalina pvc
                    </div>
                    <app-input-number (onNumChange)="form.sections.installazioneAcc.kitlineaidr = $event" [step]="1"
                        [num]="form.sections.installazioneAcc.kitlineaidr" [decimal]="false" [payment]="false"
                        [small]="true" label="Qt.à">
                    </app-input-number>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.aggiuntagas"
                            [checked]="true">Aggiunta gas</mat-checkbox>
                    </div>

                    <div class="d-inline-block mr-2">Fori muro</div>

                    <app-input-number class="mr-2" (onNumChange)="form.sections.installazioneAcc.forimuroqta = $event"
                        [step]="1" [num]="form.sections.installazioneAcc.forimuroqta" [decimal]="false"
                        [payment]="false" [small]="true" label="Qt.à">
                    </app-input-number>

                    <app-input-number (onNumChange)="form.sections.installazioneAcc.forimurocm = $event" [step]="1"
                        [num]="form.sections.installazioneAcc.forimurocm" [decimal]="false" [payment]="false"
                        [small]="true" label="cm">
                    </app-input-number>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="arielBg noteTitle">NOTE </div>
<div class="anagraficaContainer">
    <app-note-sh [nota]="form.sections.installazioneAcc.note"
        (onNotaEmit)="form.sections.installazioneAcc.note = $event" [maxLength]="255" [minLine]="3">
    </app-note-sh>
</div>