<div class="row originalRow mb-4">
    <div class="col-12">
      <div class="arielBg"> PROMOZIONE - VENDITA ABBINATA </div>
      <div class="anagraficaContainer">
        <div class="row originalRow mt-2">
          <!-- Seleziona promozione -->
          <div class="col-12 col-md-4 mb-3">
            <mat-form-field class="full-width"> 
              <mat-label>Seleziona promozione</mat-label> 
              <mat-select [(ngModel)]="this.form.ordine.promoId" (selectionChange)="onPromoChange($event)"> 
                <mat-option [value]="null">Seleziona promozione</mat-option> 
                <mat-option *ngFor="let tipo of availablePromo" [value]="tipo.id">{{ tipo.descrizione }}</mat-option>
              </mat-select> 
              <!-- <mat-hint class="text-danger" *ngIf="!this.form.ordine.promoId">Promozione campo obbligatorio</mat-hint> -->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3 mt-3 text-center">
            <mat-radio-group 
              labelPosition="after" 
              class="inline" 
              color="accent"
              [(ngModel)]="isPromoSelected" 
              (ngModelChange)="onPromoSelectedChange($event)" 
              [disabled]="!this.form.ordine.promoId"
            > 
              <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
              <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
            </mat-radio-group>
          </div>
  
          <div 
            class="col-12 col-md-5 mb-3" 
            *ngIf="this.form.ordine.promoId && availablePromo.length > 0"
          >
            <a>{{ returnPromoNote(this.form.ordine.promoId) }}</a>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="row originalRow">
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                Caldaia a condensazione ECO WIND
            </div>
            <div *ngIf="trascodifiche && MODCALDECOW">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-6">MODELLO</div>
                    <div class="col-6">TIPO</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let caldaia of MODCALDECOW; let i = index">
                    <div class="col-6">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeType($event, i)" [(ngModel)]="form.sections.caldaie[i].checked">{{caldaia.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-6 smalFt">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnTypeDescr($event, i)" [(ngModel)]="form.sections.caldaie[i].tipologiaId" [disabled]="!form.sections.caldaie[i].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of returnTypes(caldaia.id); let f = index;" [value]="tipo.id">
                                {{tipo.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i].checked && !form.sections.caldaie[i].tipologiaId">
                            selezione obbligatoria
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                Caldaia a condensazione ECO WIND DESIGN
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-5">MODELLO</div>
                    <div class="col-3">TIPO</div>
                    <div class="col-4">COLORE</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let caldaia of MODCALDECOWD; let i = index">
                    <div class="col-5">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeType($event, (i + MODCALDECOW.length))" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length].checked">{{caldaia.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-3 smalFt">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnTypeDescr($event, (i + MODCALDECOW.length))" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length].tipologiaId" [disabled]="!form.sections.caldaie[i + MODCALDECOW.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of returnTypes(caldaia.id); let f = index;" [value]="tipo.id">
                                {{tipo.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i + MODCALDECOW.length].checked && !form.sections.caldaie[i + MODCALDECOW.length].tipologiaId">
                            selezione obbligatoria
                        </div>
                    </div>
                    <div class="col-4 smallFont" *ngIf="!form.sections.caldaie[i + MODCALDECOW.length].tipologiaId">
                        Seleziona il tipo di caldaia
                    </div>
                    <div class="col-4 smalFt" *ngIf="form.sections.caldaie[i + MODCALDECOW.length].tipologiaId">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnColorDescr($event, (i + MODCALDECOW.length))" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length].coloreId" [disabled]="!form.sections.caldaie[i + MODCALDECOW.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let color of returnColors(form.sections.caldaie[i + MODCALDECOW.length].tipologiaId); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i + MODCALDECOW.length].checked && !form.sections.caldaie[i + MODCALDECOW.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="row originalRow">
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                Caldaia a condensazione ECO WIND AURUM
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-6">MODELLO</div>
                    <div class="col-6">TIPO</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let caldaia of MODCALDECOWAU; let i = index">
                    <div class="col-6">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeType($event, i + MODCALDECOW.length + MODCALDECOWD.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length].checked">{{caldaia.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-6 smalFt">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnTypeDescr($event, i + MODCALDECOW.length + MODCALDECOWD.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length].tipologiaId" [disabled]="!form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of returnTypes(caldaia.id); let f = index;" [value]="tipo.id">
                                {{tipo.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length].checked && !form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length].tipologiaId">
                            selezione obbligatoria
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                Caldaia a condensazione ECO WIND WALL
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-6">MODELLO</div>
                    <div class="col-6">TIPO</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let caldaia of MODCALDECOWWALL; let i = index">
                    <div class="col-6">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeType($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length].checked">{{caldaia.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-6 smalFt">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnTypeDescr($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length].tipologiaId" [disabled]="!form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of returnTypes(caldaia.id); let f = index;" [value]="tipo.id">
                                {{tipo.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length].checked && !form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length].tipologiaId">
                            selezione obbligatoria
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="row originalRow">

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
               Scalda acqua HOT WIND
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-6">MODELLO</div>
                    <div class="col-6">TIPO</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let caldaia of MODCALDHOTWIND; let i = index">
                    <div class="col-6">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeType($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length].checked">{{caldaia.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-6 smalFt">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnTypeDescr($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length].tipologiaId" [disabled]="!form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of returnTypes(caldaia.id); let f = index;" [value]="tipo.id">
                                {{tipo.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length].checked && !form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length].tipologiaId">
                            selezione obbligatoria
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                Caldaia a camera aperta
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-6">MODELLO</div>
                    <div class="col-6">TIPO</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let caldaia of MODCALDCAMAP; let i = index">
                    <div class="col-6">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeType($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length].checked">{{caldaia.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-6 smalFt">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnTypeDescr($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length].tipologiaId" [disabled]="!form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of returnTypes(caldaia.id); let f = index;" [value]="tipo.id">
                                {{tipo.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length].checked && !form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length].tipologiaId">
                            selezione obbligatoria
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div class="col-lg-6 mb-4">

    </div>

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                Caldaia a condensazione EASY
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-6">MODELLO</div>
                    <div class="col-6">TIPO</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let caldaia of MODCALDCONDENSEASY; let i = index">
                    <div class="col-6">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeType($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length + MODCALDCAMAP.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length + MODCALDCAMAP.length].checked">{{caldaia.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-6 smalFt">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnTypeDescr($event, i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length + MODCALDCAMAP.length)" [(ngModel)]="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length + MODCALDCAMAP.length].tipologiaId" [disabled]="!form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length + MODCALDCAMAP.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of returnTypes(caldaia.id); let f = index;" [value]="tipo.id">
                                {{tipo.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length + MODCALDCAMAP.length].checked && !form.sections.caldaie[i + MODCALDECOW.length + MODCALDECOWD.length + MODCALDECOWAU.length + MODCALDECOWWALL.length + MODCALDHOTWIND.length + MODCALDCAMAP.length].tipologiaId">
                            selezione obbligatoria
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
