import { Component, Input, OnInit } from '@angular/core';
import { ServizioPassword } from 'src/app/modules/classes/servizi/servizioPassword';
import { ServizioPasswordService } from 'src/app/services/servizio-password/servizio-password.service';

@Component({
  selector: 'sh-servizio-password',
  templateUrl: './servizio-password.component.html',
  styleUrls: ['./servizio-password.component.scss']
})
export class ServizioPasswordComponent implements OnInit {

  @Input()
  form:any;

  @Input()
  productColor: string;

  constructor(
    private servizioPasswordService: ServizioPasswordService
  ) { }

  ngOnInit(): void {
    this.servizioPasswordService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe((res: ServizioPassword) => {
      if(res[0] && res[0].id){
        this.form.sections.servizioPassword = res[0];
      }
    })
  }

}
