import { OptionsAvailableService } from './../../../../../services/optionsAvailable/options-available.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SanitariService } from '../services/sanitari/sanitari.service';
import { NuoviSanDTO } from '../classes/dati-tecnici';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-nuovi-sanitari',
  templateUrl: './form-nuovi-sanitari.component.html',
  styleUrls: ['./form-nuovi-sanitari.component.scss']
})
export class FormNuoviSanitariComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];
	@Input() mobileAttivo: boolean;
	@Output('update') change: EventEmitter<any> = new EventEmitter<any>();
	@Input() brand:any;

	availableModels: any[] = [];
	avaModel: any[] = [];
	toFilterModel: any[] = [];
	toFilterScarico: any[] = [];
	avaScarico: any[] = [];
	toFilterSanitari: any[] = [];
	avaSanitari: any[] = [];
	toFilterTipo: any[] = [];
	avaTipo: any[] = [];
	toFilterCode: any[] = [];
	avaCode: any[] = [];
	tipiVasche: Trascodifica[];
	tipiCol: Trascodifica[];
	vascaMob: Trascodifica[];
	options: Trascodifica[];
	modelloMobile: Trascodifica[] = [];
	misuraMobile: Trascodifica[] = [];
	baseMobile: Trascodifica[] = [];
	cassettiMobile: Trascodifica[] = [];
	tipoLavaboMobile: Trascodifica[] = [];

  	optionNuoviSanitariOmaggio: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  	listOptionsOrder: OptionsAvailable[];

	isVisible:boolean  = false;
	collapse:boolean=false;
	panelOpenState:boolean=false;

	constructor(
		public sanitariService: SanitariService,
		public formController: FormControllerService,
		private optionsService:OptionsAvailableService
	) {
	}

  ngOnInit(): void {
	
	this.options = this.formController.getTrascodificaByCodice( constants.TYPE_OPTIONS, this.trascodifiche).filter(op => op.descrizione === constants.TYPE_NUOVI_SANITARI);
    this.getOption();

    this.form.sections.nuoviSanitariDto = [];
	this.sanitariService.findByKey(this.form.idOrder , 'idordineId.equals=').subscribe(res => {
		this.formController.mappingSanitariDTOtoForm(res[0], this.form.sections.nuoviProdotti);
	    this.tipiVasche = this.formController.getTrascodificaByCodice(constants.COD_NUM_VASCHE_MOB , this.trascodifiche);
	    this.vascaMob = this.formController.getTrascodificaByCodice(constants.COD_VASCAMOB , this.trascodifiche);
		this.tipiCol = this.formController.getTrascodificaByCodice(constants.COD_TIPO_COLONNA_MOB , this.trascodifiche);
		this.modelloMobile = this.formController.getTrascodificaByCodice(constants.COD_MODELLO_MOBILE, this.trascodifiche);

		if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione ||
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore ||
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche ||
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile ||
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId
		) {
			this.toggleAttivo(false);

			let modelloMobileId = this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId;

			if(modelloMobileId){
				this.misuraMobile = this.trascodifiche.filter(
					(tr) => tr.codice === constants.COD_MISURA_MOBILE && tr.parentId === modelloMobileId
				);
			
				this.baseMobile = this.trascodifiche.filter(
					(tr) => tr.codice === constants.COD_BASE_MOBILE && tr.parentId === modelloMobileId
				);
			
				this.cassettiMobile = this.trascodifiche.filter(
					(tr) => tr.codice === constants.COD_CASSETTI_MOBILE && tr.parentId === modelloMobileId
				);
			
				this.tipoLavaboMobile = this.trascodifiche.filter(
					(tr) => tr.codice === constants.COD_TIPOLOGIA_LAVABO && tr.parentId === modelloMobileId
				);

				this.changeMisura()
			}
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = false;
		}else{
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = true;
		}
	  });
	
	this.sanitariService.getNuoviSan(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
		if (res[0]) {
		    this.form.sections.nuoviSanitariDto = res;
		    for(let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
			    this.avaModel.push(this.formController.getTrascodificaByCodice(constants.COD_MODELLO_SANITARI , this.trascodifiche));
				this.avaScarico.push(this.trascodifiche.filter(tr => (tr.codice === constants.COD_TIPO_SCARICO) && (tr.parentId === this.form.sections.nuoviSanitariDto[i].modsanitariId)));
			    this.avaSanitari.push(this.trascodifiche.filter(tr => (tr.codice === constants.COD_TIPO_NUOVI_SANITARI) && (tr.parentId === this.form.sections.nuoviSanitariDto[i].tiposcaricoId)));
			    this.avaTipo.push(this.trascodifiche.filter(tr => (tr.codice === constants.COD_TIPO_PRODOTTO) && (tr.parentId === this.form.sections.nuoviSanitariDto[i].tiposanitariId)));
		    }
		}
	});
  }

  changeMobileModel(event: any): void {
    	// Aggiorna i vettori in base al modello selezionato
    	this.misuraMobile = this.trascodifiche.filter(
        	(tr) => tr.codice === constants.COD_MISURA_MOBILE && tr.parentId === event
    	);

    	this.baseMobile = this.trascodifiche.filter(
        	(tr) => tr.codice === constants.COD_BASE_MOBILE && tr.parentId === event
    	);

    	this.cassettiMobile = this.trascodifiche.filter(
        	(tr) => tr.codice === constants.COD_CASSETTI_MOBILE && tr.parentId === event
    	);

    	this.tipoLavaboMobile = this.trascodifiche.filter(
        	(tr) => tr.codice === constants.COD_TIPOLOGIA_LAVABO && tr.parentId === event
    	);

    	this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId = null;
    	this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.baseMobileId = null;
    	this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.cassettiMobileId = null;
    	this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.tipoLavaboMobileId = null;
	}


	changeMisura(): void {
		
		if(this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId && this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId ){
			let element: Trascodifica = this.trascodifiche.find(
				(tr) => tr.id === this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId
			);

      		let elementMisura: Trascodifica = this.trascodifiche.find(
        		(tr) => tr.id === this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.misuraMobileId);

			if(element && element.descrizione == constants.DESC_MOBILE_TAVOLONE && elementMisura.descrizione == constants.DESC_MISURA_FILTER_TAVOLONE){
					this.tipoLavaboMobile = this.trascodifiche.filter(
						(tr) => (
							tr.codice === constants.COD_TIPOLOGIA_LAVABO && 
							tr.parentId === this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId && 
							tr.descrizione == constants.DESC_BIANCO_TONDO_40
						)
					);
			}else{
        this.tipoLavaboMobile = this.trascodifiche.filter(
          (tr) => tr.codice === constants.COD_TIPOLOGIA_LAVABO && tr.parentId == this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.modelloMobileId
      );
      }
	 }
	}


  changeModel(event, i): void { 
	this.avaScarico[i] =  this.trascodifiche.filter( tr => (tr.codice === constants.COD_TIPO_SCARICO) && (tr.parentId === event));
	  delete this.form.sections.nuoviSanitariDto[i].tiposcaricoId;
	  delete this.form.sections.nuoviSanitariDto[i].tiposanitariId;
	  delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
  }

  changeScarico(event, i): void {
	this.avaSanitari[i] = this.trascodifiche.filter(tr => (tr.codice === constants.COD_TIPO_NUOVI_SANITARI) && (tr.parentId === event));
	  delete this.form.sections.nuoviSanitariDto[i].tiposanitariId;
	  delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
  }

	changeSanitari(event, i): void {
		delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
		this.avaTipo[i] = this.trascodifiche.filter(tr => (tr.codice === constants.COD_TIPO_PRODOTTO) && (tr.parentId === event));
	}

	changeColonne(): void {
		this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili = null;
	}

	addSan(): void{
  	    this.form.sections.nuoviSanitariDto.push(new NuoviSanDTO());
		this.avaModel.push(this.formController.getTrascodificaByCodice(constants.COD_MODELLO_SANITARI , this.trascodifiche));
		this.avaScarico.push(this.trascodifiche.filter(tr => (tr.codice === constants.COD_TIPO_SCARICO)));
		this.avaSanitari.push(this.trascodifiche.filter(tr => (tr.codice === constants.COD_TIPO_NUOVI_SANITARI)));
		this.avaTipo.push(this.trascodifiche.filter(tr => (tr.codice ===  constants.COD_TIPO_PRODOTTO)));
	}
	
	removeSan(i): void {
		if (this.form.sections.nuoviSanitariDto[i].id) {
			this.sanitariService.deleteNuoviSan(this.form.sections.nuoviSanitariDto[i].id).subscribe();
		}
		this.form.sections.nuoviSanitariDto.splice(i, 1);
		this.avaModel.splice(i, 1);
		this.avaScarico.splice(i, 1);
		this.avaSanitari.splice(i, 1);
		this.avaTipo.splice(i, 1);
	}
	
	validNuoviSan(): boolean {
		let valid = true;
		if (this.form.sections.nuoviSanitariDto.length > 0) {
			for (let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
				if (valid) {
					if (this.form.sections.nuoviSanitariDto[i].modsanitariId &&
						this.form.sections.nuoviSanitariDto[i].tiposcaricoId &&
						this.form.sections.nuoviSanitariDto[i].tiposanitariId &&
						this.form.sections.nuoviSanitariDto[i].tipoprodottoId
					) {
						valid = true;
					} else {
						valid = false;
					}
				}
			}
		}
		return valid;
	}
	
	toggleAttivo(event): void {
		this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = event;
		this.change.emit(event);
	}

	showDiv(value){    
		if(value ){
		  this.isVisible = true;
		}else{
		  this.isVisible = false;
		}
	   this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0]?.id)[0].selected = value;
	  }
	
	  getOption(){
		this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
			if(res !== undefined){
			  this.listOptionsOrder = res;
			  let optionNuoviSanitariOmaggio = res.filter(op => op.typeId === this.options[0].id)  
			  if(optionNuoviSanitariOmaggio !== undefined && optionNuoviSanitariOmaggio.length > 0){
				this.isVisible = optionNuoviSanitariOmaggio[0].selected;
				this.form.sections.optionsAvailable=res;				
				this.optionNuoviSanitariOmaggio = optionNuoviSanitariOmaggio[0];
			  }else{
				this.isVisible = false;
				this.optionNuoviSanitariOmaggio = new OptionsAvailable();
				this.optionNuoviSanitariOmaggio.ordineId = this.form.idOrder;
				this.optionNuoviSanitariOmaggio.typeId = this.options[0].id;
				this.optionNuoviSanitariOmaggio.selected = undefined;
				this.form.sections.optionsAvailable.push(this.optionNuoviSanitariOmaggio);
			  }
			}
		});
	  }
}
