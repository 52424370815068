import { environment } from "../../../../../../../environments/environment";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { PromoService } from '../../services/promo.service';
import { OmaggioService } from '../../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { InstallazioneAccessoriService } from '../../services/installazione-accessori.service';
import { PrintComponent } from 'src/app/modules/shared/pdf/print/print.component';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { BrandService } from 'src/app/services/brand/brand.service';
import { MatDialog } from "@angular/material/dialog";
import { AnagraficaService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { Router } from '@angular/router';
import { PreviewComponent } from 'src/app/modules/shared/pdf/preview/preview.component';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { AbitazioneService } from '../../services/abitazione.service';
import { DettaglioService } from '../../services/dettaglio.service';
import { HttpService } from "src/app/services/http.service";
import { AnagraficaDTO } from "src/app/modules/classes/anagrafica";
import { OmaggioDto } from "src/app/modules/classes/omaggio-dto";
import { constants } from "src/app/modules/classes/constants";
declare var jQuery: any;

@Component({
	selector: 'app-print-cond',
	templateUrl: './print-cond.component.html',
	styleUrls: ['./print-cond.component.scss']
})
export class PrintCondComponent extends PrintComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];
	@Input() modale: boolean;
	@Output("reload") reload: EventEmitter<any> = new EventEmitter<any>();

	idOrdine: number;
	desiredWidth: number;
	proportionalHeight: number;
	timeLeft: number = 60;

	validAnagrafica: boolean = false;
	generatingPdf: boolean = false;
	loadAll: boolean = false;
	showFrame: boolean = false;
	sendingPdf: boolean = false;
	openSign: boolean = false;
	showModalPrint: boolean = false;
	isLine: boolean = false;

	urlFrame: string = "";
	title: string = "";
	noteAccessori: string = "";
	noteCondizionatore: string = "";
	privacyText: string = "";
	colorString: string = "#008000";

	anagrafica: AnagraficaDTO;

	opzioni: any;
	error: any;
	member: any;
	options: any;
	abitazione: any;
	installazioneAcc: any;
	disegno: any;
	promoClimaDesc: any;

	tipologie: Trascodifica[] = [];
	promozioni: Trascodifica[] = [];
	availablePromo: Trascodifica[] = [];
	modelliInverter: Trascodifica[] = [];
	omaggio: OmaggioDto[];
	rgbColorLine: number[] = [0, 128, 0];
	integratedSignature = environment.integratedSignature;
	today = new Date().toLocaleDateString("fr-FR");
	isApiV3 = environment.isV3;

	constructor(
		private router: Router,
		public dialog: MatDialog,
		private paymentService: PaymentService,
		public formController: FormControllerService,
		private orderService: OrdersService,
		private brandService: BrandService,
		private abitazioneService: AbitazioneService,
		private anagraficaService: AnagraficaService,
		private dettaglioService: DettaglioService,
		private omaggioService: OmaggioService,
		private httpService: HttpService,
		private promoServive: PromoService,
		private installazioneAccService: InstallazioneAccessoriService,
		public printService: PrintserviceService,
	) {
		super(printService);
	}

	ngOnInit(): void {
		this.idOrdine = this.form.idOrder;
		this.color = this.rgbColorLine
		this.getOrder();
	}

	getOrder(): void {
		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
			this.ordine = res;
			this.form.ordine = res;
			this.brandService
				.getDataByProduct(Number(localStorage.getItem("id_product")))
				.subscribe((resp: any) => {
					this.trascodifiche = resp;
					this.getAnagrafica();
					this.getPromozioni();
					this.getPagamento();
				});
		});
		this.loadAll = true;
	}

	private generatePdf(firma: boolean): void {
		let y = this.initPdf(this.ordine, this.anagrafica, "/assets/img/loghi/ARIEL.jpg", "Modulo d'ordine condizionatori Ariel", this.colorString);

		this.printService.writeFooterPage(this.doc, this.ordine, this.currentPdfPage, 6);

		let tableTitle = '';
		const subColumn = ['MODELLO', "QUANTITA'", 'PROMOZIONE'];

		if (this.promozioni) {
			tableTitle = 'CONDIZIONATORE';
			this.genericCondizionatoreProductTable(this.doc, this.promozioni, tableTitle, subColumn, this.printService.returnCurrentY(), this.colorString, this.tipologie, this.modelliInverter, this.promoClimaDesc);
		}
		if (this.noteCondizionatore) {
			const title = [constants.LABEL_PRINT_NOTE]
			if (this.noteCondizionatore && this.noteCondizionatore !== '') {
				this.createTableTextNote(title, this.noteCondizionatore, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
			}
		}

		if (this.abitazione) {

			if (this.disegno) {
				const tableTitle = 'DISEGNO TECNICO:'
				this.genericPrintImages(this.doc, this.printService.returnCurrentY(), this.disegno, null, tableTitle, null, this.desiredWidth, this.proportionalHeight, this.colorString, this.color);
			}

			tableTitle = constants.LABEL_PRINT_DATI_ABITAZIONE;
			this.genericCondizionatoreDatiAbitazioneTable(this.doc, this.abitazione, this.printService.returnCurrentY(), tableTitle, this.colorString);
		}

		if (this.installazioneAcc) {
			tableTitle = 'DETTAGLI INSTALLAZIONE';
			this.genericDettagliInstallazione(this.doc, this.installazioneAcc, this.printService.returnCurrentY(), tableTitle, this.colorString);
			tableTitle = 'ACCESSORI';
			this.genericAccessoriCond(this.doc, this.installazioneAcc, this.printService.returnCurrentY(), tableTitle, this.colorString);
			const title = [constants.LABEL_PRINT_NOTE]

			if (this.noteAccessori && this.noteAccessori.trim() !== '') {
				this.createTableTextNote(title, this.noteAccessori, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
			}
		}
		if (this.omaggio.length > 0) {
			this.genericOmaggio(this.doc, this.omaggio, this.printService.returnCurrentY(), this.colorString);
		}
		if (this.pagamento) {
			this.createTablePagamento(this.doc, this.pagamento, this.ordine, this.printService.returnCurrentY(), 0, this.colorString);
		}

		this.printPrivacyInformation(y);

		this.creatingPdf = false;
		if (firma) {
			if (!this.integratedSignature) {
				this.createPdf(this.doc);
			} else {
				this.toggleSignPdf(this.doc);
			}
		} else {
			const pdfBlob = this.doc.output("blob");

			// Crea un URL dall'oggetto Blob
			const pdfUrl = URL.createObjectURL(pdfBlob);
			this.openDialog(pdfUrl);
		}
	}

	// Print method	

	private printPrivacyInformation(y: number): number {

		this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, this.title)
		y = this.printService.returnCurrentY();
		this.printService.writePrivacyOne(
			this.doc,
			y,
			this.ordine,
			this.img,
			this.title,
			this.currentPdfPage,
			this.privacyText
		);
		return y;
	}

	// Http
	private getAbitazione() {
		this.abitazioneService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.abitazione = res[0];
			this.getDisegno(res[0]);
		});
		this.getDettaglioNoteCondizionatore();
	}

	private getAnagrafica(): void {
		this.anagraficaService
			.findById(this.ordine.clienteId)
			.subscribe((res) => {
				this.anagrafica = res;
				this.checkAnagrafica();
			});
		this.getAbitazione();
	}

	private getPagamento(): void {
		this.paymentService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.pagamento = res;
		});
		this.getInstallazioneAcc();
		this.getPrivacy();
	}

	private getDettaglioNoteCondizionatore(): void { //Note 1 - Menu Tab 2
		this.dettaglioService.findByKey(this.idOrdine, 'ordineId.equals=')
			.subscribe((res) => {
				if (res)
					this.noteCondizionatore = res[0]?.note;
			})
	}

	private getPromozioni(): void {
		this.promoServive.getCondizionatori(this.idOrdine, 'ordineId.equals=')
			.subscribe((res) => {
				this.promozioni = res;
			})
		this.getModelliInverter();
		this.getPromoDesc();
		this.getOmaggio();
	}

	private getTipologie(): void {
		this.tipologie = [];
		this.formController.getTrascodificaByCodicPromise(constants.COD_PROMO_CLIMA_RAGGR, this.trascodifiche).then(data => {
			this.tipologie = data;
		});
	}

	private getPromoDesc(): void {
		this.tipologie = [];
		this.formController.getTrascodificaByCodicPromise(constants.COD_PROMO_CLIMA_DESCR, this.trascodifiche).then(data => {
			this.promoClimaDesc = data;
		});
	}

	private getModelliInverter(): void {
		this.modelliInverter = [];
		this.formController.getTrascodificaByCodicPromise(constants.COD_MODELLO_INVERTER, this.trascodifiche).then(data => {
			this.modelliInverter = data;
		});
		this.getTipologie();
	}

	private getOmaggio(): void {
		this.omaggioService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
			this.omaggio = res;
		})
	}

	private getInstallazioneAcc(): void {
		this.installazioneAccService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
			if (res !== undefined && res[0] !== undefined) {
				this.installazioneAcc = res[0];
				this.noteAccessori = res[0].note;
			}
		});
	}

	private getPrivacy(): void {
		this.httpService.getPrivacy('assets/json/condizionatore.json').subscribe((res) => {
			this.privacyText = res;
		});
	}

	// Others method
	private openDialog(pdf: any): void {
		this.dialog.open(PreviewComponent, {
			width: "800px",
			maxWidth: "100%",
			data: {
				pdf,
			},
		});
	}

	private getDisegno(obj): void {
		this.disegno = "data:" + obj.disegnotecnicoContentType + ";base64," + obj.disegnotecnico;

		const img = new Image();
		img.src = this.disegno;
		img.onload = () => {
			const originalWidth = img.width;
			const originalHeight = img.height;

			this.desiredWidth = 70; // Larghezza desiderata in unità del PDF
			this.proportionalHeight =
				(this.desiredWidth * originalHeight) / originalWidth;
		};
	}

	private checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	private createPdf(pdf): void {
		this.sendingPdf = true;
		this.openSign = true;
		const title = localStorage.getItem("id_order");
		pdf.setProperties({
			title,
		});

		const newPdf = btoa(pdf.output());
		const pages = this.printService.returnCurrentPage();
		const coordinates = this.printService.returnCoordinates();
		this.formController
			.sendSignature(
				newPdf,
				this.idOrdine,
				this.form,
				pages,
				coordinates,
				title,
				false
			)
			.then(
				(res) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.router.navigate(["order-list"]);
				},
				(error) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.ordine.statopraticaCodice = "A";
					this.error = error;
					jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
					jQuery("#errorModal").modal("toggle");
				}
			);
	}

	returnIdOrdine(): string {
		return localStorage.getItem("id_order");
	}

	returnInverterMod(id): string {
		const index = this.modelliInverter.findIndex(r => r.id === id);
		return this.modelliInverter[index]?.descrizione;
	}

	togglePrintPreview(): void {
		this.generatePdf(false);
	}

	toggleSignPdf(pdf): void {
		const interval = this.startTimer();
		const title = localStorage.getItem("id_order");
		const pages = this.printService.returnCurrentPage();
		this.showModalPrint = !this.showModalPrint;
		this.openSign = true;
		this.showFrame = false;
		this.generatingPdf = true;
		this.urlFrame = '';
		jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
		jQuery("#signModal").modal("toggle");

		if (this.isApiV3) {
			const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
			var split = this.printService.returnCoordinates().split(",");
			const coordinates = {
				width: 120,
				height: 37,
				x: split[0],
				y: split[3],
			}
			this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
				this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
				setTimeout(() => {
					this.showFrame = true;
					this.sendingPdf = false;
					this.generatingPdf = false;

				}, 2000);
				clearInterval(interval);
			}, error => {
				this.generatingPdf = false;
				this.sendingPdf = false;
				this.ordine.statopraticaCodice = "A";
				clearInterval(interval);
				this.closeSignPdf();
				jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#errorModal").modal("toggle");
			});
		} else {
			const newPdf = btoa(pdf.output());
			const coordinates = this.printService.returnCoordinates();
			this.formController
				.sendSignature(
					newPdf,
					this.form.idOrder,
					this.form,
					pages,
					coordinates,
					title,
					true
				)
				.then(
					(res) => {
						this.member = res.members[0].id;
						this.urlFrame =
							environment.apiUrl +
							"/procedure/sign?members=" +
							this.member +
							"&signatureUi=" +
							environment.signAriel; // serve per recuperare il modello del prodotto corretto. In modo che il frame vega visualizzato con i colori corretti.
						this.showFrame = true;
						this.sendingPdf = false;
						this.generatingPdf = false;

						clearInterval(interval);
						// this.router.navigate(['order-list']);
					},
					(error) => {
						this.generatingPdf = false;
						this.sendingPdf = false;
						this.ordine.statopraticaCodice = "A";
						clearInterval(interval);
						jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
						jQuery("#errorModal").modal("toggle");
					}
				);
		}
	}

	public closeSignPdf(): void {
		jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#signModal').modal('toggle');
		this.reload.emit(this.openSign);
		this.setStateToActive();
		this.formController.removeFrame();
	}

	private setStateToActive() {

		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
			this.ordine = res;
			this.ordine.statopraticaCodice = "A";
			this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
				this.getOrder();
			});
		})
	}

	startTimer(): any {
		const intervalId = setInterval(() => {
			if (this.timeLeft === 0) {
				clearInterval(intervalId);
				// richiamo api per invio email
				this.orderService.sendEmail(this.form.idOrder).subscribe();
				jQuery("#timeoutModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#timeoutModal").modal("toggle");

				setTimeout(() => {
					jQuery("#timeoutModal").modal("hide");
				}, 10000);

				setTimeout(() => {
					this.formController.goToDashboard();
				}, 11000);
			}
			if (this.timeLeft > 0) {
				this.timeLeft--;
			}
		}, 1000);
		return intervalId;
	}
}