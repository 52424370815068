<div class="pt-5 text-center mb-4">
</div>

<div class="text-center mb-4">
    <h4>SELEZIONA IL PRODOTTO</h4>
</div>

<div class="row text-center" *ngIf="products">
    <div *ngFor="let product of products" class="col-2">
        <div class="singleBrand" (click)="goToProduct(product.path)">
            {{product.name}}
        </div>
    </div>
</div>
