import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';
import { FormControllerService } from '../../../../services/controller/form-controller.service';
import { PaymentService } from '../../../../services/payment/payment.service';
import { Trascodifica } from '../../../classes/trascodifica';

@Component({
    selector: 'app-pay-one',
    templateUrl: './pay-one.component.html',
    styleUrls: ['./pay-one.component.scss']
})
export class PayOneComponent implements OnInit {
    
    @Input() form: any;
    @Input() brand: string;
    @Input() trascodifiche: Trascodifica[];
    @Input() prodotto: string;
    @Input() stepPagamento: number = 0;

    @Output() isValid: any = new EventEmitter<boolean>();

    tipiIva: Trascodifica[];
    tipiCess: Trascodifica[];
    tipiPagamento: Trascodifica[];
    tipiSaldo: Trascodifica[];
    tipiDetrazione: Trascodifica[];

    typeSaldo: any;

    public constants = constants;

    constructor(
        private formController: FormControllerService, 
        private paymentService: PaymentService
    ) { }

    ngOnInit(): void {

        this.paymentService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
            //this.form.sections.pagamento.ordineId = this.form.idOrder;
            if (res[this.stepPagamento]) {
                this.form.sections.pagamento[this.stepPagamento] = res[this.stepPagamento];
            }
            if (this.brand === 'bagniHelp75' && this.stepPagamento === 0) {
                this.tipiIva = this.formController.getTrascodificaByCodice(constants.COD_PERCIVA_STEP0, this.trascodifiche);
                this.tipiIva.sort(this.formController.generateSortFn('descrizione', false));
                if (this.tipiIva.length === 1) {
                    this.form.sections.pagamento[this.stepPagamento].percivaId = this.tipiIva[0].id;
                }
                //this.tipiCess = this.formController.getTrascodificaByCodice('constants.COD_CESSIONE_CREDITO_PERCIVA , this.trascodifiche);
                this.tipiPagamento = this.formController.getTrascodificaByCodice(constants.COD_TIPOPAGCAP_STEP0, this.trascodifiche);
                this.tipiSaldo = this.formController.getTrascodificaByCodice(constants.COD_TIPOPAGSALDO_STEP0, this.trascodifiche);
                this.tipiDetrazione = this.formController.getTrascodificaByCodice(constants.TYPE_TIPODETRAZIONE0, this.trascodifiche);

            } else {
                this.tipiIva = this.formController.getTrascodificaByCodice(constants.COD_PERCIVA, this.trascodifiche);
                this.tipiIva.sort(this.formController.generateSortFn('descrizione', false));
                //this.tipiCess = this.formController.getTrascodificaByCodice('constants.COD_CESSIONE_CREDITO_PERCIVA , this.trascodifiche);
                this.tipiPagamento = this.formController.getTrascodificaByCodice(constants.COD_TIPOPAGCAP, this.trascodifiche);
                this.tipiSaldo = this.formController.getTrascodificaByCodice(constants.COD_TIPOPAGSALDO, this.trascodifiche);
                this.tipiDetrazione = this.formController.getTrascodificaByCodice(constants.TYPE_TIPODETRAZIONE, this.trascodifiche);
            }

            this.totaleOrdineTofix(this.form.sections.pagamento[this.stepPagamento]?.totaleordine);
            this.totaleDovutoTofix(this.form.sections.pagamento[this.stepPagamento]?.totaledovuto);
            this.CaparraTofix(this.form.sections.pagamento[this.stepPagamento]?.caparraconf);
            this.SaldoTofix(this.form.sections.pagamento[this.stepPagamento]?.saldoinizlav);

        })

    }

    totaleOrdineTofix(totale): void {
        let totNum: number;
        if (totale !== undefined && totale !== null) {
            totNum = Number(totale);
            this.form.sections.pagamento[this.stepPagamento].totaleordine = totNum.toFixed(2);
        } else {
            delete this.form.sections.pagamento[this.stepPagamento].totaleordine;
        }
    }

    totaleDovutoTofix(event): void {
        if (event) {
            this.form.sections.pagamento[this.stepPagamento].totaledovuto = (parseFloat(event).toFixed(2));
        } else {
            delete this.form.sections.pagamento[this.stepPagamento].totaledovuto;
        }
    }

    CaparraTofix(event): void {
        if (event || event === 0) {
            this.form.sections.pagamento[this.stepPagamento].caparraconf = (parseFloat(event).toFixed(2));
        } else {
            delete this.form.sections.pagamento[this.stepPagamento].caparraconf;
        }
    }

    SaldoTofix(event): void {
        if (event || event === 0) {
            this.form.sections.pagamento[this.stepPagamento].saldoinizlav = (parseFloat(event).toFixed(2));
        } else {
            delete this.form.sections.pagamento[this.stepPagamento].saldoinizlav;
        }
    }

    calcTotaleDovuto(totale, event): void {
        if (event !== undefined && event !== null) {
            const idIva = event;
            const iva: Trascodifica[] = this.tipiIva.filter(tr => tr.id === idIva);
            let value: number;
            if (iva[0].descrizione === '10 %') {
                value = 10;
            } else if (iva[0].descrizione === '22 %') {
                value = 22;
            } else if (iva[0].descrizione === '4 %') {
                value = 4;
            }

            let ivaVal: number = (totale * value) / 100;
            let totDovuto: number = Number(totale) + ivaVal;
            this.form.sections.pagamento[this.stepPagamento].totaledovuto = totDovuto.toFixed(2);
        } else {
            if (this.form.sections.pagamento[this.stepPagamento].tipodetrazioneId) {
                let detSelect = this.tipiDetrazione.filter(d => d.id === this.form.sections.pagamento[this.stepPagamento].tipodetrazioneId);
                this.changeCalcolo(detSelect[0]);
            } else {
                this.changeCalcolo(undefined);
            }
        }
        this.calcSaldo(this.form.sections.pagamento[this.stepPagamento].totaledovuto, this.form.sections.pagamento[this.stepPagamento].caparraconf);
    }

    calcTotaleDaDovuto(event): void {
        if (event !== undefined && event !== null) {
            const idIva = this.form.sections.pagamento[this.stepPagamento].percivaId;
            const iva: Trascodifica[] = this.tipiIva.filter(tr => tr.id === idIva);
            let value: number;
            if (iva[0].descrizione === '10 %') {
                value = 10;
            } else if (iva[0].descrizione === '22 %') {
                value = 22;
            } else if (iva[0].descrizione === '4 %') {
                value = 4;
            }

            let ivaVal: number = (event * value) / 100;
            let totDovuto: number = Number(event) - ivaVal;
            this.form.sections.pagamento[this.stepPagamento].totaleordine = totDovuto.toFixed(2);
        } else {
            this.form.sections.pagamento[this.stepPagamento].totaleordine = Number(event).toFixed(2);
        }
    }

    calcSaldo(totaleDov, caparra): void {
        let saldo: number = Number(totaleDov) - Number(caparra);
        this.form.sections.pagamento[this.stepPagamento].saldoinizlav = (saldo.toFixed(2));
    }

    /*cessioneCredito(event): void {
        this.form.sections.pagamento[this.stepPagamento].cessionecredito = event.checked;
        if (this.form.sections.pagamento[this.stepPagamento].cessionecredito) {
            if (this.brand === 'fotovoltaicoAriel') {
                this.calcTotDov(Number(50));
            }/* else {
                this.calcTotDov(Number(50));
            }
            //this.form.sections.pagamento[this.stepPagamento].scontoinfatt65 = false;
            this.form.sections.pagamento[this.stepPagamento].detrazione = false;
            this.form.sections.pagamento[this.stepPagamento].nodetrazione = false;
        } else {
            this.form.sections.pagamento[this.stepPagamento].totaledovuto = this.form.sections.pagamento[this.stepPagamento].totaleordine;
            if (this.form.sections.pagamento[this.stepPagamento].caparraconf !== undefined) {
                this.calcSaldo(this.form.sections.pagamento[this.stepPagamento].totaledovuto, this.form.sections.pagamento[this.stepPagamento].caparraconf);
            }
        }
    }*/

    scontoinfatt(event): void {
        this.form.sections.pagamento[this.stepPagamento].scontoinfatt65 = event.checked;
        if (this.form.sections.pagamento[this.stepPagamento].scontoinfatt65) {
            this.calcTotDov(Number(65));
            this.form.sections.pagamento[this.stepPagamento].cessionecredito = false;
        } else {
            this.form.sections.pagamento[this.stepPagamento].totaledovuto = this.form.sections.pagamento[this.stepPagamento].totaleordine;
            if (this.form.sections.pagamento[this.stepPagamento].caparraconf !== undefined) {
                this.calcSaldo(this.form.sections.pagamento[this.stepPagamento].totaledovuto, this.form.sections.pagamento[this.stepPagamento].caparraconf);
            }
        }
    }

    detrazione(event): void {
        this.form.sections.pagamento[this.stepPagamento].detrazione = event.checked;
        if (this.form.sections.pagamento[this.stepPagamento].detrazione) {
            if (this.brand === 'fotovoltaicoAriel') {
                this.calcSaldo(this.form.sections.pagamento[this.stepPagamento].totaledovuto, this.form.sections.pagamento[this.stepPagamento].caparraconf);
            }

            this.form.sections.pagamento[this.stepPagamento].cessionecredito = false;
            this.form.sections.pagamento[this.stepPagamento].nodetrazione = false;

        }
    }

    nodetrazione(event): void {
        this.form.sections.pagamento[this.stepPagamento].nodetrazione = event.checked;
        if (this.form.sections.pagamento[this.stepPagamento].nodetrazione) {
            if (this.brand === 'fotovoltaicoAriel') {
                this.calcSaldo(this.form.sections.pagamento[this.stepPagamento].totaledovuto, this.form.sections.pagamento[this.stepPagamento].caparraconf);
            }

            this.form.sections.pagamento[this.stepPagamento].cessionecredito = false;
            this.form.sections.pagamento[this.stepPagamento].detrazione = false;
        }
    }

    calcTotDov(valueSconto: number) {
        let tmpTotCliente = Number(this.form.sections.pagamento[this.stepPagamento].totaleordine) * valueSconto;
        let sconto: number = Number(tmpTotCliente) / 100;
        let totDovuto: number = Number(this.form.sections.pagamento[this.stepPagamento].totaleordine) - sconto;
        this.form.sections.pagamento[this.stepPagamento].totaledovuto = totDovuto.toFixed(2);
        if (this.form.sections.pagamento[this.stepPagamento].caparraconf !== undefined) {
            this.calcSaldo(this.form.sections.pagamento[this.stepPagamento].totaledovuto, this.form.sections.pagamento[this.stepPagamento].caparraconf);
        }
    }

    checkType(saldo: any) {
        this.typeSaldo = saldo;
        if (this.typeSaldo?.descrizione != constants.DESC_CENTAX || this.typeSaldo?.codice != constants.COD_TIPOPAGSALDO) {

            this.form.sections.pagamento.accettazrich = null;
        }
    }

    checkDetrazione(event) {
        let detrSelected = this.tipiDetrazione.filter(d => d.id === event.value);
        this.changeCalcolo(detrSelected[0]);
    }

    changeCalcolo(detSelect: Trascodifica) {
        if (detSelect !== undefined) {
            /*if (detSelect.descrizione === constants.TIPODETRAZIONE_CESS_50)
                this.calcTotDov(Number(50));
            else if (detSelect.descrizione === constants.TIPODETRAZIONE_CESS_65)
                this.calcTotDov(Number(65));
            else if (detSelect.descrizione === constants.TIPODETRAZIONE_CESS_75)
                this.calcTotDov(Number(75));
            else if (detSelect.descrizione === constants.TIPODETRAZIONE_50_PRIMA_CASA)
                this.calcTotDov(Number(50));
            else if (detSelect.descrizione === constants.TIPODETRAZIONE_36_SECONDA_CASA) 
                this.calcTotDov(Number(36));
            else if(detSelect.descrizione ===  constants.TIPODETRAZIONE_36){
                this.calcTotDov(Number(36));
            }
            else if (detSelect.descrizione === constants.TIPODETRAZIONE_75) 
                this.calcTotDov(Number(75));
            else*/
                this.calcTotDov(Number(0));
        } else
            this.calcTotDov(Number(0));
    }

    isCaparraConfIsValid(stepPagamento: number, brand: string): boolean {
        const caparraConf = this.form.sections.pagamento[stepPagamento]?.caparraconf;
        const totalDovuto = this.form.sections.pagamento[stepPagamento]?.totaledovuto;
        if (brand === 'bagniHelp75') {
            this.isValid.emit(this.formController.isCaparraConfIsValid(totalDovuto, caparraConf));
            return this.formController.isCaparraConfIsValid(totalDovuto, caparraConf);
        }
    }
}
