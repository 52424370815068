import { Component, Input, OnInit } from '@angular/core';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { UserService } from '../../../services/user/user.service';
import { AnagraficaService } from '../../seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { MainComponent } from '../../../main/main.component';
import { OrdersService } from '../../../services/orders/orders.service';
import { constants } from '../../classes/constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-anagrafica-sh',
  templateUrl: './anagrafica-sh.component.html',
  styleUrls: ['./anagrafica-sh.component.scss']
})
export class AnagraficaShComponent implements OnInit {
  
  @Input() form: any;
  @Input() brand: any;
  @Input() validAnagrafica: any;
  @Input() moduloServizi: boolean = false;
  @Input() productColor: string;
  @Input() readOnly: boolean = false;

  selectedCFoIVA: string = 'cf';
  email:string = '';

  province: any[];
  filtered: any[];
  sito:string;
  searchTerm: string;
  minDate: Date;

	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Italy, CountryISO.France, CountryISO.Germany, CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});
  
  constructor(
      public formController: FormControllerService,
      public userService: UserService,
      private anagraficaService: AnagraficaService,
      private mainCompo: MainComponent,
      private orderService: OrdersService,
  ) {
    
    this.province = formController.returnProvince();
  }

  ngOnInit(): void {

    const today = new Date();
    this.minDate = today; 
    
    this.getAnagrafica();

    if(this.readOnly){
      this.phoneForm.get('phone')?.disable();
    }
    
    if(this.brand === 'ariel'){
		  this.sito = "www.arielenergia.it";
		  this.email = "info@arielenergia.it";
	  }else if (this.brand === 'bagni'){
		  this.sito = "www.gdlspa.it";
		  this.email = "info@gdlspa.it";		
	  }else if (this.brand === 'remail'){
		  this.sito = "www.remail.it";
		  this.email = "info@gdlspa.it";		
	  }
		this.sito = "www.arielenergia.it";
		this.email = "info@arielenergia.it";
	 }
  


  resetNewAddress(event): void{
    this.form.anagrafica.anagrafica.indirizzoinst = '';
  }

  filter(value): any{
    const str = value.toLowerCase();
    this.filtered = [];
    this.province.forEach( r => {
      if (r.nome.toLowerCase().includes(str)){
        this.filtered.push(r);
      } else {
        // do something
      }
    });
  }

  updatePhoneNumber(value: any): void {
    const phone = this.phoneForm.value.phone;
    
    if (phone && phone.e164Number) {
      // Extract the dial code and the national number
      const newDialCode = phone.dialCode.replace(/\s+/g, '')
      const nationalNumber = phone.nationalNumber.replace(/\s+/g, '');
  
      // Combine the new dial code with the national number
      const updatedPhoneNumber = newDialCode + nationalNumber;
      
      this.form.anagrafica.anagrafica.tel1 = updatedPhoneNumber;
    } else {
      this.form.anagrafica.anagrafica.tel1 = '';
    }
  }
  

  getAnagrafica(): void {
	if(this.form.anagrafica.anagrafica.id !== undefined){
		    // recupero i dati del cliente
    	if(this.form.anagrafica.anagrafica.id){
          this.anagraficaService.findById(this.form.anagrafica.anagrafica.id).subscribe(res => {
      		  this.form.anagrafica.anagrafica = res;
            if (this.form.anagrafica.anagrafica.tel1) {
              this.phoneForm.patchValue({
                phone: this.form.anagrafica.anagrafica.tel1
              });
            }
            this.selectPIVAoCF(res);
            this.formController.checkAnagrafica(this.form)
    	  });
      }
	}

    if (this.userService.returnUserAut(constants.TYPE_ROLE_SELLER) || this.userService.returnUserAut(constants.TYPE_ROLE_CUSTOMER_CARE)) {
		  if(this.mainCompo.loggedUser.lastName !== null && this.mainCompo.loggedUser.lastName !== undefined){
			  this.form.anagrafica.infoContratto.consulente =  this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
			  this.form.anagrafica.agente = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
		  }else{
			  this.form.anagrafica.infoContratto.consulente =  this.mainCompo.loggedUser.firstName;
			  this.form.anagrafica.agente = this.mainCompo.loggedUser.firstName;
		  }
    }
    if (this.userService.returnUserAut(constants.TYPE_ROLE_TECH)) {
      this.getOrdine(constants.TYPE_ROLE_TECH);
    }
  }
  
  getOrdine(role:string): void{
    this.orderService.getSingleOrder(this.form.idOrder).subscribe(res => {
      this.form.anagrafica.infoContratto.consulente = res.venditoreLogin;
      this.form.anagrafica.agente = res.venditoreLogin;
    }, error => {
      console.error('Error => ', error);
    });
  }

  selectPIVAoCF(value): void {
    if(this.isPartitaIVA(value.cf)){
      this.selectedCFoIVA = 'piva';
      this.onChangeValue(value.cf);
    }else{
      this.selectedCFoIVA = 'cf';
      this.onChangeValue(value.cf);
    }
  }

  isPartitaIVA(cf) {
    return /[a-zA-Z0-9]/.test(cf) && cf.length === 11;
  }

  onChangeValue(value: string): void {
    this.form.anagrafica.anagrafica.cf = value;
    this.formController.checkAnagrafica(this.form)
  }

  resetInput() {
    this.form.anagrafica.anagrafica[this.selectedCFoIVA] = '';
    this.form.anagrafica.anagrafica.cf = '';
    this.form.anagrafica.anagrafica.ragionesociale = null;
  }

  isValid(type: string): boolean {
    const value = this.form.anagrafica.anagrafica[type];
    if (type === 'cf') {
      return /[a-zA-Z0-9]/.test(value);
    } else if (type === 'piva') {
      return /[a-zA-Z0-9]/.test(value);
    }
    return false;
  }
  
  getValidationMessage(type: string): string {
    if (type === 'cf') {
      return 'Codice Fiscale non valido';
    } else if (type === 'piva') {
      return 'Partita IVA non valida';
    }
    return 'Campo non valido';
  }
}
