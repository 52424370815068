import { Component, Input, OnInit } from '@angular/core';
import { Responsabile } from 'src/app/modules/classes/servizi/responsabile';
import { ServizioResponsabileService } from 'src/app/services/servizio-responsabile/servizio-responsabile.service';

@Component({
  selector: 'sh-servizio-responsabile',
  templateUrl: './servizio-responsabile.component.html',
  styleUrls: ['./servizio-responsabile.component.scss']
})
export class ServizioResponsabileComponent implements OnInit {

  @Input()
  form:any;

  @Input()
  productColor: string;

  constructor(
    private servizioResponsabileService: ServizioResponsabileService
  ) { }

  ngOnInit(): void {
    this.servizioResponsabileService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe((res: Responsabile[]) => {
      this.form.sections.responsabileList = res;
      if(res.length == 0) {
        this.addResponsabile()
      }
    })
  }

  addResponsabile(): void {
    this.form.sections.responsabileList.push(new Responsabile());
  }

  checkValidElement(i:number): boolean{
      return !(this.form.sections.responsabileList[i]?.nome && this.form.sections.responsabileList[i]?.tel);
  }
  
  removeResponsabile(responsabile:any, index:number): void {
    this.form.sections.responsabileList.splice(index, 1);
  
    if(responsabile.id){
      this.form.sections.delResponsabili.push(responsabile);
    }

    if (this.form.sections.responsabileList.length === 0) {
      this.form.sections.responsabileList.push(new Responsabile());
    }
  }
}
